import {
  ProvisionServiceData,
  ServiceData,
} from '@app/modules/service-data/service-data.types';
import { ServiceDataFilters } from '@app/modules/service-data/services/service-data.service';
import { ApiHeaders } from '@core/core.types';

export const SERVICE_DATA_NAME = 'Servicedata';
export class GetServiceDataListAction {
  static type = `[${SERVICE_DATA_NAME} GetServiceDataListAction]`;

  constructor(
    public filters: ServiceDataFilters,
    public headers: ApiHeaders,
    public pageToLoad: number,
    public reload: boolean
  ) {}
}
export class SetServiceDataAction {
  static type = `[${SERVICE_DATA_NAME} SetServiceDataAction]`;

  constructor(
    public list: ServiceData[],
    public prependOnPage = false
  ) {}
}
export class GetServiceDataAction {
  static type = `[${SERVICE_DATA_NAME} GetServiceDataAction]`;

  constructor(
    public id: string,
    public prependToPage = false,
    public reload = false
  ) {}
}
export class CreateServiceDataAction {
  static type = `[${SERVICE_DATA_NAME} CreateServiceDataAction]`;

  constructor(public serviceData: ProvisionServiceData) {}
}
export class UpdateServiceDataAction {
  static type = `[${SERVICE_DATA_NAME} UpdateServiceDataAction]`;

  constructor(public serviceData: ServiceData) {}
}
export class DeleteServiceDataAction {
  static type = `[${SERVICE_DATA_NAME} DeleteServiceDataAction]`;

  constructor(public id: string) {}
}
export class TerminateServiceDataAction {
  static type = `[${SERVICE_DATA_NAME} TerminateServiceDataAction]`;

  constructor(
    public id: string,
    public reason: string
  ) {}
}
export class SetDoctorInvoiceAction {
  static type = `[${SERVICE_DATA_NAME} SetDoctorInvoiceAction]`;

  constructor(
    public id: string,
    public invoiceNumber: string
  ) {}
}
export class SetGeneplanetInvoiceAction {
  static type = `[${SERVICE_DATA_NAME} SetGeneplanetInvoiceAction]`;

  constructor(
    public id: string,
    public invoiceNumber: string
  ) {}
}
export class SetLaboratoryInvoiceAction {
  static type = `[${SERVICE_DATA_NAME} SetLaboratoryInvoiceAction]`;

  constructor(
    public id: string,
    public invoiceNumber: string
  ) {}
}
export class SetPaymentTypeAction {
  static type = `[${SERVICE_DATA_NAME} SetPaymentTypeAction]`;

  constructor(
    public id: string,
    public paymentType: string
  ) {}
}
