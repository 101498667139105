import { FormGroup, ValidatorFn } from '@angular/forms';

export function gestationAgeValidValidator(): ValidatorFn {
  return (form: FormGroup) => {
    const gestationWeek = Number(
      form.get('currentPregnancyInformation.gestationAge.week').value
    );
    const gestationDay = Number(
      form.get('currentPregnancyInformation.gestationAge.day').value || 0
    );

    const vtsWeekControl = form.get(
      'currentPregnancyInformation.vanishingTwinSyndrome.detectedGestationAge.week'
    );
    const vtsDayControl = form.get(
      'currentPregnancyInformation.vanishingTwinSyndrome.detectedGestationAge.day'
    );

    const vtsWeek = Number(vtsWeekControl.value);
    const vtsDay = vtsDayControl.value || 0;

    if (
      !form.get('currentPregnancyInformation.vanishingTwinSyndrome.isDetected')
        .value ||
      !gestationWeek ||
      !vtsWeek
    ) {
      return null;
    }

    const diff = gestationWeek * 7 + gestationDay - vtsWeek * 7 - vtsDay;

    // If vanishing twin spotted less than 8 weeks it's invalid
    if (diff < 56) {
      vtsWeekControl.setErrors({ invalidGestationAge: true });
      return;
    }

    if (vtsWeekControl.hasError('invalidGestationAge')) {
      delete vtsWeekControl.errors['invalidGestationAge'];
      vtsWeekControl.updateValueAndValidity();
    }
  };
}
