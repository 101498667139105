import { Kit, KitPositionModel } from '../@core/core.types';

export const validateBarcode = (barcode: string) => {
  const regexExp = /^((\d{2}[a-zA-Z]{1}((\d{7})|(\d{8})))|(\d{10}))$/;
  if (barcode && regexExp.test(barcode)) {
    return true;
  }
  return false;
};
export const removeEmptyPositions = (
  kitsPositions: KitPositionModel[]
): KitPositionModel[] => {
  if (kitsPositions && kitsPositions.length) {
    const sortedByPosition = [...kitsPositions].sort(
      (a, b) => a.position - b.position
    );
    for (let i = 0; i < sortedByPosition.length; i++) {
      const kitId = sortedByPosition[i].kitId;
      sortedByPosition[i] = {
        kitId,
        position: i + 1,
      };
    }
    return sortedByPosition.reverse();
  }
  return kitsPositions;
};

export const reduceKitPositions = (
  kitsPositionList: KitPositionModel[]
): KitPositionModel[] => {
  const newList: KitPositionModel[] = [];
  for (const item of kitsPositionList) {
    const tmpItem = { ...item };
    tmpItem.position = item.position - 1;
    newList.push(tmpItem);
  }
  return newList;
};

export const enlargeKitPositions = (
  kitsPositionList: KitPositionModel[]
): KitPositionModel[] => {
  const newList: KitPositionModel[] = [];
  for (const item of kitsPositionList) {
    const tmpItem = { ...item };
    tmpItem.position = item.position + 1;
    newList.push(tmpItem);
  }
  return newList;
};

export const addNewKitPositionToList = (
  kitsPositionList: KitPositionModel[],
  kit: Kit,
  lastKitPosition: number
): KitPositionModel[] => {
  const newKitPositionItem: KitPositionModel = {
    kitId: kit.id,
    position: lastKitPosition + 1,
  };
  const kitPositionListCopy = [...kitsPositionList];
  kitPositionListCopy.push(newKitPositionItem);
  return kitPositionListCopy;
};

export const modifyKitPositionsOrder = (
  kitsPositionList: KitPositionModel[]
): KitPositionModel[] => removeEmptyPositions(kitsPositionList).reverse();

export const createNewKitPositionList = (
  kitsPositionList: KitPositionModel[],
  slicedList: KitPositionModel[],
  indexToSlice: number
): KitPositionModel[] => {
  const newSlisecList: KitPositionModel[] = reduceKitPositions(slicedList);
  const oldList = [...kitsPositionList].slice(indexToSlice + 1);
  const newKitsList = newSlisecList.concat(oldList);
  return newKitsList;
};

export const updateExpiredStatus = (kits) => {
  const now = new Date();
  for (const key of Object.keys(kits)) {
    const kit = kits[key];
    if (
      kit?.expiryDate &&
      new Date(kit.expiryDate) <= now &&
      (kit.lastStatus === 'Shipped' || kit.lastStatus === 'Generated')
    ) {
      kit.lastStatus = 'Expired';
    }
  }
  return kits;
};
