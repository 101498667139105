import { Injectable } from '@angular/core';
import { PdfPreviewTemplateOptions } from '@app/modules/pdf-request-form/pdf-request-form.types';
import { PdfPreviewTemplateStateService } from '@app/modules/pdf-request-form/services/pdf-preview-template-state.service';

@Injectable()
export class PdfPreviewTemplateService {
  private static pdfStyles = `
  .report input {padding: 0;margin: 0;font-size: 9px;font-family: sans-serif;}
  .report label {font-size: 9px;margin-top: 0;margin-bottom: 0;padding: 0;}
  .report-form-barcode {font-family: sans-serif;text-align: center;vertical-align: middle;font-size: 24px;font-weight: bold;
    color:black;height: 36px;line-height: 36px;width: 160px;float: right;border: 1px solid black;}
    .report-form-simple-code {font-family: sans-serif;text-align: center;vertical-align: middle;font-size: 24px;
      color:black;height: 36px;line-height: 36px;width: 160px;float: right;border: 1px solid black;}
   .report-form-title {font-family: sans-serif;margin-bottom: 0;margin-top: 10px;margin-left: 10px;margin-right: 10px;padding-bottom: 0;}
 .report-form-region {padding: 0;border: 1px solid black;}  .report-form-region::after {clear: both;}
.report-form-header {font-family: sans-serif;padding: 5px 10px;margin: 0;border-top: 1px solid black;
  border-left: 1px solid black;border-right: 1px solid black;border-bottom: 3px solid black;color: black;font-size: 12px;font-weight: bold;}
.report-form-row { /* border-bottom: 1px dotted #999;*/font-size: 9px;margin: 5px;line-height: 12px;font-family: sans-serif;clear: both;}
  .report-form-row-no-border {font-size: 9px;margin: 4px;line-height: 12px;font-family: sans-serif;clear: both;}
  .report-form-key {width: 170px;float: left;font-weight: bold;}  .report-form-key-wide {font-weight: bold;float: left;}
  .report-form-value-bottom { margin-top: 12px;}
   .report-form-report-form-value {margin-right: 5px;}  .report-form-inner-left-column {width: 50%;float: left; /* height: 100%;*/}
     .report-form-inner-right-column {width: 50%;float: right; /* height: 100%;*/}
     .report-form-inner-col-12 {width: 100%;float: left;}
     .report-form-inner-col-8 {width: 66.666%;float: left;}
    .report-form-inner-col-4 {width: 33.33333%;float: left;}
    .report-form-full-column {padding-left: 10px;padding-right: 10px}
    .report-form-full-column-inner {padding-right: 10px; float:left; width:100%}
   .terms {padding: 10px;font-family: sans-serif;font-size: 9px;font-weight: bold;text-align: justify;}
  .signatureLine {margin-top: 40px;margin-right: 10px;width: 200px;float: right;border-bottom: 2px solid black;}
  .signature {font-family: sans-serif;font-size: 11px;font-weight: bold;margin-top: 25px;float: right;}
  .text-center {text-align: center !important;}
  .text-left {text-align: left !important;}\n
  `;

  constructor(private state: PdfPreviewTemplateStateService) {}

  createTemplate(options: PdfPreviewTemplateOptions): string {
    this.state.options = options;

    const htmlPdfPreview = this.state.pdfTemplate.createEmbeddedView({});

    htmlPdfPreview.detectChanges();
    const html = htmlPdfPreview.rootNodes[0].innerHTML;

    return `
    <!doctype html>
    <html lang="en">
    <head>
      <meta charset="utf-8"/>
      <title>${this.state.options.pii.kitId}_results</title>
      <style>
      ${PdfPreviewTemplateService.pdfStyles}
      </style>
    </head>
    <body>
    ${html}
    </body>
    </html>
    `;
  }
}
