import { Injectable } from '@angular/core';
import { PartnerTypesService } from '@core/services/partner-types.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DOCTORS_PARTNER_TYPES,
  FIRST_LEVEL_PARTNER_TYPES,
} from '../../../../app.constants';
import { PartnerType } from '../../core.types';
import { Logger } from '../../services/logger.service';
import {
  ClearPartnerTypesAction,
  LoadPartnerTypesAction,
} from './partner-types.actions';

const PARTNER_TYPES_STATE_NAME = 'partnerTypes';

export interface PartnerTypesStateModel {
  partnerTypes: PartnerType[];
}

@State<PartnerTypesStateModel>({
  name: PARTNER_TYPES_STATE_NAME,
  defaults: {
    partnerTypes: [],
  },
})
@Injectable()
export class PartnerTypeState {
  private log = new Logger('PartnerTypeState');

  constructor(private partnerTypesService: PartnerTypesService) {}

  @Selector()
  static getFirstLevelPartnerTypes(state: PartnerTypesStateModel) {
    const firstLevelPartnerTypes = Object.values(FIRST_LEVEL_PARTNER_TYPES);
    return state.partnerTypes.filter(
      (p) => firstLevelPartnerTypes.indexOf(p.type) > -1
    );
  }

  @Selector()
  static getFirstLevelPartnerTypesIdsList(state: PartnerTypesStateModel) {
    const firstLevelPartnerTypes = Object.values(FIRST_LEVEL_PARTNER_TYPES);
    return state.partnerTypes
      .filter((p) => firstLevelPartnerTypes.indexOf(p.type) > -1)
      .map((partner) => partner.id);
  }

  @Selector()
  static getPysicianPartnerTypeId(state: PartnerTypesStateModel) {
    return state.partnerTypes.filter(
      (p) => p.type === DOCTORS_PARTNER_TYPES.physician
    );
  }

  @Selector()
  static getPartnerTypesList(state: PartnerTypesStateModel) {
    return state.partnerTypes;
  }

  @Action(LoadPartnerTypesAction)
  loadPartnerTypesList({ patchState }: StateContext<PartnerTypesStateModel>) {
    this.log.debug('loadPartnerTypesList');
    this.partnerTypesService.getPartnerTypesList().subscribe((partnerTypes) => {
      patchState({
        partnerTypes,
      });
    });
  }

  @Action(ClearPartnerTypesAction)
  clearPartnerTypesState({ setState }: StateContext<PartnerTypesStateModel>) {
    this.log.debug('clearPartnerTypesState');
    return setState({
      partnerTypes: [],
    });
  }
}
