<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      Add <b>{{ title }}</b> e-mail address
    </p>
    <nb-icon (click)="close()" icon="close"></nb-icon>
  </nb-card-header>

  <nb-card-body>
    <form (ngSubmit)="addEmail($event)" class="form-group" novalidate>
      <div class="row align-items-center">
        <label
          for="email"
          class="label text-end col-3 form-control-label mb-0"
          >E-mail:</label
        >
        <div class="col-8">
          <input
            fullWidth
            type="email"
            nbInput
            [formControl]="emailControl"
            name="email"
            id="email"
            placeholder="E-mail"
          />
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button class="me-2" nbButton status="primary" (click)="addEmail($event)">
      Add email
    </button>
  </nb-card-footer>
</nb-card>
