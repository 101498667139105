import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PAGING_HEADERS } from '@app/app.constants';
import {
  ProvisionServiceData,
  ServiceData,
} from '@app/modules/service-data/service-data.types';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import { ApiFilters, ApiHeaders } from '@core/core.types';
import { HttpUtils } from '@core/utils/http-utils';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class ServiceDataService {
  readonly API_URL = `${environment.apiUrl}/service-data`;
  constructor(
    private http: HttpClient,
    private serviceDataUtilsService: ServiceDataUtilsService
  ) {}

  getServiceDataList(
    filters: ServiceDataFilters,
    headers: ApiHeaders
  ): Observable<{
    items: ServiceData[];
    continuationToken: string;
    totalCount: number;
  }> {
    return this.http
      .get<ServiceData[]>(`${this.API_URL}/service-data`, {
        observe: 'response',
        params: this.serviceDataUtilsService.buildHttpParams(filters),
        headers: HttpUtils.buildHttpHeaders(headers),
      })
      .pipe(
        map((response) => ({
          items: response.body,
          continuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
        }))
      );
  }

  getServiceData(id: string): Observable<ServiceData> {
    return this.http.get<ServiceData>(`${this.API_URL}/service-data/${id}`);
  }

  createServiceData(
    serviceData: ProvisionServiceData
  ): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      `${this.API_URL}/service-data`,
      serviceData
    );
  }

  updateServiceData(serviceData: ServiceData): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(
      `${this.API_URL}/service-data/${serviceData.id}`,
      serviceData
    );
  }

  deleteServiceData(id: string): Observable<any> {
    return this.http.delete<{ id: string }>(
      `${this.API_URL}/service-data/${id}`
    );
  }

  terminateServiceData(id: string, reason: string): Observable<any> {
    return this.http.patch<{ id: string }>(
      `${this.API_URL}/service-data/${id}/terminate`,
      { reason: reason }
    );
  }

  setDoctorInvoiceNumber(id: string, invoiceNumber: string): Observable<any> {
    return this.http.patch<{ id: string }>(
      `${this.API_URL}/service-data/${id}/doctor-invoice-number`,
      { doctorInvoiceNumber: invoiceNumber }
    );
  }

  setGeneplanetInvoiceNumber(
    id: string,
    invoiceNumber: string
  ): Observable<any> {
    return this.http.patch<{ id: string }>(
      `${this.API_URL}/service-data/${id}/geneplanet-invoice-number`,
      { geneplanetInvoiceNumber: invoiceNumber }
    );
  }

  setLaboratoryInvoiceNumber(
    id: string,
    invoiceNumber: string
  ): Observable<any> {
    return this.http.patch<{ id: string }>(
      `${this.API_URL}/service-data/${id}/laboratory-invoice-number`,
      { laboratoryInvoiceNumber: invoiceNumber }
    );
  }

  setPaymentType(id: string, paymentType: string): Observable<any> {
    return this.http.patch<{ id: string }>(
      `${this.API_URL}/service-data/${id}/payment-type`,
      { paymentType }
    );
  }
}

export interface ServiceDataFilters extends ApiFilters {
  ids?: string[];
  sampleIds?: string[];
  kitIds?: string[];
  partnerIds?: string[];
  invoiceIds?: string[];
  doctorIds?: string[];
  referenceKeys?: string[];
  bundleIds?: string[];
  paymentTypes?: string[];
  patientNameToken?: string;
  patientEmailToken?: string;
  paymentNotesToken?: string;
  notesToken?: string;
  searchToken?: string;
  onlineReports?: boolean;
  paid?: boolean;
  status?: string;
  statusFrom?: string;
  statusTo?: string;
  generatedFrom?: string;
  generatedTo?: string;
  incompleteFrom?: string;
  incompleteTo?: string;
  finishedFrom?: string;
  finishedTo?: string;
  dateOfServiceFrom?: string;
  dateOfServiceTo?: string;
  countries?: string;
  accountManagerId?: string;
}
