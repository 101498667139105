import { Component, Injectable } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

interface WarningOptions {
  title: string;
  message: string;
}

@Component({
  selector: 'app-warning-dialog',
  styleUrls: ['warning-dialog.component.scss'],
  templateUrl: 'warning-dialog.component.html',
})
export class WarningDialogComponent {
  options: WarningOptions;

  constructor(private dialogRef: NbDialogRef<WarningDialogComponent>) {}

  ok() {
    this.dialogRef.close();
  }
}

@Injectable()
export class WarningService {
  constructor(private nbDialogService: NbDialogService) {}

  showWarning(options: WarningOptions) {
    const dialog = this.nbDialogService.open(WarningDialogComponent, {
      context: { options },
    });
    return dialog.onClose;
  }
}
