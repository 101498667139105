import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
  Input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CancerHistoryStatus,
  FamilyDiseaseInformation,
} from '@app/modules/service-data/service-data.types';
import {
  NbButtonModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbOptionModule,
  NbRadioModule,
  NbTooltipModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { controlContainerProvider } from '@shared/helpers';
import { SharedModule } from '@shared/shared.module';
import {
  Observable,
  Subject,
  concat,
  defer,
  first,
  of,
  startWith,
  tap,
} from 'rxjs';
import { InputValidationStatusDirective } from '@shared/directives/input-validation-status.directive';
import { DataInputType } from '@core/core.types';

@Component({
  selector: 'app-service-data-patient-history-information',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NbCheckboxModule,
    NbInputModule,
    NbOptionModule,
    NbRadioModule,
    NbTooltipModule,
    ReactiveFormsModule,
    TranslateModule,
    NbIconModule,
    NbButtonModule,
    ValidationErrorComponent,
    SharedModule,
    InputValidationStatusDirective,
  ],
  templateUrl: './service-data-patient-history-information.component.html',
  styleUrl: './service-data-patient-history-information.component.scss',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDataPatientHistoryInformationComponent implements OnInit {
  protected get form() {
    return this.controlContainer.control as FormGroup;
  }

  protected pregnancyDiseaseControl = new FormControl<string>('', [
    Validators.required,
  ]);
  protected familyDiseaseGroup = new FormGroup<{
    conditionDescription: FormControl<string>;
    relationToPatient: FormControl<string>;
  }>({
    conditionDescription: new FormControl<string>(''),
    relationToPatient: new FormControl<string>(''),
  });
  protected geneticConditionControl = new FormControl<string>('', [
    Validators.required,
  ]);

  protected pregnancyDiseaseConfiguration: DataTableConfiguration;
  protected familyDiseaseConfiguration: DataTableConfiguration;
  protected geneticConditionConfiguration: DataTableConfiguration;

  protected cancerHistoryStatus = CancerHistoryStatus;
  protected isCancerRecovered$ = new Subject<boolean>();

  private destroyRef = inject(DestroyRef);

  constructor(
    private controlContainer: ControlContainer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const pregnancyDiseaseRows = this.getRows(
      'pregnancyDiseaseHistory.diseases'
    );
    const familyDiseaseRows = this.getRows('familyDiseaseHistory.diseases');
    const geneticConditionRows = this.getRows(
      'carrierOfGeneticDiseases.diseases'
    );

    this.pregnancyDiseaseConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'serviceData.form.tooltips.removeDisease',
          action: this.removePregnancyDisease.bind(this),
          icon: 'close-outline',
          type: DataTableActionType.BUTTON,
        },
      ],
      columns: [
        {
          label: 'Disease',
          property: 'conditionDescription',
        },
      ],
      rows$: pregnancyDiseaseRows,
    };

    this.familyDiseaseConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'serviceData.form.tooltips.removeDisease',
          action: this.removeFamilyDisease.bind(this),
          icon: 'close-outline',
          type: DataTableActionType.BUTTON,
        },
      ],
      columns: [
        {
          label: 'Disease',
          property: 'conditionDescription',
        },
        {
          label: 'Relation to patient',
          property: 'relationToPatient',
        },
      ],
      rows$: familyDiseaseRows,
    };

    this.geneticConditionConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'serviceData.form.tooltips.removeDisease',
          action: this.removeGeneticCondition.bind(this),
          icon: 'close-outline',
          type: DataTableActionType.BUTTON,
        },
      ],
      columns: [
        {
          label: 'Disease',
          property: 'conditionDescription',
        },
      ],
      rows$: geneticConditionRows,
    };

    const cancerHistoryStatusControl = this.form.get('cancerHistory.status');

    cancerHistoryStatusControl.valueChanges
      .pipe(
        startWith(cancerHistoryStatusControl.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((cancerHistoryStatus: CancerHistoryStatus) =>
        this.isCancerRecovered$.next(
          cancerHistoryStatus === CancerHistoryStatus.Recovered
        )
      );

    this.initControlChecks();
  }

  protected addDisease(
    formControlName: string,
    inputFormControl: AbstractControl
  ): void {
    if (!inputFormControl.valid) {
      return;
    }

    const diseaseControl = this.form.get(formControlName);

    diseaseControl.patchValue([
      ...diseaseControl.value,
      { conditionDescription: inputFormControl.value },
    ]);

    inputFormControl.reset();
  }

  protected addFamilyDisease(): void {
    if (!this.familyDiseaseGroup.valid) {
      return;
    }

    const diseaseControl = this.form.get('familyDiseaseHistory.diseases');

    diseaseControl.patchValue([
      ...diseaseControl.value,
      {
        conditionDescription: this.familyDiseaseGroup.get(
          'conditionDescription'
        ).value,
        relationToPatient:
          this.familyDiseaseGroup.get('relationToPatient').value,
      },
    ]);

    this.familyDiseaseGroup.reset();
  }

  private getRows(formControlName: string): Observable<any[]> {
    return concat(
      defer(() => of(this.form.get(formControlName).value)).pipe(first()),
      this.form.get(formControlName).valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.cdRef.markForCheck())
      )
    );
  }

  private removeFamilyDisease(
    event: MouseEvent,
    diseaseHistory: FamilyDiseaseInformation
  ): void {
    event.preventDefault();

    const diseaseControl = this.form.get('familyDiseaseHistory.diseases');

    diseaseControl.patchValue(
      diseaseControl.value.filter(
        (dh: FamilyDiseaseInformation) =>
          dh?.conditionDescription !== diseaseHistory?.conditionDescription
      )
    );
  }

  private removePregnancyDisease(
    event: MouseEvent,
    diseaseHistory: FamilyDiseaseInformation
  ): void {
    event.preventDefault();

    const diseaseControl = this.form.get('pregnancyDiseaseHistory.diseases');

    diseaseControl.patchValue(
      diseaseControl.value.filter(
        (dh: FamilyDiseaseInformation) =>
          dh?.conditionDescription !== diseaseHistory?.conditionDescription
      )
    );
  }

  private removeGeneticCondition(
    event: MouseEvent,
    diseaseHistory: FamilyDiseaseInformation
  ): void {
    event.preventDefault();

    const diseaseControl = this.form.get('carrierOfGeneticDiseases.diseases');

    diseaseControl.patchValue(
      diseaseControl.value.filter(
        (dh: FamilyDiseaseInformation) =>
          dh?.conditionDescription !== diseaseHistory?.conditionDescription
      )
    );
  }

  private initControlChecks() {
    this.checkControlOnChange(
      'pregnancyDiseaseHistory.hasHistory',
      'pregnancyDiseaseHistory.diseases',
      true
    );
    this.checkControlOnChange(
      'familyDiseaseHistory.hasHistory',
      'familyDiseaseHistory.diseases',
      true
    );
    this.checkControlOnChange(
      'carrierOfGeneticDiseases.hasHistory',
      'carrierOfGeneticDiseases.diseases',
      true
    );
    this.checkControlOnChange(
      'cancerHistory.hasHistory',
      'cancerHistory.status'
    );
    this.checkControlOnChange(
      'albuminTherapy.hasReceived',
      'albuminTherapy.isRelevant'
    );
    this.checkControlOnChange(
      'cellularImmunotherapy.hasReceived',
      'cellularImmunotherapy.isRelevant'
    );
    this.checkControlOnChange(
      'bloodTransfusion.hasReceived',
      'bloodTransfusion.isRelevant'
    );
  }

  private checkControlOnChange(
    controlNameToCheck: string,
    controlNameToClear: string,
    isArray?: boolean
  ): void {
    if (
      !this.form.get(controlNameToCheck) ||
      !this.form.get(controlNameToClear)
    ) {
      return;
    }

    this.form
      .get(controlNameToCheck)
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => {
        if (!value) {
          this.form.get(controlNameToClear).reset(isArray ? [] : null);
        }
      });
  }
}
