import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { replaceNotificationEmailTemplateTerms } from '@app/modules/@core/utils/core.util';
import { NotificationProfileModel, TemplateType } from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { AddEmailComponent } from '@treatments/components/approve-document-dialog/add-email/add-email.component';
import { getReasonFromFileName } from '@treatments/documents-utils';
import { BehaviorSubject } from 'rxjs';
import {
  Document,
  DocumentApprovalRequest,
  TreatmentDocumentKeywords,
} from '../../../treatments/treatment.types';

enum DialogStep {
  prepareEmail = 'prepare-email',
  previewEmail = 'preview-email',
  approveDocument = 'approve-document',
}

enum RecipientType {
  TO = 'To',
  CC = 'Cc',
  BCC = 'Bcc',
}

@Component({
  selector: 'app-approve-document-dialog',
  templateUrl: 'approve-document-dialog.component.html',
  styleUrls: ['approve-document-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveDocumentDialogComponent implements OnInit {
  @ViewChild('emailRowActionsTemplate', { static: true })
  emailRowActionsTemplate: TemplateRef<any>;
  @ViewChild('toEmailActionsHeaderTemplate', { static: true })
  toEmailActionsHeaderTemplate: TemplateRef<any>;
  @ViewChild('ccEmailActionsHeaderTemplate', { static: true })
  ccEmailActionsHeaderTemplate: TemplateRef<any>;
  @ViewChild('bccEmailActionsHeaderTemplate', { static: true })
  bccEmailActionsHeaderTemplate: TemplateRef<any>;
  @Input() document: Document;
  @Input() kitId: string;
  @Input() doctorId: string;
  @Input() templateType: TemplateType;
  @Input() notificationProfile: NotificationProfileModel;
  @Input() emailTemplate?: {
    serviceName: string;
    predefinedRecipients: any;
    subject: string;
    body: string;
  };

  send = false;

  DialogStep = DialogStep;
  TemplateType = TemplateType;

  sendEmailForm: {
    form: UntypedFormGroup;
  };

  emailConfig: {
    to: string[];
    cc: string[];
    bcc: string[];
    subject: string;
    body: string;
  };

  toDataTableConfiguration: DataTableConfiguration;
  ccDataTableConfiguration: DataTableConfiguration;
  bccDataTableConfiguration: DataTableConfiguration;

  step$ = new BehaviorSubject(DialogStep.prepareEmail);
  isLoading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string>(null);

  toEmails$ = new BehaviorSubject<{ email: string }[]>([]);
  ccEmails$ = new BehaviorSubject<{ email: string }[]>([]);
  bccEmails$ = new BehaviorSubject<{ email: string }[]>([]);

  private readonly log = new Logger(this.constructor.name);

  constructor(
    private dialogRef: NbDialogRef<any>,
    private formBuilder: UntypedFormBuilder,
    private dialog: NbDialogService
  ) {}

  private get highRiskReason(): string {
    if (this.templateType === TemplateType.HighRisk && this.sendEmailForm) {
      const reasonField = this.sendEmailForm.form.get('reason');
      return reasonField && reasonField.value ? reasonField.value : '';
    }
  }

  ngOnInit() {
    let recipientsEmails = this.notificationProfile
      ? this.notificationProfile.recipients.map((r) => ({
          email: r.recipient,
        }))
      : [];
    if (
      this.emailTemplate &&
      this.templateType !== TemplateType.NotQualified &&
      this.templateType !== TemplateType.Canceled
    ) {
      if (
        this.emailTemplate.predefinedRecipients &&
        this.emailTemplate.predefinedRecipients.to
      ) {
        recipientsEmails = [
          ...recipientsEmails,
          ...this.emailTemplate.predefinedRecipients.to,
        ];
      }
      this.toEmails$.next(recipientsEmails);
      if (this.emailTemplate.predefinedRecipients.cc) {
        this.ccEmails$.next(
          this.emailTemplate.predefinedRecipients.cc.map((email: string) => ({
            email,
          }))
        );
      }
      if (this.emailTemplate.predefinedRecipients.bcc) {
        this.bccEmails$.next(
          this.emailTemplate.predefinedRecipients.bcc.map((email: string) => ({
            email,
          }))
        );
      }
      this.setupSendEmailNotificationsForm();
    }
  }

  dismiss(payload?: any) {
    this.dialogRef.close(payload);
  }

  async previewEmail() {
    this.log.debug('preview email');
    this.error$.next(null);
    try {
      await this.validateAndSetNotificationEmailConfig();
      this.step$.next(DialogStep.previewEmail);
    } catch (e) {
      this.error$.next(e.message);
      this.isLoading$.next(false);
    }
  }

  async sendEmails(send: boolean) {
    this.log.debug('send email', send);
    this.error$.next(null);
    this.send = send;
    this.step$.next(DialogStep.approveDocument);
    this.isLoading$.next(false);
  }

  async approveDocument() {
    this.log.debug('approve document');
    this.isLoading$.next(true);
    this.dismiss({
      recipients: this.emailConfig?.to,
      ccRecipients: this.emailConfig?.cc,
      bccRecipients: this.emailConfig?.bcc,
      body: this.emailConfig?.body,
      subject: this.emailConfig?.subject,
      sendEmail: !!this.emailConfig?.subject,
    } as DocumentApprovalRequest);
  }

  openAddEmailDialog(title) {
    this.dialog
      .open(AddEmailComponent, { context: { title } })
      .onClose.subscribe((resp) => {
        if (!resp?.email) {
          return;
        }
        switch (title) {
          case RecipientType.TO:
            this.addEmail(resp.email, this.toEmails$);
            break;
          case RecipientType.CC:
            this.addEmail(resp.email, this.ccEmails$);
            break;
          case RecipientType.BCC:
            this.addEmail(resp.email, this.bccEmails$);
            break;
        }
      });
  }

  async addEmail(email: string, list$: BehaviorSubject<{ email: string }[]>) {
    list$.next([...list$.value, { email }]);
  }

  async removeEmail(
    email: string,
    list$: BehaviorSubject<{ email: string }[]>
  ) {
    const currentEmails = list$.value;
    const filteredEmails = currentEmails.filter((e) => e.email !== email);
    if (currentEmails.length !== filteredEmails.length) {
      list$.next(filteredEmails);
    }
  }

  cancel(step: DialogStep) {
    this.log.debug('cancel', step);
    this.error$.next(null);
    switch (step) {
      case DialogStep.prepareEmail:
        this.sendEmails(false);
        break;
      case DialogStep.previewEmail:
        this.step$.next(DialogStep.prepareEmail);
        break;
      case DialogStep.approveDocument:
        this.dismiss();
        break;
      default:
    }
  }

  private async validateAndSetNotificationEmailConfig() {
    const emailSubject = this.sendEmailForm.form.get('subject').value;
    const emailBody = this.sendEmailForm.form.get('body').value;
    if (!emailSubject || !emailBody) {
      throw new Error('Please enter e-mail subject and body.');
    }

    const [to, cc, bcc] = [
      this.toEmails$.value,
      this.ccEmails$.value,
      this.bccEmails$.value,
    ];
    if (to.length < 1) {
      throw new Error(
        'Please add at least one recipient before sending an e-mail notification.'
      );
    }
    if (this.templateType === TemplateType.HighRisk) {
      const reason = this.highRiskReason;
      if (!reason) {
        throw new Error('Please enter High Risk reason.');
      }
    }
    this.emailConfig = {
      to: to.map((data) => data.email),
      cc: cc.map((data) => data.email),
      bcc: bcc.map((data) => data.email),
      subject: replaceNotificationEmailTemplateTerms(
        emailSubject,
        this.emailTemplate.serviceName,
        this.kitId,
        this.highRiskReason
      ),
      body: replaceNotificationEmailTemplateTerms(
        emailBody,
        this.emailTemplate.serviceName,
        this.kitId,
        this.highRiskReason
      ),
    };
  }

  private async setupSendEmailNotificationsForm() {
    this.log.debug('setup send email notifications form');

    this.toDataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      massActions: [
        {
          name: 'Add',
          icon: 'plus-outline',
          action: () => {
            this.openAddEmailDialog(RecipientType.TO);
          },
          type: DataTableActionType.BUTTON,
        },
      ],
      singleActions: [
        {
          name: 'Remove',
          icon: 'close-outline',
          action: (e, row) => {
            this.removeEmail(row.email, this.toEmails$);
          },
          type: DataTableActionType.BUTTON,
        },
      ],
      columns: [
        {
          label: 'notificationProfilesPage.toEmailRecipients',
          property: 'email',
        },
      ],
      rows$: this.toEmails$,
    };

    this.ccDataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      massActions: [
        {
          name: 'Add',
          icon: 'plus-outline',
          action: () => {
            this.openAddEmailDialog(RecipientType.CC);
          },
          type: DataTableActionType.BUTTON,
        },
      ],
      singleActions: [
        {
          name: 'Remove',
          icon: 'close-outline',
          action: (e, row) => {
            this.removeEmail(row.email, this.ccEmails$);
          },
          type: DataTableActionType.BUTTON,
        },
      ],
      columns: [
        {
          label: 'notificationProfilesPage.ccEmailRecipients',
          property: 'email',
        },
      ],
      rows$: this.ccEmails$,
    };

    this.bccDataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      massActions: [
        {
          name: 'Add',
          icon: 'plus-outline',
          action: () => {
            this.openAddEmailDialog(RecipientType.BCC);
          },
          type: DataTableActionType.BUTTON,
        },
      ],
      singleActions: [
        {
          name: 'Remove',
          icon: 'close-outline',
          action: (e, row) => {
            this.removeEmail(row.email, this.bccEmails$);
          },
          type: DataTableActionType.BUTTON,
        },
      ],
      columns: [
        {
          label: 'notificationProfilesPage.bccEmailRecipients',
          property: 'email',
        },
      ],
      rows$: this.bccEmails$,
    };

    this.sendEmailForm = {
      form: this.formBuilder.group({
        subject: ['', [Validators.required]],
        body: ['', [Validators.required]],
      }),
    };

    if (this.templateType === TemplateType.HighRisk) {
      const lcdName = this.document.fileName.toLowerCase();
      const highRiskReason =
        getReasonFromFileName(lcdName, TreatmentDocumentKeywords.highRisk1) ||
        getReasonFromFileName(lcdName, TreatmentDocumentKeywords.highRisk2) ||
        '';
      this.sendEmailForm.form.addControl(
        'reason',
        new UntypedFormControl(
          highRiskReason.toUpperCase(),
          Validators.required
        )
      );
    }

    if (this.emailTemplate.subject) {
      this.sendEmailForm.form
        .get('subject')
        .setValue(this.emailTemplate.subject);
    }
    if (this.emailTemplate.body) {
      this.sendEmailForm.form.get('body').setValue(this.emailTemplate.body);
    }
  }
}
