import { Injectable } from '@angular/core';
import { languages } from '../../../app.constants';
import { Language } from '../core.types';

@Injectable()
export class LanguagesService {
  getLanguages(): Language[] {
    return languages;
  }
}
