<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ options.title | translate }}
    </p>
  </nb-card-header>
  <nb-card-body class="py-4">
    <div [innerHtml]="options.message | translate"></div>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button class="me-2" nbButton (click)="ok()">Close</button>
  </nb-card-footer>
</nb-card>
