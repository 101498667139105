import { NotificationProfileModel } from '../../core.types';

export class LoadNotificationProfilesAction {
  static type = '[NotificationProfile] LoadNotificationProfiles';
  constructor(
    public notificationProfilesIds: string[],
    public refresh = false
  ) {}
}
export class LoadNotificationProfilesMultiAction {
  static type = '[NotificationProfile] LoadNotificationProfilesMulti';
  constructor(
    public notificationProfilesIds: string[],
    public refresh = false
  ) {}
}

export class AddNotificationProfilesAction {
  static type = '[NotificationProfile] AddNotificationProfiles';
  constructor(public notificationProfiles: NotificationProfileModel[]) {}
}

export class UpdateNotificationProfilesAction {
  static type = '[NotificationProfile] UpdateNotificationProfiles';
  constructor(public notificationProfiles: NotificationProfileModel[]) {}
}
