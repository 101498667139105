import { Injectable } from '@angular/core';
import {
  ProductInventoryItemFilters,
  ProductInventoryItemService,
} from '@app/modules/service-data/services/product-inventory-item.service';
import {
  AssignDocumentToProductInventoryItemAction,
  GetProductInventoryItemAction,
  GetProductInventoryItemListAction,
  GetProductInventoryItemsInProcessAction,
  PRODUCT_INVENTORY_ITEM_NAME,
  SetProductInventoryItemsAction,
  UnassignDocumentFromProductInventoryItemAction,
} from '@app/modules/service-data/store/product-intentory-item.actions';
import { GetSampleAction } from '@app/modules/service-data/store/sample.actions';
import {
  ApiHeaders,
  Pagination,
  ProductInventory,
  SortDirection,
} from '@core/core.types';
import { Util } from '@core/utils/core.util';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isEqual } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';

export interface ProductInventoryItemStateModel {
  productInventoryItems: { [id: string]: ProductInventory };
  productInventoryItemsByDocuments: { [id: string]: string };
  pagination: Pagination<ProductInventoryItemFilters>;
  inProcess: { list: string[]; totalCount: number };
  pages: {
    [index: number]: {
      items: string[];
      continuationToken: string;
    };
  };
}

const PRODUCT_INVENTORY_ITEM_DEFAULT_STATE: ProductInventoryItemStateModel = {
  productInventoryItems: {},
  productInventoryItemsByDocuments: {},
  inProcess: { list: [], totalCount: 0 },
  pages: {},
  pagination: {
    totalCount: 0,
    pagesSize: 0,
    collectionSize: 30,
    currentPageSettings: {
      pageIndex: 0,
      sortSettings: {
        orderBy: 'generated',
        sortDirection: SortDirection.desc,
        pageSize: 30,
      },
      filterSettings: {},
    },
  },
};

@State<ProductInventoryItemStateModel>({
  name: PRODUCT_INVENTORY_ITEM_NAME,
  defaults: { ...PRODUCT_INVENTORY_ITEM_DEFAULT_STATE },
})
@Injectable()
export class ProductInventoryItemState {
  constructor(
    private productInventoryItemService: ProductInventoryItemService
  ) {}

  @Selector()
  static getCurrentPageProductInventoryItems({
    productInventoryItems,
    pages,
    pagination,
  }: ProductInventoryItemStateModel) {
    return (
      pages[pagination.currentPageSettings.pageIndex]?.items?.map(
        (id) => productInventoryItems[id]
      ) ?? []
    );
  }

  @Selector()
  static getProductInventoryItemById({
    productInventoryItems,
  }: ProductInventoryItemStateModel) {
    return (id: string) => productInventoryItems[id];
  }

  @Selector()
  static getProductInventoryItemByDocumentId({
    productInventoryItems,
    productInventoryItemsByDocuments,
  }: ProductInventoryItemStateModel) {
    return (documentId: string) =>
      productInventoryItems[productInventoryItemsByDocuments[documentId]];
  }

  @Selector()
  static getProductInventoryItemsByIds({
    productInventoryItems,
  }: ProductInventoryItemStateModel) {
    return (ids: string[]) => ids.map((id) => productInventoryItems[id]);
  }

  @Selector()
  static getProductInventoryItemsInProcessList({
    inProcess,
  }: ProductInventoryItemStateModel) {
    return inProcess.list;
  }

  @Selector()
  static getProductInventoryItemsInProcessTotalCount({
    inProcess,
  }: ProductInventoryItemStateModel) {
    return inProcess.totalCount;
  }

  @Selector()
  static getPagination({ pagination, pages }: ProductInventoryItemStateModel) {
    return {
      ...pagination,
      pagesSize: Object.keys(pages).length,
    };
  }

  @Action(GetProductInventoryItemListAction)
  getProductInventoryItemListAction(
    {
      dispatch,
      getState,
      patchState,
    }: StateContext<ProductInventoryItemStateModel>,
    {
      filters,
      pageToLoad = null,
      reload = false,
      pageSize = null,
    }: GetProductInventoryItemListAction
  ) {
    const state = getState();

    if (!reload && filters.documentIds?.length) {
      filters.documentIds = filters.documentIds.filter(
        (documentId) => !state.productInventoryItemsByDocuments[documentId]
      );

      if (!filters.documentIds.length) {
        return;
      }
    }

    if (filters.documentIds?.length > 100) {
      return forkJoin(
        Util.chunkArray(filters.documentIds, 100).map((documentIds) =>
          dispatch(
            new GetProductInventoryItemListAction(
              { documentIds },
              pageToLoad,
              reload,
              pageSize
            )
          )
        )
      );
    }

    if (!reload && filters.ids?.length) {
      filters.ids = filters.ids.filter(
        (id) => !state.productInventoryItems[id]
      );

      if (!filters.ids.length) {
        return;
      }
    }

    if (filters.ids?.length > 100) {
      return forkJoin(
        Util.chunkArray(filters.ids, 100).map((ids) =>
          dispatch(
            new GetProductInventoryItemListAction(
              { ids },
              pageToLoad,
              reload,
              pageSize
            )
          )
        )
      );
    }

    const currentPageSettings = {
      ...getState().pagination.currentPageSettings,
      sortSettings: { ...state.pagination.currentPageSettings.sortSettings },
      filterSettings: {
        ...state.pagination.currentPageSettings.filterSettings,
      },
    };

    let pages = state.pages;
    let totalCount = state.pagination.totalCount;

    // Update pagination in case of listing pages.
    if (pageToLoad !== null) {
      currentPageSettings.pageIndex = pageToLoad;

      // Check that filters are same as they were for previous page.
      if (!isEqual(currentPageSettings.filterSettings, filters)) {
        // If filters are different as they were -> update filters, reset pages object and go back to page 1.
        currentPageSettings.filterSettings = filters;

        pageToLoad = 0;
        reload = true;
        pages = {};
        currentPageSettings.pageIndex = pageToLoad;
      }
    }

    if (pageSize !== null) {
      currentPageSettings.sortSettings.pageSize = pageSize;
    }

    const headers: ApiHeaders = {
      orderBy: currentPageSettings.sortSettings.orderBy,
      orderDirection: currentPageSettings.sortSettings.sortDirection,
    };
    if (pageToLoad !== null) {
      if (!headers.pagingTop) {
        headers.pagingTop = pageSize
          ? pageSize
          : currentPageSettings.sortSettings.pageSize;
      }

      headers.continuationToken =
        pageToLoad > 0
          ? getState().pages[pageToLoad - 1].continuationToken
          : null;
    }

    return this.productInventoryItemService
      .getProductInventoryItemList(filters, headers)
      .pipe(
        switchMap((data) =>
          dispatch(new SetProductInventoryItemsAction(data.items)).pipe(
            tap(() => {
              if (pageToLoad !== null) {
                totalCount = data.totalCount;
              }
              patchState({
                pages: {
                  ...pages,
                  [pageToLoad]:
                    pageToLoad !== null
                      ? {
                          items: data.items.map((pii) => pii.id),
                          continuationToken: data.continuationToken,
                        }
                      : null,
                },
                pagination: {
                  ...getState().pagination,
                  totalCount: totalCount,
                  collectionSize: currentPageSettings.sortSettings.pageSize,
                  currentPageSettings: {
                    ...currentPageSettings,
                  },
                },
              });
            })
          )
        )
      );
  }

  @Action(SetProductInventoryItemsAction)
  setProductInventoryItemsAction(
    { getState, patchState }: StateContext<ProductInventoryItemStateModel>,
    { list }: SetProductInventoryItemsAction
  ) {
    const newProductInventoryItems = {};
    const newProductInventoryItemsByDocuments = {};

    for (const pii of list) {
      newProductInventoryItems[pii.id] = pii;
      for (const document of pii.documents) {
        newProductInventoryItemsByDocuments[document] = pii.id;
      }
    }

    patchState({
      ...getState(),
      productInventoryItems: {
        ...getState().productInventoryItems,
        ...newProductInventoryItems,
      },
      productInventoryItemsByDocuments: {
        ...getState().productInventoryItemsByDocuments,
        ...newProductInventoryItemsByDocuments,
      },
    });
  }

  @Action(GetProductInventoryItemAction)
  getProductInventoryItemAction(
    { dispatch, getState }: StateContext<ProductInventoryItemStateModel>,
    { id, reload = false, reloadSample = false }: GetProductInventoryItemAction
  ) {
    if (!reload && getState().productInventoryItems[id]) {
      return;
    }
    return this.productInventoryItemService
      .getProductInventoryItem(id)
      .pipe(
        switchMap((pii) =>
          dispatch(new SetProductInventoryItemsAction([pii])).pipe(
            switchMap(() =>
              reloadSample
                ? dispatch(
                    new GetSampleAction(
                      Util.PropertiesToObject(pii.properties).sampleid,
                      false,
                      true
                    )
                  )
                : of(null)
            )
          )
        )
      );
  }
  @Action(AssignDocumentToProductInventoryItemAction)
  assignDocumentToProductInventoryItemAction(
    { dispatch }: StateContext<ProductInventoryItemStateModel>,
    { id, documentId }: AssignDocumentToProductInventoryItemAction
  ) {
    return this.productInventoryItemService
      .assignDocumentToProductInventoryItem(id, documentId)
      .pipe(
        delay(200),
        switchMap(() => dispatch(new GetProductInventoryItemAction(id, true)))
      );
  }
  @Action(UnassignDocumentFromProductInventoryItemAction)
  unassignDocumentFromProductInventoryItemAction(
    { dispatch }: StateContext<ProductInventoryItemStateModel>,
    { id, documentId }: UnassignDocumentFromProductInventoryItemAction
  ) {
    return this.productInventoryItemService
      .unassignDocumentFromProductInventoryItem(id, documentId)
      .pipe(
        delay(200),
        switchMap(() => dispatch(new GetProductInventoryItemAction(id)))
      );
  }
  @Action(GetProductInventoryItemsInProcessAction)
  getProductInventoryItemsInProcessAction(
    { patchState, dispatch }: StateContext<ProductInventoryItemStateModel>,
    { filters }: GetProductInventoryItemsInProcessAction
  ) {
    return this.productInventoryItemService
      .productInventoryItemsInProcess(filters, {})
      .pipe(
        tap((data) => {
          const patchObject = {
            list: data.items,
            totalCount: data.totalCount,
          };

          patchState({ inProcess: patchObject });
        })
      );
  }
}
