import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { MOMENT_DATE_TIME_SECONDS_FORMAT } from '../../../app.constants';
import { Ident, PriceContract, Subject } from '../core.types';
import { environment } from '@root/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PantheonService {
  private pantheonApiUrl: string;
  constructor(private httpClient: HttpClient) {
    this.pantheonApiUrl = `${environment.apiUrl}/pantheoff/v2`;
  }

  getSubjects(): Observable<Subject[]> {
    return this.httpClient.get<Subject[]>(`${this.pantheonApiUrl}/subjects`);
  }

  getIdents(): Observable<Ident[]> {
    return this.httpClient.get<Ident[]>(`${this.pantheonApiUrl}/idents`);
  }

  getPriceContactsFromPantheonByPartnerExternalId(
    externalId: string
  ): Observable<PriceContract[]> {
    const currentDateTime = moment().format(MOMENT_DATE_TIME_SECONDS_FORMAT);
    const priceContactUrl = `${this.pantheonApiUrl}/subjects/${externalId}/priceContract`;
    return this.httpClient.get<PriceContract[]>(priceContactUrl, {
      params: {
        validFor: currentDateTime,
      },
    });
  }
}
