<form [formGroup]="form" (ngSubmit)="submit()">
  <nb-card>
    <nb-card-header class="position-relative">
      <p class="mb-0">
        {{
          (id ? 'serviceData.form.editTitle' : 'serviceData.form.createTitle')
            | translate
        }}
      </p>
      <nb-icon
        (click)="dialogRef.close()"
        icon="close"
        data-cy="sd-form-close-icon"
      ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      @if (isStaging) {
        <button
          class="btn btn-success btn-sm my-2"
          type="button"
          [disabled]="prefillDisabled()"
          (click)="prefillTestData()"
        >
          {{ 'serviceData.form.prefillData' | translate }}
        </button>
      }
      <section
        formGroupName="patientInformation"
        data-cy="patient-info-section"
      >
        <p class="caption mb-1">
          {{ 'serviceData.form.patientInformation' | translate }}
        </p>
        <hr class="mt-0" />
        <app-service-data-form-patient-information />
      </section>
      <section data-cy="service-info-section">
        <p class="caption mb-1">
          {{ 'serviceData.form.serviceInformation' | translate }}
        </p>
        <hr class="mt-0" />
        <app-service-data-form-service-information [id]="id" />
        <app-service-data-form-bundle-information [selectedDataInputTypes]="selectedDataInputTypes" />
      </section>
      <ng-container formGroupName="serviceInformation">
        <ng-container formGroupName="nipt">
          <section
            *appRequirePermission="'serviceData.edit.canEditAllFields'"
            formGroupName="currentPregnancyInformation"
            data-cy="current-pregnancy-info-section"
          >
            <p class="caption mb-1">
              {{ 'serviceData.form.currentPregnancy' | translate }}
            </p>
            <hr class="mt-0" />
            <app-service-data-form-current-pregnancy-information
              [selectedDataInputTypes]="selectedDataInputTypes"
            />
          </section>
          <section
            formGroupName="patientHistory"
            data-cy="patient-history-section"
          >
            <p class="caption mb-1">
              {{ 'serviceData.form.patientHistory' | translate }}
            </p>
            <hr class="mt-0" />
            <app-service-data-patient-history-information/>
          </section>
        </ng-container>
      </ng-container>
      <section
        formGroupName="paymentInformation"
        data-cy="payment-info-section"
      >
        <p class="caption mb-1">
          {{ 'serviceData.form.paymentInformation' | translate }}
        </p>
        <hr class="mt-0" />
        <app-service-data-form-payment-information
          [paymentTypes]="paymentTypes$ | async"
        />
      </section>
      <section data-cy="other-info-section">
        <p class="caption mb-1">
          {{ 'serviceData.form.otherInformation' | translate }}
        </p>
        <hr class="mt-0" />
        <app-service-data-form-other-information />
      </section>
    </nb-card-body>
    <nb-card-footer class="d-flex flex-row-reverse">
      <button
        nbButton
        type="submit"
        status="primary"
        data-cy="sd-form-create-btn"
        [disabled]="loading"
        [class.loading]="loading"
      >
        {{
          (id ? 'common.buttons.btnUpdate' : 'common.buttons.btnCreate')
            | translate
        }}
      </button>
    </nb-card-footer>
  </nb-card>
</form>
