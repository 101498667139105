<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">Document approval</p>
    <nb-icon icon="close" (click)="dismiss(0)"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <app-data-table [configuration]="configuration"></app-data-table>
  </nb-card-body>

  <nb-card-footer class="d-flex flex-row-reverse">
    @if (!state.errors?.length) {
      <button nbButton class="ms-2" status="primary" (click)="dismiss(1)">Approve anyway</button>
    }
    <button nbButton (click)="dismiss(0)">Cancel</button>
  </nb-card-footer>
</nb-card>
