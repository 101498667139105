import { FormGroup, ValidatorFn } from '@angular/forms';

export function crossFieldValidator(field, requiredField, value): ValidatorFn {
  return (form: FormGroup) => {
    const control = form.get(field);
    const requiredFieldControl = form.get(requiredField);
    if (!requiredFieldControl) {
      console.warn('Required field doesnt exist ', requiredField);
      return null;
    }
    if (!control) {
      console.warn('Field doesnt exist ', field);
      return null;
    }

    if (value instanceof Array) {
      if (
        value.includes(requiredFieldControl.value) &&
        (control.value === null || control.value === '')
      ) {
        control.setErrors({ required: true });
        return;
      }
    } else {
      if (
        requiredFieldControl.value === value &&
        (control.value === null || control.value === '')
      ) {
        control.setErrors({ required: true });
        return;
      }
    }

    if (control.hasError('required')) {
      delete control.errors['required'];
      control.updateValueAndValidity();
    }
  };
}
