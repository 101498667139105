<div class="form-group ps-0 pe-0 form-group-iframe">
  @if (!uri) {
    <div class="alert alert-success">
      {{ 'pii.details.noDocumentsYet' | translate }}
    </div>
  }

  <div class="pt-1 loading" [hidden]="isDocumentLoaded"></div>
  @if (initialLoad) {
    @if (isError$ | async) {
      <div class="alert alert-danger">
        {{ 'pii.details.pdfMissing' | translate }}
      </div>
    } @else {
      <app-pdf-iframe-inline class="ms-5" [url]="pdfIframeUrl$" />
    }
  }
</div>
