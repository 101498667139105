import { SortDirection } from '@app/modules/@core/core.types';

export const LABORATORYS_NAME_CONSTANTS = {
  Zagreb: 'Zagreb',
  HongKong: 'Hong Kong',
  Cologne: 'Cologne',
};

export const KIT_LABORATIRIES_LIST = ['Zagreb', 'Hong Kong', 'Cologne'];

export enum Laboratory {
  Zagreb = 'Zagreb',
  HongKong = 'Hong Kong',
  Cologne = 'Cologne',
}
export const KIT_TYPE_NIPD = 'NIPD';

export const STATUSES = {
  generated: 'Generated',
  shipped: 'Shipped',
  claimed: 'Claimed',
  returned: 'Returned',
  sentToLaboratory: 'SentToLaboratory',
  canceled: 'Canceled',
  deprovisioned: 'Deprovisioned',
  failed: 'Failed',
  locked: 'Locked',
};

export const KITS_QUERY_CONSTANTS = {
  Free: 'Free',
  True: 'True',
  False: 'False',
};

export const KITS_PAGE_SORT_SETTINGS = {
  sortDirection: SortDirection.desc,
  orderBy: 'id',
};
