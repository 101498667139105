import { AbstractControl, ValidatorFn } from '@angular/forms';

export function errorValueValidator(value: any): ValidatorFn {
  return (control: AbstractControl) => {
    if (control && control.value === value) {
      return { errorValue: true };
    }

    return null;
  };
}
