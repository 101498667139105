import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AccountManager } from '../../core.types';
import { AccountManagersService } from '../../services/account-managers.service';
import { Logger } from '../../services/logger.service';
import {
  ActivateAccountManagerAction,
  AssignCountryToAccountManagerAction,
  AssignSubAccountManager,
  DeactivateAccountManagerAction,
  GenerateAccountManagerAction,
  LoadAccountManagerAction,
  LoadAccountManagersAction,
  SetAccountManagersAction,
  UnassignCountryFromAccountManagerAction,
  UnassignSubAccountManager,
  UpdateAccountManagerAction,
  UpdateSubAccountManagers,
} from './account-managers.actions';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { delay } from 'rxjs/operators';

const ACCOUNT_MANAGERS_STATE_NAME = 'accountManagers';

export interface AccountManagersStateModel {
  accountManagers: AccountManager[];
}

@State<AccountManagersStateModel>({
  name: ACCOUNT_MANAGERS_STATE_NAME,
  defaults: {
    accountManagers: [],
  },
})
@Injectable()
export class AccountManagerState {
  private log = new Logger('AccountManagerState');

  constructor(private accountManagersService: AccountManagersService) {}

  @Selector()
  static getAllAccountManagersList(state: AccountManagersStateModel) {
    return state.accountManagers;
  }

  @Selector([AccountManagerState.getAllAccountManagersList])
  static getAccountManagersList(
    state: AccountManagersStateModel,
    accountManagers
  ) {
    return accountManagers.filter(
      (a) => a.subAccountManagers == null || a.subAccountManagers.length === 0
    );
  }

  @Selector([AccountManagerState.getAllAccountManagersList])
  static getLineManagersList(
    state: AccountManagersStateModel,
    accountManagers
  ): AccountManager[] {
    return accountManagers.filter(
      (l) => l.subAccountManagers != null && l.subAccountManagers.length > 0
    );
  }

  @Selector()
  static getAccountManagerById(state: AccountManagersStateModel) {
    return (accountManagerId: string) =>
      state.accountManagers.find((ac) => ac.id === accountManagerId);
  }

  @Selector()
  static getSubAccountManagersById(state: AccountManagersStateModel) {
    return (accountManagerId: string) => {
      const subAccountManagersIds = state.accountManagers.find(
        (ac) => ac.id === accountManagerId
      ).subAccountManagers;
      return state.accountManagers.filter((accountManager) =>
        subAccountManagersIds.includes(accountManager.id)
      );
    };
  }

  @Selector([AccountManagerState.getLineManagersList])
  static getLineManagerBySubManager(
    state: AccountManagersStateModel,
    lineManagers
  ) {
    return (accountManagerId: string) =>
      lineManagers.find((lm) =>
        lm.subAccountManagers.includes(accountManagerId)
      );
  }

  @Action(LoadAccountManagersAction)
  loadAccountManagersList({
    dispatch,
  }: StateContext<AccountManagersStateModel>) {
    this.log.debug('load account managers');
    return this.accountManagersService
      .getAccountManagers()
      .pipe(
        switchMap((accountManagers) =>
          dispatch(new SetAccountManagersAction(accountManagers))
        )
      );
  }

  @Action(LoadAccountManagerAction)
  loadAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { id }: LoadAccountManagerAction
  ) {
    this.log.debug('load account managers');
    return this.accountManagersService
      .getAccountManager(id)
      .pipe(
        switchMap((accountManager) =>
          dispatch(new SetAccountManagersAction([accountManager]))
        )
      );
  }

  @Action(SetAccountManagersAction)
  setAccountManagers(
    { patchState, getState }: StateContext<AccountManagersStateModel>,
    { accountManagers }: SetAccountManagersAction
  ) {
    const filteredAccountManagers = [...getState().accountManagers].filter(
      (accountManager) =>
        !accountManagers.find(
          (newAccountManager) => newAccountManager.id === accountManager.id
        )
    );
    return patchState({
      accountManagers: filteredAccountManagers.concat(accountManagers),
    });
  }

  @Action(GenerateAccountManagerAction)
  generateAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { accountManager, subAccountManagers }: GenerateAccountManagerAction
  ) {
    return this.accountManagersService
      .generateAccountManager(accountManager)
      .pipe(
        switchMap(({ id }) => {
          let obs: Observable<any>;
          if (subAccountManagers) {
            obs = dispatch(
              new UpdateSubAccountManagers(id, subAccountManagers)
            ).pipe(delay(500));
          } else {
            obs = of(null).pipe(delay(300));
          }
          return obs.pipe(
            switchMap(() => dispatch(new LoadAccountManagerAction(id)))
          );
        })
      );
  }

  @Action(UpdateAccountManagerAction)
  updateAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { accountManager, subAccountManagers }: UpdateAccountManagerAction
  ) {
    return this.accountManagersService
      .updateAccountManager(accountManager.id, accountManager)
      .pipe(
        switchMap(({ id }) => {
          let obs: Observable<any>;
          if (subAccountManagers) {
            obs = dispatch(
              new UpdateSubAccountManagers(id, subAccountManagers)
            ).pipe(delay(500));
          } else {
            obs = of(null).pipe(delay(300));
          }
          return obs.pipe(
            switchMap(() => dispatch(new LoadAccountManagerAction(id)))
          );
        })
      );
  }

  @Action(ActivateAccountManagerAction)
  activateAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { id }: ActivateAccountManagerAction
  ) {
    return this.accountManagersService.activateAccountManager(id);
    // TODO: Update account manager properties
  }

  @Action(DeactivateAccountManagerAction)
  deactivateAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { id }: DeactivateAccountManagerAction
  ) {
    return this.accountManagersService.deactivateAccountManager(id);
    // TODO: Update account manager properties
  }

  @Action(AssignCountryToAccountManagerAction)
  assignCountryToAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { id, countryName }: AssignCountryToAccountManagerAction
  ) {
    return this.accountManagersService
      .assignCountryToAccountManager(id, countryName)
      .pipe(
        delay(500),
        switchMap(() => dispatch(new LoadAccountManagerAction(id)))
      );
  }

  @Action(UnassignCountryFromAccountManagerAction)
  unassignCountryFromAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { id, countryName }: UnassignCountryFromAccountManagerAction
  ) {
    return this.accountManagersService
      .unassignCountryToAccountManager(id, countryName)
      .pipe(
        delay(500),
        switchMap(() => dispatch(new LoadAccountManagerAction(id)))
      );
  }

  @Action(UpdateSubAccountManagers)
  updateSubAccountManagers(
    { getState, dispatch }: StateContext<AccountManagersStateModel>,
    { id, subAccountManagers }: UpdateSubAccountManagers
  ) {
    const currentSubAccountManagers = getState().accountManagers.find(
      (accountManager) => accountManager.id === id
    ).subAccountManagers;

    return forkJoin([
      ...currentSubAccountManagers
        .filter((manager) => !subAccountManagers.includes(manager))
        .map((mid) => dispatch(new UnassignSubAccountManager(id, mid))),
      ...subAccountManagers
        .filter((manager) => !currentSubAccountManagers.includes(manager))
        .map((mid) => dispatch(new AssignSubAccountManager(id, mid))),
    ]);
  }

  @Action(AssignSubAccountManager)
  assignSubAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { id, subAccountManager }: AssignSubAccountManager
  ) {
    return this.accountManagersService.assignSubAccountManager(
      id,
      subAccountManager
    );
  }

  @Action(UnassignSubAccountManager)
  unassignSubAccountManager(
    { dispatch }: StateContext<AccountManagersStateModel>,
    { id, subAccountManager }: UnassignSubAccountManager
  ) {
    return this.accountManagersService.unassignSubAccountManager(
      id,
      subAccountManager
    );
  }
}
