import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Logger } from '@core/services/logger.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { delay, takeUntil } from 'rxjs/operators';
import { LoadingCloseReason } from '../../shared.types';

interface LoadingOptions {
  message: string;
  observableToFinish$: Observable<any>;
}

@Injectable()
export class LoadingState {
  options: LoadingOptions;
  dialog: NbDialogRef<any>;
}

@Injectable()
export class LoadingService {
  constructor(
    private nbDialogService: NbDialogService,
    private state: LoadingState
  ) {}

  wait(
    options: LoadingOptions
  ): Observable<{ closeReason: LoadingCloseReason; observablePayload: any }> {
    this.state.options = options;
    this.state.dialog = this.nbDialogService.open(LoadingDialogComponent);
    return this.state.dialog.onClose;
  }
}

@Component({
  selector: 'app-loading-dialog',
  styleUrls: ['loading-dialog.component.scss'],
  templateUrl: 'loading-dialog.component.html',
})
export class LoadingDialogComponent implements OnInit, OnDestroy {
  options: LoadingOptions;

  private readonly log = new Logger('LoadingDialogComponent');
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private state: LoadingState) {
    this.options = state.options;
  }

  cancel() {
    this.state.dialog.close({
      closeReason: LoadingCloseReason.Cancel,
    });
  }

  ngOnInit(): void {
    let payload = null;
    let hasError = false;

    this.options.observableToFinish$
      .pipe(
        delay(500),
        takeUntil(this.unsubscribe$),
        tap(
          (load: any) => {
            payload = load;
          },
          (err) => {
            this.log.error(err);
            hasError = true;
          }
        )
      )
      .subscribe(
        () => {
          this.state.dialog.close({
            closeReason: LoadingCloseReason.LoadingFinished,
            observablePayload: payload,
          });
        },
        () => {
          this.state.dialog.close({
            closeReason: LoadingCloseReason.Error,
          });
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
