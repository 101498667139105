import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogRef,
  NbIconModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { SamplesListComponent } from '@app/modules/service-data/components/sample/samples-list/samples-list.component';

@Component({
  selector: 'app-sample-list-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbIconModule,
    TranslateModule,
    SamplesListComponent,
  ],
  styles: `
    nb-card {
      min-width: 850px;
    }
  `,
  templateUrl: './sample-list-dialog.component.html',
})
export class SampleListDialogComponent {
  @Input() sampleIds: string[];
  @Input() serviceDataId: string;
  constructor(protected dialogRef: NbDialogRef<SampleListDialogComponent>) {}
}
