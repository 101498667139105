import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-pdf-iframe-inline',
  templateUrl: './pdf-iframe-inline.component.html',
  styleUrls: ['./pdf-iframe-inline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfIframeInlineComponent {
  url$: Observable<string> = null;
  constructor(public sanitizer: DomSanitizer) {}

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('url')
  set _url(url$: Observable<string>) {
    this.url$ = url$.pipe(distinctUntilChanged());
  }

  public IFrameUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      '/assets/web/viewer.html?file=' + encodeURIComponent(url)
    );
  }
}
