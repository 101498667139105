import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export function inputAtLeastOneValidator(): ValidatorFn {
  return (controlGroup: FormGroup) => {
    const controls = controlGroup.controls;

    if (
      !Object.values(controls).some(
        (control: FormControl) => control.value !== ''
      )
    ) {
      return {
        atLeastOneRequired: {
          text: 'At least one should be selected',
        },
      };
    }
    return null;
  };
}
