import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

export interface SelectionListTemplateOptions {
  actionsTemplate: TemplateRef<any>;
  itemLabelTemplate: TemplateRef<any>;
}

export interface SelectionListOptions {
  items: Observable<any[]>;
  selectedItemId: Observable<string>;
}

export type SelectionListActionTypes = 'select' /*| ''*/;

export type SelectionListActionType =
  SelectionListActionSelected /*| CreateInterface*/;

export interface SelectionListActionSelected {
  actionType: SelectionListActionTypes;
  payload: {
    selectedItem: any;
  };
}

export type ModelFormGroup<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K]>;
}>;

export enum ConfirmCloseReason {
  Yes,
  No,
}

export enum LoadingCloseReason {
  Cancel,
  Error,
  LoadingFinished,
}

export enum DateTimeType {
  Date = 'DATE',
  DateTime = 'DATETIME',
}

export const PII_PILL_STAGES = [
  { class: 'bg-grey', width: 33.3 },
  { class: 'bg-yellow', width: 33.3 },
  { class: 'bg-green', width: 33.4 },
];

export const FAIL_PII_PILL_STAGES = [
  { class: 'bg-grey', width: 33.3 },
  { class: 'bg-yellow', width: 33.3 },
  { class: 'bg-red', width: 33.4 },
];
