import { ApiFilters } from '@core/core.types';
import moment, { Moment } from 'moment';
import {
  Document,
  DocumentApprovalRequest,
  DocumentStatus,
} from '../../../treatments/treatment.types';

export const DOCUMENTS_STATE_NAME = 'documents';

export class LoadDocumentById {
  static type = `[${DOCUMENTS_STATE_NAME}] LoadDocumentById`;
  constructor(
    public documentId: string,
    public reload: boolean = false
  ) {}
}

export class ArchiveDocumentAction {
  static type = `[${DOCUMENTS_STATE_NAME}] ArchiveDocument`;
  constructor(public documentId: string) {}
}

export class ArchiveDocumentsAction {
  static type = `[${DOCUMENTS_STATE_NAME}] ArchiveDocuments`;
  constructor(public documentIds: string[]) {}
}

export class DeleteDocumentAction {
  static type = `[${DOCUMENTS_STATE_NAME}] DeleteDocument`;
  constructor(public documentId: string) {}
}

export class LoadUploadedDocumentsAction {
  static type = `[${DOCUMENTS_STATE_NAME}] LoadUploadedDocuments`;
  constructor(
    public params?: ApiFilters,
    public prepend = false
  ) {}
}

export class RemoveFromUploadedList {
  static type = `[${DOCUMENTS_STATE_NAME}] RemoveFromUploadedList`;
  constructor(public ids: string[]) {}
}

export class LoadMultipleDocumentsAction {
  static type = `[${DOCUMENTS_STATE_NAME}] LoadMultipleDocuments`;
  constructor(public params?: ApiFilters) {}
}

export class AddUploadedDocumentAction {
  static type = `[${DOCUMENTS_STATE_NAME}] AddUploadedDocument`;
  constructor(public documentId: string) {}
}

export class AddUploadedDocumentsAction {
  static type = `[${DOCUMENTS_STATE_NAME}] AddUploadedDocuments`;
  constructor(public documentIds: string[]) {}
}

export class DisapproveDocumentAction {
  static type = `[${DOCUMENTS_STATE_NAME}] DisapproveDocument`;
  constructor(
    public documentId: string,
    public piiId: string
  ) {}
}

export class ApproveDocumentAction {
  static type = `[${DOCUMENTS_STATE_NAME}] ApproveDocument`;
  constructor(
    public documentId: string,
    public piiId: string,
    public requestBody?: DocumentApprovalRequest
  ) {}
}

export class SetDocumentsById {
  static type = `[${DOCUMENTS_STATE_NAME}] SetDocumentsById`;
  constructor(
    public documents: Document | Document[],
    public appendToUploaded = false
  ) {}
}

export class AddDocumentInteraction {
  static type = `[${DOCUMENTS_STATE_NAME}] AddDocumentInteraction`;
  constructor(
    public documentId: string,
    public status: DocumentStatus,
    public interactedBy: string | null = null,
    public timestamp: Moment = moment()
  ) {}
}
