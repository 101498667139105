export const awbModels = {
  pregenerated: 'Pregenerated',
  dhlApi: 'DHL API',
};

export const awbCustomerReferenceNumbers = [
  { value: '954187211', label: '954187211 (Slovenia)' },
  { value: '449100277', label: '449100277 (Kosovo)' },
  { value: '980084368', label: '980084368 (Mexico)' },
  { value: '314117214', label: '314117214 (Croatia)' },
];

export const awbDescriptions = [
  'Exempt human specimen',
  'UN3373 biological substance, Category B, 1 package',
];

export const awbPackageDescriptions = {
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  'Exempt human specimen':
    'Exempt human specimen, Non Infectious, Non-Contagious',
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  'UN3373 biological substance, Category B, 1 package':
    'UN3373 Biological Substance, Category B, Non Infectious, Non-Contagious',
};

export const awbDomesticPackageDescriptions = ['Exempt human specimen'];

export const awbDomesticDhlProductCode = [
  {
    value: 'N',
    label: 'N (Domestic Express)',
  },
  {
    value: 'O',
    label: 'O (Domestic 10:30)',
  },
];
