<div class="form-group" [formGroup]="awbAddressForm">
  @if (awbModel !== awbModels.pregenerated) {
    <div class="row align-items-center form-group">
      <div class="col-4">
        <label for="name">{{ 'waybillGeneration.fullName' | translate }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          type="text"
          formControlName="name"
          name="name"
          id="name"
          placeholder="{{
            'waybillGeneration.placeholders.fullName' | translate
          }}"
        />
      </div>
    </div>
    <div class="row align-items-center form-group">
      <div class="col-4">
        <label for="company">{{
          'waybillGeneration.companyName' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          type="text"
          formControlName="company"
          name="company"
          id="company"
          placeholder="{{
            'waybillGeneration.placeholders.companyName' | translate
          }}"
        />
      </div>
    </div>
    <div class="row align-items-center form-group">
      <div class="col-4">
        <label for="address">{{
          'waybillGeneration.address' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          type="text"
          formControlName="address"
          name="address"
          id="address"
          placeholder="{{
            'waybillGeneration.placeholders.address' | translate
          }}"
        />
      </div>
    </div>
    <div class="row align-items-center form-group">
      <div class="col-4">
        <label for="city">{{ 'waybillGeneration.city' | translate }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          type="text"
          formControlName="city"
          name="city"
          id="city"
          placeholder="{{ 'waybillGeneration.placeholders.city' | translate }}"
        />
      </div>
    </div>
    <div class="row align-items-center form-group">
      <div class="col-4">
        <label for="zip">{{ 'waybillGeneration.zip' | translate }}</label>
      </div>
      <div class="col-8">
        <input
          type="text"
          fullWidth
          nbInput
          formControlName="zip"
          name="zip"
          id="zip"
          placeholder="{{
            'waybillGeneration.placeholders.postCode' | translate
          }}"
        />
      </div>
    </div>
    <div class="row align-items-center form-group">
      <div class="col-4">
        <label for="country">{{
          'waybillGeneration.country' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          type="text"
          formControlName="country"
          name="country"
          id="country"
          placeholder="{{
            'waybillGeneration.placeholders.country' | translate
          }}"
        />
      </div>
    </div>
    <div class="row align-items-center form-group">
      <div class="col-4">
        <label for="phone">{{ 'waybillGeneration.phone' | translate }}</label>
      </div>
      <div class="col-8">
        <input
          type="text"
          fullWidth
          nbInput
          formControlName="phone"
          name="phone"
          id="phone"
          placeholder="{{
            'waybillGeneration.placeholders.contactPhone' | translate
          }}"
        />
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-8 offset-4">
        <button
          class="btn btn-primary"
          type="button"
          [class.loading]="isLoading$ | async"
          [disabled]="isLoading$ | async"
          (click)="generateAwb()"
          data-cy="generate-awb-btn"
        >
          {{ 'waybillGeneration.generate' | translate }}
        </button>
      </div>
    </div>
  }
</div>
