import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { combineLatest } from 'rxjs';

@Directive({
  selector: '[appInputValidationStatus]',
  standalone: true,
})
export class InputValidationStatusDirective implements OnInit {
  constructor(
    private control: NgControl,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    const element = this.el.nativeElement;
    const control = this.control.control;
    combineLatest([control.statusChanges, control.valueChanges]).subscribe(
      () => {
        this.renderer.removeClass(element, 'status-success');
        this.renderer.removeClass(element, 'status-danger');
        if (control && (control.dirty || control.touched)) {
          if (control.valid) {
            this.renderer.addClass(element, 'status-success');
          } else if (control.invalid) {
            this.renderer.addClass(element, 'status-danger');
          }
        }
      }
    );
  }
}
