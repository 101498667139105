import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DOCUMENT_DATE_TIME_FORMAT } from '@app/app.constants';
import { PdfPreviewTemplateService } from '@app/modules/pdf-request-form/services/pdf-preview-template.service';
import {
  ExcelFileName,
  ExportDialogDataType,
  ExportType,
  ExportTypeNames,
  ExtendedProductInventoryItem,
  StatusResponse,
} from '@app/modules/service-data/service-data.types';
import { RuntimeStatus } from '@core/core.types';
import { NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { PasswordDialogComponent } from '@shared/components/password-dialog/password-dialog.component';
import {
  ProgressData,
  ProgressDialogComponent,
} from '@shared/components/progress/progress-dialog.component';
import { WarningService } from '@shared/components/warning/warning-dialog.component';
import moment from 'moment';
import { BehaviorSubject, switchMap, take } from 'rxjs';
import { AccountUtilsService } from './account-utils.service';
import { ExportersService } from './exporters.service';

@Injectable({
  providedIn: 'root',
})
export class ExportersUtilsService {
  constructor(
    private nbDialogService: NbDialogService,
    private datePipe: DatePipe,
    private exportersService: ExportersService,
    private accountUtils: AccountUtilsService,
    private pdfPreviewTemplateService: PdfPreviewTemplateService,
    private warningService: WarningService,
    private translateService: TranslateService
  ) {}

  public async downloadFile(
    fileName: string,
    downloadFile: boolean,
    instanceId: string
  ) {
    const blobUri = await this.exportersService
      .getReadBlobUri(fileName)
      .toPromise();

    while (true) {
      const state = await this.exportersService
        .checkExporterStatus(instanceId)
        .toPromise();
      if (state.runtimeStatus === RuntimeStatus.Completed) {
        if (downloadFile) {
          window.location.href = blobUri.uri;
        }
        return blobUri.uri;
      } else if (state.runtimeStatus === RuntimeStatus.Failed) {
        return null;
      }
      await this.delay(5000);
    }
  }

  private async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async export(
    filters: { [key: string]: string },
    type: ExportType,
    fileNameToken: string,
    downloadFile = true,
    numbered = false,
    password?: string,
    anonymous?: boolean
  ): Promise<boolean | string> {
    const fileName = `${fileNameToken}_${this.datePipe.transform(
      new Date(),
      DOCUMENT_DATE_TIME_FORMAT
    )}${type === ExportType.BGIInformationSheet ? '.zip' : '.xlsx'}`;

    const countries = await this.accountUtils
      .getRoleCountries$()
      .pipe(take(1))
      .toPromise();
    try {
      const status: StatusResponse = await this.exportersService
        .getCreateBlobUri(fileName)
        .pipe(
          switchMap((response) =>
            this.exportersService.exportNipts(
              type,
              response.uri,
              countries,
              numbered,
              filters,
              password,
              anonymous
            )
          )
        )
        .toPromise();

      return await this.downloadFile(fileName, downloadFile, status.InstanceId);
    } catch (e) {
      return false;
    }
  }

  public openProgressDialog(
    title: string,
    password?: string
  ): BehaviorSubject<ProgressData> {
    const progressSubject = new BehaviorSubject<ProgressData>({
      value: 0,
      status: `Preparing a document. Please wait. `,
    });

    const dialogRef = this.nbDialogService.open(ProgressDialogComponent, {
      context: {
        progressSubject,
        title,
        password,
      },
    });

    progressSubject.subscribe((data) => {
      if (data.value === 100) {
        setTimeout(() => dialogRef.close(), 1000);
        if (password && data.success) {
          this.nbDialogService.open(PasswordDialogComponent, {
            context: { password },
          });
        }
      }
    });

    return progressSubject;
  }

  public async exportSelected(
    exportDialogDataType: ExportDialogDataType,
    ids: string[],
    type: ExportType,
    fileNameToken: string,
    downloadFile = true,
    numbered = false,
    password?: string,
    anonymous?: boolean
  ): Promise<boolean | string> {
    const fileName = `${fileNameToken}_${this.datePipe.transform(
      new Date(),
      DOCUMENT_DATE_TIME_FORMAT
    )}${type === ExportType.BGIInformationSheet ? '.zip' : '.xlsx'}`;

    try {
      const status: StatusResponse = await this.exportersService
        .getCreateBlobUri(fileName)
        .pipe(
          switchMap((response) =>
            this.exportersService.exportSelectedNipts(
              exportDialogDataType,
              ids,
              type,
              response.uri,
              numbered,
              password,
              anonymous
            )
          )
        )
        .toPromise();

      return await this.downloadFile(fileName, downloadFile, status.InstanceId);
    } catch (e) {
      return false;
    }
  }

  public getRequestFormTemplate(
    pii: ExtendedProductInventoryItem,
    previousSampleKitId: string,
    { index }
  ) {
    if (pii) {
      return {
        content: this.pdfPreviewTemplateService.createTemplate({
          pii,
          previousSampleKitId,
        }),
        filename: `${index + 1}-${pii.kitId}.pdf`,
      };
    }
    return null;
  }

  public async exportKits(
    previousMonthOnly: boolean,
    name: string,
    filenameExportType: string
  ) {
    const fileName = `Kits_${filenameExportType}_${this.datePipe.transform(
      new Date(),
      DOCUMENT_DATE_TIME_FORMAT
    )}.xlsx`;

    const progressSubject = this.openProgressDialog(name);

    const status: StatusResponse = await this.exportersService
      .getCreateBlobUri(fileName)
      .pipe(
        switchMap((response) =>
          this.exportersService.inPartnerWarehouseExport(
            response.uri,
            // If monthly export, pass from / to params
            ...(previousMonthOnly
              ? [
                  moment().add(-1, 'month').startOf('month').toISOString(),
                  moment().add(-1, 'month').endOf('month').toISOString(),
                ]
              : [])
          )
        )
      )
      .toPromise();

    await this.downloadFile(fileName, true, status.InstanceId);

    progressSubject.next({
      value: 100,
      status: `Done`,
      success: false,
    });
  }

  public async exportPartnerDoctors(filters: { [key: string]: string }) {
    const fileName = `Partners_doctors_${this.datePipe.transform(
      new Date(),
      DOCUMENT_DATE_TIME_FORMAT
    )}.xlsx`;

    const progressSubject = this.openProgressDialog(`Doctors export`);

    const status: StatusResponse = await this.exportersService
      .getCreateBlobUri(fileName)
      .pipe(
        switchMap((response) =>
          this.exportersService.doctorsByPartnersExport(response.uri, filters)
        )
      )
      .toPromise();

    if (!(await this.downloadFile(fileName, true, status.InstanceId))) {
      const { title, message } = this.translateService.instant(
        'partnersPage.errors.fileDoesNotExist'
      );
      this.warningService.showWarning({ title, message });
    }
    progressSubject.next({
      value: 100,
      status: `Done`,
      success: false,
    });
  }

  public async exportPartners(filters: { [key: string]: string }) {
    const fileName = `Partners_${this.datePipe.transform(
      new Date(),
      DOCUMENT_DATE_TIME_FORMAT
    )}.xlsx`;

    const progressSubject = this.openProgressDialog(`Partners export`);

    const status: StatusResponse = await this.exportersService
      .getCreateBlobUri(fileName)
      .pipe(
        switchMap((response) =>
          this.exportersService.allPartnersExport(response.uri, filters)
        )
      )
      .toPromise();

    if (!(await this.downloadFile(fileName, true, status.InstanceId))) {
      const { title, message } = this.translateService.instant(
        'partnersPage.errors.fileDoesNotExist'
      );
      this.warningService.showWarning({ title, message });
    }
    progressSubject.next({
      value: 100,
      status: `Done`,
      success: false,
    });
  }

  public async exportOnlinePartners(filters: { [key: string]: string }) {
    const fileName = `Online_Partners_${this.datePipe.transform(
      new Date(),
      DOCUMENT_DATE_TIME_FORMAT
    )}.xlsx`;

    const progressSubject = this.openProgressDialog(`Online partners export`);

    const status: StatusResponse = await this.exportersService
      .getCreateBlobUri(fileName)
      .pipe(
        switchMap((response) =>
          this.exportersService.onlinePartnersExport(response.uri, filters)
        )
      )
      .toPromise();

    if (!(await this.downloadFile(fileName, true, status.InstanceId))) {
      const { title, message } = this.translateService.instant(
        'partnersPage.errors.fileDoesNotExist'
      );
      this.warningService.showWarning({ title, message });
    }
    progressSubject.next({
      value: 100,
      status: `Done`,
      success: false,
    });
  }

  public async exportDoctors(doctorIds: string[]) {
    const fileName = `Doctors_${this.datePipe.transform(
      new Date(),
      DOCUMENT_DATE_TIME_FORMAT
    )}.xlsx`;

    const status: StatusResponse = await this.exportersService
      .getCreateBlobUri(fileName)
      .pipe(
        switchMap((response) =>
          this.exportersService.doctorsExport(doctorIds, response.uri)
        )
      )
      .toPromise();
    await this.downloadFile(fileName, true, status.InstanceId);
  }

  public async ExportBgiAndInfoSheet(piiIds: string[], sampleIds: string[]) {
    const progressSubject = this.openProgressDialog(
      ExportTypeNames.BGIInformationSheetAndInformationSheet
    );

    let success = !!(await this.exportSelected(
      ExportDialogDataType.Pii,
      piiIds,
      ExportType.BGIInformationSheet,
      ExcelFileName.BGIInformationSheet,
      true,
      false
    ));

    if (success) {
      success = !!(await this.exportSelected(
        ExportDialogDataType.Sample,
        sampleIds,
        null,
        ExcelFileName.InformationSheet,
        true,
        false
      ));
    }

    progressSubject.next({ value: 100, status: 'Done', success });

    return success;
  }
}
