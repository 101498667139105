<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="paymentType">{{
          'serviceData.form.paymentType' | translate
        }}</label>
      </div>
      <div class="col-8">
        @if (paymentTypes) {
          <nb-select
            class="w-100"
            id="paymentType"
            appInputValidationStatus
            formControlName="paymentType"
            [placeholder]="
              'serviceData.form.placeholders.paymentType' | translate
            "
            data-cy="payment-type-select"
          >
            @for (type of paymentTypes; track type) {
              <nb-option [value]="PaymentType[type]">{{
                PaymentType[type]
              }}</nb-option>
            }
          </nb-select>
        } @else {
          <nb-select
            fullWidth
            placeholder="{{
              'serviceData.form.placeholders.selectPartnerFirst' | translate
            }}"
            formControlName="paymentType"
          />
        }
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="paymentType"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    @if (
      form.get('paymentType').value === PaymentType.Customer1 ||
      form.get('paymentType').value === PaymentType.Customer2
    ) {
      <div class="row align-items-center">
        <div class="col-4">
          <label for="notes">{{ 'serviceData.form.payer' | translate }}</label>
        </div>
        <div class="col-8">
          <input
            fullWidth
            nbInput
            id="payer"
            formControlName="notes"
            [placeholder]="'serviceData.form.placeholders.payer' | translate"
            data-cy="payer-input"
          />
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="notes"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    }
  </div>
</div>
<div class="row form-group">
  <div class="col-6 align-self-center">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="doctorInvoiceNumber">{{
          'serviceData.form.doctorInvoiceNumber' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          nbInput
          class="w-100"
          id="doctorInvoiceNumber"
          formControlName="doctorInvoiceNumber"
          [placeholder]="
            'serviceData.form.placeholders.doctorInvoiceNumber' | translate
          "
          data-cy="doctor-invoice-number-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="doctorInvoiceNumber"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="laboratoryInvoiceNumber">{{
          'serviceData.form.laboratoryInvoiceNumber' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          nbInput
          class="w-100"
          id="laboratoryInvoiceNumber"
          formControlName="laboratoryInvoiceNumber"
          [placeholder]="
            'serviceData.form.placeholders.laboratoryInvoiceNumber' | translate
          "
          data-cy="laboratory-invoice-number-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="laboratoryInvoiceNumber"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>
