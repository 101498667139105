import { ProductInventory } from '@app/modules/@core/core.types';

export const PRODUCT_INVENTORY_TYPE_NAME = 'productInventory';

export class LoadProductInventoryById {
  static type = `[${PRODUCT_INVENTORY_TYPE_NAME}] LoadProductInventoryById`;
  constructor(public productInventoryId: string, public refresh = false) {}
}

export class DeleteProductInventory {
  static type = `[${PRODUCT_INVENTORY_TYPE_NAME}] DeleteProductInventoryById`;
  constructor(public productInventoryId: string) {}
}

export class UpdateProductInventoryItemAction {
  static type = `[${PRODUCT_INVENTORY_TYPE_NAME}] UpdateProductInventoryItem`;
  constructor(public productInventoryItem: ProductInventory) {}
}

export class LoadProductInventoriesByIdsAction {
  static type = `[${PRODUCT_INVENTORY_TYPE_NAME}] LoadProductInventoriesByIds`;
  constructor(public productInventoriesIds: string[], public refresh = false) {}
}
