import {
  AfterViewInit,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appDhlWaybill]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DhlWaybillDirective),
      multi: true,
    },
  ],
})
export class DhlWaybillDirective
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  private onTouched: any;
  private onChange: any;
  private disposeListener: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('input', ['$event'])
  onInputListener(event) {
    this.onChange(event.target.value);
  }

  @HostListener('change', ['$event'])
  onChangeListener(event) {
    this.onChange(event.target.value);
  }

  @HostListener('blur')
  onBlurListener() {
    this.onTouched();
  }

  ngAfterViewInit() {
    this.disposeListener = this.renderer.listen(
      this.elementRef.nativeElement,
      'keydown',
      (event) => {
        if (event.key === '-') {
          event.preventDefault();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.disposeListener) {
      this.disposeListener();
    }
  }

  writeValue(value: any): void {
    setTimeout(() => {
      this.elementRef.nativeElement.value = this.formatWaybill(value || '');
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = (value) => {
      let newValue = this.formatWaybill(value);
      this.elementRef.nativeElement.value = newValue;
      newValue = newValue?.replace(/\D/g, '').substring(0, 10);
      fn(newValue);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  formatWaybill(value: string): string {
    const newValue = value.replace(/\D/g, '');
    if (!newValue.length) {
      return '';
    } else if (newValue.length <= 2) {
      return newValue.replace(/^(\d{0,2})/, '$1');
    } else if (newValue.length <= 6) {
      return newValue.replace(/^(\d{0,2})(\d{0,4})/, '$1-$2');
    } else {
      return newValue
        .substring(0, 10)
        .replace(/^(\d{0,2})(\d{0,4})(\d{0,4})/, '$1-$2-$3');
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }
}
