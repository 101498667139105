import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sample } from '@app/modules/service-data/service-data.types';
import { AwbService } from '@core/services/awb.service';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { SharedModule } from '@shared/shared.module';
import { Observable, first, map, of } from 'rxjs';

@Component({
  selector: 'app-sample-details-dhl-waybill',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sample-details-dhl-waybill.component.html',
})
export class SampleDetailsDhlWaybillComponent implements OnInit {
  @Output() showPDF = new EventEmitter();
  @Input() sample: Sample;

  protected dataTableConfiguration: DataTableConfiguration;

  constructor(private awbService: AwbService) {}

  ngOnInit(): void {
    const rows$ = of(this.sample).pipe(
      map((sample) => [
        {
          fileName: `${sample.shippingInformation.waybill}-waybill.pdf`,
          documentId: sample.shippingInformation.documentId,
        },
      ])
    );

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: DataTableActionsLocation.RIGHT,
      tableHeadClasses: '',
      tableCellClasses: 'align-middle',
      singleActions: [
        {
          type: DataTableActionType.BUTTON,
          name: 'Preview document',
          icon: 'file-text-outline',
          action: this.onPreviewDocument.bind(this),
        },
      ],
      columns: [
        {
          label: 'treatmentDetailsDialog.documentFileName',
          property: 'fileName',
          customStyle: 'font-weight: bold',
        },
      ],
      actionsClasses: 'w-15 text-end',
      rows$: rows$,
    };

    this.getWaybillUri().subscribe((uri: string) => this.showPDF.emit(uri));
  }

  private getWaybillUri(): Observable<string> {
    return this.awbService
      .getWaybill(this.sample.shippingInformation.documentId)
      .pipe(
        first(),
        map(({ payload }) => {
          // decode base64 string, remove space for IE compatibility
          const binary = atob(payload.replace(/\s/g, ''));
          const len = binary.length;
          const buffer = new ArrayBuffer(len);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
          }
          const newBlob = new Blob([view], { type: 'application/pdf' });

          return window.URL.createObjectURL(newBlob);
        })
      );
  }

  private onPreviewDocument(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.getWaybillUri().subscribe((uri: string) => window.open(uri, 'blank'));
  }
}
