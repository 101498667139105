import { Injectable } from '@angular/core';
import { Util } from '@core/utils/core.util';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserAccount } from '../../core.types';
import { Logger } from '../../services/logger.service';
import { UserAccountService } from '../../services/user-accounts.service';
import {
  LoadUserByIdAction,
  LoadUsersByIdsAction,
  UpdateAccountPasswordAction,
} from './user-accounts.actions';

const USER_ACCOUNT_STATE_NAME = 'userAccounts';

export interface UserAccountsStateModel {
  users?: { [id: string]: UserAccount };
}

@State<UserAccountsStateModel>({
  name: USER_ACCOUNT_STATE_NAME,
  defaults: {
    users: {},
  },
})
@Injectable()
export class UserAccountsState {
  private readonly log = new Logger(this.constructor.name);

  constructor(private userAccountService: UserAccountService) {}

  @Selector()
  static getUserById({ users }: UserAccountsStateModel) {
    return (userId: string) => users[userId];
  }

  @Action(LoadUserByIdAction)
  getUser(
    { getState, patchState }: StateContext<UserAccountsStateModel>,
    { userId, refresh }: LoadUserByIdAction
  ) {
    const { users } = getState();
    if (refresh || !Object.prototype.hasOwnProperty.call(users, userId)) {
      if (!Object.prototype.hasOwnProperty.call(users, userId)) {
        patchState({
          users: {
            ...getState().users,
            [userId]: undefined,
          },
        });
      }
      return this.userAccountService.getUser(userId).pipe(
        switchMap((user: UserAccount) =>
          of(
            patchState({
              users: {
                ...getState().users,
                [userId]: user ? user : null,
              },
            })
          )
        )
      );
    }
  }
  @Action(LoadUsersByIdsAction)
  getUsers(
    { getState, patchState }: StateContext<UserAccountsStateModel>,
    { userIds, refresh }: LoadUsersByIdsAction
  ) {
    const { users } = getState();

    userIds = userIds.filter(
      (userId) =>
        refresh || !Object.prototype.hasOwnProperty.call(users, userId)
    );

    userIds = Util.removeDuplicates(userIds);

    if (!userIds.length) {
      return of(null);
    }

    patchState({
      users: {
        ...userIds.reduce(
          (acc, userId) => ({ ...acc, [userId]: undefined }),
          {}
        ),
        ...getState().users,
      },
    });

    return this.userAccountService.getUsers(userIds).pipe(
      switchMap((newUsers: UserAccount[]) =>
        of(
          patchState({
            users: {
              ...getState().users,
              ...newUsers.reduce(
                (acc, user) => ({ ...acc, [user.id]: user }),
                {}
              ),
            },
          })
        )
      )
    );
  }

  @Action(UpdateAccountPasswordAction)
  updateAccountPassword(
    // eslint-disable-next-line no-empty-pattern
    {}: StateContext<UserAccountsStateModel>,
    { userId, password }: UpdateAccountPasswordAction
  ) {
    return this.userAccountService.updateAccountPassword(userId, password);
  }
}
