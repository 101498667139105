import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  PaginationAndOrdering,
  Product,
  ProductConfiguration,
} from '@core/core.types';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import {
  API_SPECIFICATION_CONSTANTS,
  NIFTY_APPLICATIONID_KEY,
  PAGING_HEADERS,
} from 'src/app/app.constants';
import { PARTNER_ID, PRODUCTS_API_CONSTANTS } from '../../../app.constants';
import { Logger } from './logger.service';
import { Util } from '@core/utils/core.util';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly logger = new Logger(this.constructor.name);

  private BASE_GET_PRODUCT_CONFIGURATIONS_LIST: string;
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.apiUrl}/${PRODUCTS_API_CONSTANTS.productcatalogue}`;
    this.BASE_GET_PRODUCT_CONFIGURATIONS_LIST = `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductConfigurationDirectory}`;
  }

  getProduct(productId: string): Observable<Product> {
    const getProductUrl = `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductProjector}/${productId}/${API_SPECIFICATION_CONSTANTS.ask}/${PRODUCTS_API_CONSTANTS.queryGetProduct}`;
    return this.http.get<Product>(getProductUrl);
  }

  deactivateProduct(productId: string) {
    this.logger.debug('deactivate product');
    const deactivateProductUrl = `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProduct}/${productId}/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commandDeactivateProduct}`;
    return this.http.post(deactivateProductUrl, null);
  }

  reactivateProduct(productId: string) {
    this.logger.debug('reactivate product');
    const reactivateProductUrl = `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProduct}/${productId}/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commandReactivateProduct}`;
    return this.http.post(reactivateProductUrl, null);
  }

  createProductConfiguration(
    productConfigurationId: string,
    productId: string,
    partnerId: string
  ): Observable<string> {
    this.logger.debug('createProductConfiguration');
    const productConfigurationUrl =
      `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductConfiguration}/` +
      `${productConfigurationId}/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commandGenerateProductConfiguration}`;
    const body = {
      ProductId: productId,
      properties: [
        {
          itemKey: PARTNER_ID,
          itemValue: partnerId,
        },
      ],
    };

    return this.http.post<string>(productConfigurationUrl, body);
  }

  deleteProductConfiguration(
    productConfigurationId: string
  ): Observable<string> {
    this.logger.debug('deleteProductConfiguration');
    const productConfigurationUrl =
      `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductConfiguration}/` +
      `${productConfigurationId}/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commandDeleteProductConfiguration}`;

    return this.http.post<string>(productConfigurationUrl, null);
  }

  getProducts(paginationAndOrdering: PaginationAndOrdering) {
    this.logger.debug('getFilteredEntities');
    const headers = Util.GetHttpHeadersFromPaginationAndOrderingWithCT(
      paginationAndOrdering
    );

    return this.http
      .get<Product[]>(
        `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/${PRODUCTS_API_CONSTANTS.commandListProducts}/?${API_SPECIFICATION_CONSTANTS.applicationId}=${NIFTY_APPLICATIONID_KEY}`,
        { headers, observe: 'response' }
      )
      .pipe(
        map((response) => ({
          entitiesList: response.body,
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
          newContinuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
        }))
      );
  }

  createProduct(product: Product){
    return this.http.post(`${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commadProvisionNewProduct}`, product);
  }

  updateProduct(product: Product) {
    return this.http.post(`${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commandProvisionProductUpdate}`, product);

  }

  addChildProduct(productId, childProductId) {
    return this.http.post(`${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProduct}/${productId}/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commandAddChildProduct}`, { childProductId });
  }

  removeChildProduct(productId, childProductId) {
    return this.http.post(`${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProduct}/${productId}/${API_SPECIFICATION_CONSTANTS.tell}/${PRODUCTS_API_CONSTANTS.commandRemoveChildProduct}`, { childProductId });
  }

  getProductConfiguration(
    productConfigurationId: string
  ): Observable<ProductConfiguration> {
    this.logger.debug('getProductConfiguration');

    const getProductConfigurationUrl =
      `${this.apiUrl}/${PRODUCTS_API_CONSTANTS.actorProductConfigurationProjector}/` +
      `${productConfigurationId}/${API_SPECIFICATION_CONSTANTS.ask}/${PRODUCTS_API_CONSTANTS.commandGetProductConfiguration}`;

    return this.http.get<ProductConfiguration>(getProductConfigurationUrl);
  }

  getProductConfigurationListByPartnerId(partnerId: string) {
    this.logger.debug('getProductConfigurationListForPartner');

    const getProductConfigurationListForPartnerUrl =
      `${this.BASE_GET_PRODUCT_CONFIGURATIONS_LIST}` +
      `/!/${API_SPECIFICATION_CONSTANTS.ask}/${PRODUCTS_API_CONSTANTS.commandListProductConfigurations}?PartnerId=${partnerId}`;

    return this.http.get<ProductConfiguration[]>(
      getProductConfigurationListForPartnerUrl
    );
  }
}
