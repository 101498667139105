import { FormGroup } from '@angular/forms';
import { sampleValidationGroups } from '@app/modules/service-data/components/sample/sample-dialog/sample-validation-configuration';
import { serviceDataValidationGroups } from '@app/modules/service-data/components/service-data/service-data-form/service-data-validation-configuration';
import {
  DataInputTypeValidationGroups,
  ValidationPair,
} from '@app/modules/service-data/service-data.types';
import { Product } from '@core/core.types';

export enum ValidatorsHandlerType {
  SERVICEDATA = 'serviceData',
  SAMPLE = 'sample',
}

export function handleValidators(
  products: Product[],
  group: FormGroup,
  root: string,
  type: ValidatorsHandlerType,
  ignore: string[] = []
) {
  clearValidators(group, root, ignore);
  setValidators(
    group,
    root,
    type,
    products
      .map((product) => product?.dataInputType)
      .filter((inputType) => !!inputType)
  );
}

// Clear all validators from group (recursively)
function clearValidators(
  group: FormGroup,
  controlName: string,
  ignore: string[] = []
) {
  if (ignore.includes(controlName)) {
    return;
  }
  const control = controlName ? group.get(controlName) : group;

  if (control instanceof FormGroup) {
    Object.keys(control.controls).forEach((key) => {
      clearValidators(group, controlName ? `${controlName}.${key}` : key);
    });
  } else {
    control.clearValidators();
    control.updateValueAndValidity();
  }
}

// Set validators for selected products
function setValidators(
  group: FormGroup,
  controlName: string,
  type: ValidatorsHandlerType,
  inputTypes: string[]
) {
  let selectedValidationGroups = [];
  for (const inputType of inputTypes) {
    selectedValidationGroups = selectedValidationGroups.concat(
      DataInputTypeValidationGroups[inputType]
    );
  }
  selectedValidationGroups = [...new Set(selectedValidationGroups)];
  const validationGroups =
    type === ValidatorsHandlerType.SERVICEDATA
      ? serviceDataValidationGroups
      : sampleValidationGroups;
  for (const groupName of selectedValidationGroups) {
    if (!validationGroups[groupName]) {
      continue;
    }
    setValidatorsRecursively(group, controlName, validationGroups[groupName]);
  }
}

function setValidatorsRecursively(
  group: FormGroup,
  controlName: string,
  validationGroup: ValidationPair
) {
  const control = controlName ? group.get(controlName) : group;
  if (validationGroup.validators) {
    control.addValidators(validationGroup.validators);
    control.updateValueAndValidity();
  }
  if (validationGroup.children) {
    for (const key of Object.keys(validationGroup.children)) {
      setValidatorsRecursively(
        group,
        controlName ? `${controlName}.${key}` : key,
        validationGroup.children[key]
      );
    }
  }
}
