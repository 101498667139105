import { Pagination } from '@core/core.types';
import { TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NbComponentOrCustomStatus } from '@nebular/theme/components/component-status';

export interface DataTableConfiguration {
  title: string;
  actionsLocation?: DataTableActionsLocation;
  pagination$?: Observable<Pagination<any>>;
  hidePagination?: boolean;
  dynamicPageSize?: boolean;
  collapsable?: boolean;
  tableClasses?: string;
  tableHeadClasses?: string;
  tableCellClasses?: string;
  actionsClasses?: string;
  trackBy?: string;
  headerFilters?: TemplateRef<any>;
  titleFilters?: TemplateRef<any>;
  selectionMode: DataTableSelectionType;
  addAction?: DataTableConfigurationAction;
  massActions?: DataTableConfigurationAction[];
  additionalActions?: DataTableConfigurationAction[];
  singleActions?: DataTableConfigurationAction[];
  footerActions?: DataTableConfigurationAction[];
  columns: DataTableConfigurationColumn[];
  extraRow?: TemplateRef<any>;
  rows$: Observable<any[]>;
  doubleClickAction?: (...args) => void;
  scrollBottom?: () => void;
}

export interface DataTableConfigurationAction {
  type: string;
  name: string;
  showName?: boolean;
  icon?: string;
  buttonStatus?: NbComponentOrCustomStatus;
  permission?: string | string[];
  action?: (...args) => void;
  disabled?: (...args) => boolean;
  invisible?: (...args) => boolean;
  children?: DataTableConfigurationAction[];
}

export interface DataTableConfigurationColumn {
  label: string;
  property?: string;
  invoke?: {
    objectReference: any;
    name: string;
    args: any[];
  };
  type?: DataTableDataType;
  filter?: {
    type?: DataTableFilterType;
    subject?: Subject<any>;
    template?: TemplateRef<any>;
    options?: Observable<any[]>;
    idProperty?: string;
    nameProperty?: string;
    placeholder?: string;
  };
  onFilterChange?: (...args) => void;
  permission?: string | string[];
  propertyTemplate?: TemplateRef<any>;
  footerTemplate?: TemplateRef<any>;
  customStyle?: string;
  disabled?: (...args) => boolean;
}

export enum DataTableDataType {
  TEXT = 'text',
  DATE = 'date',
  DATETIME = 'datetime',
}

export enum DataTableSelectionType {
  NONE = 'none',
  SINGLE = 'single',
  MULTI = 'multi',
}

export enum DataTableActionsLocation {
  BOTTOM = 'bottom',
  TOP = 'top',
  RIGHT = 'right',
}

export enum DataTableFilterType {
  TEXT = 'text',
  SELECT = 'select',
  SELECTMULTIPLE = 'select_multiple',
  DATE = 'data',
  DATERANGE = 'daterange',
}

export enum DataTableActionType {
  BUTTON = 'button',
  MENUITEM = 'menu-item',
}
