import { Injectable } from '@angular/core';
import { Logger } from '@app/modules/@core/services/logger.service';
import { Util } from '@core/utils/core.util';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map, tap } from 'rxjs/operators';
import { ProductInventory } from '../../../@core/core.types';
import { ProductInventoriesService } from '../../../@core/services/product-inventories.service';
import {
  DeleteProductInventory,
  LoadProductInventoriesByIdsAction,
  LoadProductInventoryById,
  PRODUCT_INVENTORY_TYPE_NAME,
  UpdateProductInventoryItemAction,
} from './product-inventory.action';

export interface ProductInventoryStateModel {
  productInventories: { [productInventoryId: string]: ProductInventory };
}

@State<ProductInventoryStateModel>({
  name: PRODUCT_INVENTORY_TYPE_NAME,
  defaults: {
    productInventories: {},
  },
})
@Injectable()
export class ProductInventoryState {
  private readonly log = new Logger(this.constructor.name);

  constructor(private productInventoriesService: ProductInventoriesService) {}

  @Selector()
  static getProductInventoryById(state: ProductInventoryStateModel) {
    return (productInventoryId: string) =>
      state.productInventories[productInventoryId];
  }

  @Selector()
  static getProductInventoriesByIds(state: ProductInventoryStateModel) {
    return (productInventoryIds: string[]) =>
      productInventoryIds
        .map((id) => state.productInventories[id])
        .filter((item) => !!item);
  }

  @Action(LoadProductInventoryById)
  loadProductInventoryById(
    { patchState, getState }: StateContext<ProductInventoryStateModel>,
    { productInventoryId, refresh }: LoadProductInventoryById
  ) {
    this.log.debug('LoadProductInventoryById', productInventoryId);
    const { productInventories } = getState();
    if (
      refresh ||
      !Object.prototype.hasOwnProperty.call(
        productInventories,
        productInventoryId
      )
    ) {
      if (
        !Object.prototype.hasOwnProperty.call(
          productInventories,
          productInventoryId
        )
      ) {
        patchState({
          productInventories: {
            ...productInventories,
            [productInventoryId]: undefined,
          },
        });
      }
      return this.productInventoriesService
        .getProductInventory(productInventoryId)
        .pipe(
          switchMap((productInventory: ProductInventory) =>
            of(
              patchState({
                productInventories: {
                  ...getState().productInventories,
                  [productInventoryId]: productInventory,
                },
              })
            )
          )
        );
    }
    return of(null);
  }

  @Action(LoadProductInventoriesByIdsAction)
  loadProductInventoriesByIds(
    { patchState, getState }: StateContext<ProductInventoryStateModel>,
    { productInventoriesIds, refresh }: LoadProductInventoriesByIdsAction
  ) {
    this.log.debug('LoadProductInventoriesByIdsAction');
    if (productInventoriesIds && productInventoriesIds.length) {
      const { productInventories } = getState();
      const piisToLoad = productInventoriesIds.filter(
        (piiId) =>
          refresh ||
          !Object.prototype.hasOwnProperty.call(productInventories, piiId)
      );
      if (piisToLoad.length) {
        const patchedPiis = { ...productInventories };
        for (const piiId of piisToLoad) {
          if (
            !Object.prototype.hasOwnProperty.call(productInventories, piiId)
          ) {
            patchedPiis[piiId] = null;
          }
        }
        patchState({
          productInventories: patchedPiis,
        });

        return forkJoin(
          Util.chunkArray(Util.removeDuplicates(piisToLoad), 200).map((chunk) =>
            this.productInventoriesService.getProductInventories(chunk).pipe(
              switchMap((loadedPiis: ProductInventory[]) => {
                const patchedPiisState = { ...getState().productInventories };
                for (const pii of loadedPiis) {
                  patchedPiisState[pii.id] = pii;
                }
                return of(
                  patchState({
                    productInventories: patchedPiisState,
                  })
                );
              })
            )
          )
        );
      }
    }
    return of(null);
  }

  @Action(UpdateProductInventoryItemAction)
  updateProductInventoryItem(
    { patchState, getState }: StateContext<ProductInventoryStateModel>,
    { productInventoryItem }: UpdateProductInventoryItemAction
  ) {
    this.log.debug('UpdateProductInventoryItemAction');
    return this.productInventoriesService
      .updateProductInventoryItem(productInventoryItem)
      .pipe(
        map(() =>
          patchState({
            productInventories: {
              ...getState().productInventories,
              [productInventoryItem.id]: productInventoryItem,
            },
          })
        )
      );
  }

  @Action(DeleteProductInventory)
  deleteProductInventory(
    { patchState, getState }: StateContext<ProductInventoryStateModel>,
    { productInventoryId }: DeleteProductInventory
  ) {
    this.log.debug('UpdateProductInventoryItemAction');
    return this.productInventoriesService
      .deleteProductInventory(productInventoryId)
      .pipe(
        tap(() => {
          const productInventories = { ...getState().productInventories };
          delete productInventories[productInventoryId];
          patchState({
            productInventories: {
              ...productInventories,
            },
          });
        })
      );
  }
}
