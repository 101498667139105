import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
} from '@angular/core';

export class NgLetContext {
  $implicit: any = null;
  ngLet: any = null;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngLet]',
})
export class NgLetDirective implements OnInit {
  private context = new NgLetContext();

  constructor(
    private vcr: ViewContainerRef,
    private templateRef: TemplateRef<NgLetContext>
  ) {}

  @Input()
  set ngLet(value: any) {
    this.context.$implicit = this.context.ngLet = value;
  }

  ngOnInit() {
    this.vcr.createEmbeddedView(this.templateRef, this.context);
  }
}
