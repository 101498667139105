import { KitPositionModel, Run } from '../../core.types';
import { RunFilters, RunHeaders } from '@core/services/runs.service';

export class ClearRunsStateAction {
  static type = '[Runs] ClearRunsState';
}

export class AddNewRunAction {
  static readonly type = '[Run] AddNewRun';

  constructor(public payload: Run) {}
}

export class UpdateRunAction {
  static readonly type = '[Run] UpdateRun';
  constructor(public payload: { run: Run }) {}
}

export class AssignKitToRunAction {
  static readonly type = '[Run] AssignKit';
  constructor(public payload: { runId: string; kitId: string }) {}
}

export class RemoveKitFromRunAction {
  static readonly type = '[Run] RemoveKit';
  constructor(public payload: { runId: string; kitId: string }) {}
}

export class LoadAllRunsAction {
  static readonly type = '[Run] LoadAllRuns';
  constructor(public continuationToken?: string) {}
}

export class ChangeRunStatusAction {
  static readonly type = '[Run] ChangeRunStatus';
  constructor(public payload: { run: Run; isStarted: boolean }) {}
}

export class DeleteRunAction {
  static readonly type = '[Run] Delete';

  constructor(public runId: string) {}
}

export class PlasmaExportAction {
  static readonly type = '[Run] PlasmaExport';

  constructor(
    public id: string,
    public runId: string,
    public fileName: string
  ) {}
}

export class HalosExportAction {
  static readonly type = '[Run] HalosExport';

  constructor(
    public id: string,
    public runId: string,
    public fileName: string
  ) {}
}

export class SetRunsById {
  static readonly type = '[Run] SetRunsById';

  constructor(public runs: Run | Run[]) {}
}

export class LoadRunsAction {
  static readonly type = '[Run] LoadRunsAction';

  constructor(
    public filters: RunFilters,
    public headers: RunHeaders,
    public pageToLoad?: number,
    public reload: boolean = false
  ) {}
}

export class LoadRunById {
  static readonly type = '[Run] LoadRunById';

  constructor(
    public runId: string,
    public refresh = false,
    public prependToPage = false
  ) {}
}

export class AssignKitsToRunAction {
  static readonly type = `[Run] AddKitsToRun`;
  constructor(
    public payload: {
      runId: string;
      runKitsList: KitPositionModel[];
      date: Date;
    }
  ) {}
}
