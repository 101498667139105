import { Country } from '@core/core.types';

export class GetCountriesAction {
  static type = '[Countries] GetCountriesAction';
}
export class SetCountriesAction {
  static type = '[Countries] SetCountriesAction';
  constructor(public countries: Country[]) {}
}
export class GetCountryAction {
  static type = '[Countries] GetCountryAction';
  constructor(public id: string) {}
}
export class UpdateCountryAction {
  static type = '[Countries] UpdateCountryAction';
  constructor(
    public id: string,
    public country: Country
  ) {}
}
export class CreateCountryAction {
  static type = '[Countries] CreateCountryAction';
  constructor(public country: Country) {}
}
export class ActivateCountryAction {
  static type = '[Countries] ActivateCountryAction';
  constructor(public id: string) {}
}
export class DeactivateCountryAction {
  static type = '[Countries] DeactivateCountryAction';
  constructor(public id: string) {}
}
export class GetAccountManagerCountriesAction {
  static type = '[Countries] GetAccountManagerCountriesAction';
  constructor(public id: string) {}
}
export class ClearAccountManagerCountriesAction {
  static type = '[Countries] ClearAccountManagerCountriesAction';
  constructor() {}
}
