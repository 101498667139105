import { Injectable } from '@angular/core';
import { Kit } from '@core/core.types';
import { KitsFilters } from '@core/services/kits.service';
import { Logger } from '@core/services/logger.service';
import { LoadKitById, LoadKitsByIdAction } from '@core/store/kits/kits.actions';
import { KitsState } from '@core/store/kits/kits.state';
import { HttpUtils } from '@core/utils/http-utils';
import { Store } from '@ngxs/store';
import { TreatmentFilters } from '@treatments/treatment.types';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KitsUtilsService {
  private readonly log = new Logger(this.constructor.name);
  constructor(private store: Store) {}

  getKitById$(kitId: string, refresh = false): Observable<Kit> {
    this.log.debug('getKitById$');
    if (!kitId) {
      return of(null);
    }
    return this.store.dispatch(new LoadKitById(kitId, refresh)).pipe(
      switchMap(() =>
        this.store.select(KitsState.getKitById).pipe(
          map((filterById: (id: string) => Kit) => filterById(kitId)),
          filter((k) => k !== undefined)
        )
      ),
      distinctUntilChanged()
    );
  }

  getKitsById$(kitIds: string[], refresh = false): Observable<Kit[]> {
    if (!kitIds.length) {
      return of(null);
    }
    return this.store.dispatch(new LoadKitsByIdAction(kitIds, refresh)).pipe(
      switchMap(() =>
        this.store.select(KitsState.getKitsByIds).pipe(
          map((filterByIds: (ids: string[]) => Kit[]) => filterByIds(kitIds)),
          filter((k) => k !== undefined)
        )
      )
    );
  }

  buildHttpParams(params: KitsFilters) {
    let httpParams = HttpUtils.buildHttpParams(params);

    if (params.productInventoryItemIds?.length) {
      httpParams = httpParams.set(
        TreatmentFilters.ProductInventoryItemIds,
        params.productInventoryItemIds.join(',')
      );
    }

    return httpParams;
  }
}
