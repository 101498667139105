import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import {
  API_SPECIFICATION_CONSTANTS,
  AUTH0_CONFIG,
} from '../../../app.constants';
import { UserProfileData } from '../core.types';

@Injectable()
export class UserProfileService {
  constructor(private httpClient: HttpClient) {}

  getUserProfileData(): Observable<UserProfileData> {
    const getUserProfileURL = `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}/${AUTH0_CONFIG.me}`;
    return this.httpClient.get<UserProfileData>(getUserProfileURL);
  }
}
