import { Injectable } from '@angular/core';
import {
  TreatmentFilters,
  TreatmentHeaders,
} from '@core/services/treatments.service.new';
import {
  TreatmentFilters as TreatmentFiltersType,
  TreatmentSelectProperties,
  TreatmentStatus,
} from '@treatments/treatment.types';
import {
  END_TIME_OF_DAY,
  PAGING_HEADERS,
  START_TIME_OF_DAY,
} from '@app/app.constants';
import { HttpUtils } from '@core/utils/http-utils';

@Injectable({
  providedIn: 'root',
})
export class TreatmentsUtilsService {
  buildHttpParams(params: TreatmentFilters) {
    let httpParams = HttpUtils.buildHttpParams(params);

    if (params.dateOfServiceSearchKey) {
      httpParams = httpParams.delete('dateOfServiceSearchKey');

      httpParams = httpParams.set(
        TreatmentFiltersType.StatusChangedFrom,
        `${params.dateOfServiceSearchKey}${START_TIME_OF_DAY}`
      );

      httpParams = httpParams.set(
        TreatmentFiltersType.StatusChangedTo,
        `${params.dateOfServiceSearchKey}${END_TIME_OF_DAY}`
      );

      httpParams = httpParams.set(
        TreatmentFiltersType.Status,
        TreatmentStatus.dateOfService
      );
    }

    if (params.ids?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.Ids,
        params.ids.join(',')
      );
    }

    if (params.productInventoryItemIds?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.ProductInventoryItemIds,
        params.productInventoryItemIds.join(',')
      );
    }

    if (params.documentIds?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.DocumentsIds,
        params.documentIds.join(',')
      );
    }

    if (params.runIds?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.RunIds,
        params.runIds.map((run) => run.id).join(',')
      );
    }

    if (params.productIds?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.ProductIds,
        params.productIds.map((product) => product.id).join(',')
      );
    }

    if (params.paymentTypes?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.PaymentTypes,
        params.paymentTypes.join(',')
      );
    }

    return httpParams;
  }

  buildHttpHeaders(headers: TreatmentHeaders) {
    if (headers.select) {
      headers.select.push(
        ...[
          TreatmentSelectProperties.id,
          TreatmentSelectProperties.productInventoryItemId,
          TreatmentSelectProperties.documentIds,
        ]
      );
    }
    const httpHeaders = HttpUtils.buildHttpHeaders(headers);
    httpHeaders.set(
      PAGING_HEADERS.orderBy,
      headers.orderBy || 'dateOfVenipuncture'
    );
    return httpHeaders;
  }
}
