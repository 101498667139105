import { Injectable } from '@angular/core';
import {
  Kit,
  Language,
  NotificationProfileModel,
  Partner,
  Product,
  ProductConfiguration,
  ProductInventory,
  Properties,
  Run,
} from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { LoadKitsByIdAction } from '@core/store/kits/kits.actions';
import { KitsState } from '@core/store/kits/kits.state';
import { LanguageState } from '@core/store/languages/languages.state';
import { PartnersState } from '@core/store/partners/partners.state';
import { LoadProductConfigurationById } from '@core/store/product-configuration/product-configuration.actions';
import { ProductConfigurationsState } from '@core/store/product-configuration/product-configuration.state';
import { LoadAllRunsAction } from '@core/store/runs/runs.actions';
import { RunState } from '@core/store/runs/runs.state';
import { CoreUtilsService } from '@core/utils/core-utils.service';
import { Util } from '@core/utils/core.util';
import { NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { PasswordDialogComponent } from '@shared/components/password-dialog/password-dialog.component';
import {
  ProgressData,
  ProgressDialogComponent,
} from '@shared/components/progress/progress-dialog.component';
import { TreatmentsState } from '@treatments/store/treatments/treatments.state';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  firstValueFrom,
  forkJoin,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { ProductUtilsService } from '../@core/services/product-utils.service';
import { TreatmentsService } from '../@core/services/treatments.service';
import { LoadNotificationProfilesAction } from '../@core/store/notification-profiles/notification-profiles.actions';
import { NotificationProfileState } from '../@core/store/notification-profiles/notification-profiles.state';
import { LoadPartnersByIds } from '../@core/store/partners/partners.actions';
import { KitsUtilsService } from '../kits/kits-utils.service';
import { validateBarcode } from '../kits/kits.utils';
import { PartnersUtilsService } from '../partners/partners-utils.service';
import {
  LoadProductInventoriesByIdsAction,
  LoadProductInventoryById,
} from './store/product-inventory/product-inventory.action';
import { ProductInventoryState } from './store/product-inventory/product-inventory.state';
import {
  AddDocumentsToTreatmentsAction,
  LoadTreatmentsAction,
} from './store/treatments/treatments.actions';
import { Treatment, TreatmentKitData } from './treatment.types';

@Injectable({
  providedIn: 'root',
})
export class TreatmentsUtilsService {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private store: Store,
    private coreUtils: CoreUtilsService,
    private treatmentsService: TreatmentsService,
    private kitsUtils: KitsUtilsService,
    private partnersUtils: PartnersUtilsService,
    private nbDialogService: NbDialogService,
    private translateService: TranslateService,
    private productsUtils: ProductUtilsService
  ) {}

  getTreatmentsData(
    treatments: Treatment[],
    appends?: {
      appendDoctor?: boolean;
      appendKit?: boolean;
      appendPii?: boolean;
      appendPartner?: boolean;
      appendRun?: boolean;
      appendProduct?: boolean;
    }
  ): Observable<TreatmentKitData[]> {
    if (!treatments?.length) {
      return of([]);
    }

    // Check if appends are selected, load all by default
    appends = appends ?? {
      appendDoctor: true,
      appendKit: true,
      appendPii: true,
      appendPartner: true,
      appendRun: true,
      appendProduct: true,
    };

    // Iterate through all treatments, and get lists of secondary keys
    const idCollections = treatments.reduce(
      (accumulator, treatment) => {
        accumulator.doctorIds.push(treatment.doctorId);
        accumulator.kitIds.push(treatment.kitId);
        accumulator.piiIds.push(treatment.productInventoryItemId);
        accumulator.productIds.push(treatment.productId);
        accumulator.runIds.push(...(treatment.runIds ?? []));
        return accumulator;
      },
      { doctorIds: [], kitIds: [], piiIds: [], productIds: [], runIds: [] }
    );

    // Load Kits / Products / Piis if requested
    let observable$ = forkJoin([
      appends?.appendKit
        ? this.store.dispatch(new LoadKitsByIdAction(idCollections.kitIds))
        : of(null),
      appends?.appendPii || appends?.appendPartner
        ? this.store.dispatch(
            new LoadProductInventoriesByIdsAction(idCollections.piiIds)
          )
        : of(null),
      appends?.appendDoctor
        ? this.store.dispatch(new LoadPartnersByIds(idCollections.doctorIds))
        : of(null),
    ]);

    // Load Partners if requested. Pii is required for Partner ID!
    if (appends?.appendPartner) {
      observable$ = observable$.pipe(
        switchMap(() =>
          this.store
            .select(ProductInventoryState.getProductInventoriesByIds)
            .pipe(
              map((filterByIds) => filterByIds(idCollections.piiIds)),
              switchMap((piis) =>
                this.store.dispatch(
                  new LoadPartnersByIds(piis.map((pii) => pii?.partnerId))
                )
              )
            )
        )
      );
    }

    // return observable of treatments with requested appends.
    // Combine with combineLatest.
    // Every append is additional observable in the array
    return observable$.pipe(
      map(() => treatments),
      switchMap((tests) =>
        combineLatest(
          tests.map((treatment) =>
            combineLatest([
              // append treatments
              of(treatment),

              // append products
              appends?.appendProduct
                ? this.resolveProductById$(treatment.productId)
                : of(null),

              // append Pii & partner
              appends?.appendPii || appends?.appendPartner
                ? this.getTreatmentProductInventoryItem$(treatment).pipe(
                    switchMap((pii) =>
                      // append partner
                      appends?.appendPartner
                        ? this.partnersUtils
                            .getPartnerById$(pii?.partnerId)
                            .pipe(
                              map((partner) => ({
                                partner,
                                pii,
                              }))
                            )
                        : of({ pii, partner: null })
                    )
                  )
                : of({ pii: null, partner: null }),

              // append Doctor
              appends?.appendDoctor
                ? this.partnersUtils.getSubPartnerById$(treatment.doctorId)
                : of(null),

              // append Kit
              appends?.appendKit
                ? this.kitsUtils.getKitById$(treatment.kitId)
                : of(null),

              // append Run
              appends?.appendRun
                ? this.getRunsByKitId$(treatment.kitId)
                : of(null),
            ]).pipe(
              // Group all appends into an TreatmentKitData object
              map(([t, product, piiAndPartner, doctor, kit, runs]) => ({
                treatment: t,
                product,
                ...piiAndPartner,
                doctor,
                kit,
                runs,
              }))
            )
          )
        )
      )
    );
  }

  getTreatmentKitData(treatment: Treatment): Observable<TreatmentKitData> {
    this.log.debug('get treatment kit data');
    if (!treatment) {
      return of(null);
    }
    const doctor$ = this.partnersUtils.getSubPartnerById$(treatment.doctorId);
    const pii$ = this.getTreatmentProductInventoryItem$(treatment).pipe(
      switchMap((pii) => {
        if (pii) {
          const partner$ = this.partnersUtils.getPartnerById$(pii.partnerId);
          const kit$ = this.kitsUtils.getKitById$(pii.kitId);
          const product$ = this.resolveProductById$(pii.productId);
          const runs$ = this.getRunsByKitId$(pii.kitId);
          return combineLatest([partner$, kit$, product$, runs$]).pipe(
            map(([partner, kit, product, runs]) => ({
              partner,
              kit,
              product,
              pii,
              runs,
            }))
          );
        }
        return of({});
      })
    );
    return combineLatest([doctor$, pii$]).pipe(
      map(([doctor, data]) => ({ ...data, doctor, treatment }) as any)
    );
  }

  getKitsTreatments$(kitIds: string[]): Observable<Treatment[]> {
    return this.store.dispatch(new LoadKitsByIdAction(kitIds)).pipe(
      switchMap(() =>
        this.store
          .select(KitsState.getKitsByIds)
          .pipe(map((filterByIds) => filterByIds(kitIds)))
      ),
      switchMap((kits) => {
        if (!kits.length) {
          return of([]);
        }
        return this.store
          .dispatch(
            new LoadTreatmentsAction(
              {
                productInventoryItemIds: kits.map(
                  (kit) => kit.productInventoryItemIds[0]
                ),
              },
              {}
            )
          )
          .pipe(map(() => kits));
      }),
      switchMap((kits) =>
        this.store
          .select(TreatmentsState.getTreatmentsByPiiIds)
          .pipe(
            map((filterByIds) =>
              filterByIds(kits.map((kit) => kit.productInventoryItemIds[0]))
            )
          )
      )
    );
  }

  getTreatmentProductInventoryItem$(treatment: Treatment) {
    this.log.debug('get treatment product inventory item');
    return this.store
      .dispatch(new LoadProductInventoryById(treatment.productInventoryItemId))
      .pipe(
        switchMap(() =>
          this.store.select(ProductInventoryState.getProductInventoryById).pipe(
            map((selectById) => selectById(treatment.productInventoryItemId)),
            filter((pii) => pii !== undefined)
          )
        ),
        distinctUntilChanged()
      );
  }

  getRunsByKitId$(kitId: string): Observable<Run[]> {
    if (kitId) {
      this.store.dispatch(new LoadAllRunsAction());
      return this.store.select(RunState.getRunsByKitId).pipe(
        map((filterById) => filterById(kitId)),
        distinctUntilChanged()
      );
    }
    return of([]);
  }

  getLanguageCode$(languageName: string) {
    return this.store.select(LanguageState.getLanguageByName).pipe(
      map((filterByName) => filterByName(languageName)),
      map((language: Language) => {
        if (language && language.isoCode) {
          return language.isoCode.split('-')[0];
        }
        return null;
      })
    );
  }

  getKitProductInventoryItems$(kit: Kit): Observable<ProductInventory[]> {
    if (kit && kit.productInventoryItemIds) {
      if (!kit.productInventoryItemIds.length) {
        return of([]);
      }
      for (const piiId of kit.productInventoryItemIds) {
        this.store.dispatch(new LoadProductInventoryById(piiId));
      }
      const productInventories$ = kit.productInventoryItemIds.map((piiId) =>
        this.store
          .select(ProductInventoryState.getProductInventoryById)
          .pipe(map((filterById) => filterById(piiId)))
      );
      return combineLatest(productInventories$);
    }
    return of(null);
  }

  getKitTreatment$(kit: Kit, refresh = false): Observable<Treatment> {
    if (
      kit &&
      kit.productInventoryItemIds &&
      kit.productInventoryItemIds.length
    ) {
      this.log.debug('get kit treatment', kit.id);
      return this.store
        .dispatch(
          new LoadTreatmentsAction(
            { productInventoryItemIds: kit.productInventoryItemIds },
            {}
          )
        )
        .pipe(
          switchMap(() =>
            this.store
              .select(TreatmentsState.getKitTreatment)
              .pipe(map((filterByKit) => filterByKit(kit)))
          )
        );
    }
    return of(null);
  }

  async addDocumentsToTreatments(
    addDocumentsList: {
      guid: string;
      fileName: string;
      data: string;
      kitId: string;
      treatment?: Treatment;
      documentProperties?: Properties[];
    }[]
  ) {
    const kitIds = addDocumentsList
      .map((document) => {
        if (validateBarcode(document.kitId)) {
          return document.kitId;
        } else {
          this.coreUtils.showErrowMessage(
            `Unable to retrieve kit barcode from document name. (${document.fileName})`
          );
          return null;
        }
      })
      .filter((kitId) => !!kitId);

    const treatments = await firstValueFrom(this.getKitsTreatments$(kitIds));

    for (const documentItem of addDocumentsList) {
      if (kitIds.includes(documentItem.kitId)) {
        documentItem.treatment = treatments.find(
          (treatment) => treatment.kitId === documentItem.kitId
        );
        if (!documentItem.treatment) {
          this.coreUtils.showErrowMessage(
            `Unable to find treatment for this document! (${documentItem.kitId})`
          );
        }
      }
    }

    await firstValueFrom(
      this.store.dispatch(new AddDocumentsToTreatmentsAction(addDocumentsList))
    );
  }

  getPartnerDoctors$(partner: Partner): Observable<Partner[]> {
    if (partner && partner.subPartners) {
      this.log.debug(`get partner ${partner.id} doctors`);
      if (!partner.subPartners.length) {
        return of([]);
      }

      return forkJoin(
        Util.chunkArray(partner.subPartners, 100).map((chunk) =>
          this.store.dispatch(new LoadPartnersByIds(chunk))
        )
      ).pipe(
        switchMap(() =>
          this.store
            .select(PartnersState.getPartnersByIds)
            .pipe(
              map((getByPartnerIds) => getByPartnerIds(partner.subPartners))
            )
        )
      );
    }
    return of(null);
  }

  getPartnersProducts$(partner: Partner): Observable<Product[]> {
    this.log.debug('getPartnersProducts');
    if (!partner || !partner.productConfigurations) {
      return of(null);
    }
    if (!partner.productConfigurations.length) {
      return of([]);
    }
    return combineLatest(
      partner.productConfigurations.map((pcId: string) =>
        this.resolveProductConfigurationById$(pcId).pipe(
          switchMap((pc: ProductConfiguration) => {
            if (!pc) {
              return of(null);
            }
            return this.resolveProductById$(pc.productId);
          })
        )
      )
    ).pipe(
      filter((products) => products.every((product) => !!product)),
      map((products) => products.filter((product) => !product.deactivated))
    );
  }

  getDoctorNotificationProfiles(
    doctorId: string,
    refresh = false
  ): Observable<NotificationProfileModel[]> {
    this.log.debug('get doctor notification profiles', doctorId);
    return this.partnersUtils.getSubPartnerById$(doctorId, refresh).pipe(
      switchMap((doctor) => {
        if (
          !doctor ||
          !doctor.notificationProfiles ||
          !doctor.notificationProfiles.length
        ) {
          return of([]);
        }
        return this.store
          .dispatch(
            new LoadNotificationProfilesAction(doctor.notificationProfiles)
          )
          .pipe(
            switchMap(() =>
              this.store
                .select(NotificationProfileState.getNotificationProfiles)
                .pipe(
                  map((filterById) => filterById(doctor.notificationProfiles))
                )
            )
          );
      })
    );
  }

  async sendResultReferenceLinkEmail(treatmentId: string) {
    this.log.debug('SendResultReferenceLinkEmailAction');

    await this.treatmentsService
      .sendResultReferenceLinkEmail(treatmentId)
      .pipe(
        map((result) => {
          if (result && result.acceptedByEmailService === true) {
            this.coreUtils.showSuccessMessage(
              this.translateService.instant(
                'treatmentsPage.emailSuccessfullySentMessage'
              )
            );
          } else {
            this.coreUtils.showErrowMessage(
              this.translateService.instant(
                'treatmentsPage.emailNotSentMessage'
              )
            );
          }
          return result;
        })
      )
      .pipe(take(1))
      .toPromise();
  }

  public openProgressDialog(
    title: string,
    password?: string
  ): BehaviorSubject<ProgressData> {
    const progressSubject = new BehaviorSubject<ProgressData>({
      value: 0,
      status: `Preparing a document. Please wait. `,
    });

    const dialogRef = this.nbDialogService.open(ProgressDialogComponent, {
      context: {
        progressSubject,
        title,
        password,
      },
    });

    progressSubject.subscribe((data) => {
      if (data.value === 100) {
        setTimeout(() => dialogRef.close(), 1000);
        if (password && data.success) {
          this.nbDialogService.open(PasswordDialogComponent, {
            context: { password },
          });
        }
      }
    });

    return progressSubject;
  }

  private resolveProductConfigurationById$(
    productConfigurationId: string
  ): Observable<ProductConfiguration> {
    this.log.debug(
      'resolve product configuration by id',
      productConfigurationId
    );
    if (productConfigurationId) {
      return this.store
        .dispatch(new LoadProductConfigurationById(productConfigurationId))
        .pipe(
          switchMap(() =>
            this.store
              .select(ProductConfigurationsState.getProductConfigurationById)
              .pipe(
                map((filterByProductConfigurationId) =>
                  filterByProductConfigurationId(productConfigurationId)
                )
              )
          )
        );
    }
    return of(null);
  }

  private resolveProductById$(productId: string): Observable<Product> {
    if (productId) {
      return this.productsUtils.getProductById$(productId);
    }
    return of(null);
  }
}
