<nb-card style="min-width: 650px">
  <nb-card-header class="position-relative">
    <p class="mb-0">{{ 'pii.search.resultTitle' | translate }}</p>
    <nb-icon (click)="dismiss()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12">
        <app-data-table
          [configuration]="dataTableConfiguration"
          [loading]="isLoading"
        ></app-data-table>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <div class="col-12 d-flex flex-row-reverse">
        <button nbButton status="basic" (click)="dismiss()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
