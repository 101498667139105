import { Injectable } from '@angular/core';
import { Run, SortDirection } from '@core/core.types';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, switchMap } from 'rxjs/operators';
import { Logger } from '../@core/services/logger.service';
import { RunState } from '../@core/store/runs/runs.state';
import { SigneeState } from '../@core/store/signees/signees.state';
import { RunData } from './runs.types';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Util } from '@core/utils/core.util';
import { PAGING_HEADERS } from '@app/app.constants';
import { RunFilters, RunHeaders } from '@core/services/runs.service';

@Injectable({
  providedIn: 'root',
})
export class RunsUtilsService {
  private readonly log = new Logger(this.constructor.name);

  constructor(private store: Store) {}

  buildHttpParams(params: RunFilters) {
    let httpParams = new HttpParams().appendAll(
      Util.RemoveEmptyProperties({ ...params })
    );

    if (params.ids?.length) {
      httpParams = httpParams.set('ids', params.ids.join(','));
    }

    if (params.excludeIds?.length) {
      httpParams = httpParams.set('excludeIds', params.excludeIds.join(','));
    }

    return httpParams;
  }

  buildHttpHeaders(headers: RunHeaders) {
    return new HttpHeaders(
      Util.RemoveEmptyProperties({
        [PAGING_HEADERS.continuationToken]: headers.continuationToken,
        [PAGING_HEADERS.orderBy]: headers.orderBy || 'started',
        [PAGING_HEADERS.orderDirection]:
          headers.orderDirection || SortDirection.desc,
        [PAGING_HEADERS.top]: headers.pagingTop + '' || '30',
      })
    );
  }

  getRunById$(runId: string) {
    return this.store.select(RunState.getRunById).pipe(
      map((filterById) => filterById(runId)),
      filter((run) => run !== undefined)
    );
  }

  getRunDataByRunId$(runId: string): Observable<RunData> {
    this.log.debug('get run data by run id');

    return this.getRunById$(runId).pipe(
      switchMap((run) => this.getRunData(run))
    );
  }

  getRunData(run: Run): Observable<RunData> {
    this.log.debug('get run data');
    return this.store.select(SigneeState.getSigneeList).pipe(
      switchMap((signeeList) => {
        if (signeeList && signeeList.length > 0) {
          return of({
            run,
            niptSignee: signeeList.find((s) => run.signeeId === s.id),
          });
        }
        return of({
          run,
          niptSignee: null,
        });
      })
    );
  }
}
