import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  API_SPECIFICATION_CONSTANTS,
  INVOICE_KEYS,
  INVOICES_API_CONSTANTS,
  NIFTY_APPLICATIONID_KEY,
  TREATMENTS_API_CONSTANTS,
} from '../../../app.constants';
import { Invoice, InvoicePayment } from '../core.types';
import { EntityBaseService } from './entityBase.service';
import { Logger } from './logger.service';
import { environment } from '@root/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoicePaymentsService extends EntityBaseService<Invoice> {
  private readonly log = new Logger(this.constructor.name);

  private INVOICE_ENPOINT: string;
  private BASE_API: string;

  private routes = {
    listInvoices: () =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.actorInvoiceDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/${INVOICES_API_CONSTANTS.commandListInvoices}`,
    getInvoiceById: (invoiceId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.actorInvoiceProjector}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.ask}/${INVOICES_API_CONSTANTS.commandGetInvoice}`,
    getInvoicePaymentsList: (
      invoiceId: string,
      invoicePaymentsIds?: string[]
    ) => {
      let url = `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.actorInvoicePaymentDirectory}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.ask}/${INVOICES_API_CONSTANTS.commandListInvoicePayments}`;

      if (invoicePaymentsIds && invoicePaymentsIds.length > 0) {
        url = `${url}?ids=${invoicePaymentsIds.join(',')}`;
      }
      return url;
    },
    generateInvoice: (invoiceId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandGenerateInvoice}`,
    updateInvoice: (invoiceId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandUpdateInvoice}`,
    markInvoicePaymentAsPaid: (invoicePaymentId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.actorInvoicePayment}/${invoicePaymentId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandMarkInvoicePaymentAsPaid}`,
    assignInvoiceToNiptTest: (treatmentId: string) =>
      // eslint-disable-next-line max-len
      `${this.BASE_API}/${TREATMENTS_API_CONSTANTS.nipts}/${TREATMENTS_API_CONSTANTS.actorNiptTestManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandProvisionAssignInvoiceToNiptTest}`,
    generateInvoicePayment: (invoicePaymentId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.actorInvoicePayment}/${invoicePaymentId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandGenerateInvoicePayment}`,
    assignInvoicePaymentToInvoice: (invoiceId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandAssignInvoicePaymentToInvoice}`,
    unassignInvoicePaymentFromInvoice: (invoiceId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandUnassignInvoicePaymentFromInvoice}`,
    deleteInvoicePayment: (invoicePaymentId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.actorInvoicePayment}/${invoicePaymentId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandDeleteInvoicePayment}`,
    updateInvoicePayment: (invoicePaymentId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.actorInvoicePayment}/${invoicePaymentId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandUpdateInvoicePayment}`,
    setInvoiceLaboratoryPrice: (invoiceId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandSetInvoiceLaboratoryPrice}`,
    setInvoiceDiscount: (invoiceId: string) =>
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandSetInvoiceDiscount}`,
    paidInFull: (invoiceId: string) =>
      // eslint-disable-next-line max-len
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandMarkAsPaid}`,
    revokePaidInFull: (invoiceId: string) =>
      // eslint-disable-next-line max-len
      `${this.INVOICE_ENPOINT}/${INVOICES_API_CONSTANTS.invoice}/${invoiceId}/${API_SPECIFICATION_CONSTANTS.tell}/${INVOICES_API_CONSTANTS.commandRevokePaid}`,
  };

  constructor(protected http: HttpClient) {
    super(http);
    this.BASE_API = `${environment.apiUrl}`;
    this.INVOICE_ENPOINT = `${this.BASE_API}/${INVOICES_API_CONSTANTS.invoices}`;
  }

  getInvoiceById(invoiceId: string): Observable<Invoice> {
    this.log.debug('get invoice');

    return this.http.get<Invoice>(this.routes.getInvoiceById(invoiceId));
  }

  getInvoicePaymentsList(
    invoiceId: string,
    invoicePaymentsIds?: string[]
  ): Observable<InvoicePayment[]> {
    this.log.debug('get invoice payments list');

    return this.http.get<InvoicePayment[]>(
      this.routes.getInvoicePaymentsList(invoiceId, invoicePaymentsIds)
    );
  }

  generateInvoice(
    invoiceId: string,
    sepaContractValue: boolean
  ): Observable<any> {
    this.log.debug('generate invoice');

    const body = {
      Properties: [
        {
          ItemKey: INVOICE_KEYS.SepaContract,
          ItemValue: String(sepaContractValue),
        },
      ],
      ApplicationId: NIFTY_APPLICATIONID_KEY,
    };

    return this.http.post(this.routes.generateInvoice(invoiceId), body);
  }

  updateInvoice(invoiceId: string, sepaContract: boolean): Observable<any> {
    this.log.debug('update invoice');

    const body = {
      properties: [
        { itemKey: INVOICE_KEYS.SepaContract, itemValue: String(sepaContract) },
      ],
    };

    return this.http.post(this.routes.updateInvoice(invoiceId), body);
  }

  assignInvoiceToNiptTest(
    treatmentId: string,
    invoiceId: string
  ): Observable<any> {
    this.log.debug('assign invoice to niptTest');

    const body = {
      id: treatmentId,
      invoiceId,
    };

    return this.http.post(
      this.routes.assignInvoiceToNiptTest(treatmentId),
      body
    );
  }

  generateInvoicePayment(invoicePayment: InvoicePayment): Observable<any> {
    this.log.debug('generate invoice payment');
    const body = {
      paymentDate: invoicePayment.paymentDate,
      price: invoicePayment.price,
      notes: invoicePayment.notes,
    };

    return this.http.post(
      this.routes.generateInvoicePayment(invoicePayment.id),
      body
    );
  }

  assignInvoicePaymentToInvoice(
    invoiceId: string,
    invoicePayment: InvoicePayment
  ) {
    this.log.debug('assign invoice payment to invocie');

    const body = {
      invoicePaymentId: invoicePayment.id,
    };
    return this.http.post(
      this.routes.assignInvoicePaymentToInvoice(invoiceId),
      body
    );
  }

  unassignInvoicePaymentFromInvoice(invoiceId: string, paymentId: string) {
    this.log.debug('unassign invoice payment from invocie');

    const body = {
      invoicePaymentId: paymentId,
    };
    return this.http.post(
      this.routes.unassignInvoicePaymentFromInvoice(invoiceId),
      body
    );
  }

  deleteInvoicePayment(paymentId: string) {
    this.log.debug('delete invoice payment ');

    const body = {
      invoicePaymentId: paymentId,
    };
    return this.http.post(this.routes.deleteInvoicePayment(paymentId), body);
  }

  markInvoicePaymentAsPaid(invoicePaymentId: string): Observable<any> {
    return this.http.post(
      this.routes.markInvoicePaymentAsPaid(invoicePaymentId),
      null
    );
  }

  updateInvoicePayment(invoicePayment: InvoicePayment): Observable<any> {
    this.log.debug('update invoice payment');
    const body = {
      paymentDate: invoicePayment.paymentDate,
      price: invoicePayment.price,
      notes: invoicePayment.notes,
    };

    return this.http.post(
      this.routes.updateInvoicePayment(invoicePayment.id),
      body
    );
  }

  setInvoiceLaboratoryPrice(invoiceId: string, laboratoryPrice: number) {
    this.log.debug('set invoie laboratory price');
    const body = {
      laboratoryPrice,
    };

    return this.http.post(
      this.routes.setInvoiceLaboratoryPrice(invoiceId),
      body
    );
  }

  setInvoiceDiscount(invoiceId: string, discountValue: number) {
    this.log.debug('set invoice discount');
    const body = {
      discountValue,
      discountNotes: '',
    };

    return this.http.post(this.routes.setInvoiceDiscount(invoiceId), body);
  }

  paidInFull(invoiceId: string) {
    this.log.debug('paid in full');

    return this.http.post(this.routes.paidInFull(invoiceId), {});
  }

  revokePaidInFull(invoiceId: string) {
    this.log.debug('revoke paid in full');

    return this.http.post(this.routes.revokePaidInFull(invoiceId), {});
  }

  protected setUpdateEntityBody(entity: Invoice, body: Partial<Invoice>) {
    throw new Error('Method not implemented.');
  }

  protected setCreateEntityBody(entity: Invoice, body: Partial<Invoice>) {
    throw new Error('Method not implemented.');
  }

  protected setUrlBody(run: Invoice) {
    throw new Error('Method not implemented.');
  }

  protected getListUrl(): string {
    return this.routes.listInvoices();
  }

  protected getUpdateEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }

  protected getDeleteEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }

  protected getCreateEntityUrl(newEntityId: string): string {
    throw new Error('Method not implemented.');
  }

  protected getFetchEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }
}
