import { Injectable } from '@angular/core';
import { GetProductInventoryItemAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { DocumentsService } from '@core/services/documents.service';
import {
  AddDocumentInteraction,
  AddUploadedDocumentAction,
  AddUploadedDocumentsAction,
  ApproveDocumentAction,
  ArchiveDocumentAction,
  ArchiveDocumentsAction,
  DeleteDocumentAction,
  DisapproveDocumentAction,
  DOCUMENTS_STATE_NAME,
  LoadDocumentById,
  LoadMultipleDocumentsAction,
  LoadUploadedDocumentsAction,
  RemoveFromUploadedList,
  SetDocumentsById,
} from '@core/store/documents/documents.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Document, DocumentStatus } from '@treatments/treatment.types';
import moment from 'moment';
import { of } from 'rxjs/internal/observable/of';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { AzureBlobService } from '../../services/azure-blob.service';
import { Logger } from '../../services/logger.service';
import { ProductInventoriesService } from '../../services/product-inventories.service';
import { TreatmentsService } from '../../services/treatments.service';
import { combineLatest } from 'rxjs';
import { GetSampleAction } from '@app/modules/service-data/store/sample.actions';

export interface DocumentsStateModel {
  documents: { [documentId: string]: Document };
  uploadedDocuments: string[];
}

@State<DocumentsStateModel>({
  name: DOCUMENTS_STATE_NAME,
  defaults: {
    documents: {},
    uploadedDocuments: [],
  },
})
@Injectable()
export class DocumentsState {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private documentsService: DocumentsService,
    private authenticationService: AuthenticationService
  ) {}

  @Selector()
  static getDocumentById(state: DocumentsStateModel) {
    return (documentId: string) => state.documents[documentId];
  }

  @Selector()
  static getDocumentsByIds({ documents }: DocumentsStateModel) {
    return (documentIds: string[]) => {
      return documentIds
        .map((documentId) => documents[documentId])
        .filter((document) => !!document);
    };
  }

  @Selector()
  static getUploadedDocuments(state: DocumentsStateModel) {
    return state.uploadedDocuments.map(
      (documentId) => state.documents[documentId]
    );
  }

  @Action(LoadDocumentById)
  loadDocumentById(
    { patchState, getState }: StateContext<DocumentsStateModel>,
    { documentId, reload }: LoadDocumentById
  ) {
    const { documents } = getState();
    if (
      !Object.prototype.hasOwnProperty.call(documents, documentId) ||
      reload
    ) {
      return this.documentsService.getDocument(documentId).pipe(
        catchError(() => of(null)),
        switchMap((document: Document) =>
          of(
            patchState({
              documents: {
                ...getState().documents,
                [documentId]: document ? document : null,
              },
            })
          )
        )
      );
    }
  }

  @Action(LoadMultipleDocumentsAction)
  loadMultipleDocuments(
    { getState, patchState }: StateContext<DocumentsStateModel>,
    { params }: LoadMultipleDocumentsAction
  ) {
    this.log.debug('LoadUploadedDocumentsAction');
    return this.documentsService.listDocuments(null, params).pipe(
      switchMap((loadedDocuments: Document[]) => {
        const documents = { ...getState().documents };
        for (const doc of loadedDocuments) {
          documents[doc.id] = doc;
        }
        return of(
          patchState({
            documents,
          })
        );
      })
    );
  }

  @Action(LoadUploadedDocumentsAction)
  loadDocuments(
    { getState, patchState }: StateContext<DocumentsStateModel>,
    { params, prepend }: LoadUploadedDocumentsAction
  ) {
    this.log.debug('LoadUploadedDocumentsAction');
    return this.documentsService.listDocuments('Uploaded', params).pipe(
      switchMap((uploadedDocuments: Document[]) => {
        const documents = { ...getState().documents };
        for (const doc of uploadedDocuments) {
          documents[doc.id] = doc;
        }
        return of(
          patchState({
            documents,
            uploadedDocuments: prepend
              ? [
                  ...uploadedDocuments.map((doc) => doc.id),
                  ...getState().uploadedDocuments,
                ]
              : uploadedDocuments.map((doc) => doc.id),
          })
        );
      })
    );
  }

  @Action(AddUploadedDocumentsAction)
  addUploadedDocuments(
    { dispatch }: StateContext<DocumentsStateModel>,
    { documentIds }: AddUploadedDocumentsAction
  ) {
    this.log.debug('AddUploadedDocumentAction');

    return this.documentsService
      .listDocuments(null, {
        ids: documentIds,
      })
      .pipe(
        switchMap((documents: Document[]) =>
          dispatch(new SetDocumentsById(documents, true))
        )
      );
  }

  @Action(AddUploadedDocumentAction)
  addUploadedDocument(
    { dispatch }: StateContext<DocumentsStateModel>,
    { documentId }: AddUploadedDocumentAction
  ) {
    this.log.debug('AddUploadedDocumentAction');
    return this.documentsService
      .getDocument(documentId)
      .pipe(
        switchMap((document: Document) =>
          dispatch(new SetDocumentsById(document, true))
        )
      );
  }

  @Action(RemoveFromUploadedList)
  removeFromUploadedList(
    { patchState, getState }: StateContext<DocumentsStateModel>,
    { ids }: RemoveFromUploadedList
  ) {
    this.log.debug('RemoveFromUploadedList');
    patchState({
      uploadedDocuments: getState().uploadedDocuments.filter(
        (id) => !ids.includes(id)
      ),
    });
  }

  @Action(SetDocumentsById)
  setDocumentsById(
    { patchState, getState }: StateContext<DocumentsStateModel>,
    { documents }: SetDocumentsById
  ) {
    const documentsArray: Document[] = Array.isArray(documents)
      ? documents
      : [documents];
    if (documentsArray.length) {
      const documentsObject = { ...getState().documents };
      const updatedDocumentsList: string[] = [...getState().uploadedDocuments];
      for (const document of documentsArray) {
        documentsObject[document.id] = document;
        if (updatedDocumentsList.indexOf(document.id) < 0) {
          updatedDocumentsList.unshift(document.id);
        }
      }

      patchState({
        documents: {
          ...documentsObject,
        },
        uploadedDocuments: updatedDocumentsList,
      });
    }
  }

  @Action(AddDocumentInteraction)
  addDocumentInteraction(
    { getState, patchState }: StateContext<DocumentsStateModel>,
    { documentId, interactedBy, status, timestamp }: AddDocumentInteraction
  ) {
    this.log.debug('addDocumentInteraction', documentId, status);
    const userId = interactedBy || this.authenticationService.getUserId();
    const stateToPatch: Partial<DocumentsStateModel> = {};
    const storedDocument = getState().documents[documentId];
    if (storedDocument) {
      const interactions = [
        ...storedDocument.interactions,
        {
          interactedBy: userId,
          interacted: moment(timestamp).format(),
          interaction: status,
        },
      ];
      const document = {
        ...storedDocument,
        interactions,
        [status]: {
          by: userId,
          timestamp: moment(timestamp).format(),
        },
        lastStatus: status,
        lastStatusChanged: moment(timestamp).format(),
        lastStatusChangedBy: userId,
      };
      stateToPatch.documents = {
        ...getState().documents,
        [documentId]: document,
      };
    }
    // update uploaded documents based on new document status
    const uploadedDocuments = getState().uploadedDocuments
      ? getState().uploadedDocuments
      : [];
    if (
      status === DocumentStatus.uploaded &&
      uploadedDocuments.indexOf(documentId) < 0
    ) {
      stateToPatch.uploadedDocuments = [documentId, ...uploadedDocuments];
    } else {
      if (uploadedDocuments && uploadedDocuments.length) {
        stateToPatch.uploadedDocuments = uploadedDocuments.filter(
          (docId) => docId !== documentId
        );
      }
    }
    return of(patchState(stateToPatch));
  }

  @Action(ApproveDocumentAction)
  approveDocumentAction(
    { dispatch }: StateContext<DocumentsStateModel>,
    { documentId, piiId, requestBody }: ApproveDocumentAction
  ) {
    return this.documentsService.approveDocument(documentId, requestBody).pipe(
      delay(200),
      switchMap(() => dispatch(new LoadDocumentById(documentId, true))),
      switchMap(() =>
        dispatch(new GetProductInventoryItemAction(piiId, true, true))
      )
    );
  }

  @Action(DisapproveDocumentAction)
  disapproveDocumentAction(
    { dispatch }: StateContext<DocumentsStateModel>,
    { documentId, piiId }: DisapproveDocumentAction
  ) {
    return this.documentsService.disapproveDocument(documentId).pipe(
      delay(500),
      switchMap(() => dispatch(new LoadDocumentById(documentId, true))),
      switchMap(() =>
        dispatch(new GetProductInventoryItemAction(piiId, true, true))
      )
    );
  }

  @Action(ArchiveDocumentAction)
  archiveDocumentAction(
    { getState, patchState, dispatch }: StateContext<DocumentsStateModel>,
    { documentId }: ArchiveDocumentAction
  ) {
    return this.documentsService.archiveDocument(documentId).pipe(
      delay(200),
      switchMap(() => dispatch(new LoadDocumentById(documentId, true))),
      switchMap(() =>
        of(
          patchState({
            uploadedDocuments: [
              ...getState().uploadedDocuments.filter(
                (docId) => docId !== documentId
              ),
            ],
          })
        )
      )
    );
  }

  @Action(ArchiveDocumentsAction)
  archiveDocumentsAction(
    { dispatch, patchState, getState }: StateContext<DocumentsStateModel>,
    { documentIds }: ArchiveDocumentsAction
  ) {
    return combineLatest(
      documentIds.map((id) => this.documentsService.archiveDocument(id))
    ).pipe(
      delay(200),
      switchMap(() =>
        dispatch(new LoadMultipleDocumentsAction({ ids: documentIds }))
      ),
      tap(() => {
        patchState({
          uploadedDocuments: [
            ...getState().uploadedDocuments.filter(
              (docId) => !documentIds.includes(docId)
            ),
          ],
        });
      })
    );
  }

  @Action(DeleteDocumentAction)
  deleteDocumentAction(
    { patchState, getState }: StateContext<DocumentsStateModel>,
    { documentId }: DeleteDocumentAction
  ) {
    return this.documentsService.deleteDocument(documentId).pipe(
      delay(200),
      switchMap(() => {
        const documents = { ...getState().documents };
        documents[documentId] = null;

        return of(
          patchState({
            documents,
            uploadedDocuments: [
              ...getState().uploadedDocuments.filter(
                (docId) => docId !== documentId
              ),
            ],
          })
        );
      })
    );
  }
}
