import { PriorScreeningTestType } from '@app/modules/service-data/service-data.types';
import { PaymentType } from '@core/core.types';
import { Util } from '@core/utils/core.util';
import moment from 'moment';

export const prefillData = {
  id: Util.CreateGuid(),
  paymentInformation: {
    paymentType: PaymentType.Customer1,
    notes: 'This is a test service data!',
  },
  patientInformation: {
    firstName: 'Mojca',
    lastName: 'Pokrajculja',
    email: 'mojca.pokrajculja@geneplanet.com',
    dateOfBirth: moment().add(-25, 'y'),
    documentNumber: 'MP20211102',
    phoneNumber: '041111111',
    weight: 60,
    height: 170,
  },
  serviceInformation: {
    nipt: {
      currentPregnancyInformation: {
        expectedConfinementDate: moment().add(25, 'w'),
        inVitro: {
          isInVitroFertilized: false,
        },
        priorScreeningTest: {
          priorScreeningTestType: PriorScreeningTestType.NoTestTaken,
        },
        vanishingTwinSyndrome: {
          isDetected: false,
        },
        heparinTherapy: {
          hasReceived: false,
        },
        ultrasoundDate: moment().subtract(1, 'w'),
      },
      patientHistory: {
        numberOfPregnancies: 1,
        numberOfBirths: 0,
        familyDiseaseHistory: {
          hasHistory: false,
        },
        pregnancyDiseaseHistory: {
          hasHistory: false,
        },
        carrierOfGeneticDiseases: {
          hasHistory: false,
        },
        cancerHistory: {
          hasHistory: false,
        },
        albuminTherapy: {
          hasReceived: false,
        },
        cellularImmunotherapy: {
          hasReceived: false,
        },
        stemCellTherapy: {
          hasReceived: false,
        },
        transplantSurgery: {
          hasReceived: false,
        },
        bloodTransfusion: {
          hasReceived: false,
        },
      },
      provideGenderInformation: false,
    },
  },
};
