<nb-card *ngIf="step$ | async as step" [nbSpinner]="isLoading$ | async">
  <nb-card-header class="position-relative">
    <p class="mb-0">
      Document <b>{{ document.fileName }}</b> approval
    </p>
    <nb-icon (click)="dismiss()" icon="close"></nb-icon>
  </nb-card-header>

  <nb-card-body>
    @if (step === DialogStep.prepareEmail) {
      @if (notificationProfile || TemplateType.Canceled) {
        <div class="mb-3">
          Send
          <b>{{ notificationProfile?.name ?? TemplateType.Canceled }}</b> e-mail
          notifications
        </div>

        @if (
          templateType === TemplateType.NotQualified ||
          templateType === TemplateType.Canceled
        ) {
          <label
            class="label-primary ps-1"
            translate="treatmentsPage.approveDocuments.createCustomEmailNotQualifiedMessage"
          ></label>
        } @else {
          <form
            class="row form-group"
            [formGroup]="sendEmailForm.form"
            novalidate
          >
            <div
              class="label form-control-label col-12 mb-2"
              *ngIf="templateType === TemplateType.HighRisk"
            >
              <label class="label-primary ps-1" for="reason"
                >High Risk Reason</label
              >
              <input
                fullWidth
                nbInput
                formControlName="reason"
                name="reason"
                id="reason"
                type="text"
                (keydown)="error$.next(null)"
                placeholder="Enter High Risk Reason"
              />
            </div>
            <div class="label form-control-label col-12 mb-2">
              <label
                class="label-primary ps-1"
                for="subject"
                translate="treatmentsPage.approveDocuments.mailSubject"
              ></label>
              <input
                fullWidth
                nbInput
                formControlName="subject"
                name="subject"
                id="subject"
                type="text"
                placeholder="Enter E-mail Subject"
              />
            </div>
            <div class="label form-control-label col-12">
              <label class="label-primary ps-1" for="body">Mail Body</label>
              <textarea
                rows="10"
                fullWidth
                nbInput
                formControlName="body"
                name="body"
                id="body"
                type="text"
                placeholder="Enter E-mail Body"
              ></textarea>
            </div>
          </form>
          <div class="row">
            <div class="col-12">
              <app-data-table
                [configuration]="toDataTableConfiguration"
              ></app-data-table>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <app-data-table
                [configuration]="ccDataTableConfiguration"
              ></app-data-table>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <app-data-table
                [configuration]="bccDataTableConfiguration"
              ></app-data-table>
            </div>
          </div>
        }
      } @else {
        <div class="mb-0">
          Please add notification profiles to send e-mail notifications.
        </div>
      }
    } @else if (step === DialogStep.previewEmail) {
      <div class="mb-3">
        Confirm sending <b>{{ notificationProfile.name }}</b> e-mail
        notifications
      </div>
      <div class="row form-group">
        <div class="col-12 mb-2" *ngIf="emailConfig.to.length">
          <label class="label form-control-label label-primary">To:</label>
          <div>{{ emailConfig.to.join(', ') }}</div>
        </div>

        <div class="col-12 mb-2" *ngIf="emailConfig.cc.length">
          <label class="label form-control-label label-primary">Cc:</label>
          <div>{{ emailConfig.cc.join(', ') }}</div>
        </div>

        <div class="col-12 mb-2" *ngIf="emailConfig.bcc.length">
          <label class="label form-control-label label-primary">Bcc:</label>
          <div>{{ emailConfig.bcc.join(', ') }}</div>
        </div>

        <div class="col-12 mb-2" *ngIf="emailConfig.subject">
          <label class="label form-control-label label-primary">Subject:</label>
          <div>{{ emailConfig.subject }}</div>
        </div>

        <div class="col-12 mb-2" *ngIf="emailConfig.body">
          <label class="label form-control-label label-primary">Body:</label>
          <div style="white-space: pre-line">{{ emailConfig.body }}</div>
        </div>
      </div>
    } @else if (step === DialogStep.approveDocument) {
      <div>
        Would you like to approve document <b>{{ document.fileName }}</b> for
        treatment with barcode <b>{{ kitId }}</b
        >?
      </div>
    }

    <div class="caption status-danger" *ngIf="error$ | async as error">
      {{ error }}
    </div>
  </nb-card-body>

  <nb-card-footer class="d-flex flex-row-reverse">
    <button
      nbButton
      status="info"
      (click)="sendEmails(true)"
      *ngIf="step === DialogStep.previewEmail"
      [disabled]="
        !notificationProfile ||
        (isLoading$ | async) ||
        templateType === TemplateType.NotQualified ||
        templateType === TemplateType.Canceled
      "
      translate="treatmentsPage.approveDocuments.sendEmail"
    ></button>
    <button
      nbButton
      status="info"
      (click)="previewEmail()"
      *ngIf="step === DialogStep.prepareEmail"
      [disabled]="
        !notificationProfile ||
        (isLoading$ | async) ||
        templateType === TemplateType.NotQualified ||
        templateType === TemplateType.Canceled
      "
      translate="treatmentsPage.approveDocuments.previewEmail"
    ></button>
    <button
      nbButton
      status="info"
      (click)="approveDocument()"
      *ngIf="step === DialogStep.approveDocument"
      [disabled]="isLoading$ | async"
    >
      Approve
    </button>
    <button
      nbButton
      (click)="cancel(step)"
      class="me-2"
      [disabled]="isLoading$ | async"
    >
      <span *ngIf="step === DialogStep.previewEmail">Edit</span>
      <span *ngIf="step !== DialogStep.previewEmail">Cancel</span>
    </button>
  </nb-card-footer>
</nb-card>

<ng-template #loading>
  <div class="pt-1 loading"></div>
</ng-template>
