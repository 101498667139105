import { Component, Input, OnInit } from '@angular/core';
import { Sample } from '@app/modules/service-data/service-data.types';
import { SampleUtilsService } from '@app/modules/service-data/services/sample-utils.service';
import { GetSamplesListAction } from '@app/modules/service-data/store/sample.actions';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableDataType,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { SharedModule } from '@shared/shared.module';
import { Observable, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-sample-results',
  standalone: true,
  imports: [
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    SharedModule,
    TranslateModule,
  ],
  templateUrl: './sample-results.component.html',
})
export class SampleResultsComponent implements OnInit {
  @Input() public sampleIds: string[];

  protected dataTableConfiguration: DataTableConfiguration;
  protected isLoading = true;

  private rows$: Observable<Sample[]>;

  constructor(
    private dialogRef: NbDialogRef<SampleResultsComponent>,
    private sampleUtilsService: SampleUtilsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.rows$ = this.store
      .dispatch(
        new GetSamplesListAction({ ids: this.sampleIds }, {}, null, false)
      )
      .pipe(
        switchMap(() =>
          this.store.select(SampleState.getSamplesByIds).pipe(
            map((getSamplesByIds) => getSamplesByIds(this.sampleIds)),
            tap(() => (this.isLoading = false))
          )
        )
      );

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: DataTableActionsLocation.RIGHT,
      tableHeadClasses: '',
      actionsClasses: 'text-end',
      columns: [
        {
          label: 'Barcode',
          property: 'kitId',
        },
        {
          label: 'Date of venipuncture',
          property: 'dateOfVenipuncture',
          type: DataTableDataType.DATETIME,
        },
        {
          label: 'Waybill',
          property: 'shippingInformation.waybill',
        },
      ],
      singleActions: [
        {
          name: 'Open service data details',
          icon: 'file-outline',
          type: DataTableActionType.BUTTON,
          action: this.openSampleDetails.bind(this),
        },
      ],
      rows$: this.rows$,
    };
  }

  private openSampleDetails(event: MouseEvent, sample: Sample) {
    this.sampleUtilsService.openDetails(sample.id);
    this.dismiss();
  }

  dismiss() {
    this.dialogRef.close();
  }
}
