<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ 'serviceData.financeDetails.title' | translate }}
    </p>
    <nb-icon (click)="dialogRef.close()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    @if (serviceData$ | async; as serviceData) {
      <!--      Personal information-->
      <div class="d-flex justify-content-between align-items-end">
        <p class="caption mb-1">
          {{ 'common.titleBaseInformation' | translate }}
        </p>
      </div>
      <hr class="mt-0" />
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.partner' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.partner?.name }}
          </div>
        </div>
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.externalId' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.partner?.externalId ?? 'External ID not set!' }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.bundles' | translate
          }}</label>
          <div
            class="col-8"
            [innerHTML]="serviceData.bundleIds | getList: (bundles$ | async)"
          ></div>
        </div>
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.country' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.partner?.address.country }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.name' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.patientInformation.firstName }}
            {{ serviceData.patientInformation.lastName }}
          </div>
        </div>
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.dateOfService' | translate
          }}</label>
          <div class="col-8">
            {{ (serviceData.dateOfService | date) ?? '—' }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.notes' | translate
          }}</label>

          <div class="col-8">
            {{ serviceData.notes ? serviceData.notes : '—' }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.nipt.patientState' | translate
          }}</label>
          <div class="col-8">
            {{
              serviceData.serviceInformation.nipt.comments
                ? serviceData.serviceInformation.nipt.comments
                : '—'
            }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-end">
        <p class="caption mb-1">
          {{ 'serviceData.financeDetails.sampleInformation' | translate }}
        </p>
      </div>
      <hr class="mt-0" />
      @if (serviceData.samples?.length) {
        @for (sample of serviceData.samples; track sample.id) {
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'serviceData.details.kitId' | translate
              }}</label>
              <div class="col-8">
                {{ sample.kitId }}
              </div>
            </div>
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'serviceData.details.dateOfVenipuncture' | translate
              }}</label>
              <div class="col-8">
                {{ sample.dateOfVenipuncture | date }}
              </div>
            </div>
          </div>
        }
      } @else {
        <div class="row mb-2">
          <div class="col-12 text-center">
            {{ 'serviceData.financeDetails.noSamplesYet' | translate }}
          </div>
        </div>
      }
      <div class="d-flex justify-content-between align-items-end">
        <p class="caption mb-1">
          {{ 'serviceData.financeDetails.paymentInformation' | translate }}
        </p>
      </div>
      <hr class="mt-0" />
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.payerName' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.paymentInformation.notes || '—' }}
          </div>
        </div>
      </div>
      @if (prices$ | async; as prices) {
        <div class="row mb-2">
          <div class="col-12 col-md-6 row align-items-center">
            <label class="label col-4 mb-0 text-end">{{
              'serviceData.details.endUserPrice' | translate
            }}</label>
            <div class="col-8">
              {{ prices.endUserPrice ?? '—' }}
            </div>
          </div>
          <div class="col-12 col-md-6 row align-items-center">
            <label class="label col-4 mb-0 text-end">{{
              'serviceData.details.doctorFee' | translate
            }}</label>
            <div class="col-8">
              {{ prices.doctorFee ?? '—' }}
            </div>
          </div>
        </div>
      }
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.doctorInvoice' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.paymentInformation.doctorInvoiceNumber || '—' }}
          </div>
        </div>
        <div class="col-12 col-md-6 row align-items-center">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.laboratoryInvoice' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.paymentInformation.laboratoryInvoiceNumber || '—' }}
          </div>
        </div>
      </div>
      <hr class="mt-0" />
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label
            class="label col-4 mb-0 text-end"
            for="gpInvoice"
            translate="serviceData.financeDetails.gpInvoice"
          ></label>
          <div class="col-8 d-flex">
            <nb-form-field class="flex-fill mw-100">
              <input
                nbInput
                type="number"
                id="gpInvoice"
                class="mw-100"
                (appCtrlS)="saveGpInvoice()"
                placeholder="{{
                  'serviceData.financeDetails.placeholders.gpInvoice'
                    | translate
                }}"
                [formControl]="gpInvoiceField"
              />
              <button
                nbSuffix
                nbButton
                status="primary"
                type="button"
                (click)="saveGpInvoice()"
                [disabled]="!gpInvoiceField.dirty"
              >
                <nb-icon icon="save-outline"></nb-icon>
              </button>
            </nb-form-field>
          </div>
        </div>
        <div class="col-12 col-md-6 row align-items-center">
          <label
            class="label col-4 mb-0 text-end"
            for="laboratoryPrice"
            translate="serviceData.financeDetails.laboratoryPrice"
          ></label>
          <div class="col-8 d-flex">
            <nb-form-field class="flex-fill mw-100">
              <input
                nbInput
                type="number"
                id="laboratoryPrice"
                class="mw-100"
                [readOnly]="!!serviceData.invoice.paid"
                [disabled]="!!serviceData.invoice.paid"
                (appCtrlS)="saveLaboratoryPrice()"
                placeholder="{{
                  'serviceData.financeDetails.placeholders.laboratoryPrice'
                    | translate
                }}"
                [formControl]="laboratoryPriceField"
              />
              <button
                nbSuffix
                nbButton
                status="primary"
                type="button"
                (click)="saveLaboratoryPrice()"
                [disabled]="!laboratoryPriceField.dirty"
              >
                <nb-icon icon="save-outline"></nb-icon>
              </button>
            </nb-form-field>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center">
          <label
            class="label col-4 mb-0 text-end"
            for="paymentType"
            translate="serviceData.financeDetails.paymentType"
          ></label>
          <div class="col-8 d-flex">
            <nb-select
              id="paymentType"
              class="flex-fill mw-100"
              [disabled]="!!serviceData.invoice.paid"
              placeholder="{{
                'serviceData.financeDetails.placeholders.paymentType'
                  | translate
              }}"
              [formControl]="paymentTypeField"
            >
              @for (
                paymentType of Object.keys(PaymentType);
                track paymentType
              ) {
                <nb-option [value]="paymentType">{{ paymentType }}</nb-option>
              }
            </nb-select>
            <button
              nbSuffix
              nbButton
              status="primary"
              type="button"
              (click)="savePaymentType()"
              [disabled]="!paymentTypeField.dirty"
            >
              <nb-icon icon="save-outline"></nb-icon>
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 row align-items-center">
          <label
            class="label col-4 mb-0 text-end"
            for="discount"
            translate="serviceData.financeDetails.discount"
          ></label>
          <div class="col-8 d-flex">
            <nb-form-field class="flex-fill mw-100">
              <input
                nbInput
                type="number"
                id="discount"
                class="mw-100"
                [readOnly]="!!serviceData.invoice.paid"
                [disabled]="!!serviceData.invoice.paid"
                (appCtrlS)="saveDiscount()"
                placeholder="{{
                  'serviceData.financeDetails.placeholders.discount' | translate
                }}"
                [formControl]="discountField"
              />
              <button
                nbSuffix
                nbButton
                status="primary"
                type="button"
                (click)="saveDiscount()"
                [disabled]="!discountField.dirty"
              >
                <nb-icon icon="save-outline"></nb-icon>
              </button>
            </nb-form-field>
          </div>
        </div>
      </div>
      <hr class="mt-0" />

      <div class="row mb-2">
        <div class="col-2 row"></div>
        <div class="col-10 row align-items-center">
          <div>
            <nb-toggle
              [formControl]="sepaField"
              (checkedChange)="toggleSepa(serviceData.invoice.id, $event)"
              [disabled]="serviceData.invoice.paid"
              >SEPA/CONTRACT</nb-toggle
            >
          </div>
          <div class="d-flex my-3">
            <div class="d-flex flex-fill" [formGroup]="paymentFormGroup">
              <input
                nbInput
                class="flex-fill me-2 mw-100"
                type="text"
                placeholder="Date"
                formControlName="paymentDate"
                [nbDatepicker]="datepicker"
              />
              <nb-datepicker #datepicker></nb-datepicker>
              <input
                nbInput
                class="flex-fill me-2 mw-100"
                type="number"
                placeholder="Price"
                formControlName="price"
              />
              <input
                nbInput
                class="flex-fill me-2 mw-100"
                type="text"
                placeholder="Payer name"
                formControlName="notes"
              />
            </div>
            <div>
              <button
                nbButton
                status="primary"
                [disabled]="
                  paymentFormGroup.invalid || serviceData.invoice.paid
                "
                (click)="addPayment(serviceData.invoice.id)"
                type="button"
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </div>
          </div>
          <app-data-table
            [configuration]="paymentsDataTableConfiguration"
          ></app-data-table>
          <div class="d-flex">
            <div class="sum flex-fill pt-3">
              SUM PRICE: {{ sumPrice$ | async }}
            </div>
            <div class="text-end mt-3">
              @if (serviceData.invoice.paid) {
                <span
                  class="bg-success text-light px-3 py-2 mb-2 rounded d-inline-block"
                  >Service paid</span
                ><br />
                <button
                  *appRequirePermission="
                    'serviceData.list.buttons.paymentDetails.revokePaidInFull'
                  "
                  nbButton
                  size="small"
                  (click)="revokePaidInFull()"
                >
                  Revoke paid in full
                </button>
              } @else {
                <button
                  nbButton
                  status="primary"
                  size="small"
                  [disabled]="!(paidInFullEnabled$ | async)"
                  (click)="markAsPaidInFull()"
                >
                  PAID IN FULL
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="py-4 loading"></div>
    }
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dialogRef.close()">
      {{ 'common.buttons.btnClose' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
