import { Validators } from '@angular/forms';
import { ValidationPair } from '@app/modules/service-data/service-data.types';
import { crossFieldValidator } from '@shared/validators/cross-field-validator';
import { errorValueValidator } from '@shared/validators/error-value-validator';

export const sampleValidationGroups: { [groupKey: string]: ValidationPair } = {
  niptGeneric: {
    children: {
      doctorId: {
        validators: [Validators.required],
      },
      gestationAge: {
        children: {
          day: {
            validators: [
              Validators.required,
              Validators.min(0),
              Validators.max(6),
            ],
          },
          week: {
            validators: [Validators.required, Validators.min(10)],
          },
        },
      },
      heparinTherapy: {
        children: {
          hasReceived: {
            validators: [Validators.required],
          },
          isRelevant: {
            validators: [errorValueValidator(true)],
          },
        },
      },
      isGestationAgeAutomaticallyCalculated: {
        validators: [Validators.required],
      },
      priorScreeningTest: {
        children: {
          priorScreeningTestType: {
            validators: [Validators.required],
          },
        },
      },
      ultrasoundDate: {
        validators: [Validators.required],
      },
      patientHistory: {
        children: {
          albuminTherapy: {
            children: {
              hasReceived: {
                validators: [Validators.required],
              },
              isRelevant: {
                validators: [errorValueValidator(true)],
              },
            },
          },
          cellularImmunotherapy: {
            children: {
              hasReceived: {
                validators: [Validators.required],
              },
              isRelevant: {
                validators: [errorValueValidator(true)],
              },
            },
          },
          stemCellTherapy: {
            children: {
              hasReceived: {
                validators: [Validators.required, errorValueValidator(true)],
              },
            },
          },
          transplantSurgery: {
            children: {
              hasReceived: {
                validators: [Validators.required, errorValueValidator(true)],
              },
            },
          },
          bloodTransfusion: {
            children: {
              hasReceived: {
                validators: [Validators.required],
              },
              isRelevant: {
                validators: [errorValueValidator(true)],
              },
            },
          },
        },
      },
    },
    validators: [
      crossFieldValidator(
        'patientHistory.bloodTransfusion.isRelevant',
        'patientHistory.bloodTransfusion.hasReceived',
        true
      ),
      crossFieldValidator(
        'patientHistory.cellularImmunotherapy.isRelevant',
        'patientHistory.cellularImmunotherapy.hasReceived',
        true
      ),
      crossFieldValidator(
        'patientHistory.albuminTherapy.isRelevant',
        'patientHistory.albuminTherapy.hasReceived',
        true
      ),
      crossFieldValidator(
        'heparinTherapy.isRelevant',
        'heparinTherapy.hasReceived',
        true
      ),
    ],
  },
  nipt: {
    children: {
      gestationAge: {
        children: {
          week: {
            validators: [Validators.max(40)],
          },
        },
      },
    },
  },
  mono: {
    children: {
      gestationAge: {
        children: {
          week: {
            validators: [Validators.max(24)],
          },
        },
      },
    },
  },
};
