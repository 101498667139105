import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DATEPICKER_DATE_FORMAT,
  MOMENT_DATE_FORMAT,
  MOMENT_DATE_TIME_FORMAT,
} from '@app/app.constants';
import { AuthenticationService } from '@core/services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableDataType,
  DataTableFilterType,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { get } from 'lodash';
import moment from 'moment';
import { Subject, combineLatest, takeUntil, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent {
  @Input() loading = false;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() doubleClick = new EventEmitter();

  @Output() selectedRowsChange = new EventEmitter<any[]>();

  DataTableSelectionType = DataTableSelectionType;
  DataTableActionsLocation = DataTableActionsLocation;
  collapsed = false;

  initialLoad = true;

  hasFooterCells = false;

  configurationChange$ = new Subject<void>();
  checkboxes: boolean[] = [];

  DataTableFilterType = DataTableFilterType;
  DataTableDataType = DataTableDataType;

  get = get;
  moment = moment;
  MOMENT_DATE_TIME_FORMAT = MOMENT_DATE_TIME_FORMAT;
  MOMENT_DATE_FORMAT = MOMENT_DATE_FORMAT;
  DATEPICKER_DATE_FORMAT = DATEPICKER_DATE_FORMAT;

  protected rows$: Observable<any[]>;

  private _configuration: DataTableConfiguration;
  private rows: any[] = [];

  constructor(private authService: AuthenticationService) {}

  get checkAllStatus() {
    return this.checkboxes.some((checked) => checked);
  }

  get selectedRows() {
    return this.checkboxes
      .map((value, i) => (value ? this.rows[i] : null))
      .filter((row) => !!row);
  }

  get configuration() {
    return this._configuration;
  }

  @Input() set configuration(configuration: DataTableConfiguration) {
    this.rows$ = configuration.rows$.pipe(shareReplay(1));
    this.configurationChange$.next();
    this._configuration = {
      ...{
        actionsLocation: DataTableActionsLocation.BOTTOM,
        selectionMode: DataTableSelectionType.SINGLE,
        tableClasses: 'table-striped',
        tableHeadClasses: 'table-secondary',
        actionsClasses: 'text-end',
        trackBy: 'id',
      },
      ...configuration,
    };
    this.hasFooterCells =
      this._configuration.columns.some((column) => column.footerTemplate) ||
      !!this._configuration.footerActions?.length;

    console.warn('Configuration changed!');
    this.rows$
      .pipe(takeUntil(this.configurationChange$), untilDestroyed(this))
      .subscribe((rows) => {
        this.checkboxes = this.keepSelectedRows(
          this.rows,
          rows,
          this.checkboxes
        );
        this.rows = rows;
        this.handleSelectedRows();
      });
  }

  toggleAllCheckboxes(state) {
    this.checkboxes = this.checkboxes.map(() => state);
    this.handleSelectedRows();
  }

  onPageChange(page: number) {
    this.pageChanged.emit(page);
  }

  openDetails(event: MouseEvent, row: any) {
    event.preventDefault();
    event.stopPropagation();
    this._configuration.doubleClickAction?.(event, row);
  }

  invoke(obj, fn, args, row) {
    return obj[fn](...[...args, row]);
  }

  collapse() {
    if (this._configuration.collapsable) {
      this.collapsed = !this.collapsed;
    }
  }

  rowClick(event: MouseEvent, rowIndex: number) {
    event.stopPropagation();
    event.preventDefault();
    if (this._configuration.selectionMode === DataTableSelectionType.MULTI) {
      if (!event.shiftKey) {
        this.checkboxes[rowIndex] = !this.checkboxes[rowIndex];
      } else {
        // If clicked row is selected, unselect all following rows
        if (this.checkboxes[rowIndex] === true) {
          for (let i = rowIndex + 1; i < this.checkboxes.length; i++) {
            this.checkboxes[i] = false;
          }
        } else {
          // If row is not selected, select all rows between clicked row and first selected row.
          // If there is no selected rows, findIndex returns -1 and acts like first row was selected (index 0)
          const firstCheckedIndex = this.checkboxes.findIndex(
            (checkbox) => !!checkbox
          );
          if (rowIndex > firstCheckedIndex) {
            for (let i = firstCheckedIndex; i <= rowIndex; i++) {
              this.checkboxes[i] = true;
            }
          } else {
            for (let i = rowIndex; i <= firstCheckedIndex; i++) {
              this.checkboxes[i] = true;
            }
          }
        }

        // Remove all selections on page, as shift + click causes text to be selected.
        document.getSelection().removeAllRanges();
      }
    } else if (
      this._configuration.selectionMode === DataTableSelectionType.SINGLE
    ) {
      this.checkboxes = this.checkboxes.map(() => false);
      this.checkboxes[rowIndex] = true;
    }

    this.handleSelectedRows();
  }

  protected getTableColSpan() {
    let visibleColumns = this.configuration.columns?.length;

    combineLatest(
      this.configuration.columns
        .filter((column) => column?.permission)
        .map((column) =>
          this.authService.hasRequiredPermission$(column.permission)
        )
    ).subscribe((isVisible) => {
      visibleColumns -= isVisible.reduce(
        (acc, visible) => (!visible ? acc + 1 : acc),
        0
      );
    });

    return (
      visibleColumns +
      (this.configuration.selectionMode === DataTableSelectionType.MULTI
        ? 1
        : 0) +
      (this.configuration.actionsLocation === DataTableActionsLocation.RIGHT
        ? 1
        : 0) +
      1
    );
  }

  private keepSelectedRows(oldRows, newRows, checkboxes) {
    const newCheckboxes = newRows.map(() => false);
    const selectedRows = oldRows.filter((row, index) => !!checkboxes[index]);
    for (let i = 0; i < newRows.length; i++) {
      const row = newRows[i];
      if (
        selectedRows.find(
          (selectedRow) =>
            get(selectedRow, this._configuration.trackBy) ===
            get(row, this._configuration.trackBy)
        )
      ) {
        newCheckboxes[i] = true;
      }
    }
    return newCheckboxes;
  }

  private handleSelectedRows() {
    this.selectedRowsChange.emit(this.selectedRows);
  }
}
