import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { combineLatest, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NotificationProfileModel } from '../../core.types';
import { Logger } from '../../services/logger.service';
import { NotificationProfilesService } from '../../services/notification-profiles.service';
import {
  AddNotificationProfilesAction,
  LoadNotificationProfilesAction,
  LoadNotificationProfilesMultiAction,
  UpdateNotificationProfilesAction,
} from './notification-profiles.actions';

export interface NotificationProfilesStateModel {
  profiles: { [id: string]: NotificationProfileModel };
}

@State<NotificationProfilesStateModel>({
  name: 'notificationProfiles',
  defaults: {
    profiles: {},
  },
})
@Injectable()
export class NotificationProfileState {
  private log = new Logger('NotificationProfileState');

  constructor(
    private notificationProfilesService: NotificationProfilesService
  ) {}

  @Selector()
  static getNotificationProfileById({
    profiles,
  }: NotificationProfilesStateModel) {
    return (profileId: string) => profiles[profileId];
  }

  @Selector()
  static getNotificationProfiles({ profiles }: NotificationProfilesStateModel) {
    return (profileIds: string[]) => {
      if (profileIds) {
        return profileIds.map((profileId) => profiles[profileId]);
      }
      return [];
    };
  }

  @Action(AddNotificationProfilesAction)
  addNotificationProfiles(
    { patchState, getState }: StateContext<NotificationProfilesStateModel>,
    { notificationProfiles }: AddNotificationProfilesAction
  ) {
    if (!notificationProfiles || !notificationProfiles.length) {
      return of(null);
    }
    const profiles = { ...getState().profiles };
    for (const profile of notificationProfiles) {
      profiles[profile.id] = profile;
    }
    return patchState({
      profiles,
    });
  }

  @Action(UpdateNotificationProfilesAction)
  updateNotificationProfiles(
    { patchState, getState }: StateContext<NotificationProfilesStateModel>,
    { notificationProfiles }: UpdateNotificationProfilesAction
  ) {
    return forkJoin(
      notificationProfiles.map((notificationProfile) =>
        this.notificationProfilesService
          .updateNotificationProfile(notificationProfile)
          .pipe(map(() => notificationProfile))
      )
    ).pipe(
      switchMap((updatedNotificationProfiles) => {
        const profiles = { ...getState().profiles };
        for (const profile of updatedNotificationProfiles) {
          profiles[profile.id] = profile;
        }
        return of(
          patchState({
            profiles,
          })
        );
      })
    );
  }

  @Action(LoadNotificationProfilesAction)
  loadNotificationProfiles(
    { patchState, getState }: StateContext<NotificationProfilesStateModel>,
    { notificationProfilesIds, refresh }: LoadNotificationProfilesAction
  ) {
    this.log.debug('LoadNotificationProfilesAction');
    const storedNotificationProfiles = { ...getState().profiles };
    if (!refresh && notificationProfilesIds && notificationProfilesIds.length) {
      notificationProfilesIds = notificationProfilesIds.filter(
        (npId) =>
          !Object.prototype.hasOwnProperty.call(
            storedNotificationProfiles,
            npId
          )
      );
    }
    if (notificationProfilesIds && notificationProfilesIds.length) {
      for (const npId of notificationProfilesIds) {
        if (
          !Object.prototype.hasOwnProperty.call(
            storedNotificationProfiles,
            npId
          )
        ) {
          storedNotificationProfiles[npId] = null;
        }
      }
      patchState({ profiles: storedNotificationProfiles });
      return this.notificationProfilesService
        .getNotificationProfiles(notificationProfilesIds)
        .pipe(
          switchMap((notificationProfiles) => {
            const profiles = { ...getState().profiles };
            for (const profile of notificationProfiles) {
              profiles[profile.id] = profile;
            }
            return of(
              patchState({
                profiles,
              })
            );
          })
        );
    }
    return of(null);
  }

  @Action(LoadNotificationProfilesMultiAction)
  LoadNotificationProfilesMulti(
    { patchState, getState }: StateContext<NotificationProfilesStateModel>,
    { notificationProfilesIds, refresh }: LoadNotificationProfilesAction
  ) {
    this.log.debug('LoadNotificationProfilesAction');
    const storedNotificationProfiles = { ...getState().profiles };
    if (!refresh && notificationProfilesIds && notificationProfilesIds.length) {
      notificationProfilesIds = notificationProfilesIds.filter(
        (npId) =>
          !Object.prototype.hasOwnProperty.call(
            storedNotificationProfiles,
            npId
          )
      );
    }
    if (notificationProfilesIds && notificationProfilesIds.length) {
      for (const npId of notificationProfilesIds) {
        if (
          !Object.prototype.hasOwnProperty.call(
            storedNotificationProfiles,
            npId
          )
        ) {
          storedNotificationProfiles[npId] = null;
        }
      }
      patchState({ profiles: storedNotificationProfiles });

      const notificationProfilesObservables = [];
      while (notificationProfilesIds.length) {
        const observable = this.notificationProfilesService
          .getNotificationProfilesMulti(notificationProfilesIds.splice(0, 200))
          .pipe(
            switchMap((notificationProfiles) => {
              const profiles = { ...getState().profiles };
              for (const profile of notificationProfiles) {
                profiles[profile.id] = profile;
              }
              return of(
                patchState({
                  profiles,
                })
              );
            })
          );
        notificationProfilesObservables.push(observable);
      }

      return combineLatest(notificationProfilesObservables);
    }
    return of(null);
  }
}
