import { Component, Injectable } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { ConfirmCloseReason } from '../../shared.types';

interface ConfirmOptions {
  title: string;
  message: string;
  width?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  styleUrls: ['confirm-dialog.component.scss'],
  templateUrl: 'confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  options: ConfirmOptions;

  constructor(private state: ConfirmState) {
    this.options = state.options;
  }

  yes() {
    this.state.dialog.close({
      closeReason: ConfirmCloseReason.Yes,
    });
  }

  no() {
    this.state.dialog.close({
      closeReason: ConfirmCloseReason.No,
    });
  }
}

@Injectable()
export class ConfirmState {
  options: ConfirmOptions;
  dialog: NbDialogRef<any>;
}

@Injectable()
export class ConfirmService {
  constructor(
    private nbDialogService: NbDialogService,
    private state: ConfirmState
  ) {}

  confirm(
    options: ConfirmOptions
  ): Observable<{ closeReason: ConfirmCloseReason }> {
    this.state.options = options;
    this.state.dialog = this.nbDialogService.open(ConfirmDialogComponent);
    return this.state.dialog.onClose;
  }
}
