import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@root/environments/environment';

@Injectable()
export class SubscriptionKeyInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request?.url?.includes('/tell/') || request?.url?.includes('/ask/')) {
      request = request.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
        },
      });
    }
    return next.handle(request);
  }
}
