import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Language } from '../../core.types';
import { LanguagesService } from '../../services/languages.service';
import { Logger } from '../../services/logger.service';
import { AddLanguagesList } from './languages.actions';

export interface LanguagesStateModel {
  languages: Language[];
}

@State<LanguagesStateModel>({
  name: 'languages',
  defaults: {
    languages: [],
  },
})
@Injectable()
export class LanguageState {
  private log = new Logger(this.constructor.name);
  constructor(private languagesService: LanguagesService) {}

  @Selector()
  static getLanguages(state: LanguagesStateModel) {
    return state.languages;
  }

  @Selector()
  static getLanguageById(state: LanguagesStateModel) {
    return (languageId: string) =>
      state.languages.find((l) => l.id === languageId);
  }

  @Selector()
  static getLanguageByName(state: LanguagesStateModel) {
    return (languageName: string) =>
      state.languages.find((l) => l.name === languageName);
  }

  @Action(AddLanguagesList)
  addLanguagesList({ patchState }: StateContext<LanguagesStateModel>) {
    this.log.debug('AddLanguagesList');
    patchState({
      languages: this.languagesService
        .getLanguages()
        .concat()
        .sort((l1, l2) => l1.name.localeCompare(l2.name)),
    });
  }
}
