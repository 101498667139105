import { Component, Input } from '@angular/core';
import {
  DataTableActionType,
  DataTableConfigurationAction,
} from '@shared/components/data-table/data-table.types';

@Component({
  selector: 'app-data-table-nestable-actions',
  templateUrl: './data-table-nestable-actions.component.html',
})
export class DataTableNestableActionsComponent {
  @Input() button: DataTableConfigurationAction;
  @Input() rows: any[];
  @Input() type = '';

  DataTableActionType = DataTableActionType;

  callAction(action: (event, rows) => void, event: MouseEvent, rows: any[]) {
    event.stopPropagation();
    action?.(event, this.type === 'single' ? rows[0] : rows);
  }

  isDisabled(button) {
    let rows = this.rows;
    if (this.type === 'single') {
      rows = rows[0];
    }
    return (
      (this.type === 'single' && this.rows?.length !== 1) ||
      button?.disabled?.(rows)
    );
  }

  isInvisible(button) {
    return button?.invisible?.(
      this.type === 'single' ? this.rows?.[0] : this.rows
    );
  }
}
