import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_SPECIFICATION_CONSTANTS,
  NOTIFICATION_PROFILES_API_CONSTANTS,
} from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, switchMap } from 'rxjs/operators';
import { NotificationProfileModel } from '../core.types';
import { PartnersService } from './partners.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationProfilesService {
  public recipientsByCountry: {
    [country: string]: { cc: string[]; bcc: string[] };
  };

  private NOTIFICATION_PROFILES_ENDPOINT: string;
  private routes = {
    getNotificationProfileById: (notificationProfileId: string) =>
      `${this.NOTIFICATION_PROFILES_ENDPOINT}/${NOTIFICATION_PROFILES_API_CONSTANTS.actorNotificationProfileProjector}/` +
      `${notificationProfileId}/${API_SPECIFICATION_CONSTANTS.ask}/${NOTIFICATION_PROFILES_API_CONSTANTS.queryGetNotificationProfile}`,
    getNotificationProfiles: () =>
      `${this.NOTIFICATION_PROFILES_ENDPOINT}/${NOTIFICATION_PROFILES_API_CONSTANTS.actorNotificationProfileDirectory}/!
      /${API_SPECIFICATION_CONSTANTS.ask}/${NOTIFICATION_PROFILES_API_CONSTANTS.commandListNotificationProfiles}`,
    generateNotificationProfile: (notificationProfileId: string) =>
      `${this.NOTIFICATION_PROFILES_ENDPOINT}/${NOTIFICATION_PROFILES_API_CONSTANTS.actorNotificationProfile}/` +
      `${notificationProfileId}/${API_SPECIFICATION_CONSTANTS.tell}/${NOTIFICATION_PROFILES_API_CONSTANTS.commandGenerateNotificationProfile}`,
    updateNotificationProfile: (notificationProfileId: string) =>
      `${this.NOTIFICATION_PROFILES_ENDPOINT}/${NOTIFICATION_PROFILES_API_CONSTANTS.actorNotificationProfile}/` +
      `${notificationProfileId}/${API_SPECIFICATION_CONSTANTS.tell}/${NOTIFICATION_PROFILES_API_CONSTANTS.commandUpdateNotificatioNProfile}`,
    deleteNotificationProfile: (notificationProfileId: string) =>
      `${this.NOTIFICATION_PROFILES_ENDPOINT}/${NOTIFICATION_PROFILES_API_CONSTANTS.actorNotificationProfile}/` +
      `${notificationProfileId}/${API_SPECIFICATION_CONSTANTS.tell}/${NOTIFICATION_PROFILES_API_CONSTANTS.commandDeleteNotificationProfile}`,
  };

  constructor(
    private httpClient: HttpClient,
    private partnersService: PartnersService
  ) {
    this.NOTIFICATION_PROFILES_ENDPOINT = `${environment.apiUrl}/${NOTIFICATION_PROFILES_API_CONSTANTS.notificationprofiles}`;
  }

  getNotificationProfile(
    notificationProfileId: string
  ): Observable<NotificationProfileModel> {
    return this.httpClient.get<NotificationProfileModel>(
      this.routes.getNotificationProfileById(notificationProfileId)
    );
  }

  getNotificationProfiles(
    notificationProfileIds: string[]
  ): Observable<NotificationProfileModel[]> {
    return forkJoin(
      notificationProfileIds.map((notificationProfileId) =>
        this.getNotificationProfile(notificationProfileId)
      )
    );
  }

  getNotificationProfilesMulti(
    notificationProfileIds: string[]
  ): Observable<NotificationProfileModel[]> {
    return this.httpClient.get<NotificationProfileModel[]>(
      this.routes.getNotificationProfiles(),
      {
        params: {
          ids: notificationProfileIds.join(','),
        },
      }
    );
  }

  getNotificationProfilesOfPartner(
    partnerId: string
  ): Observable<NotificationProfileModel[]> {
    return this.partnersService.getPartner(partnerId).pipe(
      switchMap((partner) => {
        if (partner) {
          if (
            partner.notificationProfiles &&
            partner.notificationProfiles.length
          ) {
            return forkJoin(
              partner.notificationProfiles.map((notificationProfileId) =>
                this.getNotificationProfile(notificationProfileId)
              )
            );
          }
          return of([]);
        }
        return of(null);
      }),
      catchError(() => of([]))
    );
  }

  generateNotificationProfile(
    notificationProfile: NotificationProfileModel
  ): Observable<NotificationProfileModel> {
    return this.httpClient.post<NotificationProfileModel>(
      this.routes.generateNotificationProfile(notificationProfile.id),
      {
        name: notificationProfile.name,
        sender: notificationProfile.sender,
        senderName: notificationProfile.senderName,
        applicationId: notificationProfile.applicationId,
        recipients: notificationProfile.recipients,
        properties: [
          {
            itemKey: 'TemplateId',
            itemValue: notificationProfile.templateId,
          },
        ],
      }
    );
  }

  updateNotificationProfile(
    notificationProfile: NotificationProfileModel
  ): Observable<NotificationProfileModel> {
    return this.httpClient.post<NotificationProfileModel>(
      this.routes.updateNotificationProfile(notificationProfile.id),
      {
        name: notificationProfile.name,
        sender: notificationProfile.sender,
        senderName: notificationProfile.senderName,
        recipients: notificationProfile.recipients,
        properties: [
          {
            itemKey: 'TemplateId',
            itemValue: notificationProfile.templateId,
          },
        ],
      }
    );
  }

  deleteNotificationProfile(notificationProfileId: string) {
    return this.httpClient.post(
      this.routes.deleteNotificationProfile(notificationProfileId),
      {}
    );
  }

  getResultsApprovedProfiles() {
    return this.httpClient.get<{ [key: string]: string }>(
      `${environment.apiUrl}/nipts/notification-profiles/results-approved`
    );
  }
}
