import { Component } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
})
export class PasswordDialogComponent {
  password = '';

  constructor(
    private dialogRef: NbDialogRef<PasswordDialogComponent>,
    private toastr: NbToastrService
  ) {}

  close() {
    this.dialogRef.close();
  }

  copyToClipboard(password) {
    navigator.clipboard.writeText(password).then(
      () => {
        this.toastr.success('Password copied to clipboard!');
      },
      (err) => {
        this.toastr.danger('Could not copy to clipboard.');
      }
    );
  }
}
