import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DATEPICKER_DATE_FORMAT } from '@app/app.constants';
import { isBMIOver40 } from '@core/utils/core.util';
import { NbMomentDateService } from '@nebular/moment';
import {
  NbDateService,
  NbDatepickerModule,
  NbInputModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { InputValidationStatusDirective } from '@shared/directives/input-validation-status.directive';
import { controlContainerProvider } from '@shared/helpers';
import { SharedModule } from '@shared/shared.module';
import moment from 'moment';
import { Subject, combineLatest, startWith } from 'rxjs';

@Component({
  selector: 'app-service-data-form-patient-information',
  standalone: true,
  imports: [
    CommonModule,
    NbInputModule,
    ReactiveFormsModule,
    TranslateModule,
    ValidationErrorComponent,
    NbDatepickerModule,
    InputValidationStatusDirective,
    SharedModule,
  ],
  providers: [{ provide: NbDateService, useClass: NbMomentDateService }],
  templateUrl: './service-data-form-patient-information.component.html',
  styleUrl: './service-data-form-patient-information.component.scss',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDataFormPatientInformationComponent implements OnInit {
  protected get form() {
    return this.controlContainer.control as FormGroup;
  }

  protected highBMI$ = new Subject<boolean>();

  protected minDate = moment().add(-100, 'year');
  protected maxDate = moment().add(100, 'year');
  protected birthdayStartDate = moment().subtract(25, 'year');
  protected DATEPICKER_DATE_FORMAT = DATEPICKER_DATE_FORMAT;

  private destroyRef = inject(DestroyRef);

  constructor(
    private controlContainer: ControlContainer,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const heightControl = this.form.get('height');
    const weightControl = this.form.get('weight');

    combineLatest([
      heightControl.valueChanges.pipe(startWith(heightControl.value)),
      weightControl.valueChanges.pipe(startWith(weightControl.value)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([height, weight]) =>
        this.highBMI$.next(this.calculateBMI(height, weight))
      );
  }

  private calculateBMI(height: number, weight: number) {
    if (!height || !weight) {
      return;
    }

    return isBMIOver40(weight, height);
  }
}
