<nb-card class="p-4">
  <b class="mb-2 d-block">{{ title }}</b>
  <i class="mb-2 d-block">
    {{ (progressSubject | async)?.status }}
  </i>
  <div class="pt-4 loading"></div>
  <div class="mt-2" *ngIf="password">
    Files will be password protected:
    <a class="cursor-pointer" (click)="copyToClipboard(password)"
      ><span>{{ password }}</span> <nb-icon icon="copy-outline"></nb-icon
    ></a>
  </div>
</nb-card>
