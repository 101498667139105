import { ExtendedProductInventoryItem } from '@app/modules/service-data/service-data.types';
import moment from 'moment';
import { Run } from '../@core/core.types';
import { Logger } from '../@core/services/logger.service';
import { ReportGenerationData, Treatment } from './treatment.types';

const log = new Logger('TreatmentUtils');

export const getReportReportGenerationData = (
  data: ExtendedProductInventoryItem,
  approvedBy: string,
  partnerLanguageCode: string
): ReportGenerationData => ({
  Barcode: data.sample.kitId,
  PatientName: `${data.sample.serviceData.patientInformation.firstName} ${data.sample.serviceData.patientInformation.lastName}`,
  PatientDateOfBirth: new Date(
    data.sample.serviceData.patientInformation.dateOfBirth
  ).toISOString(),
  GestationWeeks:
    data.sample.serviceData.serviceInformation.nipt.currentPregnancyInformation
      .gestationAge.week,
  GestationDays:
    data.sample.serviceData.serviceInformation.nipt.currentPregnancyInformation
      .gestationAge.day,
  DocumentId: data.sample.serviceData.patientInformation.documentNumber,
  DateOfVenipuncture: new Date(data.sample.dateOfVenipuncture).toISOString(),
  DateOfConfinement: new Date(
    data.sample.serviceData.serviceInformation.nipt.currentPregnancyInformation.expectedConfinementDate
  ).toISOString(),
  ServiceName: data.product.name,
  CountryCode: data.partner.address.countryIsoCode,
  LanguageCode: partnerLanguageCode,
  ApprovedBy: approvedBy,
  Gender:
    data.sample.serviceData.serviceInformation.nipt.provideGenderInformation,
  IncidentalFindings:
    data.sample.serviceData.serviceInformation.nipt.incidentalFindings,
  productInventoryItemId: data.id,
  testId: data.id,
  sampleExtractorId: data.sample.doctor.id,
  doctorId: data.sample.serviceData.doctor.id,
  SampleReceived: data.sample.kit.returned
    ? new Date(data.sample.kit.returned.timestamp).toISOString()
    : null,
  DoctorName: data.sample.serviceData.doctor.name,
});

export const getDaysSinceVenipuncture = (treatment: Treatment) => {
  const endDate = treatment.processed
    ? moment(treatment.processed.timestamp)
    : moment();
  const diff = endDate.diff(moment(treatment.dateOfVenipuncture));
  return Math.floor(diff / (24 * 3600000));
};

export const getRunsDisplayString = (runs: Run[]): string => {
  if (!runs || !runs.length) {
    return '';
  }
  return runs.map((r) => r.name).join(', ');
};

export const trimBarcode = (barcode: string) => {
  let bar = barcode;
  if (barcode && barcode.includes('-')) {
    bar = bar.split('-')[0];
  }

  if (barcode && barcode.includes('D')) {
    bar = bar.replace('D', '');
  }

  if (barcode && barcode.includes('R')) {
    bar = bar.replace('R', '');
  }

  if (barcode && barcode.includes('V')) {
    bar = bar.replace('V', '');
  }

  return bar;
};

export const redirectToDhl = (treatmentDhlWaybill: string) => {
  window.open(
    `https://www.dhl.com/si-en/home/tracking/tracking-express.html?submit=1&tracking-id=${treatmentDhlWaybill.replace(
      / /g,
      ''
    )}`,
    '_blank'
  );
};
