import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationProjectService {
  private translationProjectApiUrl: string;
  constructor(private httpClient: HttpClient) {
    this.translationProjectApiUrl = `${environment.apiUrl}/translationproject`;
  }

  get(projectId: string): Observable<any> {
    return this.httpClient.get(
      `${this.translationProjectApiUrl}${environment.actorsPrefix}/TranslationProjectProjector/${projectId}/ask/GetTranslationProject`
    );
  }
}
