import { ProductInventoryItemFilters } from '@app/modules/service-data/services/product-inventory-item.service';
import { ProductInventory } from '@core/core.types';

export const PRODUCT_INVENTORY_ITEM_NAME = 'ProductInventoryItem';
export class GetProductInventoryItemListAction {
  static type = `[${PRODUCT_INVENTORY_ITEM_NAME} GetProductInventoryItemListAction]`;

  constructor(
    public filters: ProductInventoryItemFilters,
    public pageToLoad: number,
    public reload: boolean,
    public pageSize?: number
  ) {}
}
export class SetProductInventoryItemsAction {
  static type = `[${PRODUCT_INVENTORY_ITEM_NAME} SetProductInventoryItemAsction]`;

  constructor(public list: ProductInventory[]) {}
}
export class GetProductInventoryItemAction {
  static type = `[${PRODUCT_INVENTORY_ITEM_NAME} GetProductInventoryItemAction]`;

  constructor(
    public id: string,
    public reload?: boolean,
    public reloadSample?: boolean
  ) {}
}
export class AssignDocumentToProductInventoryItemAction {
  static type = `[${PRODUCT_INVENTORY_ITEM_NAME} AssignDocumentToProductInventoryItemAction]`;

  constructor(
    public id: string,
    public documentId: string
  ) {}
}
export class UnassignDocumentFromProductInventoryItemAction {
  static type = `[${PRODUCT_INVENTORY_ITEM_NAME} UnassignDocumentFromProductInventoryItemAction]`;

  constructor(
    public id: string,
    public documentId: string
  ) {}
}
export class GetProductInventoryItemsInProcessAction {
  static type = `[${PRODUCT_INVENTORY_ITEM_NAME} GetProductInventoryItemsInProcessAction]`;

  constructor(
    public filters: {
      dateOfVenipunctureFrom: string;
      dateOfVenipunctureTo: string;
      country?: string;
      productInventoryItemStatus: string;
      accountManagerId?: string;
    }
  ) {}
}
