<form [formGroup]="form">
  <nb-card>
    <nb-card-header class="position-relative">
      @if (resampleKitId) {
        <p class="mb-0">
          {{ 'sample.form.resample' | translate }}
        </p>
      } @else if (sampleId) {
        <p class="mb-0">
          {{ 'sample.form.editTitle' | translate }}
        </p>
      } @else {
        <p class="mb-0">
          {{ 'sample.form.title' | translate }}
        </p>
      }
      <nb-icon
        icon="close"
        (click)="dialogRef.close()"
        data-cy="sample-dialog-close-icon"
      />
    </nb-card-header>
    <nb-card-body>
      <section>
        <p class="caption mb-1">
          {{ 'sample.form.kitInformation' | translate }}
        </p>
        <hr class="mt-0" />
        <div class="row form-group">
          <div class="col-6">
            <div class="row align-items-center">
              <div class="col-4">
                <label for="partnerId">{{
                  'sample.form.partner' | translate
                }}</label>
              </div>
              <div class="col-8">
                <app-partner-typeahead
                  name="partnerId"
                  id="partnerId"
                  class="w-50"
                  [fieldSize]="'medium'"
                  [disabled]="true"
                  [control]="form.controls.partnerId"
                />
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="partnerId"
                  translations="validation.generic"
                ></app-validation-error>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row align-items-center">
              <div class="col-4">
                <label for="doctor">{{
                  'sample.form.doctor' | translate
                }}</label>
              </div>
              <div class="col-8">
                @if (doctors$ | async; as doctors) {
                  <nb-select
                    fullWidth
                    id="doctor"
                    [placeholder]="
                      doctors.length
                        ? ('sample.form.placeholders.selectDoctor' | translate)
                        : ('sample.form.placeholders.noDoctorsAvailable'
                          | translate)
                    "
                    [formControl]="form.get('serviceInformation.nipt.doctorId')"
                    data-cy="doctor-select"
                  >
                    @for (doctor of doctors; track doctor?.id) {
                      <nb-option [value]="doctor?.id">
                        {{ doctor?.name }}
                      </nb-option>
                    }
                  </nb-select>
                } @else {
                  <nb-select
                    fullWidth
                    placeholder="{{
                      'sample.form.placeholders.selectPartnerFirst' | translate
                    }}"
                    data-cy="disabled-doctor-select"
                  />
                }
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="serviceInformation.nipt.doctorId"
                  translations="validation.generic"
                ></app-validation-error>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-6">
            <div class="row align-items-center">
              <div class="col-4">
                <label for="productId">{{
                  'sample.form.product' | translate
                }}</label>
              </div>
              <div class="col-8">
                @if (availableProducts$ | async; as availableProducts) {
                  <nb-select
                    fullWidth
                    id="productId"
                    formControlName="productId"
                    [placeholder]="
                      availableProducts.length
                        ? ('sample.form.placeholders.selectProduct' | translate)
                        : ('sample.form.placeholders.noProductsAvailable'
                          | translate)
                    "
                    data-cy="product-select"
                  >
                    @for (product of availableProducts; track product?.id) {
                      <nb-option [value]="product?.id">{{
                        product.name
                      }}</nb-option>
                    }
                  </nb-select>
                }
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="productId"
                  translations="validation.generic"
                ></app-validation-error>
              </div>
            </div>
          </div>
          @if (!resampleKitId) {
            <div class="col-6 align-self-center">
              <div class="row align-items-center">
                <div class="col-4">
                  <label for="kitId">{{ 'sample.form.kit' | translate }}</label>
                </div>
                <div class="col-8">
                  @if (!sampleId) {
                    @if (kits$ | async; as kits) {
                      <nb-select
                        fullWidth
                        id="kitId"
                        formControlName="kitId"
                        [placeholder]="
                          kits.length
                            ? ('sample.form.placeholders.selectKit' | translate)
                            : ('sample.form.placeholders.noKitsAvailable'
                              | translate)
                        "
                        data-cy="kit-select"
                      >
                        @for (kit of kits; track kit?.id) {
                          <nb-option [value]="kit?.id">{{ kit.id }}</nb-option>
                        }
                      </nb-select>
                    } @else {
                      <nb-select
                        fullWidth
                        placeholder="{{
                          'sample.form.placeholders.selectPartnerFirst'
                            | translate
                        }}"
                        formControlName="kitId"
                      />
                    }
                  } @else {
                    <b>{{ form.get('kitId').value }}</b>
                  }
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-8">
                  <app-validation-error
                    for="kitId"
                    translations="validation.generic"
                  ></app-validation-error>
                </div>
              </div>
            </div>
          }
        </div>

        @if (resampleKitId) {
          <div class="row form-group">
            <div class="col-6 align-self-center">
              <div class="row">
                <div class="col-4">
                  <label for="productId">{{
                    'sample.form.previousKit' | translate
                  }}</label>
                </div>
                <div class="col-8">
                  <b>{{ resampleKitId }}</b>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row align-items-center">
                <div class="col-4">
                  <label for="kitId">{{ 'sample.form.kit' | translate }}</label>
                </div>
                <div class="col-8">
                  @if (kits$ | async; as kits) {
                    <nb-select
                      fullWidth
                      id="kitId"
                      formControlName="kitId"
                      [placeholder]="
                        kits.length
                          ? ('sample.form.placeholders.selectKit' | translate)
                          : ('sample.form.placeholders.noKitsAvailable'
                            | translate)
                      "
                      data-cy="kit-select"
                    >
                      @for (kit of kits; track kit?.id) {
                        <nb-option [value]="kit?.id">{{ kit.id }}</nb-option>
                      }
                    </nb-select>
                  } @else {
                    <nb-select
                      fullWidth
                      placeholder="{{
                        'sample.form.placeholders.selectPartnerFirst'
                          | translate
                      }}"
                      formControlName="kitId"
                    />
                  }
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-8">
                  <app-validation-error
                    for="kitId"
                    translations="validation.generic"
                  ></app-validation-error>
                </div>
              </div>
            </div>
          </div>
        }

        <div [formGroup]="dovForm" class="row form-group">
          <div class="col-6">
            <div class="row align-items-center">
              <div class="col-4">
                <label for="dateOfVenipuncture">{{
                  'sample.form.dateOfVenipuncture' | translate
                }}</label>
              </div>
              <div class="col-8">
                <input
                  fullWidth
                  nbInput
                  id="dateOfVenipuncture"
                  formControlName="date"
                  [placeholder]="
                    'sample.form.placeholders.dateFormat' | translate
                  "
                  [nbDatepicker]="venipunctureDatepicker"
                  data-cy="date-of-venipuncture-input"
                />
                <nb-datepicker
                  #venipunctureDatepicker
                  [format]="DATEPICKER_DATE_FORMAT"
                />
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="date"
                  translations="validation.generic"
                ></app-validation-error>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row align-items-center">
              <div class="col-4">
                <label for="dateOfVenipuncture">{{
                  'sample.form.timeOfVenipuncture' | translate
                }}</label>
              </div>
              <div class="col-8">
                <input
                  fullWidth
                  nbInput
                  formControlName="time"
                  [placeholder]="
                    'sample.form.placeholders.dateFormat' | translate
                  "
                  [nbTimepicker]="venipunctureTimepicker"
                  data-cy="date-of-venipuncture-input"
                />
                <nb-timepicker #venipunctureTimepicker />
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="time"
                  translations="validation.generic"
                ></app-validation-error>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ng-container formGroupName="serviceInformation">
        <section formGroupName="nipt">
          <p class="caption mb-1">
            {{ 'sample.form.niptServiceInformation' | translate }}
          </p>
          <hr class="mt-0" />
          <div class="row form-group">
            <div class="col-6">
              <div class="row align-items-center" formGroupName="gestationAge">
                <div class="col-4">
                  <label> {{ 'sample.form.gestationAge' | translate }}</label>
                </div>
                <div class="col-8">
                  <div class="row">
                    <div class="col-6">
                      <input
                        fullWidth
                        nbInput
                        type="number"
                        formControlName="week"
                        id="gestationWeek"
                        [placeholder]="
                          'sample.form.placeholders.week' | translate
                        "
                        [readonly]="
                          form.get(
                            'serviceInformation.nipt.isGestationAgeAutomaticallyCalculated'
                          ).value
                        "
                      />
                    </div>
                    <div class="col-6">
                      <input
                        fullWidth
                        nbInput
                        type="number"
                        formControlName="day"
                        id="gestationDay"
                        [placeholder]="
                          'sample.form.placeholders.day' | translate
                        "
                        [readonly]="
                          form.get(
                            'serviceInformation.nipt.isGestationAgeAutomaticallyCalculated'
                          ).value
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end p-0 m-0">
                  <div class="col-4">
                    <app-validation-error
                      for="week"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                  <div class="col-4">
                    <app-validation-error
                      for="day"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-8 offset-4">
                  <nb-checkbox
                    formControlName="isGestationAgeAutomaticallyCalculated"
                  >
                    {{ 'sample.form.autoCalculate' | translate }}
                  </nb-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-6">
              <div class="row align-items-center">
                <div class="col-4">
                  <label for="ultrasoundDate">{{
                    'sample.form.ultrasoundDate' | translate
                  }}</label>
                </div>
                <div class="col-8">
                  <input
                    fullWidth
                    nbInput
                    formControlName="ultrasoundDate"
                    id="ultrasoundDate"
                    [nbDatepicker]="datepicker"
                    [placeholder]="
                      'sample.form.placeholders.dateFormat' | translate
                    "
                  />
                  <nb-datepicker
                    #datepicker
                    [format]="DATEPICKER_DATE_FORMAT"
                  ></nb-datepicker>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-8">
                  <app-validation-error
                    for="ultrasoundDate"
                    translations="validation.generic"
                  ></app-validation-error>
                </div>
              </div>
            </div>
          </div>

          <ng-container formGroupName="priorScreeningTest">
            <div class="row form-group">
              <div class="col-6">
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="priorScreeningTest">{{
                      'sample.form.priorScreeningTest' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <nb-select
                      fullWidth
                      class="w-100"
                      id="priorScreeningTest"
                      formControlName="priorScreeningTestType"
                      [placeholder]="
                        'sample.form.placeholders.priorScreeningTest'
                          | translate
                      "
                    >
                      @for (
                        type of Object.keys(PriorScreeningTestType);
                        track type
                      ) {
                        <nb-option [value]="type">{{
                          'sample.form.priorScreeningTestType.' + type
                            | translate
                        }}</nb-option>
                      }
                    </nb-select>
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="priorScreeningTestType"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6" formGroupName="firstTRisks">
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="firstT21Risk">{{
                      'sample.form.firstT21Risk' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <input
                      fullWidth
                      nbInput
                      formControlName="t21Risk"
                      id="firstT21Risk"
                      [placeholder]="
                        'sample.form.placeholders.firstT21Risk' | translate
                      "
                    />
                  </div>
                </div>
                <div class="row form-group justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="t21Risk"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="firstT18Risk">{{
                      'sample.form.firstT18Risk' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <input
                      fullWidth
                      nbInput
                      formControlName="t18Risk"
                      id="firstT18Risk"
                      [placeholder]="
                        'sample.form.placeholders.firstT18Risk' | translate
                      "
                    />
                  </div>
                </div>
                <div class="row form-group justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="t18Risk"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="firstT13Risk">{{
                      'sample.form.firstT13Risk' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <input
                      fullWidth
                      nbInput
                      formControlName="t13Risk"
                      id="firstT13Risk"
                      [placeholder]="
                        'sample.form.placeholders.firstT13Risk' | translate
                      "
                    />
                  </div>
                </div>
                <div class="row form-group justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="t13Risk"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
              </div>
              <div class="col-6" formGroupName="secondTRisks">
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="secondT21Risk">{{
                      'sample.form.secondT21Risk' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <input
                      fullWidth
                      nbInput
                      formControlName="t21Risk"
                      id="secondT21Risk"
                      [placeholder]="
                        'sample.form.placeholders.secondT21Risk' | translate
                      "
                    />
                  </div>
                </div>
                <div class="row form-group justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="t21Risk"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="secondT18Risk">{{
                      'sample.form.secondT18Risk' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <input
                      fullWidth
                      nbInput
                      formControlName="t18Risk"
                      id="secondT18Risk"
                      [placeholder]="
                        'sample.form.placeholders.secondT18Risk' | translate
                      "
                    />
                  </div>
                </div>
                <div class="row form-group justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="t18Risk"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="secondT13Risk">{{
                      'sample.form.secondT13Risk' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <input
                      fullWidth
                      nbInput
                      formControlName="t13Risk"
                      id="secondT13Risk"
                      [placeholder]="
                        'sample.form.placeholders.secondT13Risk' | translate
                      "
                    />
                  </div>
                </div>
                <div class="row form-group justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="t13Risk"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          @if (!sampleId || resampleKitId) {
            <ng-container formGroupName="heparinTherapy">
              <div class="row form-group">
                <div class="col-6">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <label for="albuminTherapy">{{
                        'serviceData.form.heparinTherapy' | translate
                      }}</label>
                    </div>
                    <div class="col-8">
                      <nb-radio-group
                        id="albuminTherapy"
                        name="heparinTherapy"
                        class="radio-toolbar"
                        formControlName="hasReceived"
                        data-cy="heparin-therapy-rg"
                      >
                        <nb-radio [value]="true">{{
                          'common.yes' | translate
                        }}</nb-radio>
                        <nb-radio [value]="false">{{
                          'common.no' | translate
                        }}</nb-radio>
                      </nb-radio-group>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-8">
                      <app-validation-error
                        for="hasReceived"
                        translations="validation.generic"
                      ></app-validation-error>
                    </div>
                  </div>
                </div>
                <div class="col-6 align-self-center">
                  @if (
                    form.get(
                      'serviceInformation.nipt.heparinTherapy.hasReceived'
                    ).value
                  ) {
                    <div class="row align-items-center">
                      <div class="col-4">
                        <label for="heparinTherapyRelevant">{{
                          'serviceData.form.heparinTherapyIsRelevant'
                            | translate: { hours: 24 }
                        }}</label>
                      </div>
                      <div class="col-8">
                        <nb-radio-group
                          id="heparinTherapyRelevant"
                          name="heparinTherapyRelevant"
                          class="radio-toolbar"
                          formControlName="isRelevant"
                          data-cy="heparin-therapy-relevant-rg"
                        >
                          <nb-radio [value]="false">{{
                            'common.yes' | translate
                          }}</nb-radio>
                          <nb-radio [value]="true">{{
                            'common.no' | translate
                          }}</nb-radio>
                        </nb-radio-group>
                      </div>
                    </div>
                    <div class="row justify-content-end">
                      <div class="col-8">
                        <app-validation-error
                          for="isRelevant"
                          translations="serviceData.form.validation.heparinTherapy"
                        ></app-validation-error>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </ng-container>
            <ng-container formGroupName="patientHistory">
              <app-sample-patient-history-information></app-sample-patient-history-information>
            </ng-container>
          }
        </section>
      </ng-container>

      <section>
        <p class="caption mb-1">
          {{ 'sample.form.shippingInformation' | translate }}
        </p>
        <hr class="mt-0" />
        <div class="row form-group">
          <div class="col-6" formGroupName="shippingInformation">
            <div class="row align-items-center">
              <div class="col-4">
                <label for="waybill">{{
                  'sample.form.waybill' | translate
                }}</label>
              </div>
              <div class="col-8">
                <input
                  appDhlWaybill
                  nbInput
                  fullWidth
                  type="text"
                  id="waybill"
                  formControlName="waybill"
                  appInputValidationStatus
                  [placeholder]="'sample.form.placeholders.waybill' | translate"
                  [readonly]="(awbModel$ | async) !== awbModels.pregenerated"
                />
                <input nbInput type="hidden" formControlName="documentId" />
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="waybill"
                  translations="sample.form.validation.waybill"
                ></app-validation-error>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <app-waybill-generation
                [partnerId]="form.value.partnerId"
                [kitId]="form.value.kitId"
                [doctorId]="form.value.serviceInformation.nipt.doctorId"
                [awbModel]="awbModel$ | async"
                [address]="address"
                (waybillGenerated)="onWaybillGenerated($event)"
              ></app-waybill-generation>
            </div>
          </div>
        </div>
      </section>
      <section>
        <p class="caption mb-1">
          {{ 'sample.form.otherInformation' | translate }}
        </p>
        <hr class="mt-0" />
        <div class="row form-group">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-2">
                <label for="notes">{{ 'sample.form.notes' | translate }}</label>
              </div>
              <div class="col-10">
                <textarea
                  fullWidth
                  nbInput
                  formControlName="notes"
                  name="notes"
                  id="notes"
                  [placeholder]="'sample.form.placeholders.notes' | translate"
                ></textarea>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="notes"
                  translations="validation.generic"
                ></app-validation-error>
              </div>
            </div>
          </div>
        </div>
      </section>
    </nb-card-body>
    <nb-card-footer class="d-flex flex-row-reverse">
      <button
        nbButton
        status="primary"
        (click)="submit()"
        data-cy="sample-dialog-create-btn"
        [disabled]="loading"
        [class.loading]="loading"
      >
        {{
          (this.sampleId && !this.resampleKitId
            ? 'common.buttons.btnUpdate'
            : 'common.buttons.btnCreate'
          ) | translate
        }}
      </button>
    </nb-card-footer>
  </nb-card>
</form>
