import { Injectable } from '@angular/core';
import {
  ALL_KITS_PARTNER_ID,
  NIFTY_APPLICATIONID_KEY,
  PARTNERS_STATE_NAME,
  UNASSIGNED_PARTNER_ID,
} from '@app/app.constants';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import { Util } from '@core/utils/core.util';
import { PartnerUtil } from '@core/utils/partner.util';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { PARTNERS_PAGE_SORT_SETTINGS } from '@shared/constants/partners.constants';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError, delay, first, map, switchMap, tap } from 'rxjs/operators';
import { PROPERTY_KEYS } from '../../../../app.constants';
import {
  NO_ID_SELECTED,
  Pagination,
  Partner,
  PaymentType,
  StatePageSettings,
  StateType,
  UserAccountResponse,
} from '../../core.types';
import { ExportersService } from '../../services/exporters.service';
import { Logger } from '../../services/logger.service';
import { PartnersService } from '../../services/partners.service';
import { UserAccountService } from '../../services/user-accounts.service';
import { LoadBundleConfigurationByIds } from '../bundle-configuration/bundle-configuration.actions';
import {
  ActivatePartnerAction,
  AddPartnerAction,
  AddPartnerDoctorAction,
  AssignBundleToPartner,
  ClearPartnersStateAction,
  DeactivatePartnerAction,
  LoadPartnerById,
  LoadPartnersAverageTestsPerMonthAction,
  LoadPartnersByIds,
  LoadPartnersListAction,
  RemovePartnerDoctorAction,
  RemovePartnerFromStateListAction,
  SetPartnerProductsConfigurationsAction,
  SetPartnersById,
  SetSelectedPartnerIdAction,
  UnassignBundleConfigurationFromPartner,
  UpdatePartnerAction,
  UpdatePartnerCountryAction,
  UpdatePartnerDoctorAction,
} from './partners.actions';

export const unassignedKitsPartner: Partner = {
  id: UNASSIGNED_PARTNER_ID,
  applicationId: NIFTY_APPLICATIONID_KEY,
  name: 'Unassigned Kits',
  email: 'unassigned@gmail.com',
  phoneNumber: '',
  partnerTypeId: '11678bbb-b2ff-45b1-b5ad-b48317f98492',
  address: {
    street: 'Sleme 34',
    zip: '5632',
    city: 'slovenj gradec',
    country: 'Slovakia',
    countryIsoCode: 'SK',
  },
  properties: [
    { key: 'MinimalSupply', value: '0' },
    { key: 'PaymentType', value: 'Customer1' },
    { key: 'ExternalId', value: '4' },
  ],
  languages: ['Slovenian'],
  barcodeRanges: [],
  notes: '',
  subPartners: [],
  productConfigurations: [],
  bundleConfigurations: [],
  accountManagerId: null,
  userAccounts: [],
  minimalSupply: 2,
  externalId: '1',
  notificationProfiles: [],
  paymentTypes: [PaymentType.Partner],
  onlineReport: false,
};

export const allKitsPartner: Partner = {
  id: ALL_KITS_PARTNER_ID,
  applicationId: NIFTY_APPLICATIONID_KEY,
  name: 'All Kits',
  email: 'all@gmail.com',
  partnerTypeId: '11678bbb-b2ff-45b1-b5ad-b48317f98493',
  address: {
    street: 'Sleme 34',
    zip: '5632',
    city: 'slovenj gradec',
    country: 'Slovakia',
    countryIsoCode: 'SK',
  },
  properties: [
    { key: 'GeneticCounselor', value: 'false' },
    { key: 'MinimalSupply', value: '0' },
    { key: 'PaymentType', value: 'Customer' },
    { key: 'ExternalId', value: '4' },
  ],
  languages: ['Slovenian'],
  barcodeRanges: [],
  notes: '',
  subPartners: [],
  productConfigurations: [],
  bundleConfigurations: [],
  accountManagerId: null,
  phoneNumber: '',
  userAccounts: [],
  minimalSupply: 2,
  externalId: '1',
  notificationProfiles: [],
  paymentTypes: [PaymentType.Partner, PaymentType.Customer2],
  onlineReport: false,
};

export interface PartnersListFilterSettings {
  ids?: string;
  subPartnerId?: string;
  name?: string;
  search?: string;
  activatedState: StateType | '';
  street?: string;
  zip?: string;
  city?: string;
  countries?: string;
  language?: string;
  paymentType?: string;
  minimalSupply?: string;
  accountManagerId?: string;
}

export interface PartnersStateModel {
  partners: { [id: string]: Partner };
  subPartners: { [key: string]: string[] };
  averageTestsPerMonth: { [id: string]: number };
  pages?: {
    items: string[];
    continuationToken: string;
  }[];
  currentPageSettings: StatePageSettings<PartnersListFilterSettings>;
  totalCount?: number;
  selectedPartnerId: string;
}

export const PARTNERS_LIST_PAGE_SIZE = 30;

const PARTNERS_DEFAULT_STATE: PartnersStateModel = {
  partners: {
    [unassignedKitsPartner.id]: unassignedKitsPartner,
    [allKitsPartner.id]: allKitsPartner,
  },
  subPartners: {},
  averageTestsPerMonth: {},
  pages: [],
  currentPageSettings: {
    pageIndex: 0,
    sortSettings: {
      orderBy: PARTNERS_PAGE_SORT_SETTINGS.orderBy,
      sortDirection: PARTNERS_PAGE_SORT_SETTINGS.sortDirection,
      pageSize: PARTNERS_LIST_PAGE_SIZE,
    },
    filterSettings: {
      name: '',
      search: '',
      activatedState: '',
      street: '',
      zip: '',
      city: '',
      countries: '',
      language: '',
      minimalSupply: '',
      paymentType: '',
    },
  },
  totalCount: 0,
  selectedPartnerId: NO_ID_SELECTED,
};

@State<PartnersStateModel>({
  name: PARTNERS_STATE_NAME,
  defaults: { ...PARTNERS_DEFAULT_STATE },
})
@Injectable()
export class PartnersState {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private partnersService: PartnersService,
    private exportersService: ExportersService,
    private store: Store,
    private userAccountService: UserAccountService
  ) {}

  @Selector()
  static getSelectedPartnerId(state: PartnersStateModel) {
    return state.selectedPartnerId;
  }

  @Selector()
  static getAllPartners(state: PartnersStateModel) {
    return state.partners;
  }

  @Selector()
  static getPartnerById({ partners }: PartnersStateModel) {
    return (partnerId: string) => partners[partnerId];
  }

  @Selector()
  static getPartnersByIds({ partners }: PartnersStateModel) {
    return (partnerIds: string[]) =>
      partnerIds.map((partnerId) => partners[partnerId]);
  }

  @Selector()
  static getSubPartners({ partners }: PartnersStateModel) {
    return (partnerId: string) => partners[partnerId].subPartners;
  }

  @Selector()
  static getCurrentPage(state: PartnersStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;
    if (pages.length === 0 || pageIndex > pages.length || pageIndex < 0) {
      return [];
    }
    return state.pages[pageIndex].items;
  }

  @Selector()
  static getAllLoadedPartners(state: PartnersStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;

    if (pages.length === 0 || pageIndex > pages.length || pageIndex < 0) {
      return [];
    }

    let items = [];
    for (const page of pages) {
      items = [...items, ...page.items];
    }

    return items;
  }

  @Selector()
  static getPaginationInfo({
    currentPageSettings,
    totalCount,
    pages,
  }: PartnersStateModel): Pagination<PartnersListFilterSettings> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: pages.length,
      collectionSize: currentPageSettings.sortSettings.pageSize,
    };
  }

  @Selector()
  static getPartnerAverageTestsPerMonth({
    averageTestsPerMonth,
  }: PartnersStateModel) {
    return (partnerId: string) => averageTestsPerMonth[partnerId];
  }

  @Action(SetSelectedPartnerIdAction)
  setSelectedPartner(
    { patchState }: StateContext<PartnersStateModel>,
    { partnerId }: SetSelectedPartnerIdAction
  ) {
    return patchState({
      selectedPartnerId: partnerId,
    });
  }

  @Action(ClearPartnersStateAction)
  clearPartnersState({ setState, getState }: StateContext<PartnersStateModel>) {
    this.log.debug('ClearPartnersStateAction');
    return setState({
      ...PARTNERS_DEFAULT_STATE,
      partners: getState().partners,
      averageTestsPerMonth: getState().averageTestsPerMonth,
    });
  }

  @Action(LoadPartnersListAction)
  loadPartnersList(
    { getState, patchState, dispatch }: StateContext<PartnersStateModel>,
    {
      options: { orderBy, orderDirection, pageToLoad, filter, pageSize },
    }: LoadPartnersListAction
  ) {
    this.log.debug('LoadPartnersListAction');
    pageToLoad = pageToLoad || null;
    filter =
      filter ||
      ({
        name: '',
        search: '',
        zip: '',
        street: '',
        city: '',
        countries: '',
        paymentType: '',
        language: '',
        minimalSupply: '',
      } as PartnersListFilterSettings);

    filter.activatedState = !filter.activatedState
      ? StateType.Active
      : filter.activatedState;

    const {
      currentPageSettings: { sortSettings, filterSettings, pageIndex },
      pages,
    } = getState();
    let isReloadRequired = false;

    if (sortSettings.pageSize !== pageSize) {
      isReloadRequired = true;
    }
    pageSize = pageSize || sortSettings.pageSize;

    if (orderBy) {
      if (orderBy !== sortSettings.orderBy) {
        isReloadRequired = true;
      }
    } else {
      orderBy = sortSettings.orderBy;
    }
    if (orderDirection) {
      if (orderDirection !== sortSettings.sortDirection) {
        isReloadRequired = true;
      }
    } else {
      orderDirection = sortSettings.sortDirection;
    }
    if (
      filter &&
      Object.keys(filterSettings).some((filterKey) =>
        Object.prototype.hasOwnProperty.call(filter, filterKey)
          ? filterSettings[filterKey] !== filter[filterKey]
          : false
      )
    ) {
      isReloadRequired = true;
    }

    const mapKey = (key: string) => {
      switch (key) {
        case 'name':
          return 'NameToken';
        case 'search':
          return 'SearchToken';
        case 'activatedState':
          return 'State';
        case 'street':
          return 'AddressStreetToken';
        case 'zip':
          return 'AddressZipToken';
        case 'city':
          return 'AddressCityToken';
        case 'countries':
          return 'AddressCountryTokens';
        case 'language':
          return 'Language';
        case 'paymentType':
          return 'PaymentTypeToken';
        case 'minimalSupply':
          return 'MinimalSupply';
        default:
          return key;
      }
    };
    const filterMap = Object.keys(filter)
      .map((filterKey) =>
        filter[filterKey] ? [mapKey(filterKey), filter[filterKey]] : null
      )
      .filter((f) => f !== null) as [string, string][];
    const clearOrSkip$ = isReloadRequired
      ? this.store.dispatch(new ClearPartnersStateAction())
      : of(null);

    let continuationToken = null;
    if (pageToLoad !== null) {
      if (pageToLoad - 1 === pageIndex + 1) {
        continuationToken = pages[pageIndex].continuationToken;
      }
      if (!isReloadRequired) {
        if (pages.length >= pageToLoad) {
          return of(
            patchState({
              currentPageSettings: {
                ...getState().currentPageSettings,
                pageIndex: pageToLoad - 1,
              },
            })
          );
        }
      }
    }

    return clearOrSkip$.pipe(
      switchMap(() =>
        this.store
          .select(PartnerTypeState.getFirstLevelPartnerTypesIdsList)
          .pipe(
            first((pt) => pt && !!pt.length),
            switchMap((firstLevelPartnerTypeIds: string[]) =>
              this.partnersService
                .getFilteredEntities({
                  continuationToken,
                  orderBy,
                  orderDirection,
                  top: pageSize,
                  filters: new Map<string, string>([
                    ...filterMap,
                    ['PartnerTypeIds', firstLevelPartnerTypeIds.join(',')],
                  ]),
                })
                .pipe(
                  tap(
                    ({
                      entitiesList,
                      newContinuationToken,
                      totalCount,
                    }: {
                      entitiesList;
                      totalCount;
                      newContinuationToken;
                    }) => {
                      const entitiesListIds = entitiesList.map((t) => t.id);
                      const updatedPages = [
                        ...getState().pages,
                        {
                          items:
                            !pageToLoad && filterMap.length <= 1
                              ? [
                                  unassignedKitsPartner.id,
                                  allKitsPartner.id,
                                  ...entitiesListIds,
                                ]
                              : entitiesListIds,
                          continuationToken: newContinuationToken,
                        },
                      ];
                      dispatch(new SetPartnersById(entitiesList));
                      patchState({
                        currentPageSettings: {
                          sortSettings: {
                            sortDirection: orderDirection,
                            orderBy,
                            pageSize,
                          },
                          filterSettings: {
                            search: filter.search || '',
                            activatedState:
                              filter.activatedState || StateType.Active,
                            name: filter.name || '',
                            street: filter.street || '',
                            zip: filter.zip || '',
                            city: filter.city || '',
                            countries: filter.countries || '',
                            paymentType: filter.paymentType || '',
                            minimalSupply: filter.minimalSupply || '',
                            language: filter.language || '',
                          },
                          pageIndex: pageToLoad !== null ? pageToLoad - 1 : 0,
                        },
                        totalCount,
                        pages: updatedPages,
                      });
                    }
                  )
                )
            )
          )
      )
    );
  }

  @Action(AddPartnerAction)
  addPartner(
    { getState, patchState, dispatch }: StateContext<PartnersStateModel>,
    { partner, selectedPartnerTypes }: AddPartnerAction
  ) {
    this.log.debug('Add partner');
    PartnerUtil.filterPartnerProperties(partner);

    return this.partnersService.createNewEntity(partner).pipe(
      switchMap(() => {
        PartnerUtil.addViewAddress(partner);

        return this.updatePartnerPaymentTypes(
          partner.id,
          [],
          selectedPartnerTypes
        ).pipe(
          switchMap((requests) => {
            const {
              currentPageSettings: { pageIndex },
              pages,
              partners,
            } = getState();

            const pageItemsCopy = [...pages[pageIndex].items];
            pageItemsCopy.splice(2, 0, partner.id);

            const pageCopy = {
              ...pages[pageIndex],
              ...{ items: pageItemsCopy },
            };

            const partnersPagesCopy = [...pages];
            if (pageIndex >= 0) {
              partnersPagesCopy.splice(pageIndex, 1, pageCopy);
            }

            return of(
              patchState({
                pages: [...partnersPagesCopy],
                partners: {
                  ...partners,
                  [partner.id]: partner,
                },
              })
            );
          })
        );
      }),
      delay(300),
      tap(() => dispatch(new LoadPartnerById(partner.id, true)))
    );
  }

  @Action(UpdatePartnerAction)
  updatePartner(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partner, selectedPartnerTypes }: UpdatePartnerAction
  ) {
    this.log.debug('Update partner');

    const currentPartner = getState().partners[partner.id];
    const currentPartnerPaymentTypes = currentPartner.paymentTypes;

    PartnerUtil.mergePartnerProperties(partner, currentPartner);
    PartnerUtil.filterPartnerProperties(partner);

    this.setPartnerExternalId(partner);

    return this.partnersService.updateEntity(partner).pipe(
      switchMap(() => {
        PartnerUtil.addViewAddress(partner);

        return this.updatePartnerPaymentTypes(
          partner.id,
          currentPartnerPaymentTypes,
          selectedPartnerTypes
        ).pipe(
          switchMap((requests) => {
            if (requests) {
              return of(
                patchState({
                  partners: {
                    ...getState().partners,
                    [partner.id]: { ...partner },
                  },
                })
              );
            }
            return of(null);
          })
        );
      })
    );
  }

  @Action(UpdatePartnerCountryAction)
  updatePartnerCountryAction(
    { getState, dispatch }: StateContext<PartnersStateModel>,
    { country, partnerId }: UpdatePartnerCountryAction
  ) {
    return this.partnersService
      .updatePartnerCountry(country, partnerId)
      .pipe(
        switchMap(() =>
          dispatch(
            new LoadPartnersByIds(
              getState().partners[partnerId].subPartners,
              true
            )
          )
        )
      );
  }

  @Action(SetPartnerProductsConfigurationsAction)
  setPartnerProductsConfigurations(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { productConfigurations, partnerId }: SetPartnerProductsConfigurationsAction
  ) {
    const { partners } = getState();
    return of(
      patchState({
        partners: {
          ...partners,
          [partnerId]: {
            ...partners[partnerId],
            productConfigurations,
          },
        },
      })
    );
  }

  @Action(AssignBundleToPartner)
  assignBundleToPartner(
    { dispatch, patchState, getState }: StateContext<PartnersStateModel>,
    { partnerId, bundleId }: AssignBundleToPartner
  ) {
    const { partners } = getState();
    return this.partnersService.assignBundleToPartner(partnerId, bundleId).pipe(
      delay(200),
      switchMap(({ id }) =>
        dispatch(new LoadBundleConfigurationByIds([id])).pipe(
          tap(() =>
            patchState({
              partners: {
                ...partners,
                [partnerId]: {
                  ...partners[partnerId],
                  bundleConfigurations: [
                    ...partners[partnerId].bundleConfigurations,
                    id,
                  ],
                },
              },
            })
          )
        )
      )
    );
  }

  @Action(UnassignBundleConfigurationFromPartner)
  unassignBundleConfigurationFromPartner(
    { patchState, getState }: StateContext<PartnersStateModel>,
    { partnerId, bundleConfigurationId }: UnassignBundleConfigurationFromPartner
  ) {
    const { partners } = getState();
    return this.partnersService
      .unassignBundleConfigurationFromPartner(partnerId, bundleConfigurationId)
      .pipe(
        tap(() =>
          patchState({
            partners: {
              ...partners,
              [partnerId]: {
                ...partners[partnerId],
                bundleConfigurations: [
                  ...partners[partnerId].bundleConfigurations.filter(
                    (bundleConfiguration) =>
                      bundleConfiguration !== bundleConfigurationId
                  ),
                ],
              },
            },
          })
        )
      );
  }

  @Action(LoadPartnersAverageTestsPerMonthAction)
  loadPartnersAverageTestsPerMonthAction(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partnerIds }: LoadPartnersAverageTestsPerMonthAction
  ) {
    const { averageTestsPerMonth } = getState();
    partnerIds = partnerIds.filter(
      (id) => !Object.prototype.hasOwnProperty.call(averageTestsPerMonth, id)
    );
    if (!partnerIds.length) {
      return EMPTY;
    }
    return this.partnersService
      .loadPartnersAverageTestsPerMonth(partnerIds)
      .pipe(
        map((data) =>
          data.reduce(
            (accumulator, value) => ({
              ...accumulator,
              [value.partnerId]: value.averageTestsGenerated,
            }),
            {}
          )
        ),
        tap((data) =>
          patchState({
            averageTestsPerMonth: {
              ...averageTestsPerMonth,
              ...data,
            },
          })
        )
      );
  }

  @Action(AddPartnerDoctorAction)
  addPartnerDoctor(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partnerId, doctor }: AddPartnerDoctorAction
  ): any {
    this.log.debug('AddPartnerDoctorAction');
    PartnerUtil.filterPartnerProperties(doctor);

    return this.userAccountService
      .registerUser(doctor.username, doctor.password)
      .pipe(
        switchMap((response: UserAccountResponse) =>
          this.userAccountService
            .setUserMetadataToUser(
              response.userAccountId,
              doctor.id,
              doctor.application
            )
            .pipe(
              switchMap(() =>
                this.partnersService.createDoctor(doctor).pipe(
                  switchMap(() =>
                    this.partnersService
                      .assignUserAccountToPartner(
                        doctor.id,
                        response.userAccountId
                      )
                      .pipe(
                        switchMap(() =>
                          this.partnersService
                            .assignDoctorToPartner(partnerId, doctor.id)
                            .pipe(
                              delay(300),
                              switchMap(() =>
                                this.store
                                  .dispatch(new LoadPartnersByIds([doctor.id]))
                                  .pipe(
                                    switchMap(() => {
                                      const { partners } = getState();
                                      return of(
                                        patchState({
                                          partners: {
                                            ...partners,
                                            [partnerId]: {
                                              ...partners[partnerId],
                                              subPartners: [
                                                ...partners[partnerId]
                                                  .subPartners,
                                                doctor.id,
                                              ],
                                            },
                                          },
                                        })
                                      );
                                    })
                                  )
                              )
                            )
                        )
                      )
                  )
                )
              )
            )
        )
      );
  }

  @Action(RemovePartnerDoctorAction)
  removePartnerDoctor(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partnerId, doctorId, doctorUserAccountId }: RemovePartnerDoctorAction
  ) {
    return this.partnersService
      .unassignDoctorFromPartner(partnerId, doctorId)
      .pipe(
        switchMap(() =>
          this.userAccountService.deprovisionUser(doctorUserAccountId).pipe(
            switchMap(() =>
              this.partnersService.deactivateDoctor(doctorId).pipe(
                switchMap(() => {
                  const { partners } = getState();

                  const partnerSubPartnersCopy = [
                    ...partners[partnerId].subPartners,
                  ];
                  const subPartnerToRemoveIndex = partners[
                    partnerId
                  ].subPartners.findIndex((p) => p === doctorId);
                  if (subPartnerToRemoveIndex >= 0) {
                    partnerSubPartnersCopy.splice(subPartnerToRemoveIndex, 1);
                  }

                  return of(
                    patchState({
                      partners: {
                        ...partners,
                        [partnerId]: {
                          ...partners[partnerId],
                          subPartners: partnerSubPartnersCopy,
                        },
                      },
                    })
                  );
                })
              )
            )
          )
        )
      );
  }

  @Action(DeactivatePartnerAction)
  deactivatePartner(
    { getState, patchState, dispatch }: StateContext<PartnersStateModel>,
    { partnerId }: DeactivatePartnerAction
  ) {
    return this.partnersService
      .deactivatePartner(partnerId)
      .pipe(
        switchMap(() =>
          this.partnersService
            .getPartner(partnerId)
            .pipe(
              switchMap((partner: Partner) =>
                dispatch(new RemovePartnerFromStateListAction(partner))
              )
            )
        )
      );
  }

  @Action(ActivatePartnerAction)
  activatePartner(
    { getState, patchState, dispatch }: StateContext<PartnersStateModel>,
    { partnerId }: ActivatePartnerAction
  ) {
    return this.partnersService
      .activatePartner(partnerId)
      .pipe(
        switchMap(() =>
          this.partnersService
            .getPartner(partnerId)
            .pipe(
              switchMap((partner: Partner) =>
                dispatch(new RemovePartnerFromStateListAction(partner))
              )
            )
        )
      );
  }

  @Action(UpdatePartnerDoctorAction)
  updatePartnerDoctor(
    { getState, dispatch }: StateContext<PartnersStateModel>,
    { doctor }: UpdatePartnerDoctorAction
  ) {
    this.log.debug('UpdatePartnerDoctorAction');
    PartnerUtil.filterPartnerProperties(doctor);

    return this.partnersService
      .updateDoctor(doctor)
      .pipe(
        switchMap(() => dispatch(new LoadPartnersByIds([doctor.id], true)))
      );
  }

  @Action(SetPartnersById)
  setPartnerById(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partners }: SetPartnersById
  ) {
    this.log.debug('SetPartnersById');
    const partnerArray: Partner[] = Array.isArray(partners)
      ? partners
      : [partners];
    if (partnerArray.length) {
      const updatedPartners = {
        ...getState().partners,
      };
      for (const partner of partnerArray) {
        updatedPartners[partner.id] = partner;
      }
      patchState({
        partners: updatedPartners,
      });
    }
  }

  @Action(LoadPartnerById)
  loadPartnerById(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partnerId, refresh }: LoadPartnerById
  ) {
    this.log.debug('LoadPartnerById');
    const { partners } = getState();

    if (refresh || !Object.prototype.hasOwnProperty.call(partners, partnerId)) {
      if (!Object.prototype.hasOwnProperty.call(partners, partnerId)) {
        patchState({
          partners: { ...partners, [partnerId]: undefined },
        });
      }
      return this.partnersService.getPartner(partnerId).pipe(
        switchMap((partner: Partner) =>
          of(
            patchState({
              partners: {
                ...getState().partners,
                [partnerId]: partner ? partner : null,
              },
            })
          )
        )
      );
    }
    return of(null);
  }

  @Action(RemovePartnerFromStateListAction)
  removePartnerFromStateList(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partner }: RemovePartnerFromStateListAction
  ) {
    const pages = [...getState().pages];
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      pages[i] = {
        ...page,
        items: [...page.items.filter((id) => id !== partner.id)],
      };
    }

    patchState({
      pages,
      partners: {
        ...getState().partners,
        [partner.id]: partner,
      },
    });
  }

  @Action(LoadPartnersByIds)
  loadPartnersByIds(
    { getState, patchState }: StateContext<PartnersStateModel>,
    { partnerIds, refresh }: LoadPartnersByIds
  ) {
    this.log.debug('LoadPartnersByIds');
    const partners = { ...getState().partners };

    partnerIds = Util.removeDuplicates(partnerIds);
    if (!refresh) {
      partnerIds = partnerIds.filter(
        (id) => !Object.prototype.hasOwnProperty.call(partners, id)
      );
    }

    if (!partnerIds.length) {
      return of([]);
    }

    for (const partnerId of partnerIds) {
      if (!Object.prototype.hasOwnProperty.call(partners, partnerId)) {
        partners[partnerId] = null;
      }
    }
    patchState({ partners });

    const uniqueIds = [...new Set(partnerIds)];
    return forkJoin(
      Util.chunkArray(uniqueIds, 200).map((ids) =>
        this.partnersService.getPartners(ids).pipe(
          map((loadedPartners: Partner[]) => {
            const patchedPartnersState = { ...getState().partners };

            for (const partner of loadedPartners) {
              patchedPartnersState[partner.id] = partner;
            }

            return patchState({
              partners: patchedPartnersState,
            });
          })
        )
      )
    );
  }

  private setPartnerExternalId(partner) {
    let externalIdProp = partner.properties.find(
      (p) => p.key.toLowerCase() === PROPERTY_KEYS.ExternalId.toLowerCase()
    );
    externalIdProp = externalIdProp ? externalIdProp.value : '';

    partner.externalId = externalIdProp;
  }

  private updatePartnerPaymentTypes(
    partnerId: string,
    currentPartnerPaymentTypes: string[],
    selectedPartnerTypes: string[]
  ): Observable<any> {
    const paymentTypesForAssign = selectedPartnerTypes.filter(
      (pt) => !currentPartnerPaymentTypes.includes(pt)
    );

    const paymentTypesForUnAssign = currentPartnerPaymentTypes.filter(
      (pt) => !selectedPartnerTypes.includes(pt)
    );

    const allRequests: Observable<any>[] = [];

    if (paymentTypesForUnAssign.length > 0) {
      paymentTypesForUnAssign.map((pt) =>
        allRequests.push(
          this.partnersService
            .assignPaymentTypeToPartner(partnerId, pt, false)
            .pipe(catchError((err) => of(err.status)))
        )
      );
    }

    paymentTypesForAssign.map((pt) =>
      allRequests.push(
        this.partnersService
          .assignPaymentTypeToPartner(partnerId, pt, true)
          .pipe(catchError((err) => of(err.status)))
      )
    );
    if (allRequests.length > 0) {
      return forkJoin(allRequests).pipe(
        map((r) => r.every((r1) => r1 === null))
      );
    } else {
      return of(true);
    }
  }
}
