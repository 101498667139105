import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PAGING_HEADERS } from '@app/app.constants';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../services/logger.service';
import { LanguageState } from '@core/store/languages/languages.state';
import { map } from 'rxjs/operators';
import { Language } from '@core/core.types';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class CoreUtilsService {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private toastrService: NbToastrService,
    private http: HttpClient,
    private translate: TranslateService,
    private store: Store
  ) {}

  showErrowMessage(message: string) {
    this.log.debug('show error message', message);
    this.toastrService.danger(message, this.translate.instant('toastr.error'), {
      destroyByClick: true,
      duration: 0,
      preventDuplicates: true,
    });
  }



  getLanguageCode$(languageName: string) {
    return this.store.select(LanguageState.getLanguageByName).pipe(
      map((filterByName) => filterByName(languageName)),
      map((language: Language) => {
        if (language && language.isoCode) {
          return language.isoCode.split('-')[0];
        }
        return null;
      })
    );
  }

  showSuccessMessage(message: string) {
    this.log.debug('show success message', message);
    this.toastrService.success(message);
  }

  async getAllElements<T>(
    url: string,
    continuationToken: string = null,
    elements: T[] = [],
    params: HttpParams = new HttpParams()
  ): Promise<T[]> {
    let headers = new HttpHeaders();
    if (continuationToken) {
      headers = headers.append(
        PAGING_HEADERS.continuationToken,
        continuationToken
      );
    }
    const response = await this.http
      .get(url, { headers, observe: 'response', params })
      .toPromise();
    if (response.body) {
      const newElements = response.body as T[];
      elements = [...elements, ...newElements];
    }
    if (response && response.headers.has(PAGING_HEADERS.continuationToken)) {
      const newContinuationToken = response.headers.get(
        PAGING_HEADERS.continuationToken
      );
      return this.getAllElements<T>(
        url,
        newContinuationToken,
        elements,
        params
      );
    }
    return elements;
  }

  getPaymentTypesDisplayString(paymentTypes: string[]) {
    const translatedPaymentTypes: string[] = [];

    paymentTypes.map((pt) =>
      translatedPaymentTypes.push(this.translate.instant('paymentType.' + pt))
    );

    return translatedPaymentTypes.join(', ');
  }

  getRequestPasswordResetPayload(auth0domain: string, email: string) {
    return {
      email,
      domain: auth0domain,
      redirectTo: `${window.location.origin}`,
      senderEmail: 'donotreply@geneplanet.com',
      senderName: 'GenePlanet',
      subject: this.translate.instant('ACCOUNTS.RESET.EMAIL.SUBJECT'),
      title: this.translate.instant('ACCOUNTS.RESET.EMAIL.TITLE'),
      paragraph1: this.translate.instant('ACCOUNTS.RESET.EMAIL.PARAGRAPH1'),
      paragraph2: this.translate.instant('ACCOUNTS.RESET.EMAIL.PARAGRAPH2'),
      linkName: this.translate.instant('ACCOUNTS.RESET.EMAIL.LINK'),
      footer: this.translate.instant('ACCOUNTS.RESET.EMAIL.FOOTER'),

      supportEmail: this.translate.instant('ACCOUNTS.RESET.EMAIL.SUPPORTEMAIL'),
    };
  }

  getPriceContractForProduct(
    priceContracstList: any,
    productExternalId: string
  ): any {
    const priceContract = priceContracstList.filter(
      (p) => p.ident === productExternalId
    );

    return priceContract;
  }
}
