import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs/internal/observable/of';
import { switchMap } from 'rxjs/operators';
import { ProductConfiguration } from '../../core.types';
import { Logger } from '../../services/logger.service';
import { ProductsService } from '../../services/products.service';
import {
  LoadProductConfigurationById,
  PRODUCT_CONFIGURATION_STATE_NAME,
} from './product-configuration.actions';

export interface ProductConfigurationsStateModel {
  productConfigurations: {
    [productConfigurationId: string]: ProductConfiguration;
  };
}

@State<ProductConfigurationsStateModel>({
  name: PRODUCT_CONFIGURATION_STATE_NAME,
  defaults: {
    productConfigurations: {},
  },
})
@Injectable()
export class ProductConfigurationsState {
  private readonly log = new Logger(this.constructor.name);
  constructor(private productsService: ProductsService) {}

  @Selector()
  static getProductConfigurationById(state: ProductConfigurationsStateModel) {
    return (productConfigurationId: string) =>
      state.productConfigurations[productConfigurationId];
  }

  @Selector()
  static getProductConfigurationByIds(state: ProductConfigurationsStateModel) {
    return (productConfigurationIds: string[]) => {
      if (!productConfigurationIds || !productConfigurationIds.length) {
        return null;
      }
      return productConfigurationIds.map(
        (productConfigurationId) =>
          state.productConfigurations[productConfigurationId]
      );
    };
  }

  @Action(LoadProductConfigurationById)
  loadProductConfigurationById(
    { patchState, getState }: StateContext<ProductConfigurationsStateModel>,
    { productConfigurationId }: LoadProductConfigurationById
  ) {
    this.log.debug('LoadProductConfigurationById');
    const { productConfigurations } = getState();
    if (
      !Object.prototype.hasOwnProperty.call(
        productConfigurations,
        productConfigurationId
      )
    ) {
      patchState({
        productConfigurations: {
          ...getState().productConfigurations,
          [productConfigurationId]: undefined,
        },
      });
      return this.productsService
        .getProductConfiguration(productConfigurationId)
        .pipe(
          switchMap((productConfiguration: ProductConfiguration) =>
            of(
              patchState({
                productConfigurations: {
                  ...getState().productConfigurations,
                  [productConfigurationId]: productConfiguration,
                },
              })
            )
          )
        );
    }
    return of(null);
  }
}
