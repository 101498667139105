import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-progress-dialog',
  styleUrls: ['progress-dialog.component.scss'],
  templateUrl: 'progress-dialog.component.html',
})
export class ProgressDialogComponent {
  title = '';
  password = '';
  progressSubject: BehaviorSubject<ProgressData>;

  constructor(private toastr: NbToastrService) {}

  copyToClipboard(password) {
    navigator.clipboard.writeText(password).then(
      () => {
        this.toastr.success('Password copied to clipboard!');
      },
      (err) => {
        this.toastr.danger('Could not copy to clipboard.');
      }
    );
  }
}

export interface ProgressData {
  status: string;
  value: number;
  success?: boolean;
}
