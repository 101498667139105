import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import {
  N2dStatistics,
  Treatment,
  TreatmentSelectProperties,
} from '@treatments/treatment.types';
import { map } from 'rxjs/internal/operators/map';
import { TreatmentsUtilsService } from './treatments-utils.service.new';
import { PAGING_HEADERS } from '@app/app.constants';
import {
  ApiFilters,
  ApiHeaders,
  Product,
  Run,
  SortDirection,
} from '@core/core.types';

@Injectable({
  providedIn: 'root',
})
export class TreatmentsService {
  private apiUrl = `${environment.apiUrl}/nipts/treatments`;

  constructor(
    protected httpClient: HttpClient,
    private treatmentsUtils: TreatmentsUtilsService
  ) {}

  getTreatments(
    queryParams: TreatmentFilters,
    headers: TreatmentHeaders
  ): Observable<{
    items: Treatment[];
    continuationToken: string;
    totalCount: number;
  }> {
    return this.httpClient
      .get<Treatment[]>(this.apiUrl, {
        params: this.treatmentsUtils.buildHttpParams(queryParams),
        headers: this.treatmentsUtils.buildHttpHeaders(headers),
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          items: response.body,
          continuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
        }))
      );
  }

  getTreatment(guid: string): Observable<Treatment> {
    return this.httpClient.get<Treatment>(`${this.apiUrl}/${guid}`);
  }

  getTreatmentsStatistics(queryParams: TreatmentFilters): Observable<number> {
    return this.httpClient
      .get<{ result: number }>(`${this.apiUrl}/count`, {
        params: this.treatmentsUtils.buildHttpParams(queryParams),
      })
      .pipe(map((response) => response.result));
  }

  generateTreatment(treatment: Treatment): Observable<Treatment> {
    return this.httpClient
      .post<{ id: string }>(`${this.apiUrl}`, treatment)
      .pipe(
        map((response) => {
          treatment.id = response.id;
          return treatment;
        })
      );
  }

  updateTreatment(treatment: Treatment): Observable<Treatment> {
    return this.httpClient
      .put<void>(`${this.apiUrl}/${treatment.id}`, treatment)
      .pipe(map(() => treatment));
  }

  deleteTreatment(treatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${treatmentId}`);
  }

  deactivateTreatment(treatmentId): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.apiUrl}/${treatmentId}/status/deactivate`,
      {}
    );
  }

  reactivateTreatment(treatmentId): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.apiUrl}/${treatmentId}/status/reactivate`,
      {}
    );
  }

  markTreatmentAsFailed(treatmentId, failureReason): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.apiUrl}/${treatmentId}/status/failed`,
      { failureReason }
    );
  }

  setTreatmentNotes(treatmentId, notes): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${treatmentId}/notes`, {
      notes,
    });
  }

  setLaboratoryInvoiceNumber(
    treatmentId,
    laboratoryInvoiceNumber
  ): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.apiUrl}/${treatmentId}/laboratory-invoice-number`,
      { laboratoryInvoiceNumber }
    );
  }

  setGeneplanetInvoiceNumber(
    treatmentId,
    geneplanetInvoiceNumber
  ): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.apiUrl}/${treatmentId}/geneplanet-invoice-number`,
      { geneplanetInvoiceNumber }
    );
  }

  setDoctorInvoiceNumber(treatmentId, doctorInvoiceNumber): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.apiUrl}/${treatmentId}/doctor-invoice-number`,
      { doctorInvoiceNumber }
    );
  }

  markTreatmentAsExported(ids): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/status/exported`, {
      Ids: ids.join(','),
    });
  }

  assignDocumentToNiptTest(treatmentId, documentId): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.apiUrl}/${treatmentId}/add-document`,
      {
        documentId,
      }
    );
  }

  n2dStatistics(kitId): Observable<N2dStatistics> {
    return this.httpClient.get<N2dStatistics>(
      `${environment.apiUrl}/nipt-online/n2d-information/${kitId}`
    );
  }
}

export interface TreatmentFilters extends ApiFilters {
  partnerId?: string;
  doctorIds?: string[];
  statusChangedFrom?: string;
  statusChangedTo?: string;
  generatedFrom?: string;
  generatedTo?: string;
  status?: string;
  documentStatus?: string;
  kitToken?: string;
  patientNameToken?: string;
  dhlWaybillToken?: string;
  notesToken?: string;
  dateOfVenipuncture?: string;
  processedDateSearchKey?: string;
  dateOfServiceSearchKey?: string;
  partnerAddressCountryTokens?: string;
  runIds?: Run[];
  productIds?: Product[];
  laboratory?: string;
  lastStatuses?: string;
  lastStatusChangedFrom?: string;
  lastStatusChangedTo?: string;
  isPaid?: boolean;
  paymentInformationNotesToken?: string;
  geneplanetInvoiceNumberToken?: string;
  doctorInvoiceNumberToken?: string;
  laboratoryInvoiceNumberToken?: string;
  paymentTypes?: string[];
  isExported?: boolean;
  hasDateOfVenipuncture?: boolean;
  dateOfVenipunctureFrom?: string;
  dateOfVenipunctureTo?: string;
  isRedraw?: boolean;
  documentIds?: string[];
  productInventoryItemIds?: string[];
}

export interface TreatmentHeaders extends ApiHeaders {
  select?: TreatmentSelectProperties[];
}

export enum TreatmentStatisticsType {
  BgiExport = 'bgi_export',
  StatisticsToday = 'statistics_today',
  StatisticsWeek = 'statistics_week',
  StatisticsMonth = 'statistics_month',
  StatisticsPreviousMonth = 'statistics_previous_month',
  StatisticsAll = 'statistics_all',
}
