import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PAGING_HEADERS } from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EntityBase,
  PaginationAndOrdering,
  PartnerAverageMonthlyTests,
} from '../core.types';
import { Util } from '../utils/core.util';
import { Logger } from './logger.service';

export abstract class EntityBaseService<T extends EntityBase> {
  protected logger;
  protected baseApiEndpoint;
  protected apiUrl;

  constructor(protected http: HttpClient) {
    this.logger = new Logger(this.constructor.name);
  }

  getFilteredEntities(paginationAndOrdering: PaginationAndOrdering) {
    this.logger.debug('getFilteredEntities');
    const headers = Util.GetHttpHeadersFromPaginationAndOrderingWithCT(
      paginationAndOrdering
    );

    let params = new HttpParams();
    if (paginationAndOrdering.filters) {
      for (const [key, value] of paginationAndOrdering.filters.entries()) {
        params = params.append(key, value);
      }
    }

    return this.http
      .get<T[]>(this.getListUrl(), { headers, observe: 'response', params })
      .pipe(
        map((response) => {
          const responseHeaders = response.headers;
          const totalCount = responseHeaders.has(PAGING_HEADERS.totalCount)
            ? parseInt(responseHeaders.get(PAGING_HEADERS.totalCount), 10)
            : 1;
          const newContinuationToken = responseHeaders.get(
            PAGING_HEADERS.continuationToken
          );
          const entitiesList = response.body as T[];

          return {
            entitiesList,
            totalCount,
            newContinuationToken,
          };
        })
      );
  }

  async getAllEntities(additionalHeaders = {}): Promise<T[]> {
    this.logger.debug('getAllEntities');
    const entites: T[] = [];
    let continuationToken = '';
    try {
      do {
        let headers = new HttpHeaders({
          [PAGING_HEADERS.continuationToken]: continuationToken,
        });

        for (const key of Object.keys(additionalHeaders)) {
          headers = headers.set(key, additionalHeaders[key]);
        }

        const response = await this.http
          .get<T[]>(this.getListUrl(), { headers, observe: 'response' })
          .toPromise();
        if (response && response.body) {
          for (const e of response.body) {
            entites.push(e);
          }
        }
        if (response.headers.has(PAGING_HEADERS.continuationToken)) {
          continuationToken = response.headers.get(
            PAGING_HEADERS.continuationToken
          );
        } else {
          continuationToken = '';
        }
      } while (continuationToken);
      // eslint-disable-next-line no-empty
    } catch {}
    return entites;
  }

  createNewEntity(newEntity: T): Observable<T> {
    this.logger.debug('createNewEntity');
    let body = this.setUrlBody(newEntity);
    body = this.setCreateEntityBody(newEntity, body);
    return this.http.post<T>(this.getCreateEntityUrl(newEntity.id), body);
  }

  deleteEntity(entityId: string): Observable<any> {
    this.logger.debug('deleteEntity');
    return this.http.post(this.getDeleteEntityUrl(entityId), null);
  }

  updateEntity(entity: T) {
    this.logger.debug('updateEntity');
    let body = this.setUrlBody(entity);
    body = this.setUpdateEntityBody(entity, body);
    return this.http.post(this.getUpdateEntityUrl(entity.id), body);
  }

  getEntity(entityId: string) {
    return this.http.get<T>(this.getFetchEntityUrl(entityId));
  }

  loadPartnersAverageTestsPerMonth(
    partnerIds: string[]
  ): Observable<PartnerAverageMonthlyTests[]> {
    this.logger.debug('loadPartnersAverageTestsPerMonth');
    return this.http.get<PartnerAverageMonthlyTests[]>(
      `${environment.apiUrl}/service-data/samples/average`,
      {
        params: {
          partnerIds: partnerIds.join(','),
        },
      }
    );
  }

  updatePartnerCountry(country: string, partnerId: string): Observable<any> {
    return this.http.get<PartnerAverageMonthlyTests[]>(
      `${environment.apiUrl}/partners/update-partner-and-subpartner-country-properties`,
      {
        params: {
          country,
          partnerId,
        },
      }
    );
  }

  protected abstract setUpdateEntityBody(entity: T, body: Partial<T>);

  protected abstract setCreateEntityBody(entity: T, body: Partial<T>);

  protected abstract setUrlBody(run: T);

  protected abstract getListUrl(): string;

  protected abstract getUpdateEntityUrl(entityId: string): string;

  protected abstract getDeleteEntityUrl(entityId: string): string;

  protected abstract getCreateEntityUrl(newEntityId: string): string;

  protected abstract getFetchEntityUrl(entityId: string): string;
}
