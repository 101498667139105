<ng-container *appRequirePermission="button.permission">
  <button
    nbButton
    *ngIf="button.type === DataTableActionType.BUTTON && !isInvisible(button)"
    (click)="callAction(button?.action, $event, rows)"
    size="small"
    class="p-1 me-1"
    [status]='button.buttonStatus ?? "primary"'
    [class.dropdown-toggle]="button.children"
    [nbTooltip]="button.name | translate"
    [disabled]="isDisabled(button)"
    [matMenuTriggerFor]="button.children ? submenu : null"
    [attr.data-cy]="button.name + '-btn'"
  >
    <nb-icon *ngIf="button.icon" [icon]="button.icon"></nb-icon>
    <span *ngIf="button.showName">{{ button.name | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="button.type === DataTableActionType.MENUITEM && !isInvisible(button)"
    (click)="callAction(button?.action, $event, rows)"
    size="small"
    [disabled]="isDisabled(button)"
    [matMenuTriggerFor]="button.children ? submenu : null"
    [attr.data-cy]="button.name + '-btn'"
  >
    <nb-icon *ngIf="button.icon" [icon]="button.icon"></nb-icon>
    {{ button.name | translate }}
  </button>

  <mat-menu #submenu="matMenu">
    <ng-container *ngFor="let child of button.children">
      <ng-container *appRequirePermission="child.permission">
        <app-data-table-nestable-actions
          [button]="child"
          [rows]="rows"
          *ngIf="child.children; else menuItem"
        ></app-data-table-nestable-actions>
        <ng-template #menuItem>
          <button
            *ngIf="!isInvisible(child)"
            mat-menu-item
            (click)="callAction(child?.action, $event, rows)"
            size="small"
            [disabled]="isDisabled(child)"
          >
            <nb-icon *ngIf="child.icon" [icon]="child.icon"></nb-icon>
            {{ child.name | translate }}
          </button>
        </ng-template>
      </ng-container>
    </ng-container>
  </mat-menu>
</ng-container>
