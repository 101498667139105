import { Injectable } from '@angular/core';
import { Country } from '../core.types';
import { environment } from '@root/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private readonly route = `${environment.apiUrl}/nipts/countries`;

  constructor(private http: HttpClient) {}

  getCountries() {
    return this.http.get<Country[]>(this.route);
  }

  getAccountManagerCountriesAction(accountManagerId: string) {
    return this.http.get<string[]>(`${environment.apiUrl}/partners/countries`, {
      params: { accountManagerId },
    });
  }

  getCountry(id: string) {
    return this.http.get<Country>(`${this.route}/${id}`);
  }

  updateCountry(id: string, country: Country) {
    return this.http.put<void>(`${this.route}/${id}`, country);
  }

  createCountry(country: Country) {
    return this.http.post<{ id: string }>(this.route, country);
  }

  activateCountry(id: string) {
    return this.http.patch(`${this.route}/${id}/activate`, {});
  }

  deactivateCountry(id: string) {
    return this.http.patch(`${this.route}/${id}/deactivate`, {});
  }
}
