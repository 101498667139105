<app-data-table
  [configuration]="dataTableConfiguration"
  (selectedRowsChange)="selectedRowsSubject$.next($event)"
></app-data-table>

<ng-template #interactionTableTemplate let-row="row">
  @if (row?.interactions) {
    <app-document-interactions-list [rows]="row.interactions" />
  }
</ng-template>
