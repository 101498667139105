import { Partner } from '@core/core.types';
import {
  NOTIFICATION_EMAILS_CONSTANTS,
  ServiceType,
} from '../../../app.constants';

export class PartnerUtil {
  static filterPartnerProperties(partner: Partner) {
    partner.properties = [...partner.properties]
      .map(({ key, value }) => {
        if (typeof value === 'string') {
          value = value ? value.trim() : null;
        }

        return {
          key,
          value,
        };
      })
      .filter(({ value }) => value !== '' && value !== null);
  }

  static addViewAddress(
    partner: Partner & {
      addressCountry: string;
      addressStreet: string;
      addressZip: string;
      addressCity: string;
    }
  ) {
    partner.address = {
      country: partner.addressCountry,
      street: partner.addressStreet,
      zip: partner.addressZip,
      city: partner.addressCity,
    };
  }

  static mergePartnerProperties(newPartner: Partner, currentPartner: Partner) {
    const propertiesToMerge = currentPartner.properties.filter(
      (p) => !newPartner.properties.find((np) => np.key === p.key)
    );

    for (const property of propertiesToMerge) {
      newPartner.properties.push(property);
    }
  }
}

export const getTemplateId = (serviceName: string) =>
  NOTIFICATION_EMAILS_CONSTANTS.generalSendGridTemplateId[serviceName];
