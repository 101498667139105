import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_SPECIFICATION_CONSTANTS } from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { UserAccount, UserAccountResponse } from '../core.types';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  private userMetaDataMap: { [key: string]: Observable<any> } = {};

  constructor(private httpClient: HttpClient) {}

  getUser(userId: string): Observable<UserAccount> {
    return this.httpClient.get<UserAccount>(
      `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}/${userId}`
    );
  }

  getUsers(userIds: string[]): Observable<UserAccount[]> {
    return this.httpClient.get<UserAccount[]>(
      `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}`,
      {
        params: {
          query: userIds.map((userId) => `user_id=${userId}`).join(' OR '),
        },
      }
    );
  }

  updateAccountPassword(accountId: string, password: string): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}/${accountId}/password`,
      { password }
    );
  }

  registerUser(
    email: string,
    password: string
  ): Observable<UserAccountResponse> {
    const payload = {
      Domain: environment.auth0.domain,
      email,
      password,
    };
    return this.httpClient.post<UserAccountResponse>(
      `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}`,
      payload
    );
  }

  setPartnerIdToUser(userId: string, partnerId: string) {
    const setPartnerIdUrl = `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}/${userId}/metadata?PartnerId=${partnerId}`;
    return this.httpClient.put(setPartnerIdUrl, {});
  }

  setUserMetadataToUser(
    userId: string,
    partnerId: string,
    application: string
  ) {
    const setUserMetadataToUserUrl = `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}/${userId}/usermetadata`;
    return this.httpClient.put(setUserMetadataToUserUrl, {
      partnerId,
      application,
    });
  }

  deprovisionUser(userId: string) {
    const deprovisionUserUrl = `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}/${userId}?Domain=${environment.auth0.domain}`;
    return this.httpClient.delete(deprovisionUserUrl);
  }

  getUserMetadata(userId: string): Observable<any> {
    if (!this.userMetaDataMap[userId]) {
      const getUserMetadataUrl = `${environment.apiUrl}/${API_SPECIFICATION_CONSTANTS.userAccounts}/${userId}/appmetadata`;
      this.userMetaDataMap[userId] = this.httpClient
        .get(getUserMetadataUrl)
        .pipe(shareReplay());
    }
    return this.userMetaDataMap[userId];
  }
}
