<ng-container [formGroup]="form.get('serviceInformation.nipt')">
  <div class="row form-group">
    <div class="col-12">
      <div class="row align-items-center">
        <div class="col-2">
          <label for="patientState">{{
            'serviceData.form.patientState' | translate
          }}</label>
        </div>
        <div class="col-10">
          <textarea
            fullWidth
            nbInput
            formControlName="comments"
            name="patientState"
            id="patientState"
            [placeholder]="
              'serviceData.form.placeholders.patientState' | translate
            "
            data-cy="patient-state-textarea"
          ></textarea>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="comments"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="row form-group">
  <div class="col-12">
    <div class="row align-items-center">
      <div class="col-2">
        <label for="notes">{{ 'serviceData.form.notes' | translate }}</label>
      </div>
      <div class="col-10">
        <textarea
          fullWidth
          nbInput
          formControlName="notes"
          name="notes"
          id="notes"
          [placeholder]="'serviceData.form.placeholders.notes' | translate"
          data-cy="notes-textarea"
        ></textarea>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="notes"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>
