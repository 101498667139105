import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_SPECIFICATION_CONSTANTS,
  PARTNERS_API_CONSTANTS,
} from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { PartnerType } from '../core.types';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerTypesService {
  private cataloguesApiUrl: string = environment.cataloguesApiUrl;
  private routes = {
    getPartnerTypesList: () =>
      `${this.cataloguesApiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerTypeDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/${PARTNERS_API_CONSTANTS.commandListPartnerTypes}`,
  };
  private log = new Logger(this.constructor.name);
  constructor(private http: HttpClient) {}

  getPartnerTypesList(): Observable<PartnerType[]> {
    this.log.debug('get partner types list');
    return this.http.get<PartnerType[]>(this.routes.getPartnerTypesList());
  }
}
