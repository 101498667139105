import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.sentry.environment,
  normalizeDepth: 5,
  beforeSend: (event) => {
    if (
      !environment.sentry.enabled ||
      window.location.href.includes('localhost')
    ) {
      // Console error message when developing
      console.error(event.message);
      return null;
    }
    return event;
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
