<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ 'sample.details.title' | translate }}
    </p>
    <nb-icon (click)="dialogRef.close()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    @if (sample$ | async; as sample) {
      <div class="row">
        <div
          [ngClass]="{
            'col-6': sample.shippingInformation.documentId,
            'col-12':
              !sample.shippingInformation.documentId ||
              !(documentPreviewPermission$ | async)
          }"
        >
          <div class="d-flex justify-content-between align-items-end mb-1">
            <p class="caption mb-1">
              {{ 'common.titleBaseInformation' | translate }}
            </p>
            <div
              *appRequirePermission="'serviceData.details.sample.editSample'"
              class="text-end col-3"
            >
              <button
                nbButton
                class="p-1"
                (click)="editSample(sample.id, sample.serviceData.id)"
                nbTooltip="Edit sample"
                nbTooltipPlacement="left"
                status="primary"
                size="small"
                type="submit"
              >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
            </div>
          </div>
          <hr class="mt-0" />
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.kit' | translate
              }}</label>
              <div class="col-8">
                {{ sample?.kitId }}
              </div>
            </div>
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.product' | translate
              }}</label>
              <div class="col-8">
                {{ sample.product?.name }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.partner' | translate
              }}</label>
              <div class="col-8">
                {{ sample.partner?.name }}
              </div>
            </div>
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.doctor' | translate
              }}</label>

              <div class="col-8">
                {{ sample.doctor?.name }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.dateOfVenipuncture' | translate
              }}</label>
              <div class="col-8">
                {{ sample.dateOfVenipuncture | date: 'short' }}
              </div>
            </div>
            <div
              *appRequirePermission="
                'serviceData.details.sample.details.gestationalWeek'
              "
              class="col-12 col-md-6 row align-items-center"
            >
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.gestationAge' | translate
              }}</label>
              <div class="col-8">
                {{ sample.serviceInformation.nipt.gestationAge.week }}W
                {{ sample.serviceInformation.nipt.gestationAge.day }}D
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.status' | translate
              }}</label>

              <div class="col-8">
                {{ sample.status }}
              </div>
            </div>
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.lastStatusChange' | translate
              }}</label>

              <div class="col-8">
                {{ sample.statusTimestamp | date }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div
              *appRequirePermission="
                'serviceData.details.sample.details.laboratory'
              "
              class="col-12 col-md-6 row align-items-center"
            >
              <label class="label col-4 mb-0 text-end">{{
                'sample.details.laboratory' | translate
              }}</label>
              <div class="col-8">
                {{ sample.kit.laboratory }}
              </div>
            </div>
            @if (runs$ | async; as runs) {
              <div class="col-12 col-md-6 row align-items-center">
                <label class="label col-4 mb-0 text-end">{{
                  'sample.details.runs' | translate
                }}</label>

                <div class="col-8">
                  {{ runs }}
                </div>
              </div>
            }
          </div>
          <div class="d-flex justify-content-between align-items-end">
            <p class="caption mb-1">
              {{ 'sample.details.serviceInformation' | translate }}
            </p>
          </div>
          <hr class="mt-0" />
          <div class="row mb-2">
            <div class="col-12 row align-items-center">
              <label class="label col-2 mb-0 text-end">{{
                'sample.details.serviceData' | translate
              }}</label>
              <div class="col-10">
                <a
                  class="caption-1 cursor-pointer"
                  [nbTooltip]="
                    'sample.details.tooltips.serviceDataDetails' | translate
                  "
                  (click)="openServiceDataDetails(sample.serviceData.id)"
                >
                  {{ sample.serviceData.referenceKey }}
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            @if (
              sample?.previousSampleId && previousSample$ | async;
              as previousSample
            ) {
              <div class="col-6 row align-items-center">
                <label class="label col-4 mb-0 text-end"
                  >Previous sample:</label
                >
                <div class="col-8">
                  <a
                    class="caption-1 cursor-pointer"
                    [nbTooltip]="
                      'sample.details.tooltips.openSample' | translate
                    "
                    (click)="openSample(sample.previousSampleId)"
                  >
                    {{ previousSample.kitId }}
                  </a>
                </div>
              </div>
            }
            @if (sample?.nextSampleId && nextSample$ | async; as nextSample) {
              <div class="col-6 row align-items-center">
                <label class="label col-4 mb-0 text-end">Next sample:</label>
                <div class="col-8">
                  <a
                    class="caption-1 cursor-pointer"
                    [nbTooltip]="
                      'sample.details.tooltips.openSample' | translate
                    "
                    (click)="openSample(sample.nextSampleId)"
                  >
                    {{ nextSample.kitId }}
                  </a>
                </div>
              </div>
            }
          </div>

          <ng-container
            *appRequirePermission="'serviceData.details.sample.details.waybill'"
          >
            <div class="d-flex justify-content-between align-items-end">
              <p class="caption mb-1">
                {{ 'sample.details.waybillInformation' | translate }}
              </p>
            </div>
            <hr class="mt-0" />
            <div class="row mb-2">
              <div class="col-12 col-md-6 row">
                <label class="label col-4 mb-0 text-end">{{
                  'sample.details.waybill' | translate
                }}</label>
                <div class="col-8">
                  <u
                    class="cursor-pointer fw-bold"
                    (click)="redirectToDhl(sample.shippingInformation.waybill)"
                  >
                    {{ sample?.shippingInformation?.waybill }}
                  </u>
                </div>
              </div>
              @if (sample.shippingInformation.address; as address) {
                <div class="col-12 col-md-6 row">
                  <label class="label col-4 mb-0 text-end">{{
                    'sample.details.senderInformation' | translate
                  }}</label>
                  <div class="col-8">
                    {{ address.fullName }}<br />
                    {{ address.companyName }}<br />
                    {{ address.street }}<br />
                    {{ address.postCode }} {{ address.city }}<br />
                    {{ address.contactNumber }}
                  </div>
                </div>
              }
            </div>
          </ng-container>
          <ng-container *appRequirePermission="'sample.details.sampleNotes'">
            <div class="d-flex justify-content-between align-items-end">
              <p class="caption mb-1">
                {{ 'sample.details.notesTitle' | translate }}
              </p>
            </div>
            <hr class="mt-0" />
            <div class="row mb-2">
              <div class="col-12 row align-items-center">
                <label class="label col-2 mb-0 text-end">{{
                  'sample.details.notes' | translate
                }}</label>
                <div class="col-8">
                  {{ sample.notes || '—' }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        @if (sample.shippingInformation.documentId) {
          <div
            *appRequirePermission="'serviceData.details.sample.details.waybill'"
            class="col-6"
          >
            <div class="d-flex justify-content-between align-items-end">
              <p class="caption mb-1">
                {{ 'sample.details.documentPreview' | translate }}
              </p>
            </div>
            <app-document-preview [uri]="onShowPdfSubject$ | async" />
          </div>
        }
      </div>
      @if (sample.shippingInformation.documentId) {
        <div
          *appRequirePermission="'serviceData.details.sample.details.waybill'"
          class="form-group"
        >
          <div class="d-flex justify-content-between align-items-end">
            <p class="caption mb-1">
              {{ 'sample.details.waybillDocument' | translate }}
            </p>
          </div>
          <hr class="mt-0" />
          <app-sample-details-dhl-waybill
            [sample]="sample"
            (showPDF)="onShowPdfSubject$.next($event)"
          />
        </div>
      }

      <div class="d-flex justify-content-between align-items-end">
        <p class="caption mb-1">
          {{ 'sample.details.titlePii' | translate }}
        </p>
      </div>
      <hr class="mt-0" />
      <app-data-table
        [configuration]="piisDataTableConfiguration"
      ></app-data-table>
    } @else {
      <div class="py-4 loading"></div>
    }
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dialogRef.close()">
      {{ 'common.buttons.btnClose' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
