import { SortDirection } from '../../@core/core.types';
export const RUNS_STATE_NAME = 'runs';

export const RUNS_STATUSES = {
  started: 'Started',
  finished: 'Finished',
};

export const RUNS_PAGE_SORT_SETTINGS = {
  sortDirection: SortDirection.desc,
  orderBy: 'started',
};

export const RUNIDS_EXCEPTIONS_LIST: string[] = [
  '5f579512-8412-c74a-daa9-08d771a18c6e',
  'f8ebc7af-9c6d-cc7c-671e-08d71b191a0d',
  'e366eaae-72be-cf8b-a828-08d7a55c4f9b',
  '2145ccde-197e-c87e-a066-08d71b147822',
  '2730b2d8-3be4-cfc4-e7a7-08d6d3834d2d',
  '9e863730-1ca0-caed-be1a-08d7845b2ca0',
  'fdbea341-f531-c35c-cf21-08d76d77b277',
  'f15ac8c4-ef39-c69d-b913-08d7b9cf9cb5',
  '8c420ca3-18e8-cb9b-f180-08d6ea57f077',
  'f44b92af-5dcf-cb2a-6453-08d7848d33ea',
  '24e32558-81ef-cdff-ec24-08d71b14b539',
  'ca84d6e9-d238-c98c-61b2-08d71b146577',
  'b09ca1c0-ad5b-cdb8-18ac-08d72c61b646',
  'cbf1c6d2-90f9-c87a-c345-08d6d38fde54',
  '76c923a2-ab04-4cea-83b8-26d77d54809b',
  'b4801660-829f-4e71-bbab-c807d9dbd99d',
  '3cd92c8a-ac1c-4731-b042-30790caae464',
  'dfb5b66b-7ee2-4f0e-bcfe-a7faaa7fbaf0',
];
