<nb-card style="min-width: 650px">
  <nb-card-header class="position-relative">
    <p class="mb-0">{{ 'sample.replaceKitDialog.title' | translate }}</p>
    <nb-icon icon="close" (click)="dismiss()"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-12">
        @if (sample$ | async; as sample) {
          {{
            'sample.replaceKitDialog.description'
              | translate: { kitId: sample.kitId }
          }}
        }

        @if (kits$ | async; as kits) {
          <nb-select
            fullWidth
            id="kitId"
            [formControl]="kitFormControl"
            [placeholder]="
              kits.length
                ? ('sample.form.placeholders.selectKit' | translate)
                : ('sample.form.placeholders.noKitsAvailable' | translate)
            "
            data-cy="kit-select"
            class="mt-4"
          >
            @for (kit of kits; track kit?.id) {
              <nb-option [value]="kit?.id">{{ kit.id }}</nb-option>
            }
          </nb-select>
        } @else {
          <div class="loading"></div>
        }
      </div>
      <div class="col-12 d-flex flex-row-reverse mt-3">
        <button
          nbButton
          status="primary"
          [disabled]="kitFormControl.invalid || loading"
          [class.loading]="loading"
          (click)="submit()"
        >
          {{ 'sample.replaceKitDialog.button' | translate }}
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
