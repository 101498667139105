import { SortDirection } from '@core/core.types';

export const PARTNERS_PAGE_SORT_SETTINGS = {
  sortDirection: SortDirection.desc,
  orderBy: 'registered',
};

export enum PartnerFilters {
  State = 'state',
  ApplicationId = 'applicationId',
  PartnerTypeId = 'partnerTypeId',
  AccountManagerId = 'accountManagerId',
  PartnerTypeIds = 'partnerTypeIds',
  SearchToken = 'searchToken',
  PolicyId = 'policyId',
  SubPartnerId = 'subPartnerId',
  NameToken = 'nameToken',
  AddressStreetToken = 'addressStreetToken',
  AddressCityToken = 'addressCityToken',
  AddressZipToken = 'addressZipToken',
  AddressCountryTokens = 'addressCountryTokens',
  Language = 'language',
  PaymentTypeToken = 'paymentTypeToken',
  MinimalSupply = 'minimalSupply',
}
