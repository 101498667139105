import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import { Partner } from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { Util } from '@core/utils/core.util';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  merge,
} from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { tap } from 'rxjs/internal/operators/tap';
import {
  catchError,
  debounceTime,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';

@Component({
  selector: 'app-partner-typeahead',
  templateUrl: './partner-typeahead.component.html',
  styleUrls: ['./partner-typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerTypeaheadComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() hideLabel = false;
  @Input() control: UntypedFormControl;
  @Input() prepend: Partner[] = [];
  @Input() fieldSize = 'tiny';
  @Input() placeholder = 'Partner';
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('htmlTypeaheadElement', { static: true })
  htmlTypeaheadElement: ElementRef;
  @Output()
  partnerSelected = new EventEmitter<Partner>();

  isDisabled = false;
  lastSearchTerm = '';
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  isSearching$ = new BehaviorSubject<boolean>(false);
  isFailedSearch$ = new BehaviorSubject<boolean>(false);

  private partnerIdSubject = new ReplaySubject<string>();
  private readonly log = new Logger(this.constructor.name);
  private unsubscribe$ = new Subject<void>();
  constructor(
    private partnersUtils: PartnersUtilsService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  get c() {
    if (this.control) {
      return this.control;
    }
    return null;
  }

  @Input()
  set partnerId(value: string) {
    this.partnerIdSubject.next(value);
  }

  @Input()
  set disabled(disabled: boolean) {
    if (disabled === true) {
      this.isDisabled = disabled;
    }
  }

  ngOnInit(): void {
    if (this.control) {
      this.partnerIdSubject.next(this.control.value || null);
      this.control.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((partnerId) => {
          this.log.debug('value changed', partnerId);
          this.partnerIdSubject.next(partnerId || null);
        });
    }
  }

  ngAfterViewInit() {
    this.log.debug('after view init');
    this.partnerIdSubject
      .pipe(
        switchMap((partnerId) => {
          this.log.debug('set partner id', partnerId);
          if (partnerId) {
            return this.partnersUtils.getPartnerById$(partnerId);
          }
          return of(null);
        }),
        tap((partner: Partner) => {
          Util.UpdateComponentView(this.cd, this.ngZone, () => {
            const partnerName = partner ? partner.name : '';
            this.htmlTypeaheadElement.nativeElement.value = partnerName;
            this.lastSearchTerm = partnerName;
          });
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  search = (text$: Observable<string>) => {
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => (this.instance ? !this.instance.isPopupOpen() : false))
    );
    const inputFocus$ = this.focus$.pipe(map(() => this.lastSearchTerm));
    return merge(
      text$.pipe(distinctUntilChanged()),
      inputFocus$,
      clicksWithClosedPopup$
    ).pipe(
      debounceTime(500),
      tap(() => {
        this.isSearching$.next(true);
        this.isFailedSearch$.next(false);
      }),
      switchMap((searchTerm) => {
        this.lastSearchTerm = searchTerm;
        if (!searchTerm) {
          this.c.setValue(null);
        }
        return this.partnersUtils.getPartnersBySearchString(searchTerm).pipe(
          map((partners) => {
            partners = [...this.prepend, ...partners];
            if (partners && partners.length > 0) {
              return partners.filter((p) => !p.deactivated);
            }
            return null;
          })
        );
      }),
      catchError(() => {
        this.isFailedSearch$.next(true);
        return of([]);
      }),
      tap(() => this.isSearching$.next(false))
    );
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };

  partnerFormatter = (partner: Partner) => partner.name;

  selectItem(selectItemEvent: NgbTypeaheadSelectItemEvent) {
    if (!Object.prototype.hasOwnProperty.call(selectItemEvent.item, 'id')) {
      selectItemEvent.preventDefault();
    } else {
      const selectedPartner = selectItemEvent.item as Partner;
      this.c.setValue(selectedPartner.id);
      this.partnerSelected.emit(selectedPartner);
      this.lastSearchTerm = (selectItemEvent.item as Partner).name;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
