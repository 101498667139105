import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_SPECIFICATION_CONSTANTS,
  MAX_URL_QUERY_PARAMETERS_IDS,
  NIFTY_APPLICATIONID_KEY,
  PRODUCT_INVENTORY_API_CONSTANTS,
} from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ProductInventory } from '../core.types';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ProductInventoriesService {
  private readonly log = new Logger(this.constructor.name);
  private productInventoryApiUrl: string;
  private readonly routes = {
    getProductInventory: (productInventoryId: string) =>
      `${this.productInventoryApiUrl}/${PRODUCT_INVENTORY_API_CONSTANTS.actorProductInventoryItemProjector}/` +
      `${productInventoryId}/${API_SPECIFICATION_CONSTANTS.ask}/${PRODUCT_INVENTORY_API_CONSTANTS.commandGetProductInventoryItem}`,
    getProductInventories: (productInventoruesIds: string[]) =>
      `${this.productInventoryApiUrl}/${PRODUCT_INVENTORY_API_CONSTANTS.actorProductInventoryItemDirectory}/` +
      `!/${API_SPECIFICATION_CONSTANTS.ask}/${PRODUCT_INVENTORY_API_CONSTANTS.commandListProductInventoryItems}?Ids=` +
      productInventoruesIds.join(','),
    createProductInventory: () =>
      `${this.productInventoryApiUrl}/` +
      `${PRODUCT_INVENTORY_API_CONSTANTS.actorProductInventoryManager}/!/` +
      `${API_SPECIFICATION_CONSTANTS.tell}/` +
      `${PRODUCT_INVENTORY_API_CONSTANTS.commandProvisionNewProductInventoryItem}`,
    getListProductInventoryItems: (kitId?: string) =>
      `${this.productInventoryApiUrl}/` +
      `${PRODUCT_INVENTORY_API_CONSTANTS.actorProductInventoryItemDirectory}/!/` +
      `${API_SPECIFICATION_CONSTANTS.ask}/` +
      `${PRODUCT_INVENTORY_API_CONSTANTS.commandListProductInventoryItems}?` +
      `${kitId ? `KitId=${kitId}` : ''}`,
    deleteProductInventory: (productInventoryId: string) =>
      `${this.productInventoryApiUrl}/${PRODUCT_INVENTORY_API_CONSTANTS.actorProductInventoryItem}/` +
      `${productInventoryId}/${API_SPECIFICATION_CONSTANTS.tell}/` +
      `${PRODUCT_INVENTORY_API_CONSTANTS.commandDeleteProductInventoryItem}`,
    updateProductInventory: (productInventoryId: string) =>
      `${this.productInventoryApiUrl}/` +
      `${PRODUCT_INVENTORY_API_CONSTANTS.actorProductInventoryItem}/` +
      `${productInventoryId}/${API_SPECIFICATION_CONSTANTS.tell}/` +
      `${PRODUCT_INVENTORY_API_CONSTANTS.commandUpdateProductInventoryItem}`,
  };
  private itemsLoadingQueue: {
    id: string;
    subject: Subject<ProductInventory>;
  }[] = [];
  private itemsLoadingSubject = new ReplaySubject<void>();

  constructor(private httpClient: HttpClient) {
    this.productInventoryApiUrl = `${environment.apiUrl}/${PRODUCT_INVENTORY_API_CONSTANTS.productinventory}`;
    this.itemsLoadingSubject
      .pipe(debounceTime(300))
      .subscribe(() => this.loadQueuedItems());
  }

  getProductInventory(
    productInventoryId: string
  ): Observable<ProductInventory> {
    // return this.httpClient.get<ProductInventory>(
    //   this.routes.getProductInventory(productInventoryId)
    // );
    let loadingInProgress = this.itemsLoadingQueue.find(
      (i) => i.id === productInventoryId
    );
    if (!loadingInProgress) {
      loadingInProgress = {
        id: productInventoryId,
        subject: new Subject<ProductInventory>(),
      };
      this.itemsLoadingQueue.push(loadingInProgress);
      this.queueItemsLoad();
    }
    return loadingInProgress.subject.asObservable();
  }

  getProductInventories(
    productInventoriesIds: string[]
  ): Observable<ProductInventory[]> {
    if (!productInventoriesIds || !productInventoriesIds.length) {
      return of([]);
    }
    const url = this.routes.getProductInventories(productInventoriesIds);
    return this.httpClient.get<ProductInventory[]>(url);
  }

  createProductInventory(
    productInventoryItemId: string,
    productId: string,
    partnerId: string,
    kitId: string,
    treatmentId: string
  ): Observable<any> {
    return this.httpClient.post(this.routes.createProductInventory(), {
      applicationId: NIFTY_APPLICATIONID_KEY,
      productInventoryItemId,
      productId,
      partnerId,
      properties: [
        {
          key: 'KitId',
          value: kitId,
        },
        {
          key: 'TreatmentId',
          value: treatmentId,
        },
      ],
    });
  }

  updateProductInventoryItem(
    productInventoryItem: ProductInventory
  ): Observable<ProductInventory> {
    return this.httpClient.post<any>(
      this.routes.updateProductInventory(productInventoryItem.id),
      productInventoryItem
    );
  }

  getListProductInventoryItems(kitId?: string): Observable<any> {
    return this.httpClient.get(this.routes.getListProductInventoryItems(kitId));
  }

  deleteProductInventory(productInventoryItemId: string) {
    return this.httpClient.post(
      this.routes.deleteProductInventory(productInventoryItemId),
      {}
    );
  }

  private queueItemsLoad() {
    this.log.debug('queue item load');
    if (this.itemsLoadingQueue.length > MAX_URL_QUERY_PARAMETERS_IDS) {
      this.loadQueuedItems();
    } else {
      this.itemsLoadingSubject.next();
    }
  }

  private async loadQueuedItems() {
    if (this.itemsLoadingQueue.length) {
      const elementsSize = Math.min(
        this.itemsLoadingQueue.length,
        MAX_URL_QUERY_PARAMETERS_IDS
      );
      const itemsToLoad = this.itemsLoadingQueue.splice(0, elementsSize);
      const itemsIdsToLoad = itemsToLoad
        .map((itl) => itl.id)
        .filter((e, i, a) => e && a.indexOf(e) === i);
      let loadedItems: ProductInventory[] = [];
      try {
        loadedItems = await this.getProductInventories(
          itemsIdsToLoad
        ).toPromise();
        // eslint-disable-next-line no-empty
      } catch (e) {}
      for (const itl of itemsToLoad) {
        const item = loadedItems.find((li) => li.id === itl.id);
        itl.subject.next(item ? item : null);
        itl.subject.complete();
      }
      this.queueItemsLoad();
    }
  }
}
