<input
  fullWidth
  nbInput
  name="partner"
  id="partner"
  [placeholder]="placeholder"
  [fieldSize]="fieldSize"
  class="status-basic"
  [status]="c.dirty ? (c.invalid ? 'danger' : 'success') : ''"
  [attr.aria-invalid]="c.invalid && c.touched ? true : null"
  [ngbTypeahead]="search"
  [container]="'body'"
  [resultTemplate]="partnerTemplate"
  [inputFormatter]="partnerFormatter"
  (selectItem)="selectItem($event)"
  [editable]="true"
  [disabled]="isDisabled"
  (focus)="focus$.next($event.target.value)"
  (click)="click$.next($event.target.value)"
  #htmlTypeaheadElement
  #instance="ngbTypeahead"
  [attr.data-cy]="'partner-input'"
/>

<ng-template #partnerTemplate let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
