import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  NbButtonModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbOptionModule,
  NbRadioModule,
  NbTooltipModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { controlContainerProvider } from '@shared/helpers';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-sample-patient-history-information',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NbCheckboxModule,
    NbInputModule,
    NbOptionModule,
    NbRadioModule,
    NbTooltipModule,
    ReactiveFormsModule,
    TranslateModule,
    NbIconModule,
    NbButtonModule,
    ValidationErrorComponent,
    SharedModule,
  ],
  templateUrl: './sample-patient-history-information.component.html',
  styleUrl: './sample-patient-history-information.component.scss',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SamplePatientHistoryInformationComponent implements OnInit {
  protected get form() {
    return this.controlContainer.control as FormGroup;
  }

  private destroyRef = inject(DestroyRef);

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.initControlChecks();
  }

  private initControlChecks() {
    this.checkControlOnChange(
      'albuminTherapy.hasReceived',
      'albuminTherapy.isRelevant'
    );
    this.checkControlOnChange(
      'cellularImmunotherapy.hasReceived',
      'cellularImmunotherapy.isRelevant'
    );
    this.checkControlOnChange(
      'bloodTransfusion.hasReceived',
      'bloodTransfusion.isRelevant'
    );
  }

  private checkControlOnChange(
    controlNameToCheck: string,
    controlNameToClear: string,
    isArray?: boolean
  ): void {
    if (
      !this.form.get(controlNameToCheck) ||
      !this.form.get(controlNameToClear)
    ) {
      return;
    }

    this.form
      .get(controlNameToCheck)
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => {
        if (!value) {
          this.form.get(controlNameToClear).reset(isArray ? [] : null);
        }
      });
  }
}
