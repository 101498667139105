import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appCtrlS]',
})
export class CtrlSDirective {
  @Output() appCtrlS = new EventEmitter<void>();

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.ctrlKey && e.key === 's') {
      e.preventDefault();
      this.appCtrlS.emit();
    }
  }
}
