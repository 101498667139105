<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ 'sample.list.title' | translate }}
    </p>
    <nb-icon
      icon="close"
      (click)="dialogRef.close()"
      data-cy="sample-dialog-close-icon"
    />
  </nb-card-header>
  <nb-card-body>
    <app-samples-list [serviceDataId]="serviceDataId" [readonly]="false"></app-samples-list>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dialogRef.close()" data-cy="sd-details-close-btn">
      {{ 'common.buttons.btnClose' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
