/* eslint-disable max-len */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_SPECIFICATION_CONSTANTS,
  END_TIME_OF_DAY,
  EXPORTERS_API_CONSTANTS,
  START_TIME_OF_DAY,
  TREATMENTS_API_CONSTANTS,
} from '@app/app.constants';
import { environment } from '@root/environments/environment';
import {
  Treatment,
  TreatmentFilters,
  TreatmentStatus,
} from '@treatments/treatment.types';
import { Observable, forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExporterState } from '../core.types';
import { CoreUtilsService } from '../utils/core-utils.service';
import {
  CarrierOfGeneticDiseaseInformation,
  FamilyDiseaseHistory,
  PregnancyDiseaseHistory,
} from './../../treatments/treatment.types';
import { EntityBaseService } from './entityBase.service';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class TreatmentsService extends EntityBaseService<Treatment> {
  private readonly log = new Logger(this.constructor.name);
  private treatmentsApiUrl: string;
  private exportersApiUrl: string;
  private actorNiptsExporter: string;
  private emailNotifierApiUrl: string;
  private commandNiptsExport: string;
  private commandExportBgiInformationSheet: string;
  private commandExportNiptsFull: string;
  private commandExportInformationSheet: string;
  private commandNiptsInvoiceExport: string;
  private commandNiptsAnalyticsExport: string;
  private commandNiptsReportExport: string;
  private commandN2DReportExport: string;
  private commandN2DCustomerOrdersExport: string;
  private commandLogisticsReportExport: string;
  private commandInsuranceReportExport: string;
  private commandOrdersReportExport: string;
  private commandConsentsReportExport: string;
  private commandOperationsReportExport: string;
  private commandStatus: string;
  private html2pdfUrl: string;

  private params = {
    getTreatmentsParams: (
      partnerId: string,
      doctorIds: string[],
      from: string,
      to: string,
      status: string,
      documentStatus: string,
      kitBarcodeSearchKey: string,
      patientNameSearchKey: string,
      trackingNumberSearchKey: string,
      notesSearchKey: string,
      dateOfVenipunctureSearchKey: string,
      processedDateSearchKey: string,
      dateOfServiceSearchKey: string,
      partnerAddressCountryTokens: string,
      runIds: string,
      productIds: string[],
      laboratory: string,
      lastStatuses: string,
      lastStatusChangedFrom: string,
      lastStatusChangedTo: string,
      isPaid: boolean,
      paymentInformationNotesToken?: string,
      gpInvoice?: string,
      doctorInvoice?: string,
      laboratoryInvoice?: string,
      paymentTypes?: string[],
      isExported?: boolean,
      hasDateOfVenipuncture?: boolean,
      dateOfVenipunctureFrom?: string,
      dateOfVenipunctureTo?: string,
      isRedraw?: boolean
    ) => {
      let params = new HttpParams();
      if (dateOfServiceSearchKey) {
        params = params.set(
          TreatmentFilters.StatusChangedFrom,
          `${dateOfServiceSearchKey}${START_TIME_OF_DAY}`
        );
        params = params.set(
          TreatmentFilters.StatusChangedTo,
          `${dateOfServiceSearchKey}${END_TIME_OF_DAY}`
        );
        params = params.set(
          TreatmentFilters.Status,
          TreatmentStatus.dateOfService
        );
      } else if (processedDateSearchKey) {
        params = params.set(
          TreatmentFilters.StatusChangedFrom,
          `${processedDateSearchKey}${START_TIME_OF_DAY}`
        );
        params = params.set(
          TreatmentFilters.StatusChangedTo,
          `${processedDateSearchKey}${END_TIME_OF_DAY}`
        );
        params = params.set(TreatmentFilters.Status, TreatmentStatus.processed);
      } else {
        if (from) {
          params = params.set(TreatmentFilters.StatusChangedFrom, from);
        }
        if (to) {
          params = params.set(TreatmentFilters.StatusChangedTo, to);
        }
        if (status) {
          params = params.set(TreatmentFilters.Status, status);
        }
      }
      if (documentStatus) {
        params = params.set(TreatmentFilters.DocumentStatus, documentStatus);
      }
      if (partnerId) {
        params = params.set(TreatmentFilters.PartnerId, partnerId);
      }
      if (doctorIds && doctorIds.length > 0) {
        params = params.set(TreatmentFilters.DoctorIds, doctorIds.join(','));
      }
      if (kitBarcodeSearchKey) {
        params = params.set(
          TreatmentFilters.KitToken,
          kitBarcodeSearchKey.trim()
        );
      }
      if (patientNameSearchKey) {
        params = params.set(
          TreatmentFilters.PatientNameToken,
          patientNameSearchKey.trim()
        );
      }
      if (trackingNumberSearchKey) {
        params = params.set(
          TreatmentFilters.DhlWaybillToken,
          trackingNumberSearchKey.trim()
        );
      }
      if (notesSearchKey) {
        params = params.set(TreatmentFilters.NotesToken, notesSearchKey.trim());
      }
      if (dateOfVenipunctureSearchKey) {
        params = params.set(
          TreatmentFilters.DateOfVenipuncture,
          dateOfVenipunctureSearchKey.trim()
        );
      }
      if (partnerAddressCountryTokens) {
        params = params.set(
          TreatmentFilters.PartnerAddressCountryTokens,
          partnerAddressCountryTokens.trim()
        );
      }
      if (runIds) {
        params = params.set(TreatmentFilters.RunIds, runIds);
      }
      if (productIds && productIds.length > 0) {
        params = params.set(TreatmentFilters.ProductIds, productIds.join(','));
      }
      if (laboratory) {
        params = params.set(TreatmentFilters.Laboratory, laboratory);
      }
      if (lastStatuses) {
        params = params.set(TreatmentFilters.LastStatuses, lastStatuses);
      }
      if (lastStatusChangedFrom) {
        params = params.set(
          TreatmentFilters.LastStatusChangedFrom,
          lastStatusChangedFrom
        );
      }
      if (lastStatusChangedTo) {
        params = params.set(
          TreatmentFilters.LastStatusChangedTo,
          lastStatusChangedTo
        );
      }
      if (gpInvoice) {
        params = params.set(
          TreatmentFilters.GeneplanetInvoiceNumberToken,
          gpInvoice
        );
      }
      if (doctorInvoice) {
        params = params.set(
          TreatmentFilters.DoctorInvoiceNumberToken,
          doctorInvoice
        );
      }
      if (laboratoryInvoice) {
        params = params.set(
          TreatmentFilters.LaboratoryInvoiceNumberToken,
          laboratoryInvoice
        );
      }
      if (paymentTypes && paymentTypes.length > 0) {
        params = params.set(
          TreatmentFilters.PaymentTypes,
          paymentTypes.join(',')
        );
      }
      if (paymentInformationNotesToken) {
        params = params.set(
          TreatmentFilters.PaymentInformationNotesToken,
          paymentInformationNotesToken
        );
      }
      if (isPaid === false || isPaid === true) {
        params = params.set(TreatmentFilters.IsPaid, '' + isPaid);
      }
      if (isExported !== undefined && isExported !== null) {
        params = params.set(TreatmentFilters.Exported, '' + isExported);
      }
      if (isRedraw !== undefined && isRedraw !== null) {
        params = params.set(TreatmentFilters.Redraw, '' + isRedraw);
      }
      if (
        hasDateOfVenipuncture !== undefined &&
        hasDateOfVenipuncture !== null
      ) {
        params = params.set(
          TreatmentFilters.HadDateOfVenipuncture,
          '' + hasDateOfVenipuncture
        );
      }
      if (dateOfVenipunctureFrom) {
        params = params.set(
          TreatmentFilters.DateOfVenipunctureFrom,
          dateOfVenipunctureFrom
        );
      }
      if (dateOfVenipunctureTo) {
        params = params.set(
          TreatmentFilters.DateOfVenipunctureTo,
          dateOfVenipunctureTo
        );
      }
      return params;
    },
  };

  private routes = {
    getTreatments: () =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${TREATMENTS_API_CONSTANTS.commandListNiptTests}`,
    listTreatments: (from: string, to: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${TREATMENTS_API_CONSTANTS.commandListNiptTests}?${TreatmentFilters.StatusChangedFrom}=${from}` +
      `&${TreatmentFilters.StatusChangedTo}=${to}&${TreatmentFilters.Status}=${TreatmentStatus.generated}`,
    getTreatmentsByProductInventoryItemId: (productInventoryItemId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${TREATMENTS_API_CONSTANTS.commandListNiptTests}?ProductInventoryItemIds=${productInventoryItemId}`,
    createTreatment: () =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandProvisionNiptTest}`,
    updateTreatment: () =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandProvisionNiptTestUpdate}`,
    deleteTreatment: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandDeleteNiptTest}`,
    deactivateTreatment: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandDeactivateNiptTest}`,
    addPatientFamilyDiseaseHistory: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandAddPatientFamilyDiseaseHistory}`,
    removePatientFamilyDiseaseHistory: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandRemovePatientFamilyDiseaseHistory}`,
    addPatientPregnancyDiseaseHistory: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandAddPatientPregnancyDiseaseHistory}`,
    removePatientPregnancyDiseaseHistory: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandRemovePatientPregnancyDiseaseHistory}`,
    addPatientCarrierOfGeneticDiseaseInformation: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}` +
      `/${TREATMENTS_API_CONSTANTS.commandAddPatientCarrierOfGeneticDiseaseInformation}`,
    removePatientCarrierOfGeneticDiseaseInformation: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandRemovePatientCarrierOfGeneticDiseaseInformation}`,
    unassignDocumentFromNiptTest: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandUnassignDocumentFromNiptTest}`,
    generateRequestForm: () => this.html2pdfUrl,
    updateTreatmentKit: () =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandProvisionKitChange}`,
    setGeneplanetInvoiceNumber: (treatmentId: string) =>
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTest}/${treatmentId}/${API_SPECIFICATION_CONSTANTS.tell}/${TREATMENTS_API_CONSTANTS.commandSetGeneplanetInvoiceNumber}`,
    getNiptTestTAT: (queries: string[]) =>
      `${this.treatmentsApiUrl}/${
        TREATMENTS_API_CONSTANTS.actorNiptTestDirectory
      }/!/${API_SPECIFICATION_CONSTANTS.ask}/${
        TREATMENTS_API_CONSTANTS.commandGetNiptTestTAT
      }?${queries.join('&')}`,
    sendResultReferenceLinkEmail: (treatmentId: string) =>
      `${this.emailNotifierApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptManagement}/${TREATMENTS_API_CONSTANTS.commandResultReferenceLink}?${TreatmentFilters.NiptTestId}=${treatmentId}`,
  };

  constructor(
    protected httpClient: HttpClient,
    private coreUtils: CoreUtilsService
  ) {
    super(httpClient);
    this.treatmentsApiUrl = `${environment.apiUrl}/${TREATMENTS_API_CONSTANTS.nipts}`;
    this.exportersApiUrl = environment.exportersApiUrl;
    this.actorNiptsExporter = EXPORTERS_API_CONSTANTS.actorNiptsExporter;
    this.commandNiptsExport = EXPORTERS_API_CONSTANTS.commandExportNipts;
    this.commandExportNiptsFull =
      EXPORTERS_API_CONSTANTS.commandExportNiptsFull;
    this.commandExportInformationSheet =
      EXPORTERS_API_CONSTANTS.commandExportInformationSheet;
    this.commandExportInformationSheet =
      EXPORTERS_API_CONSTANTS.commandExportInformationSheet;
    this.commandExportBgiInformationSheet =
      EXPORTERS_API_CONSTANTS.commandExportBgiInformationSheet;
    this.commandNiptsInvoiceExport =
      EXPORTERS_API_CONSTANTS.commandNiptsInvoiceExport;
    this.commandNiptsAnalyticsExport =
      EXPORTERS_API_CONSTANTS.commandNiptsAnalyticsExport;
    this.commandNiptsReportExport =
      EXPORTERS_API_CONSTANTS.commandNiptsReportExport;
    this.commandN2DReportExport =
      EXPORTERS_API_CONSTANTS.commandN2DReportExport;
    this.commandN2DCustomerOrdersExport =
      EXPORTERS_API_CONSTANTS.commandN2DCustomerOrdersExport;
    this.commandLogisticsReportExport =
      EXPORTERS_API_CONSTANTS.commandLogisticsReportExport;
    this.commandInsuranceReportExport =
      EXPORTERS_API_CONSTANTS.commandInsuranceReportExport;
    this.commandOrdersReportExport =
      EXPORTERS_API_CONSTANTS.commandOrdersReportExport;
    this.commandConsentsReportExport =
      EXPORTERS_API_CONSTANTS.commandConsentsReportExport;
    this.commandOperationsReportExport =
      EXPORTERS_API_CONSTANTS.commandOperationsReportExport;
    this.commandStatus = EXPORTERS_API_CONSTANTS.commandStatus;
    this.html2pdfUrl = environment.html2pdfUrl;

    this.emailNotifierApiUrl = `${environment.apiUrl}/notifier`;
  }

  getPartnerTreatments(
    partnerId: string,
    additionalFilters: { [key: string]: string[] }
  ): Observable<Treatment[]> {
    this.logger.debug(`find partner ${partnerId} treatments`);
    const treatmentsUrl =
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${TREATMENTS_API_CONSTANTS.commandListNiptTests}`;

    let params = new HttpParams().set(TreatmentFilters.PartnerId, partnerId);
    for (const key in additionalFilters) {
      if (Object.prototype.hasOwnProperty.call(additionalFilters, key)) {
        params = params.set(key, `${additionalFilters[key]}`);
      }
    }
    return from(
      this.coreUtils.getAllElements<Treatment>(treatmentsUrl, null, [], params)
    );
  }

  async findDocumentTreatments(documentIds: string[]): Promise<Treatment[]> {
    if (!documentIds || !documentIds.length) {
      return Promise.reject();
    }

    let documents = [];

    while (documentIds.length) {
      const tempDocumentIds = documentIds.splice(0, 100);
      const documentIdsQueryParameter = tempDocumentIds.join(',');
      this.logger.debug(
        `find documents ${documentIdsQueryParameter} treatments`
      );
      const documentTreatmentsSearchUrl =
        `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
        `/${TREATMENTS_API_CONSTANTS.commandListNiptTests}`;

      documents = [
        ...documents,
        ...(await this.coreUtils.getAllElements<Treatment>(
          documentTreatmentsSearchUrl,
          null,
          [],
          new HttpParams().set(
            TreatmentFilters.DocumentsIds,
            documentIdsQueryParameter
          )
        )),
      ];
    }
    return documents;
  }

  findTreatmentsByPatientName(patientNameKey: string) {
    const treatmentsSearchUrl =
      `${this.treatmentsApiUrl}/${TREATMENTS_API_CONSTANTS.actorNiptTestDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${TREATMENTS_API_CONSTANTS.commandListNiptTests}`;
    return this.http.get<Treatment[]>(treatmentsSearchUrl, {
      params: new HttpParams().set(
        TreatmentFilters.PatientNameToken,
        patientNameKey
      ),
    });
  }

  getTreatmentsByProductInventoryItemId(
    productInventoryItemIds: string
  ): Observable<Treatment[]> {
    const piiIds = productInventoryItemIds.split(',');
    const observables = [];

    while (piiIds.length) {
      const tempProductInventoryItemIds = piiIds.splice(0, 100);
      const piiIdsQueryParameter = tempProductInventoryItemIds.join(',');
      this.logger.debug(`find PII ${piiIdsQueryParameter} treatments`);

      const url =
        this.routes.getTreatmentsByProductInventoryItemId(piiIdsQueryParameter);

      observables.push(this.httpClient.get<Treatment[]>(url));
    }

    return forkJoin(observables).pipe(
      map((results) => [].concat.apply([], ...results))
    );
  }

  addPatientFamilyDiseaseHistory(
    treatmentId: string,
    familyDiseaseHistory: FamilyDiseaseHistory
  ): Observable<any> {
    return this.httpClient.post(
      this.routes.addPatientFamilyDiseaseHistory(treatmentId),
      familyDiseaseHistory
    );
  }

  removePatientFamilyDiseaseHistory(
    treatmentId: string,
    familyDiseaseHistory: FamilyDiseaseHistory
  ): Observable<any> {
    return this.httpClient.post(
      this.routes.removePatientFamilyDiseaseHistory(treatmentId),
      familyDiseaseHistory
    );
  }

  addPatientPregnancyDiseaseHistory(
    treatmentId: string,
    pregnancyDiseaseHistory: PregnancyDiseaseHistory
  ): Observable<any> {
    return this.httpClient.post(
      this.routes.addPatientPregnancyDiseaseHistory(treatmentId),
      pregnancyDiseaseHistory
    );
  }

  removePatientPregnancyDiseaseHistory(
    treatmentId: string,
    pregnancyDiseaseHistory: PregnancyDiseaseHistory
  ): Observable<any> {
    return this.httpClient.post(
      this.routes.removePatientPregnancyDiseaseHistory(treatmentId),
      pregnancyDiseaseHistory
    );
  }

  addPatientCarrierOfGeneticDiseaseInformation(
    treatmentId: string,
    carrierOfGeneticDiseaseInformation: CarrierOfGeneticDiseaseInformation
  ): Observable<any> {
    return this.httpClient.post(
      this.routes.addPatientCarrierOfGeneticDiseaseInformation(treatmentId),
      carrierOfGeneticDiseaseInformation
    );
  }

  removePatientCarrierOfGeneticDiseaseInformation(
    treatmentId: string,
    pregnancyDiseaseHistory: PregnancyDiseaseHistory
  ): Observable<any> {
    return this.httpClient.post(
      this.routes.removePatientCarrierOfGeneticDiseaseInformation(treatmentId),
      pregnancyDiseaseHistory
    );
  }

  unassignDocumentFromNiptTest(
    treatmentId: string,
    documentId: string
  ): Observable<any> {
    return this.httpClient.post(
      this.routes.unassignDocumentFromNiptTest(treatmentId),
      {
        documentId,
      }
    );
  }

  checkExporterStatus(id: string): Observable<ExporterState> {
    return this.http.get<ExporterState>(
      `${this.exportersApiUrl}/tasks/${id}/${this.commandStatus}`
    );
  }

  updateKitForTreatment(treatmentId: string, kitId: string): Observable<any> {
    this.logger.debug('update kit for treatment');

    const body = {
      niptTestId: treatmentId,
      kitId,
    };
    return this.http.post(this.routes.updateTreatmentKit(), body);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  SetGeneplanetInvoiceNumber(
    treatmentId: string,
    geneplanetInvoiceNumber: number
  ) {
    this.logger.debug('set geneplanet invoice number');

    return this.httpClient.post(
      this.routes.setGeneplanetInvoiceNumber(treatmentId),
      {
        geneplanetInvoiceNumber,
      }
    );
  }

  getNiptTestTAT(queries: string[]): Observable<any> {
    this.logger.debug('get nipt test TAT');

    return this.http.get(this.routes.getNiptTestTAT(queries));
  }

  sendResultReferenceLinkEmail(treatmentId: string): Observable<any> {
    this.log.debug('send result reference link email');

    return this.httpClient.post(
      this.routes.sendResultReferenceLinkEmail(treatmentId),
      {}
    );
  }

  protected setCreateEntityBody(entity: Treatment, body: Partial<Treatment>) {
    return {
      id: body.id,
      doctorId: body.doctorId,
      doctorType: body.doctorType,
      partnerId: entity.partnerId,
      kitId: entity.kitId,
      productInventoryItemId: body.productInventoryItemId,
      notes: body.notes,
      comment: body.comment,
      dateOfVenipuncture: body.dateOfVenipuncture,
      genderInformation: body.genderInformation,
      redraw: body.redraw,
      redrawKitId: body.redrawKitId,
      incidentalFindings: body.incidentalFindings,
      dhlWaybill: body.dhlWaybill,
      productId: body.productId,
      paymentInformation: {
        type: body.paymentInformation.paymentType,
        notes: body.paymentInformation.notes,
      },
      patientInformation: body.patientInformation,
      currentPregnancy: body.currentPregnancy,
      historyInformation: body.historyInformation,
      sampleExtractorId: body.sampleExtractorId,
    };
  }
  protected setUpdateEntityBody(entity: Treatment, body: Partial<Treatment>) {
    return {
      id: body.id,
      notes: body.notes,
      partnerId: entity.partnerId,
      kitId: entity.kitId,
      comment: body.comment,
      dateOfVenipuncture: body.dateOfVenipuncture,
      genderInformation: body.genderInformation,
      redraw: body.redraw,
      redrawKitId: body.redrawKitId,
      incidentalFindings: body.incidentalFindings,
      dhlWaybill: body.dhlWaybill,
      productId: body.productId,
      paymentInformation: {
        type: body.paymentInformation.paymentType,
        notes: body.paymentInformation.notes,
      },
      patientInformation: body.patientInformation,
      currentPregnancy: body.currentPregnancy,
      historyInformation: body.historyInformation,
      productInventoryItemId: body.productInventoryItemId,
      doctorId: body.doctorId,
      doctorType: body.doctorType,
      sampleExtractorId: body.sampleExtractorId,
    };
  }
  protected setUrlBody(treatment: Treatment): Partial<Treatment> {
    return treatment;
  }
  protected getListUrl(): string {
    throw new Error('Method not implemented.');
  }
  protected getUpdateEntityUrl(entityId: string): string {
    return this.routes.updateTreatment();
  }
  protected getDeleteEntityUrl(entityId: string): string {
    return this.routes.deleteTreatment(entityId);
  }
  protected getCreateEntityUrl(newEntityId: string): string {
    return this.routes.createTreatment();
  }
  protected getFetchEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }
}
