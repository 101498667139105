<nb-card>
  <nb-card-header class="position-relative">
    <p>Files are password protected</p>
  </nb-card-header>
  <nb-card-body class="py-4">
    <div>
      Please copy following password:
      <a class="cursor-pointer" (click)="copyToClipboard(password)"
        ><span>{{ password }}</span> <nb-icon icon="copy-outline"></nb-icon
      ></a>
    </div>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton status="basic" (click)="close()">Close</button>
  </nb-card-footer>
</nb-card>
