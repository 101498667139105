<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ 'pii.details.title' | translate }}
    </p>
    <nb-icon (click)="dialogRef.close()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    @if (productInventoryItem$ | async; as pii) {
      <div class="row col-12">
        <div
          [ngClass]="{
            'col-6': (documentPreviewPermission$ | async),
            'col-12': !(documentPreviewPermission$ | async)
          }"
        >
          <div class="d-flex justify-content-between align-items-end">
            <p class="caption mb-1">
              {{ 'common.titleBaseInformation' | translate }}
            </p>
          </div>
          <hr class="mt-0" />
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'common.patientName' | translate
              }}</label>
              <div class="col-8">
                {{ pii.sample.serviceData.patientInformation.firstName }}
                {{ pii.sample.serviceData.patientInformation.lastName }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'pii.details.kitId' | translate
              }}</label>
              <div class="col-8">
                {{ pii.kitId }}
              </div>
            </div>
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'pii.details.product' | translate
              }}</label>
              <div class="col-8">
                {{ pii.product.name }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'pii.details.partner' | translate
              }}</label>

              <div class="col-8">
                {{ pii.partner.name }}
              </div>
            </div>
            <div
              *appRequirePermission="'pii.details.laboratory'"
              class="col-12 col-md-6 row align-items-center"
            >
              <label class="label col-4 mb-0 text-end">{{
                'pii.details.laboratory' | translate
              }}</label>
              <div class="col-8">
                {{ pii.sample?.kit?.laboratory }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'pii.details.generated' | translate
              }}</label>
              <div class="col-8">
                {{ pii.generated.timestamp | date }}
              </div>
            </div>
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'pii.details.statusChanged' | translate
              }}</label>

              <div class="col-8">
                {{ pii.lastStatusChanged | date }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 row align-items-center">
              <label class="label col-4 mb-0 text-end">{{
                'pii.details.status' | translate
              }}</label>

              <div class="col-8">
                {{ pii.lastStatus }}
              </div>
            </div>
            <ng-container *appRequirePermission="'pii.details.statusReason'">
              @if (pii.lastStatusChangedReason) {
                <div class="col-12 col-md-6 row align-items-center">
                  <label class="label col-4 mb-0 text-end">{{
                    'pii.details.statusReason' | translate
                  }}</label>

                  <div class="col-8">
                    {{ pii.lastStatusChangedReason }}
                    @if (getStatusReason(pii); as documentStatus) {
                      {{ ' (' + documentStatus + ')' }}
                    }
                  </div>
                </div>
              }
            </ng-container>
          </div>
          <div class="d-flex justify-content-between align-items-end">
            <p class="caption mb-1">
              {{ 'pii.details.serviceInformation' | translate }}
            </p>
          </div>
          <hr class="mt-0" />
          <div class="row mb-2">
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="partner-row"
            >
              <label class="label col-4 mb-0 text-end">{{
                'serviceData.details.nipt.genderInformation' | translate
              }}</label>
              <div class="col-8">
                {{
                  pii.sample.serviceData.serviceInformation.nipt
                    .provideGenderInformation
                    ? 'YES'
                    : 'NO'
                }}
              </div>
            </div>
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="partner-row"
            >
              <label class="label col-4 mb-0 text-end">{{
                'serviceData.details.nipt.incidentalFindings' | translate
              }}</label>
              <div class="col-8">
                {{
                  pii.sample.serviceData.serviceInformation.nipt
                    .incidentalFindings
                    ? 'YES'
                    : 'NO'
                }}
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 row align-items-center">
              <label class="label col-2 mb-0 text-end">{{
                'pii.details.serviceData' | translate
              }}</label>
              <div class="col-10">
                <a
                  class="caption-1 cursor-pointer"
                  [nbTooltip]="
                    'pii.details.tooltips.serviceDataDetails' | translate
                  "
                  (click)="openServiceDataDetails(pii.sample.serviceData.id)"
                >
                  {{ pii.sample.serviceData.referenceKey }}
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 row align-items-center">
              <label class="label col-2 mb-0 text-end">{{
                'pii.details.sample' | translate
              }}</label>
              <div class="col-10">
                <a
                  class="caption-1 cursor-pointer"
                  [nbTooltip]="'pii.details.tooltips.sampleDetails' | translate"
                  (click)="openSampleDetails(pii.sample.id)"
                >
                  {{ pii.sample.kitId }}
                </a>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-end">
            <p class="caption mb-1">
              {{ 'serviceData.details.commentsAndNotes' | translate }}
            </p>
          </div>
          <hr class="mt-0" />
          <div class="row mb-2">
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="partner-row"
            >
              <label class="label col-4 mb-0 text-end">{{
                'serviceData.details.patientState' | translate
              }}</label>
              <div class="col-8">
                {{
                  pii.sample.serviceData.serviceInformation.nipt.comments || '—'
                }}
              </div>
            </div>
            <div
              class="col-12 col-md-6 row align-items-center"
              data-cy="partner-row"
            >
              <label class="label col-4 mb-0 text-end">{{
                'serviceData.details.notes' | translate
              }}</label>
              <div class="col-8">
                {{ pii.sample.serviceData.notes || '—' }}
              </div>
            </div>
          </div>
        </div>

        <div
          *appRequirePermission="'pii.details.documentPreview'"
          class="col-6"
        >
          <div class="d-flex justify-content-between align-items-end">
            <p class="caption mb-1">
              {{ 'pii.details.documentPreview' | translate }}
            </p>
          </div>
          <app-document-preview [uri]="onShowPdfSubject$ | async" />
        </div>
      </div>
      <ng-container *appRequirePermission="'pii.details.documentsList'">
        <div class="d-flex justify-content-between align-items-end">
          <p class="caption mb-1">
            {{ 'pii.details.documentsTitle' | translate }}
          </p>
        </div>
        <hr class="mt-0" />
        <app-product-inventory-item-documents
          [pii]="pii"
          (showPDF)="onShowPdfSubject$.next($event)"
        />
      </ng-container>
    } @else {
      <div class="py-4 loading"></div>
    }
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dialogRef.close()">
      {{ 'common.buttons.btnClose' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
