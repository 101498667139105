import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EXPORTERS_API_CONSTANTS } from '@app/app.constants';
import {
  ExportDialogDataType,
  ExportType,
  StatusResponse,
} from '@app/modules/service-data/service-data.types';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { BlobUriResponseModel, ExporterState } from '../core.types';

@Injectable({
  providedIn: 'root',
})
export class ExportersService {
  private exportersApiUrl: string;
  private actorBlobUriGenerator: string;
  private queryGetCreateBlobUri: string;
  private queryGetReadBlobUri: string;

  private commandNiptsExport = EXPORTERS_API_CONSTANTS.commandExportNipts;
  private commandExportNiptsFull =
    EXPORTERS_API_CONSTANTS.commandExportNiptsFull;
  private commandExportInformationSheet =
    EXPORTERS_API_CONSTANTS.commandExportInformationSheet;
  private commandExportBgiInformationSheet =
    EXPORTERS_API_CONSTANTS.commandExportBgiInformationSheet;
  private commandNiptsInvoiceExport =
    EXPORTERS_API_CONSTANTS.commandNiptsInvoiceExport;
  private commandNiptsAnalyticsExport =
    EXPORTERS_API_CONSTANTS.commandNiptsAnalyticsExport;
  private commandNiptsReportExport =
    EXPORTERS_API_CONSTANTS.commandNiptsReportExport;
  private commandN2DReportExport =
    EXPORTERS_API_CONSTANTS.commandN2DReportExport;
  private commandN2DCustomerOrdersExport =
    EXPORTERS_API_CONSTANTS.commandN2DCustomerOrdersExport;
  private commandLogisticsReportExport =
    EXPORTERS_API_CONSTANTS.commandLogisticsReportExport;
  private commandInsuranceReportExport =
    EXPORTERS_API_CONSTANTS.commandInsuranceReportExport;
  private commandOrdersReportExport =
    EXPORTERS_API_CONSTANTS.commandOrdersReportExport;
  private commandConsentsReportExport =
    EXPORTERS_API_CONSTANTS.commandConsentsReportExport;
  private commandStatisticsExport =
    EXPORTERS_API_CONSTANTS.commandStatisticsExport;
  private commandOperationsReportExport =
    EXPORTERS_API_CONSTANTS.commandOperationsReportExport;

  private commandInPartnerWarehouseExport =
    EXPORTERS_API_CONSTANTS.commandExportInPartnerWarehouseKits;

  private commandExportDoctors = EXPORTERS_API_CONSTANTS.commandExportDoctors;
  private commandExportPartners = EXPORTERS_API_CONSTANTS.commandExportPartners;
  private commandExportOnlinePartners =
    EXPORTERS_API_CONSTANTS.commandExportOnlinePartners;
  private commandExportDoctorsByPartners =
    EXPORTERS_API_CONSTANTS.commandExportDoctorsByPartners;

  private commandStatus = EXPORTERS_API_CONSTANTS.commandStatus;
  private html2pdfUrl = environment.html2pdfUrl;

  constructor(private httpClient: HttpClient) {
    this.exportersApiUrl = environment.exportersApiUrl;
    this.actorBlobUriGenerator = EXPORTERS_API_CONSTANTS.actorBlobUriGenerator;
    this.queryGetCreateBlobUri = EXPORTERS_API_CONSTANTS.queryGetCreateBlobUri;
    this.queryGetReadBlobUri = EXPORTERS_API_CONSTANTS.queryGetReadBlobUri;
  }

  public getCreateBlobUri(fileName: string): Observable<BlobUriResponseModel> {
    return this.httpClient.get<BlobUriResponseModel>(
      `${this.exportersApiUrl}/${this.queryGetCreateBlobUri}/${fileName}`
    );
  }

  public getReadBlobUri(fileName: string): Observable<BlobUriResponseModel> {
    return this.httpClient.get<BlobUriResponseModel>(
      `${this.exportersApiUrl}/${this.queryGetReadBlobUri}/${fileName}`
    );
  }

  public getBlob(readUri: string) {
    return this.httpClient.get(readUri);
  }

  public exportSelectedNipts(
    exportDialogDataType: ExportDialogDataType,
    niptIds: string[],
    exportType: ExportType,
    uri: string,
    numbered = false,
    password?: string,
    anonymous?: boolean
  ) {
    const body: any = { blobUri: uri, laboratory: 'All', password, anonymous };

    if (niptIds && niptIds.length > 0) {
      switch (exportDialogDataType) {
        case ExportDialogDataType.Pii:
          body.productInventoryItemIds = niptIds;
          break;
        case ExportDialogDataType.ServiceData:
          body.serviceDataIds = niptIds;
          break;
        case ExportDialogDataType.Sample:
          body.sampleIds = niptIds;
          break;
      }
    }
    if (numbered) {
      body.numbered = true;
    }

    let exportCommand = this.commandNiptsExport;
    if (!exportType) {
      exportCommand = this.commandExportInformationSheet;
    } else if (exportType === ExportType.Full) {
      exportCommand = this.commandExportNiptsFull;
    } else if (exportType === ExportType.BGIInformationSheet) {
      exportCommand = this.commandExportBgiInformationSheet;
    } else if (exportType === ExportType.Invoice) {
      exportCommand = this.commandNiptsInvoiceExport;
    } else if (exportType === ExportType.Analytics) {
      exportCommand = this.commandNiptsAnalyticsExport;
    } else if (exportType === ExportType.Report) {
      exportCommand = this.commandNiptsReportExport;
    } else if (exportType === ExportType.Logistics) {
      exportCommand = this.commandLogisticsReportExport;
    } else if (exportType === ExportType.Insurance) {
      exportCommand = this.commandInsuranceReportExport;
    } else if (exportType === ExportType.Operations) {
      exportCommand = this.commandOperationsReportExport;
    } else if (exportType === ExportType.Consents) {
      exportCommand = this.commandConsentsReportExport;
    } else if (exportType === ExportType.Statistics) {
      exportCommand = this.commandStatisticsExport;
    }

    return this.httpClient.post<StatusResponse>(
      `${this.exportersApiUrl}/v2/${exportCommand}`,
      body
    );
  }

  public checkExporterStatus(id: string): Observable<ExporterState> {
    return this.httpClient.get<ExporterState>(
      `${this.exportersApiUrl}/tasks/${id}/${this.commandStatus}`
    );
  }

  public exportNipts(
    exportType: ExportType,
    uri: string,
    countries: string[],
    numbered = false,
    filters: { [key: string]: string },
    password?: string,
    anonymous?: boolean
  ) {
    let body: any = {
      blobUri: uri,
      password,
      anonymous,
    };
    if (exportType === ExportType.Full) {
      body.fullExport = true;
    }

    if (numbered) {
      body.numbered = true;
    }
    if (countries && !filters?.countries) {
      filters.countries = countries.join(',');
    }
    if (filters && exportType === ExportType.Orders) {
      body = { ...body, ...filters };
    } else if (filters) {
      body.query = filters;
    }
    let exportCommand = this.commandNiptsExport;
    if (!exportType) {
      exportCommand = this.commandExportInformationSheet;
    } else if (exportType === ExportType.BGIInformationSheet) {
      exportCommand = this.commandExportBgiInformationSheet;
    } else if (exportType === ExportType.Full) {
      exportCommand = this.commandExportNiptsFull;
    } else if (exportType === ExportType.Invoice) {
      exportCommand = this.commandNiptsInvoiceExport;
    } else if (exportType === ExportType.Analytics) {
      exportCommand = this.commandNiptsAnalyticsExport;
    } else if (exportType === ExportType.Report) {
      exportCommand = this.commandNiptsReportExport;
    } else if (exportType === ExportType.N2DStatistics) {
      exportCommand = this.commandN2DReportExport;
    } else if (exportType === ExportType.N2DCustomerOrders) {
      exportCommand = this.commandN2DCustomerOrdersExport;
    } else if (exportType === ExportType.Logistics) {
      exportCommand = this.commandLogisticsReportExport;
    } else if (exportType === ExportType.Insurance) {
      exportCommand = this.commandInsuranceReportExport;
    } else if (exportType === ExportType.Operations) {
      exportCommand = this.commandOperationsReportExport;
    } else if (exportType === ExportType.Orders) {
      exportCommand = this.commandOrdersReportExport;
    } else if (exportType === ExportType.Consents) {
      exportCommand = this.commandConsentsReportExport;
    } else if (exportType === ExportType.Statistics) {
      exportCommand = this.commandStatisticsExport;
    }

    return this.httpClient.post<StatusResponse>(
      `${this.exportersApiUrl}/v2/${exportCommand}`,
      body
    );
  }

  public generateRequestForm(
    content: string | { filename: string; content: string }[],
    password = ''
  ) {
    return this.httpClient.post<{ data: string }>(this.html2pdfUrl, {
      content,
      password,
    });
  }

  public inPartnerWarehouseExport(
    uri: string,
    expiresFrom?: string,
    expiresTo?: string
  ): Observable<StatusResponse> {
    return this.httpClient.post<StatusResponse>(
      `${this.exportersApiUrl}/v2/${this.commandInPartnerWarehouseExport}`,
      { blobUri: uri, query: { expiresFrom, expiresTo } }
    );
  }

  public doctorsExport(
    doctorIds: string[],
    uri: string
  ): Observable<StatusResponse> {
    return this.httpClient.post<StatusResponse>(
      `${this.exportersApiUrl}/v2/${this.commandExportDoctors}`,
      {
        blobUri: uri,
        doctors: doctorIds,
      }
    );
  }

  public allPartnersExport(
    uri: string,
    filters: any
  ): Observable<StatusResponse> {
    const body = {
      blobUri: uri,
      query: {
        ...filters,
      },
    };

    return this.httpClient.post<StatusResponse>(
      `${this.exportersApiUrl}/v2/${this.commandExportPartners}`,
      body
    );
  }

  public onlinePartnersExport(
    uri: string,
    filters: any
  ): Observable<StatusResponse> {
    const body = {
      blobUri: uri,
      query: {
        ...filters,
      },
    };

    return this.httpClient.post<StatusResponse>(
      `${this.exportersApiUrl}/v2/${this.commandExportOnlinePartners}`,
      body
    );
  }

  public doctorsByPartnersExport(
    uri: string,
    filters: any
  ): Observable<StatusResponse> {
    const body = {
      blobUri: uri,
      query: {
        ...filters,
      },
    };
    return this.httpClient.post<StatusResponse>(
      `${this.exportersApiUrl}/v2/${this.commandExportDoctorsByPartners}`,
      body
    );
  }
}
