import { Injectable } from '@angular/core';
import { AnonymousCredential, BlobServiceClient } from '@azure/storage-blob';
import { environment } from '@root/environments/environment';
import { Util } from '../utils/core.util';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class AzureBlobService {
  private readonly log = new Logger(this.constructor.name);
  private readonly blobServiceClient: BlobServiceClient;

  constructor() {
    this.blobServiceClient = this.createServiceClient();
  }

  public async uploadFile(
    blobName: string,
    data: File,
    onProgress?: (progress: any) => void
  ) {
    this.log.debug('upload file', blobName);
    const containerClient = this.blobServiceClient.getContainerClient(
      environment.azureBlobContainer
    );
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    return blockBlobClient.uploadBrowserData(data, {
      onProgress,
      blobHTTPHeaders: {
        blobContentType: 'application/pdf',
      },
    });
  }

  public async uploadFileFromByteArray(
    blobName: string,
    data: string,
    onProgress?: (progress: any) => void
  ) {
    const containerClient = this.blobServiceClient.getContainerClient(
      environment.azureBlobContainer
    );
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    const blob = new Blob([Util.ConvertBase64ToBlob(data)], {
      type: 'application/pdf',
    });
    return blockBlobClient.uploadBrowserData(blob, {
      onProgress,
      blobHTTPHeaders: {
        blobContentType: 'application/pdf',
      },
    });
  }

  public deleteFile(blobName: string) {
    const containerClient = this.blobServiceClient.getContainerClient(
      environment.azureBlobContainer
    );
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    return blockBlobClient.delete();
  }

  private createServiceClient() {
    const credentials = new AnonymousCredential();
    return new BlobServiceClient(
      `${environment.azureBlobUrl}/${environment.azureBlobSas}`,
      credentials
    );
  }
}
