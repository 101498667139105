import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import { Bundle, DataInputType, Partner } from '@core/core.types';
import { ProductsListState } from '@core/store/products/products.state';
import {
  NbButtonModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { controlContainerProvider } from '@shared/helpers';
import { SharedModule } from '@shared/shared.module';
import {
  Observable,
  combineLatest,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
  concat,
  defer,
  of,
} from 'rxjs';
import { FamilyDiseaseInformation } from '@app/modules/service-data/service-data.types';

@Component({
  selector: 'app-service-data-form-bundle-information',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NbSelectModule,
    NbTooltipModule,
    NbIconModule,
    NbButtonModule,
    AsyncPipe,
    SharedModule,
    TranslateModule,
    ValidationErrorComponent,
    NbRadioModule,
    NbInputModule,
  ],
  templateUrl: './service-data-form-bundle-information.component.html',
  viewProviders: [controlContainerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDataFormBundleInformationComponent implements OnInit {
  protected bundles$: Observable<Bundle[]>;
  protected dataTableConfiguration: DataTableConfiguration;
  protected bundleFormControl = new FormControl<Bundle>(
    { value: null, disabled: true },
    [Validators.required]
  );
  @Input({ required: true }) selectedDataInputTypes: DataInputType[] = [];

  private rows: Observable<Bundle[]>;

  protected cysticFibrosisHistoryGroup = new FormGroup<{
    conditionDescription: FormControl<string>;
    relationToPatient: FormControl<string>;
  }>({
    conditionDescription: new FormControl<string>(''),
    relationToPatient: new FormControl<string>(''),
  });

  protected cysticFibrosisHistoryConfiguration: DataTableConfiguration;

  protected get form() {
    return this.controlContainer.control as FormGroup;
  }
  private destroyRef = inject(DestroyRef);

  constructor(
    private controlContainer: ControlContainer,
    private partnersUtilsService: PartnersUtilsService,
    private store: Store,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.bundles$ = this.getBundles();

    this.rows = this.form.get('bundleIds').valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(this.form.get('bundleIds').value),
      switchMap((bundleIds) =>
        this.store
          .select(ProductsListState.getBundles)
          .pipe(map((getBundles) => getBundles(bundleIds)))
      )
    );

    this.cysticFibrosisHistoryConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'serviceData.form.tooltips.removeDisease',
          action: this.removecysticFibrosisHistory.bind(this),
          icon: 'close-outline',
          type: DataTableActionType.BUTTON,
        },
      ],
      columns: [
        {
          label: 'Disease',
          property: 'conditionDescription',
        },
        {
          label: 'Relation to patient',
          property: 'relationToPatient',
        },
      ],
      rows$: this.getRows(
        'serviceInformation.nipt.patientHistory.cysticFibrosisHistory.diseases'
      ),
    };

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      tableHeadClasses: '',
      actionsLocation: DataTableActionsLocation.RIGHT,
      singleActions: [
        {
          name: 'serviceData.form.tooltips.unassignBundleFromServiceData',
          action: this.unassignBundle.bind(this),
          icon: 'close-outline',
          type: DataTableActionType.BUTTON,
          disabled: () => this.form.get('bundleIds').disabled,
        },
      ],
      columns: [
        {
          label: 'Bundle name',
          property: 'name',
        },
      ],
      rows$: this.rows,
    };
  }

  protected assignBundle(): void {
    if (!this.bundleFormControl.valid) {
      return;
    }

    const bundleIdsControl = this.form.get('bundleIds');

    bundleIdsControl.patchValue([
      ...bundleIdsControl.value,
      this.bundleFormControl.value,
    ]);

    this.bundleFormControl.reset();
  }

  private unassignBundle(event: MouseEvent, bundle: Bundle) {
    event.preventDefault();

    const bundleIdsControl = this.form.get('bundleIds');

    bundleIdsControl.patchValue(
      bundleIdsControl.value.filter((bundleId) => bundleId !== bundle.id)
    );
  }

  addcysticFibrosisHistory() {
    if (!this.cysticFibrosisHistoryGroup.valid) {
      return;
    }

    const diseaseControl = this.form.get(
      'serviceInformation.nipt.patientHistory.cysticFibrosisHistory.diseases'
    );

    diseaseControl.patchValue([
      ...diseaseControl.value,
      {
        conditionDescription: this.cysticFibrosisHistoryGroup.get(
          'conditionDescription'
        ).value,
        relationToPatient:
          this.cysticFibrosisHistoryGroup.get('relationToPatient').value,
      },
    ]);

    this.cysticFibrosisHistoryGroup.reset();
  }

  private removecysticFibrosisHistory(
    event: MouseEvent,
    diseaseHistory: FamilyDiseaseInformation
  ): void {
    event.preventDefault();

    const diseaseControl = this.form.get(
      'serviceInformation.nipt.patientHistory.cysticFibrosisHistory.diseases'
    );

    diseaseControl.patchValue(
      diseaseControl.value.filter(
        (dh: FamilyDiseaseInformation) =>
          dh?.conditionDescription !== diseaseHistory?.conditionDescription
      )
    );
  }

  private getBundles(): Observable<Bundle[]> {
    const partnerIdControl = this.form.get('partnerId');
    const bundleIdsControl = this.form.get('bundleIds');

    return combineLatest([
      partnerIdControl.valueChanges.pipe(startWith(partnerIdControl.value)),
      bundleIdsControl.valueChanges.pipe(startWith([])),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(([partnerId, bundleIds]) =>
        this.partnersUtilsService.getPartnerById$(partnerId).pipe(
          first(),
          filter((partner: Partner) => !!partner),
          switchMap((partner: Partner) =>
            this.partnersUtilsService
              .getPartnerBundles(partner.bundleConfigurations)
              .pipe(
                map((bundles: Bundle[]) =>
                  bundles.filter(
                    (bundle) =>
                      bundleIds.findIndex(
                        (bundleId) => bundleId === bundle.id
                      ) < 0
                  )
                ),
                tap(() => {
                  if (this.form.get('bundleIds').enabled) {
                    this.bundleFormControl.enable();
                  }
                })
              )
          )
        )
      ),
      map((bundles) =>
        bundles.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      )
    );
  }

  private getRows(formControlName: string): Observable<any[]> {
    return concat(
      defer(() => of(this.form.get(formControlName).value)).pipe(first()),
      this.form.get(formControlName).valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.cdRef.markForCheck())
      )
    );
  }

  protected readonly DataInputType = DataInputType;
}
