import { Component, Input, OnInit } from '@angular/core';
import { ServiceData } from '@app/modules/service-data/service-data.types';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import { GetServiceDataListAction } from '@app/modules/service-data/store/service-data.actions';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  DataTableActionType,
  DataTableActionsLocation,
  DataTableConfiguration,
  DataTableSelectionType,
} from '@shared/components/data-table/data-table.types';
import { SharedModule } from '@shared/shared.module';
import { Observable, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-service-data-results',
  standalone: true,
  imports: [
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    SharedModule,
    TranslateModule,
  ],
  templateUrl: './service-data-results.component.html',
})
export class ServiceDataResultsComponent implements OnInit {
  @Input() public serviceDataIds: string[];

  protected dataTableConfiguration: DataTableConfiguration;
  protected isLoading = true;

  private rows$: Observable<ServiceData[]>;

  constructor(
    private dialogRef: NbDialogRef<ServiceDataResultsComponent>,
    private serviceDataUtilsService: ServiceDataUtilsService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.rows$ = this.store
      .dispatch(
        new GetServiceDataListAction(
          { ids: this.serviceDataIds },
          {},
          null,
          false
        )
      )
      .pipe(
        switchMap(() =>
          this.store.select(ServiceDataState.getServiceDatasByIds).pipe(
            map((getServiceDataByIds) =>
              getServiceDataByIds(this.serviceDataIds)
            ),
            tap(() => (this.isLoading = false))
          )
        )
      );

    this.dataTableConfiguration = {
      title: '',
      selectionMode: DataTableSelectionType.NONE,
      actionsLocation: DataTableActionsLocation.RIGHT,
      tableHeadClasses: '',
      actionsClasses: 'text-end',
      columns: [
        {
          label: 'Reference key',
          property: 'referenceKey',
        },
        {
          label: 'Name',
          invoke: {
            objectReference: {
              getFullName: (serviceData: ServiceData) =>
                `${serviceData.patientInformation.firstName} ${serviceData.patientInformation.lastName}`,
            },
            name: 'getFullName',
            args: [],
          },
        },
        {
          label: 'Payer name',
          property: 'paymentInformation.notes',
        },
        {
          label: 'Email',
          property: 'patientInformation.email',
        },
      ],
      singleActions: [
        {
          name: 'Open service data details',
          icon: 'file-outline',
          type: DataTableActionType.BUTTON,
          action: this.openServiceDataDetails.bind(this),
        },
      ],
      rows$: this.rows$,
    };
  }

  private openServiceDataDetails(event: MouseEvent, serviceData: ServiceData) {
    this.serviceDataUtilsService.openDetails(serviceData.id);
    this.dismiss();
  }

  dismiss() {
    this.dialogRef.close();
  }
}
