<ng-container formGroupName="albuminTherapy">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="albuminTherapy">{{
            'serviceData.form.albuminTherapy' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="albuminTherapy"
            name="albuminTherapy"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="albumin-therapy-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      @if (form.get('albuminTherapy.hasReceived').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="albuminTherapyRelevant">{{
              'serviceData.form.albuminTherapyRelevant' | translate
            }}</label>
          </div>
          <div class="col-8">
            <nb-radio-group
              id="albuminTherapyRelevant"
              name="albuminTherapyRelevant"
              class="radio-toolbar"
              formControlName="isRelevant"
              data-cy="albumin-therapy-relevant-rg"
            >
              <nb-radio [value]="false">{{ 'common.yes' | translate }}</nb-radio>
              <nb-radio [value]="true">{{ 'common.no' | translate }}</nb-radio>
            </nb-radio-group>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="isRelevant"
              translations="serviceData.form.validation.albuminTherapy"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>

<ng-container formGroupName="cellularImmunotherapy">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="cellularImmunotherapy">{{
            'serviceData.form.cellularImmunotherapy' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="cellularImmunotherapy"
            name="cellularImmunotherapy"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="cellular-immunotherapy-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      @if (form.get('cellularImmunotherapy.hasReceived').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="cellularImmunotherapyRelevant">{{
              'serviceData.form.cellularImmunotherapyRelevant' | translate
            }}</label>
          </div>
          <div class="col-8">
            <nb-radio-group
              id="cellularImmunotherapyRelevant"
              name="cellularImmunotherapyRelevant"
              class="radio-toolbar"
              formControlName="isRelevant"
              data-cy="cellular-immunotherapy-relevant-rg"
            >
              <nb-radio [value]="false">{{ 'common.yes' | translate }}</nb-radio>
              <nb-radio [value]="true">{{ 'common.no' | translate }}</nb-radio>
            </nb-radio-group>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="isRelevant"
              translations="serviceData.form.validation.cellularImmunotherapy"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>

<ng-container formGroupName="stemCellTherapy">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="stemCellTherapy">{{
            'serviceData.form.stemCellTherapy' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="stemCellTherapy"
            name="stemCellTherapy"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="stem-cell-therapy-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="sample.form.validation.stemCellTherapy"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container formGroupName="transplantSurgery">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="transplantSurgery">{{
            'serviceData.form.transplantSurgery' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="transplantSurgery"
            name="transplantSurgery"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="transplant-surgery-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="sample.form.validation.transplantSurgery"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container formGroupName="bloodTransfusion">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="bloodTransfusion">{{
            'serviceData.form.bloodTransfusion' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="bloodTransfusion"
            name="bloodTransfusion"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="blood-transfusion-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="hasReceived"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6 align-self-center">
      @if (form.get('bloodTransfusion.hasReceived').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="bloodTransfusionRelevant">{{
              'serviceData.form.bloodTransfusionRelevant' | translate
            }}</label>
          </div>
          <div class="col-8">
            <nb-radio-group
              id="bloodTransfusionRelevant"
              name="bloodTransfusionRelevant"
              class="radio-toolbar"
              formControlName="isRelevant"
              data-cy="blood-transfusion-relevant-rg"
            >
              <nb-radio [value]="false">{{ 'common.yes' | translate }}</nb-radio>
              <nb-radio [value]="true">{{ 'common.no' | translate }}</nb-radio>
            </nb-radio-group>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="isRelevant"
              translations="serviceData.form.validation.bloodTransfusion"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
