import { PatientForm } from '@app/modules/patient-forms/patient-forms.types';
import { ExtendedProductInventoryItem } from '@app/modules/service-data/service-data.types';
import {
  AwbAddress,
  EntityBase,
  Kit,
  ModalMode,
  Partner,
  Product,
  ProductInventory,
  Properties,
  RecipientModel,
  ResolvedProductInventory,
  Run,
} from '@core/core.types';
import moment from 'moment';

export interface FamilyDiseaseHistory {
  diseaseInformation: string;
  relationToPatient: string;
}

export interface PregnancyDiseaseHistory {
  diseaseInformation: string;
}

export interface CarrierOfGeneticDiseaseInformation {
  diseaseInformation: string;
}

export interface Timestamp {
  by: string;
  timestamp: string;
}

export interface IntResponseModel {
  result: number;
}

export interface Treatment extends EntityBase {
  productInventoryItemId: string;
  doctorId: string;
  partnerId: string;
  doctorType: string;
  notes: string;
  comment: string;
  dateOfVenipuncture: string;
  genderInformation: boolean;
  redraw: boolean;
  kitId: string;
  redrawKitId: string;
  redrawnKitId?: string;
  incidentalFindings: boolean;
  dhlWaybill: string;
  dhlWaybillDocumentId: string;
  dhlAddress: AwbAddress;
  productId: string;
  doctorInvoiceNumber: string;
  geneplanetInvoiceNumber: string;
  laboratoryInvoiceNumber: string;
  dateOfService?: string;
  documentIds: string[];
  paymentInformation: {
    paymentType: string;
    notes: string;
  };
  patientInformation: {
    name?: string;
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    documentNumber: string;
    telephoneNumber: string;
    height: number;
    weight: number;
  };
  currentPregnancy: {
    expDateOfConfinement: string;
    gestationWeek: number;
    gestationDay: number;
    gestationAutoCalculate: boolean;
    pregnancyType: string;
    inVitro: boolean;
    vitroType: string;
    hasPriorScreeningTest: boolean;
    priorScreeningTestType: string;
    dateOfUltrasound: string;
    firstT21Risk?: number;
    firstT18Risk?: number;
    firstT13Risk?: number;
    secondT21Risk?: number;
    secondT18Risk?: number;
    secondT13Risk?: number;
    chorionicityType: string;
    isSurrogateMother: boolean;
    vanishingTwinSyndrome: boolean;
    vanishingTwinSyndromeGestationalWeek: number;
    vanishingTwinSyndromeGestationalDay: number;
    heparinTherapy: boolean;
    isHeparinTherapyRelevant?: boolean;
    medicationDuringPregnancy: boolean;
  };
  historyInformation: {
    gravida: number;
    para: number;
    familyDiseaseHistory: FamilyDiseaseHistory[];
    pregnancyDiseaseHistory: PregnancyDiseaseHistory[];
    carrierOfGeneticDiseaseInformations: CarrierOfGeneticDiseaseInformation[];
    historyOfCancer: HistoryOfCancerType;
    albuminTherapy: boolean;
    isAlbuminTherapyRelevant?: boolean;
    cellularImmunotherapy: boolean;
    isCellularImmunotherapyRelevant?: boolean;
    stemCellTherapy: boolean;
    transplantSurgery: boolean;
    bloodTransfusion: boolean;
    isBloodTransfusionRelevant?: boolean;
  };
  latestDocumentInformation?: {
    documentId: string;
    status: DocumentStatus;
    statusChanged: string;
    statusChangedBy: string;
  };
  sampleExtractorId?: string;
  testCode?: string;
  generated?: Timestamp;
  exported?: Timestamp;
  processed?: Timestamp;
  canceled?: Timestamp;
  failed?: Timestamp;
  failureReason?: TreatmentMarkAsFailedType;
  deactivated?: Timestamp;
  lastStatus?: TreatmentStatus;
  lastStatusChanged?: string;
  lastStatusChangedBy?: string;
  resample?: Timestamp;
  resampleReason?: TreatmentNotQualifiedType;
  notQualified?: Timestamp;
  notQualifiedReason?: TreatmentNotQualifiedType;
  notQualifiedType?: string;
  invoiceId?: string;
  laboratory?: string;
  paid: boolean;
  runIds: string[];
}

export interface TreatmentKitData {
  kit?: Kit;
  treatment?: Treatment;
  pii?: ProductInventory;
  partner?: Partner;
  doctor?: Partner;
  product?: Product;
  runs?: Run[];
}

export interface ResolvedTreatment extends Treatment {
  productInventory: ResolvedProductInventory;
  doctor: Partner;
}

export interface TreatmentDialogExtended {
  partnerId: string;
  doctorId: string;
  kitId: string;
  productId: string;
}

export interface TreatmentDialogContext {
  modalMode: ModalMode;
  editItem?: Treatment;
  partnerId: string;
  patientForm?: PatientForm;
}

export interface ApproveDocumentDialogContext {
  document?: Document;
  recipients?: RecipientModel[];
}

export interface TreatmentDetailsDialogContext {
  selectedTreatment?: Treatment;
}

export interface SetInvoiceNumberDialogContext {
  treatments?: Treatment[];
}

export interface SetFailureReasonDialogContext {
  treatment?: Treatment;
}

export interface TreatmentRedrawDialogContext {
  treatment?: Treatment;
}

export interface TreatmentCalculatedTatDialogContext {
  filters?: any;
}

export enum PregnancyType {
  singleton = 'Singleton',
  twins = 'Twins',
}

export const PregnancyTypes: PregnancyType[] = [
  PregnancyType.singleton,
  PregnancyType.twins,
];

export enum VitroType {
  ownEgg = 'Own Egg',
  donorEgg = 'Donor Egg',
}

export const VitroTypes: VitroType[] = [VitroType.ownEgg, VitroType.donorEgg];

export enum ChorionicityType {
  DCDA = 'DCDA',
  MCDA = 'MCDA',
  MCMA = 'MCMA',
}

export enum PriorScreeningTestType {
  UltrasoundBiochemistryFirstTrimester = 'UltrasoundBiochemistryFirstTrimester',
  UltrasoundFirstTrimester = 'UltrasoundFirstTrimester',
  BiochemistryFirstTrimester = 'BiochemistryFirstTrimester',
  SecondTrimester = 'SecondTrimester',
  FirstAndSecondTrimesterIntegratedTest = 'FirstAndSecondTrimesterIntegratedTest',
  Other = 'Other',
  NoTestTaken = 'NoTestTaken',
}

export const PriorScreeningTestTypes: PriorScreeningTestType[] = [
  PriorScreeningTestType.UltrasoundBiochemistryFirstTrimester,
  PriorScreeningTestType.UltrasoundFirstTrimester,
  PriorScreeningTestType.BiochemistryFirstTrimester,
  PriorScreeningTestType.SecondTrimester,
  PriorScreeningTestType.FirstAndSecondTrimesterIntegratedTest,
  PriorScreeningTestType.Other,
  PriorScreeningTestType.NoTestTaken,
];

export enum TreatmentSelectProperties {
  comment = 'comment',
  currentPregnancy = 'currentPregnancy',
  dateOfService = 'dateOfService',
  dateOfVenipuncture = 'dateOfVenipuncture',
  dhlWaybill = 'dhlWaybill',
  doctorId = 'doctorId',
  documentIds = 'documentIds',
  genderInformation = 'genderInformation',
  generated = 'generated',
  historyInformation = 'historyInformation',
  id = 'id',
  incidentalFindings = 'incidentalFindings',
  kitId = 'kitId',
  laboratory = 'laboratory',
  lastStatus = 'lastStatus',
  lastStatusChanged = 'lastStatusChanged',
  lastStatusChangedBy = 'lastStatusChangedBy',
  latestDocumentInformation = 'latestDocumentInformation',
  patientInformation = 'patientInformation',
  paymentInformation = 'paymentInformation',
  processed = 'processed',
  productId = 'productId',
  productInventoryItemId = 'productInventoryItemId',
  redraw = 'redraw',
  redrawKitId = 'redrawKitId',
  redrawnKitId = 'redrawnKitId',
  runIds = 'runIds',
  sampleExtractorId = 'sampleExtractorId',
  notes = 'notes',
  doctorInvoiceNumber = 'doctorInvoiceNumber',
  laboratoryInvoiceNumber = 'laboratoryInvoiceNumber',
  geneplanetInvoiceNumber = 'geneplanetInvoiceNumber',
  invoiceId = 'invoiceId',
  paid = 'paid',
  exported = 'exported',
  failureReason = 'failureReason',
  resampleReason = 'resampleReason',
  notQualifiedReason = 'notQualifiedReason',
}

export interface ReportResult {
  SubLibraryId: string;
  KitId: string;
  OperationAdvice: string;
  ReasonForQualityControlFailure: string;
  TestChr13: string;
  TestChr18: string;
  TestChr21: string;
  TestSexChr: string;
  FetalFraction: number;
  OtherAutosomes: string;
  Disease: string;
  DelDup: string;
  FetusGender: string;
}

export interface ReportGenerationData {
  Barcode: string;
  PatientName: string;
  PatientDateOfBirth: string;
  GestationWeeks: number;
  GestationDays: number;
  DocumentId: string;
  DateOfVenipuncture: string;
  DateOfConfinement: string;
  SampleReceived?: string;
  DoctorName: string;
  ServiceName: string;
  CountryCode: string;
  LanguageCode: string;
  ApprovedBy: string;
  Gender: boolean;
  IncidentalFindings: boolean;
  productInventoryItemId: string;
  testId: string;
  doctorId: string;
  sampleExtractorId: string;
}

export interface GeneratedReport {
  Data: string;
  FileName: string;
  SubLibraryId?: string;
  error?: string;
  resultRisk?: string;
  reportRisk?: string;
  reportType?: string;
  ReportType?: string;
  inputFilename?: string;
  sourceFileName?: string;
}

export interface DocumentInteraction {
  interaction: string;
  interacted: string;
  interactedBy: string;
}

export interface DocumentApprovalRequest {
  subject: string;
  body: string;
  recipients: string[];
  ccRecipients: string[];
  bccRecipients: string[];
  sendEmail: boolean;
}

export interface Document {
  id: string;
  typeId: string;
  fileName: string;
  extension: string;
  sizeInBytes: string;
  interactions?: DocumentInteraction[];
  approved?: Timestamp;
  generated: Timestamp;
  lastStatus: DocumentStatus;
  lastStatusChanged: string;
  lastStatusChangedBy: string;
  disapproved?: Timestamp;
  archived?: Timestamp;
  properties: Properties[];
  uploaded?: { by: string };
}

export interface ExtendedDocument extends Document {
  productInventoryItem?: ExtendedProductInventoryItem;
}

export interface StatusResponse {
  StatusUri: string;
  TerminateUri: string;
  InstanceId: string;
}

export interface BgiXlsResult {
  KitId: string;
  Result: BgiResult;
  ReportType: string;
  ResultRisk?: Risk;
  index?: number;
  fileName?: string;
  sourceFileName?: string;
  Signee?: string;
}

export interface BgiResult {
  Trisomy21Risk: Risk;
  Trisomy18Risk: Risk;
  Trisomy13Risk: Risk;
  FetalFraction: string;
  Trisomy9Risk: Risk;
  Trisomy22Risk: Risk;
  Trisomy16Risk: Risk;
  XoResult: ChromosomeDetection;
  XxxResult: ChromosomeDetection;
  XyyResult: ChromosomeDetection;
  XxyResult: ChromosomeDetection;
  MicrodeletionsDuplications: string;
  IncidentalFindings: string;
  Dated: moment.Moment;
  SampleReceived: moment.Moment;
  YChromosome: ChromosomeDetection | string;
  TireType: PregnancyType;
}

export interface N2dStatistics {
  NiptTestId: string;
  ProductInventoryItemId: string;
  KitId: string;
  KitClaimed?: string;
  CustomerId: string;
  CustomerCreated?: string;
  PatientFormCode?: string;
  PatientFormEmailId?: string;
  PatientFormEmailSent?: string;
  PatientFormEmailInteractions?: string[];
  ResultReferenceCode: string;
  ResultReferenceEmailId: string;
  ResultReferenceEmailSent: string;
  ResultReferenceEmailInteractions?: string[];
  UserAccountId: string;
  UserAccountCreated: string;
  UserAccountMetadata: string;
  RegistrationSourceType: string;
  LastLoginMobile?: string;
  LastLoginWeb: string;
  Newsletter: string;
  Intent?: string;
  IntentType?: string;
  IntentLastStep?: string;
}

export enum Risk {
  HighRisk = 'HIGH_RISK',
  LowRisk = 'LOW_RISK',
}

export enum ChromosomeDetection {
  Detected = 'DETECTED',
  NotDetected = 'NOT_DETECTED',
}

export enum DocumentStatus {
  uploaded = 'Uploaded',
  approved = 'Approved',
  downloaded = 'Downloaded',
  disapproved = 'Disapproved',
  archived = 'Archived',
}

export enum TreatmentDocumentKeywords {
  resample1 = 're-sample',
  resample2 = 'resample',
  resample3 = 'resampling',
  official = 'official',
  nocall = 'nocall',
  refund = 'refund',
  delay = 'delay',
  highRisk1 = 'high risk',
  highRisk2 = 'high-risk',
  notqualified = 'notqualified',
  notqualifiedbypartner = 'notqualified-bypartner',
  notqualifiedbylaboratory = 'notqualified-bylaboratory',
  canceled = 'canceled',
}

export const NonOfficialTreatmentDocumentReportTypes = [
  TreatmentDocumentKeywords.resample1,
  TreatmentDocumentKeywords.resample2,
  TreatmentDocumentKeywords.nocall,
  TreatmentDocumentKeywords.refund,
  TreatmentDocumentKeywords.delay,
  TreatmentDocumentKeywords.highRisk1,
  TreatmentDocumentKeywords.highRisk2,
  TreatmentDocumentKeywords.notqualifiedbylaboratory,
  TreatmentDocumentKeywords.notqualifiedbypartner,
  TreatmentDocumentKeywords.notqualified,
];

export enum CalculatedTreatmentStatus {
  resample = 'resample',
  nocall = 'nocall',
  processing = 'processing',
  processed = 'processed',
  delay = 'delay',
  acknowledged = 'acknowledged',
  ready = 'ready',
  failed = 'failed',
  redrawn = 'redrawn',
  notQualified = 'notQualified',
}

export enum TreatmentStatus {
  generated = 'Generated',
  processed = 'Processed',
  failed = 'Failed',
  deactivated = 'Deactivated',
  resample = 'Resample',
  noCall = 'NoCall',
  delayed = 'Delayed',
  redrawn = 'Redrawn',
  dateOfService = 'DateOfService',
  notQualified = 'NotQualified',
}

export enum DsvCategory {
  blue = 'blue',
  red = 'red',
  orange = 'orange',
  green = 'green',
  gray = 'gray',
  none = 'none',
}

export enum TreatmentMarkAsFailedType {
  Miscarriage = 'Miscarriage',
  CanceledByPatient = 'CanceledByPatient',
  TestLimitation = 'TestLimitation',
}

export const TREATMENT_MARK_AS_FAILED_TYPES: TreatmentMarkAsFailedType[] = [
  TreatmentMarkAsFailedType.Miscarriage,
  TreatmentMarkAsFailedType.CanceledByPatient,
  TreatmentMarkAsFailedType.TestLimitation,
];

export enum TreatmentNotQualifiedType {
  None = 'None',
  Hemolysis = 'Hemolysis',
  InsufficientVolume = 'InsufficientVolume',
  TestLimitation = 'TestLimitation',
  ZGLabMistake = 'ZGLabMistake',
  KitMixup = 'KitMixup',
  NinetySixHPlus = '96h+',
  DamagedTube = 'DamagedTube',
  DHL = 'DHL',
  Other = 'Other',
  Expired = 'Expired',
}

export const TREATMENT_NOTQUALIFIED_TYPES: TreatmentNotQualifiedType[] = [
  TreatmentNotQualifiedType.Hemolysis,
  TreatmentNotQualifiedType.InsufficientVolume,
  TreatmentNotQualifiedType.TestLimitation,
  TreatmentNotQualifiedType.ZGLabMistake,
  TreatmentNotQualifiedType.KitMixup,
  TreatmentNotQualifiedType.NinetySixHPlus,
  TreatmentNotQualifiedType.DamagedTube,
  TreatmentNotQualifiedType.DHL,
  TreatmentNotQualifiedType.Other,
  TreatmentNotQualifiedType.Expired,
];

export enum HistoryOfCancerType {
  no = 'No',
  recovered = 'Recovered',
  currentlyAffected = 'CurrentlyAffected',
}

export const HistoryOfCancerTypes: HistoryOfCancerType[] = [
  HistoryOfCancerType.currentlyAffected,
  HistoryOfCancerType.recovered,
  HistoryOfCancerType.no,
];

export enum TreatmentFilters {
  Ids = 'ids',
  DateOfService = 'dateOfService',
  DateOfVenipuncture = 'dateOfVenipuncture',
  DhlWaybillToken = 'dhlWaybillToken',
  DoctorIds = 'doctorIds',
  DoctorInvoiceNumberToken = 'DoctorInvoiceNumberToken',
  DocumentsIds = 'documentIds',
  DocumentStatus = 'documentStatus',
  GeneplanetInvoiceNumberToken = 'GeneplanetInvoiceNumberToken',
  GeneratedFrom = 'GeneratedFrom',
  GeneratedTo = 'GeneratedTo',
  IsPaid = 'IsPaid',
  KitToken = 'kitToken',
  KitIds = 'kitIds',
  Laboratory = 'laboratory',
  LaboratoryInvoiceNumberToken = 'LaboratoryInvoiceNumberToken',
  LastStatuses = 'lastStatuses',
  LastStatusChangedFrom = 'lastStatusChangedFrom',
  LastStatusChangedTo = 'lastStatusChangedTo',
  NiptTestId = 'niptTestId',
  NotesToken = 'notesToken',
  PartnerAddressCountryTokens = 'partnerAddressCountryTokens',
  PartnerId = 'partnerId',
  PatientNameToken = 'patientNameToken',
  PaymentInformationNotesToken = 'PaymentInformationNotesToken',
  PaymentTypes = 'paymentTypes',
  ProductIds = 'productIds',
  ProductInventoryItemIds = 'productInventoryItemIds',
  RedrawKitId = 'redrawKitId',
  RunIds = 'runIds',
  Status = 'status',
  StatusChangedFrom = 'statusChangedFrom',
  StatusChangedTo = 'statusChangedTo',
  Exported = 'isExported',
  Redraw = 'isRedraw',
  HadDateOfVenipuncture = 'hasDateOfVenipuncture',
  DateOfVenipunctureFrom = 'dateOfVenipunctureFrom',
  DateOfVenipunctureTo = 'dateOfVenipunctureTo',
}

export enum ExcelFileName {
  InformationSheet = 'Information sheet',
  BGIInformationSheet = 'BGI Information sheet',
  FullInformationSheet = 'Full Information sheet',
  InvoiceSheet = 'Invoice sheet',
  AnalyticsExport = 'Analytics export',
  Insurance = 'Insurance export',
  ReportExport = 'Report export',
  N2DStatistics = 'N2D statistics export',
  N2DCustomerOrders = 'N2D customer orders export',
  Logistics = 'Logistics export',
  Orders = 'Orders export',
  Consents = 'Consents export',
  Operations = 'Operations export',
}
