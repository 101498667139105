import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FamilyDiseaseInformation } from '@app/modules/service-data/service-data.types';

export function diseasesValidator(relationRequired = false): ValidatorFn {
  return (group: AbstractControl) => {
    const hasHistory = group.get('hasHistory').value;
    const errors = {};
    if (hasHistory) {
      const diseases: FamilyDiseaseInformation[] = group.get('diseases').value;
      if (!diseases.length) {
        errors['noDiseases'] = true;
      } else {
        for (const disease of diseases) {
          if (!disease.conditionDescription.trim().length) {
            errors['conditionMissing'] = true;
          }
          if (relationRequired && !disease.relationToPatient.trim().length) {
            errors['relationMissing'] = true;
          }
        }
      }
    }
    return errors;
  };
}
