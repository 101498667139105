import { AbstractControl, ValidatorFn } from '@angular/forms';

export function crossFieldLowerHigherValidator(
  lowerControlName: string,
  higherControlName: string
): ValidatorFn {
  return (group: AbstractControl) => {
    const lowerControl = group.get(lowerControlName);
    const higherControl = group.get(higherControlName);

    if (
      (!lowerControl.value && lowerControl.value !== 0) ||
      (!higherControl.value && higherControl.value !== 0)
    ) {
      return null;
    }

    if (higherControl.value <= lowerControl.value) {
      lowerControl.setErrors({ lower: true });
      higherControl.setErrors({ higher: true });
    } else {
      lowerControl.setErrors(null);
      higherControl.setErrors(null);
    }
    return null;
  };
}
