export const PRODUCT_CONFIGURATION_STATE_NAME = 'productConfiguration';

export class LoadProductConfigurationById {
  static type = `[${PRODUCT_CONFIGURATION_STATE_NAME}] LoadProductConfigurationById`;
  constructor(public productConfigurationId: string) {}
}

// export class LoadProductConfigurationsByIdsAction {
//   static type = `[${PRODUCT_CONFIGURATION_STATE_NAME}] LoadProductConfigurationsByIds`;
//   constructor(public productConfigurationsIds: string[]) {}
// }
