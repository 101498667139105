import { PartnersListFilterSettings } from '@core/store/partners/partners.state';
import { Partner, SortDirection } from '../../core.types';

export class LoadPartnersListAction {
  static type = '[Partners] LoadPartnersListAction';

  constructor(
    public options: {
      pageToLoad?: number;
      orderBy?: string;
      orderDirection?: SortDirection;
      filter?: Partial<PartnersListFilterSettings>;
      pageSize?: number;
    }
  ) {}
}

export class ClearPartnersStateAction {
  static type = '[Partners] ClearPartnersStateAction';
}

export class AddPartnerAction {
  static type = '[Partner] AddPartnerAction';

  constructor(
    public partner: Partner & {
      addressCountry: string;
      addressStreet: string;
      addressZip: string;
      addressCity: string;
    },
    public selectedPartnerTypes: string[]
  ) {}
}

export class UpdatePartnerAction {
  static type = '[Partner] UpdatePartnerAction';

  constructor(
    public partner: Partner & {
      addressCountry: string;
      addressStreet: string;
      addressZip: string;
      addressCity: string;
    },
    public selectedPartnerTypes: string[]
  ) {}
}

export class UpdatePartnerCountryAction {
  static type = '[Partner] UpdatePartnerCountryAction';

  constructor(public country: string, public partnerId: string) {}
}

export class SetSelectedPartnerIdAction {
  static type = '[Partner] SetSelectedPartnerId';

  constructor(public partnerId: string) {}
}

export class SetPartnerProductsConfigurationsAction {
  static type = '[Partner] SetPartnerProductsConfigurations';

  constructor(
    public partnerId: string,
    public productConfigurations: string[]
  ) {}
}

export class AssignBundleToPartner {
  static type = '[Partner] AssignBundleToPartner';
  constructor(public partnerId: string, public bundleId: string) {}
}

export class UnassignBundleConfigurationFromPartner {
  static type = '[Partner] UnassignBundleConfigurationFromPartner';
  constructor(public partnerId: string, public bundleConfigurationId: string) {}
}

export class AddPartnerDoctorAction {
  static type = '[Partner] AddPartnerDoctorAction';

  constructor(public partnerId: string, public doctor: Partner) {}
}

export class UpdatePartnerDoctorAction {
  static type = '[Partner] UpdatePartnerDoctorAction';

  constructor(public doctor: Partner) {}
}

export class RemovePartnerDoctorAction {
  static type = '[Partner] RemovePartnerDoctorAction';

  constructor(
    public partnerId: string,
    public doctorId: string,
    public doctorUserAccountId: string
  ) {}
}

export class DeactivatePartnerAction {
  static type = '[Partner] DeactivatePartnerAction';

  constructor(public partnerId: string) {}
}

export class ActivatePartnerAction {
  static type = '[Partner] ActivatePartnerAction';

  constructor(public partnerId: string) {}
}

export class LoadPartnerById {
  static type = '[Partner] LoadPartnerById';
  constructor(public partnerId: string, public refresh = false) {}
}

export class LoadPartnersByIds {
  static type = '[Partner] LoadPartnersByIds';
  constructor(public partnerIds: string[], public refresh = false) {}
}

export class SetPartnersById {
  static type = '[Partner] SetPartnersById';

  constructor(public partners: Partner | Partner[]) {}
}

export class DoctorsExportAction {
  static type = '[Partner] DoctorsExport';
  constructor(
    public id: string,
    public doctorIds: string[],
    public fileName: string
  ) {}
}

export class LoadPartnersAverageTestsPerMonthAction {
  static readonly type = '[Partner] LoadPartnersAverageTestsPerMonthAction';
  constructor(public partnerIds: string[]) {}
}

// export class PartnersExportAction {
//   static type = '[Partner] PartnersExport';
//   constructor(
//     public id: string,
//     public partnerIds: string[],
//     public fileName: string,
//   ) {}
// }

export class AllPartnersExportAction {
  static type = '[Partner] AllPartnersExportAction';
  constructor(
    public id: string,
    public fileName: string,
    public filters: any
  ) {}
}

// export class DoctorsByPartnersExportAction {
//   static type = '[Partner] DoctorsByPartnersExport';
//   constructor(
//     public id: string,
//     public partnerIds: string[],
//     public fileName: string
//   ) {}
// }
export class DoctorsByPartnersExportAction {
  static type = '[Partner] DoctorsByPartnersExport';
  constructor(
    public id: string,
    public fileName: string,
    public filters: any
  ) {}
}

export class RemovePartnerFromStateListAction {
  static type = '[Partner] RemovePartnerFromStateList';
  constructor(public partner: Partner) {}
}
