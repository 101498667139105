import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProductInventoryItemDocumentsComponent } from '@app/modules/service-data/components/product-inventory-item/product-inventory-item-documents/product-inventory-item-documents.component';
import { ExtendedProductInventoryItem } from '@app/modules/service-data/service-data.types';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import { SampleUtilsService } from '@app/modules/service-data/services/sample-utils.service';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import { GetProductInventoryItemAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import { AuthenticationService } from '@core/services/authentication.service';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbTooltipModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { Observable, Subject, switchMap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DocumentPreviewComponent } from '../../document-preview/product-inventory-item-document-preview/document-preview.component';

@Component({
  selector: 'app-product-inventory-item-details',
  standalone: true,
  imports: [
    AsyncPipe,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbTooltipModule,
    TranslateModule,
    DatePipe,
    ProductInventoryItemDocumentsComponent,
    DocumentPreviewComponent,
    SharedModule,
    NgClass,
  ],
  templateUrl: './product-inventory-item-details.component.html',
  styleUrl: './product-inventory-item-details.component.scss',
})
export class ProductInventoryItemDetailsComponent implements OnInit {
  @Input() piiId: string;

  protected productInventoryItem$: Observable<ExtendedProductInventoryItem>;
  protected onShowPdfSubject$ = new Subject<string>();

  documentPreviewPermission$: Observable<boolean>;

  constructor(
    protected dialogRef: NbDialogRef<ProductInventoryItemDetailsComponent>,
    private store: Store,
    private productInventoryItemUtilsService: ProductInventoryItemUtilsService,
    private serviceDataUtilsService: ServiceDataUtilsService,
    private sampleUtilsService: SampleUtilsService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.productInventoryItem$ = this.store
      .dispatch(new GetProductInventoryItemAction(this.piiId))
      .pipe(
        switchMap(() =>
          this.store
            .select(ProductInventoryItemState.getProductInventoryItemById)
            .pipe(map((getById) => getById(this.piiId)))
        ),
        switchMap((pii) =>
          this.productInventoryItemUtilsService.getExtendedProductInventoryItem(
            pii,
            {
              partner: true,
              product: true,
              sample: {
                kit: true,
                serviceData: { invoice: true },
              },
            }
          )
        )
      );

    this.documentPreviewPermission$ = this.authService
      .hasRequiredPermission$('pii.details.documentPreview')
      .pipe(first());
  }

  protected openServiceDataDetails(serviceDataId: string) {
    this.serviceDataUtilsService.openDetails(serviceDataId);
  }

  protected openSampleDetails(sampleId: string) {
    this.sampleUtilsService.openDetails(sampleId);
  }

  protected getStatusReason(pii: ExtendedProductInventoryItem) {
    const documentReason = pii.properties.find(
      (p) => p.key === 'DocumentReason'
    )?.value;

    return documentReason ?? '';
  }
}
