<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="partnerId">{{
          'serviceData.form.partner' | translate
        }}</label>
      </div>
      <div class="col-8">
        <app-partner-typeahead
          name="partnerId"
          id="partnerId"
          class="w-50"
          [fieldSize]="'medium'"
          [control]="form.controls.partnerId"
          [disabled]="!!id"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="partnerId"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="doctor">{{ 'serviceData.form.doctor' | translate }}</label>
      </div>
      <div class="col-8">
        @if (doctors$ | async; as doctors) {
          <nb-select
            fullWidth
            id="doctor"
            appInputValidationStatus
            [placeholder]="
              doctors.length
                ? ('serviceData.form.placeholders.selectDoctor' | translate)
                : ('serviceData.form.placeholders.noDoctorsAvailable'
                  | translate)
            "
            [formControl]="
              form.get('serviceInformation.nipt.doctorInformation.doctorId')
            "
            data-cy="doctor-select"
          >
            @for (doctor of doctors; track doctor) {
              <nb-option [value]="doctor?.id"> {{ doctor?.name }} </nb-option>
            }
          </nb-select>
        } @else {
          <nb-select
            fullWidth
            placeholder="{{
              'serviceData.form.placeholders.selectPartnerFirst' | translate
            }}"
            [formControl]="
              form.get('serviceInformation.nipt.doctorInformation.doctorId')
            "
            data-cy="disabled-doctor-select"
          />
        }
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="serviceInformation.nipt.doctorInformation.doctorId"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>
