/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  stagingFavIcon: true,
  auth0: {
    audience: 'https://geneplanet-platform-stg',
    domain: 'geneplanet-stg.eu.auth0.com',
    clientId: '1ZFrTfv8L6hZZC5eNJgBRAwv6jF5dvVp',
  },
  apiUrl: 'https://api-stg.geneplanet.com/api',
  apimSubscriptionKey: 'c4e899188c57471db693aaed9997c817',
  actorsPrefix: '/actors',
  pantheonStringToken: 'P#\\)b(D5[fyfzyvU:^GKBFm#fXr?]&a3r',
  azureBlobUrl: 'https://documentsstgstore.blob.core.windows.net',
  azureBlobSas:
    '?st=2020-01-16T07%3A22%3A22Z&se=2030-01-16T14%3A00%3A00Z&sp=rcwdl&sv=2018-03-28&sr=c&sig=X%2BLJxUBjrAI%2FM2IgRN2CmmMxXgKYnUYrDmY13I7MGU8%3D',
  azureBlobContainer: 'documents',
  html2pdfUrl:
    'https://html2pdf.geneplanet.com/api/Html2Pdf?code=GKwoS5GufiaAhgB/g5M4xTGFaLWPhDFFqHeQjILHweNLSSj3geqqag==',
  reportManagerUrl: 'https://nipt-report-manager-stg-fa.azurewebsites.net/api',
  reportManagerCode: 'om8ux/RKTnYJHePz2O/RlRXbwSVNT/8Uk2t9b9aZVaBIIXBCz5U0JQ==',
  usersWithLimitedAccessToUploadedDocuments: ['auth0|5af15a2d17688b753df8a3ae'],
  exportersApiUrl: `https://api-stg.geneplanet.com/api/nipt-exporters`,
  cataloguesApiUrl: `https://api-stg.geneplanet.com/api/catalogues/actors`,
  resultReferenceLinkEmail: 'screening.stg@geneplanet.com',
  sentry: {
    environment: 'staging',
    enabled: true,
    dsn: 'https://eecabe752ab74a6f890ca5129d1d32ff@o468713.ingest.sentry.io/5830109',
  },
  overrideReportLanguages: {},
  features: {
    bgiXlsImport: true,
  },
};
