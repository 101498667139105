<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="firstName">
          {{ 'serviceData.form.firstName' | translate }}
        </label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="firstName"
          id="firstName"
          appInputValidationStatus
          [placeholder]="'serviceData.form.placeholders.firstName' | translate"
          data-cy="first-name-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="firstName"
          translations="serviceData.form.validation.name"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="lastName">
          {{ 'serviceData.form.lastName' | translate }}
        </label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="lastName"
          id="lastName"
          appInputValidationStatus
          [placeholder]="'serviceData.form.placeholders.lastName' | translate"
          data-cy="last-name-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="lastName"
          translations="serviceData.form.validation.name"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>
<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="dateOfBirth">{{
          'serviceData.form.dateOfBirth' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          id="dateOfBirth"
          formControlName="dateOfBirth"
          appInputValidationStatus
          [nbDatepicker]="datepicker"
          [placeholder]="'serviceData.form.placeholders.dateFormat' | translate"
          data-cy="date-of-birth-input"
        />
        <nb-datepicker
          #datepicker
          [max]="maxDate"
          [min]="minDate"
          [date]="birthdayStartDate"
          [format]="DATEPICKER_DATE_FORMAT"
        ></nb-datepicker>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="dateOfBirth"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div *appRequirePermission="'serviceData.edit.documentNumber'" class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="documentNumber">{{
          'serviceData.form.documentNumber' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="documentNumber"
          id="documentNumber"
          appInputValidationStatus
          [placeholder]="
            'serviceData.form.placeholders.documentNumber' | translate
          "
          data-cy="document-number-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="documentNumber"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>
<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="email">{{ 'serviceData.form.email' | translate }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="email"
          id="email"
          appInputValidationStatus
          [placeholder]="'serviceData.form.placeholders.email' | translate"
          data-cy="email-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="email"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="phoneNumber">{{
          'serviceData.form.phoneNumber' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="phoneNumber"
          id="phoneNumber"
          appInputValidationStatus
          [placeholder]="
            'serviceData.form.placeholders.phoneNumber' | translate
          "
          data-cy="phone-number-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="phoneNumber"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>
<div
  *appRequirePermission="'serviceData.edit.canEditAllFields'"
  class="row form-group"
>
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="height">{{ 'serviceData.form.height' | translate }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="height"
          id="height"
          type="number"
          appInputValidationStatus
          [placeholder]="'serviceData.form.placeholders.height' | translate"
          data-cy="height-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="height"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="weight">{{ 'serviceData.form.weight' | translate }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="weight"
          id="weight"
          type="number"
          appInputValidationStatus
          [placeholder]="'serviceData.form.placeholders.weight' | translate"
          data-cy="weight-input"
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="weight"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>

@if (highBMI$ | async) {
  <div class="row form-group justify-content-end">
    <div class="notes col-10">
      {{ 'serviceData.form.highBMI' | translate }}
    </div>
  </div>
}
