import { HttpHeaders, HttpParams } from '@angular/common/http';
import { PAGING_HEADERS } from '@app/app.constants';
import { ApiFilters, ApiHeaders, SortDirection } from '@core/core.types';
import { Util } from '@core/utils/core.util';
import { TreatmentFilters as TreatmentFiltersType } from '@treatments/treatment.types';

export class HttpUtils {
  static buildHttpParams(filter: ApiFilters = {}) {
    let params = new HttpParams().appendAll(
      Util.RemoveEmptyProperties({ ...filter })
    );

    if (filter.ids?.length) {
      params = params.set(TreatmentFiltersType.Ids, filter.ids.join(','));
    }

    return params;
  }

  static buildHttpHeaders(headers: ApiHeaders) {
    return new HttpHeaders(
      Util.RemoveEmptyProperties({
        [PAGING_HEADERS.continuationToken]: headers.continuationToken,
        [PAGING_HEADERS.orderBy]: headers.orderBy,
        [PAGING_HEADERS.orderDirection]:
          headers.orderDirection || SortDirection.desc,
        [PAGING_HEADERS.top]: headers.pagingTop ? headers.pagingTop + '' : 1000,
        [PAGING_HEADERS.select]: headers.select?.join(','),
      })
    );
  }
}
