/* eslint-disable @typescript-eslint/naming-convention */
export const permissions: { [role: string]: any } = {
  'NIPT-IT-development': {
    dashboard: {
      bgiExport: true,
      productInventoryItems: { columns: { runs: true } },
      documents: {
        documentsWaitingForApproval: {
          documentActions: true,
          buttons: {
            delete: true,
            approve: true,
            upload: true,
            bgiXlsImport: true,
            bgiForm: true,
            archive: true,
          },
          row: { buttons: { archive: true, openDetails: true } },
          selectable: true,
        },
        unclassifiedDocuments: {
          documentActions: true,
          archiveDocuments: true,
        },
      },
    },
    finance: { edit: { revokePaidInFull: true } },
    orders: true,
    coupons: true,
    customerInvites: true,
    configuration: {
      countries: { onlineReports: true },
      bundles: true,
      products: true,
      accountManagers: true,
    },
    kits: {
      extendedTable: true,
      buttons: {
        add: true,
        edit: true,
        delete: true,
        received: true,
        setRun: true,
        assignPartner: true,
        export: true,
      },
    },
    runs: { buttons: { edit: true, remove: true, export: true } },
    statistics: true,
    partners: {
      list: {
        buttons: {
          viewDetails: true,
          activeCheckbox: true,
          create: true,
          export: true,
          exportDoctors: true,
        },
        partnerStorage: true,
      },
      details: {
        view: { isOnline: true, notes: true },
        doctors: { add: true },
        activateDeactivate: true,
        products: { add: true, remove: true },
        bundles: { add: true, remove: true },
      },
      doctors: {
        notificationProfiles: {
          buttons: { editAndCreate: true },
          editForm: true,
        },
        edit: { submit: true },
        buttons: { export: true },
        row: {
          buttons: {
            remove: true,
            activateDeactivate: true,
            edit: true,
            notificationProfiles: true,
          },
        },
        selection: { multi: true },
      },
      edit: {
        products: true,
        isOnlinePartner: true,
        onlineReports: true,
        paymentType: true,
        showAllPaymentTypes: true,
      },
    },
    serviceData: {
      list: {
        buttons: {
          add: true,
          edit: true,
          details: true,
          delete: true,
          terminate: true,
          searchServiceData: true,
          export: true,
          paymentDetails: { revokePaidInFull: true },
        },
        button: { setInvoiceNumber: true },
        patientName: true,
        notes: true,
        payerName: true,
        paymentType: true,
        gpInvoice: true,
        paid: true,
      },
      details: {
        sample: {
          addSample: true,
          editSample: true,
          detailsButton: true,
          delete: true,
          details: {
            laboratory: true,
            gestationalWeek: true,
            waybillList: true,
            waybill: true,
            edit: { changeExportedDov: true, editDoctorId: true },
          },
          list: { laboratory: true },
        },
        buttons: { paymentDetails: true },
        patientName: true,
        dateOfBirth: true,
        gestationalWeek: true,
        expDateOfConfinement: true,
        documentNumber: true,
        genderInformation: true,
      },
      export: {
        fullExport: true,
        invoiceExport: true,
        analyticsExport: true,
        N2DStatistics: true,
        N2DCustomerOrders: true,
        consents: true,
        statistics: true,
      },
      edit: {
        canSelectAnyPaymentType: true,
        canEditAllFields: true,
        editDoctorId: true,
        documentNumber: true,
      },
    },
    sample: {
      list: {
        buttons: {
          details: true,
          edit: true,
          resample: true,
          searchSample: true,
          export: true,
          replaceKit: true,
        },
        laboratory: true,
        runs: true,
        trackingNumber: true,
        sampleNotes: true,
      },
      export: {
        logistics: true,
        informationSheet: true,
      },
      details: {
        sampleNotes: true,
      },
    },
    pii: {
      buttons: { details: true },
      list: {
        buttons: {
          searchPii: true,
          sendResultReferenceLinkEmail: true,
          showN2dStatistics: true,
          previewRequestForm: true,
          uploadDocuments: true,
          generateReports: true,
          export: true,
        },
        laboratory: true,
        trackingNumber: true,
        runs: true,
        dsv: true,
        bgiExported: true,
        reasonTooltip: true,
        highRiskIcon: true,
        highRiskFilter: true,
        documentReason: true,
      },
      export: {
        requestForms: true,
        informationSheet: true,
        BGIInformationSheet: true,
        reportExport: { notAnonymous: true },
        logistics: true,
        insurance: true,
        operations: true,
      },
      details: {
        documents: { buttons: { preview: true, actions: true, delete: true } },
        documentPreview: true,
        laboratory: true,
        documentsList: {
          showUnapproved: true,
          disapprove: { enabledAfterApproved: true },
        },
        statusReason: true,
      },
    },
    advancedSearch: {
      serviceData: true,
      samples: true,
      partners: true,
      pii: true,
    },
    invoiceExporter: true,
    documentViewer: true,
  },
  'NIPT-biotech-process-manager': {
    dashboard: {
      bgiExport: true,
      productInventoryItems: { columns: { runs: true } },
      documents: {
        documentsWaitingForApproval: {
          documentActions: true,
          buttons: {
            delete: true,
            approve: true,
            upload: true,
            bgiXlsImport: true,
            bgiForm: true,
            archive: true,
          },
          row: { buttons: { archive: true, openDetails: true } },
          selectable: true,
        },
        unclassifiedDocuments: {
          documentActions: true,
          archiveDocuments: true,
        },
      },
    },
    finance: { edit: { revokePaidInFull: true } },
    orders: true,
    coupons: true,
    customerInvites: true,
    configuration: {
      countries: { onlineReports: true },
      bundles: true,
      products: true,
      accountManagers: true,
    },
    kits: {
      extendedTable: true,
      buttons: {
        add: true,
        edit: true,
        delete: true,
        received: true,
        setRun: true,
        assignPartner: true,
        export: true,
      },
    },
    runs: { buttons: { edit: true, remove: true, export: true } },
    statistics: true,
    partners: {
      list: {
        buttons: {
          viewDetails: true,
          activeCheckbox: true,
          create: true,
          export: true,
          exportDoctors: true,
        },
        partnerStorage: true,
      },
      details: {
        view: { isOnline: true, notes: true },
        doctors: { add: true },
        activateDeactivate: true,
        products: { add: true, remove: true },
        bundles: { add: true, remove: true },
      },
      doctors: {
        notificationProfiles: {
          buttons: { editAndCreate: true },
          editForm: true,
        },
        edit: { submit: true },
        buttons: { export: true },
        row: {
          buttons: {
            remove: true,
            activateDeactivate: true,
            edit: true,
            notificationProfiles: true,
          },
        },
        selection: { multi: true },
      },
      edit: {
        products: true,
        isOnlinePartner: true,
        onlineReports: true,
        paymentType: true,
        showAllPaymentTypes: true,
      },
    },
    serviceData: {
      list: {
        buttons: {
          add: true,
          edit: true,
          details: true,
          delete: true,
          terminate: true,
          searchServiceData: true,
          export: true,
          paymentDetails: { revokePaidInFull: true },
        },
        button: { setInvoiceNumber: true },
        patientName: true,
        notes: true,
        payerName: true,
        paymentType: true,
        gpInvoice: true,
        paid: true,
      },
      details: {
        sample: {
          addSample: true,
          editSample: true,
          detailsButton: true,
          delete: true,
          details: {
            laboratory: true,
            gestationalWeek: true,
            waybillList: true,
            waybill: true,
            edit: { changeExportedDov: true, editDoctorId: true },
          },
          list: { laboratory: true },
        },
        buttons: { paymentDetails: true },
        patientName: true,
        dateOfBirth: true,
        gestationalWeek: true,
        expDateOfConfinement: true,
        documentNumber: true,
        genderInformation: true,
      },
      export: {
        fullExport: true,
        invoiceExport: true,
        analyticsExport: true,
        N2DStatistics: true,
        N2DCustomerOrders: true,
        consents: true,
        statistics: true,
      },
      edit: {
        canSelectAnyPaymentType: true,
        canEditAllFields: true,
        editDoctorId: true,
        documentNumber: true,
      },
    },
    sample: {
      list: {
        buttons: {
          details: true,
          edit: true,
          resample: true,
          searchSample: true,
          export: true,
          replaceKit: true,
        },
        laboratory: true,
        runs: true,
        trackingNumber: true,
        sampleNotes: true,
      },
      export: {
        logistics: true,
        informationSheet: true,
      },
      details: {
        sampleNotes: true,
      },
    },
    pii: {
      buttons: { details: true },
      list: {
        buttons: {
          searchPii: true,
          sendResultReferenceLinkEmail: true,
          showN2dStatistics: true,
          previewRequestForm: true,
          uploadDocuments: true,
          generateReports: true,
          export: true,
        },
        laboratory: true,
        trackingNumber: true,
        runs: true,
        dsv: true,
        bgiExported: true,
        reasonTooltip: true,
        highRiskIcon: true,
        highRiskFilter: true,
        documentReason: true,
      },
      export: {
        requestForms: true,
        informationSheet: true,
        BGIInformationSheet: true,
        reportExport: { notAnonymous: true },
        logistics: true,
        insurance: true,
        operations: true,
      },
      details: {
        documents: { buttons: { preview: true, actions: true, delete: true } },
        documentPreview: true,
        laboratory: true,
        documentsList: {
          showUnapproved: true,
          disapprove: { enabledAfterApproved: true },
        },
        statusReason: true,
      },
    },
    advancedSearch: {
      serviceData: true,
      samples: true,
      partners: true,
      pii: true,
    },
    invoiceExporter: true,
    documentViewer: true,
  },
  'NIPT-local': {
    dashboard: { productInventoryItems: true },
    orders: true,
    kits: { buttons: { add: true, edit: true, assignPartner: true } },
    statistics: true,
    partners: {
      list: {
        buttons: { create: true, viewDetails: true },
        partnerStorage: true,
      },
      details: {
        view: { notes: true },
        doctors: { add: true },
        products: { add: true, remove: true },
        bundles: { add: true, remove: true },
      },
      doctors: {
        notificationProfiles: {
          buttons: { editAndCreate: true },
          editForm: true,
        },
        edit: { submit: true },
        row: { buttons: { edit: true, notificationProfiles: true } },
        selection: { single: true },
      },
      edit: {
        paymentType: true,
        products: true,
      },
    },
    serviceData: {
      list: {
        buttons: {
          add: true,
          edit: true,
          details: true,
          searchServiceData: true,
        },
        patientName: true,
        notes: true,
      },
      details: {
        sample: {
          addSample: true,
          editSample: true,
          detailsButton: true,
          details: {
            laboratory: true,
            gestationalWeek: true,
            waybillList: true,
            waybill: true,
          },
          list: { laboratory: true },
        },
        patientName: true,
        dateOfBirth: true,
        gestationalWeek: true,
        expDateOfConfinement: true,
        documentNumber: true,
        genderInformation: true,
      },
      edit: {
        canSelectPartnerPaymentTypes: true,
        canEditAllFields: true,
        documentNumber: true,
      },
    },
    sample: {
      list: {
        buttons: {
          details: true,
          edit: true,
          resample: true,
          searchSample: true,
        },
        laboratory: true,
        trackingNumber: true,
      },
    },
    pii: {
      buttons: { details: true },
      list: {
        buttons: {
          searchPii: true,
          export: true,
        },
        laboratory: true,
        trackingNumber: true,
        dsv: true,
      },
      export: {
        reportExport: true,
      },
      details: {
        laboratory: true,
      },
    },
    advancedSearch: {
      serviceData: true,
      samples: true,
      partners: true,
      pii: true,
    },
    documentViewer: true,
  },
  'NIPT-doctors-support': {
    serviceData: {
      list: {
        buttons: {
          details: true,
        },
        notes: true,
      },
      details: {
        sample: {
          detailsButton: true,
          details: {
            laboratory: true,
            gestationalWeek: true,
            waybillList: true,
            waybill: true,
          },
          list: { laboratory: true },
        },
        patientName: true,
        gestationalWeek: true,
        expDateOfConfinement: true,
        documentNumber: true,
        genderInformation: true,
      },
    },
    sample: {
      list: {
        buttons: {
          details: true,
          searchSample: true,
        },
        laboratory: true,
        trackingNumber: true,
        sampleNotes: true,
      },
      details: {
        sampleNotes: true,
      },
    },
    pii: {
      buttons: { details: true },
      list: {
        laboratory: true,
        trackingNumber: true,
        dsv: true,
        reasonTooltip: true,
        highRiskIcon: true,
        highRiskFilter: true,
        documentReason: true,
      },
      details: {
        documents: { buttons: { preview: true } },
        documentPreview: true,
        laboratory: true,
        documentsList: true,
        statusReason: true,
      },
    },
    documentViewer: true,
  },
  'NIPT-accountant-manager': {
    finance: true,
    orders: true,
    partners: {
      details: { view: true },
      doctors: {
        row: { buttons: { edit: true } },
        selection: { multi: true, single: true },
      },
      edit: true,
      list: {
        buttons: { viewDetails: true, export: true, exportDoctors: true },
      },
    },
    serviceData: {
      list: {
        buttons: {
          export: true,
          details: true,
          paymentDetails: true,
          searchServiceData: true,
        },
        button: { setInvoiceNumber: true },
        patientName: true,
        payerName: true,
        paymentType: true,
        gpInvoice: true,
        paid: true,
      },
      details: {
        sample: { detailsButton: true },
        patientName: true,
        buttons: { paymentDetails: true },
      },
      export: { invoiceExport: true },
      edit: { canSelectAnyPaymentType: true },
    },
    pii: { buttons: { details: true }, list: { buttons: { searchPii: true } } },
    advancedSearch: {
      serviceData: true,
      samples: true,
      partners: true,
      pii: true,
    },
    invoiceExporter: true,
    documentViewer: true,
  },
  'NIPT-customer-support': {
    dashboard: {
      bgiExport: true,
      productInventoryItems: { columns: { runs: true } },
      documents: {
        documentsWaitingForApproval: {
          documentActions: true,
          buttons: {
            delete: true,
            approve: true,
            upload: true,
            bgiXlsImport: true,
            bgiForm: true,
            archive: true,
          },
          row: { buttons: { archive: true, openDetails: true } },
          selectable: true,
        },
        unclassifiedDocuments: {
          documentActions: true,
          archiveDocuments: true,
        },
      },
    },
    configuration: {
      countries: true,
    },
    kits: {
      extendedTable: true,
      buttons: { add: true, edit: true, assignPartner: true, export: true },
    },
    runs: true,
    partners: {
      list: {
        buttons: {
          viewDetails: true,
          activeCheckbox: true,
          create: true,
          export: true,
        },
        partnerStorage: true,
      },
      details: {
        view: { notes: true },
        doctors: { add: true },
        products: { add: true, remove: true },
        bundles: { add: true, remove: true },
      },
      doctors: {
        notificationProfiles: {
          buttons: { editAndCreate: true },
          editForm: true,
        },
        edit: { submit: true },
        row: { buttons: { edit: true, notificationProfiles: true } },
        selection: {
          single: true,
        },
      },
      edit: {
        products: true,
      },
    },
    serviceData: {
      list: {
        buttons: {
          add: true,
          edit: true,
          details: true,
          delete: true,
          searchServiceData: true,
          export: true,
        },
        patientName: true,
        notes: true,
      },
      details: {
        sample: {
          addSample: true,
          editSample: true,
          detailsButton: true,
          delete: true,
          details: {
            laboratory: true,
            gestationalWeek: true,
            waybillList: true,
            waybill: true,
          },
          list: { laboratory: true },
        },
        patientName: true,
        dateOfBirth: true,
        gestationalWeek: true,
        expDateOfConfinement: true,
        documentNumber: true,
        genderInformation: true,
      },
      export: { consents: true },
      edit: {
        canSelectPartnerPaymentTypes: true,
        canEditAllFields: true,
        documentNumber: true,
      },
    },
    sample: {
      list: {
        buttons: {
          details: true,
          edit: true,
          resample: true,
          searchSample: true,
          export: true,
        },
        laboratory: true,
        runs: true,
        trackingNumber: true,
        sampleNotes: true,
      },
      export: {
        logistics: true,
        informationSheet: true,
      },
      details: {
        sampleNotes: true,
      },
    },
    pii: {
      buttons: { details: true },
      list: {
        buttons: {
          searchPii: true,
          sendResultReferenceLinkEmail: true,
          previewRequestForm: true,
          uploadDocuments: true,
          generateReports: true,
          export: true,
        },
        laboratory: true,
        trackingNumber: true,
        runs: true,
        dsv: true,
        bgiExported: true,
        reasonTooltip: true,
        highRiskIcon: true,
        highRiskFilter: true,
        documentReason: true,
      },
      export: {
        requestForms: true,
        informationSheet: true,
        BGIInformationSheet: true,
        logistics: true,
        operations: true,
      },
      details: {
        documents: { buttons: { preview: true, actions: true } },
        documentPreview: true,
        laboratory: true,
        documentsList: { showUnapproved: true, disapprove: true },
        statusReason: true,
      },
    },
    advancedSearch: {
      serviceData: true,
      samples: true,
      partners: true,
      pii: true,
    },
    invoiceExporter: true,
    documentViewer: true,
  },
  'NIPT-lab-operations': {
    dashboard: {
      bgiExport: false,
      productInventoryItems: { columns: { runs: true } },
      documents: {
        documentsWaitingForApproval: {
          documentActions: true,
          buttons: {
            delete: false,
            approve: true,
            upload: true,
            bgiXlsImport: true,
            bgiForm: true,
            archive: true,
          },
          row: { buttons: { archive: true, openDetails: true } },
          selectable: true,
        },
        unclassifiedDocuments: {
          documentActions: true,
          archiveDocuments: true,
        },
      },
    },
    runs: { buttons: { edit: true, export: true } },
    partners: {
      details: { products: true, bundles: true },
      doctors: {
        notificationProfiles: {
          buttons: { editAndCreate: true },
          editForm: true,
        },
        edit: { submit: true },
        row: { buttons: { edit: true, notificationProfiles: true } },
        selection: {
          single: true,
        },
      },
      edit: true,
      list: {
        buttons: { viewDetails: true, export: true },
        partnerStorage: true,
      },
    },
    serviceData: {
      list: {
        buttons: {
          add: true,
          edit: true,
          details: true,
          export: true,
          searchServiceData: true,
        },
        patientName: true,
      },
      details: {
        sample: {
          addSample: true,
          editSample: true,
          detailsButton: true,
          delete: true,
          details: {
            laboratory: true,
            gestationalWeek: true,
            waybillList: true,
            waybill: true,
          },
          list: { laboratory: true },
        },
        patientName: true,
        dateOfBirth: true,
        gestationalWeek: true,
        expDateOfConfinement: true,
        documentNumber: true,
        genderInformation: true,
      },
      export: { invoiceExport: true, consents: true },
      edit: {
        canSelectPartnerPaymentTypes: true,
        canEditAllFields: true,
        documentNumber: true,
      },
    },
    sample: {
      list: {
        buttons: {
          details: true,
          edit: true,
          resample: true,
          searchSample: true,
          export: true,
        },
        laboratory: true,
        runs: true,
        trackingNumber: true,
        sampleNotes: true,
      },
      export: {
        informationSheet: true,
      },
      details: {
        sampleNotes: true,
      },
    },
    pii: {
      buttons: { details: true },
      list: {
        buttons: {
          sendResultReferenceLinkEmail: true,
          previewRequestForm: true,
          uploadDocuments: true,
          generateReports: true,
          export: true,
          searchPii: true,
        },
        laboratory: true,
        trackingNumber: true,
        runs: true,
        dsv: true,
        reasonTooltip: true,
        highRiskIcon: true,
        highRiskFilter: true,
        documentReason: true,
      },
      export: { informationSheet: true, insurance: true },
      details: {
        documents: { buttons: { preview: true, actions: true } },
        documentPreview: true,
        laboratory: true,
        documentsList: { showUnapproved: true, disapprove: true },
        statusReason: true,
      },
    },
    advancedSearch: {
      serviceData: true,
      samples: true,
      partners: true,
      pii: true,
    },
    kits: {
      buttons: {
        add: true,
        edit: true,
        received: true,
        setRun: true,
        assignPartner: true,
        export: true,
      },
    },
    invoiceExporter: true,
    documentViewer: true,
  },
  'NIPT-lab-worker': {
    dashboard: {
      productInventoryItems: { columns: { runs: true } },
      documents: {
        documentsWaitingForApproval: { selectable: true },
        unclassifiedDocuments: true,
      },
    },
    kits: { extendedTable: true },
    runs: {
      buttons: {
        edit: true,
        export: true,
      },
    },
    serviceData: {
      list: {
        buttons: {
          edit: true,
          details: true,
          export: true,
          searchServiceData: true,
        },
        patientName: true,
        notes: true,
      },
      details: {
        sample: {
          editSample: true,
          detailsButton: true,
          details: {
            laboratory: true,
            gestationalWeek: true,
            waybillList: true,
            waybill: true,
          },
          list: { laboratory: true },
        },
        patientName: true,
        dateOfBirth: true,
        gestationalWeek: true,
        expDateOfConfinement: true,
        documentNumber: true,
        genderInformation: true,
      },
      export: { consents: true },
      edit: { canEditAllFields: true, documentNumber: true },
    },
    sample: {
      list: {
        buttons: {
          details: true,
          edit: true,
          searchSample: true,
        },
        laboratory: true,
        runs: true,
        trackingNumber: true,
        sampleNotes: true,
      },
      details: {
        sampleNotes: true,
      },
    },
    advancedSearch: {
      serviceData: true,
      samples: true,
      pii: true,
    },
    pii: {
      buttons: { details: true },
      list: {
        buttons: {
          previewRequestForm: true,
          uploadDocuments: true,
          generateReports: true,
          searchPii: true,
        },
        laboratory: true,
        trackingNumber: true,
        runs: true,
        dsv: true,
      },
      details: { laboratory: true },
    },
    documentViewer: true,
  },
  'NIPT-marketing': { orders: true },
  'NIPT-invoice-exporter': {
    invoiceExporter: true,
    partners: {
      list: {
        buttons: {
          export: true,
          exportDoctors: true,
        },
      },
    },
    serviceData: {
      details: {
        sample: {
          delete: true,
        },
      },
      list: {
        buttons: {
          export: true,
          delete: true,
        },
      },
      export: {
        invoiceExport: true,
      },
    },
  },
  'NIPT-high-risk-viewer': {
    pii: {
      details: {
        statusReason: true,
      },
      list: {
        reasonTooltip: true,
        highRiskIcon: true,
        highRiskFilter: true,
        documentReason: true,
      },
    },
  },
};
