<nb-card>
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ 'serviceData.details.title' | translate }}
    </p>
    <nb-icon
      (click)="dialogRef.close()"
      icon="close"
      data-cy="sd-details-close-icon"
    ></nb-icon>
  </nb-card-header>
  <nb-card-body>
    @if (extendedServiceData$ | async; as serviceData) {
      <!--      Personal information-->
      <div class="d-flex justify-content-between align-items-end mb-1">
        <p class="caption mb-1">
          {{ 'common.titleBaseInformation' | translate }}
        </p>
        <div class="text-end col-3">
          <ng-container
            *appRequirePermission="'serviceData.details.buttons.paymentDetails'"
          >
            <button
              nbButton
              class="p-1"
              nbTooltip="{{
                'serviceData.list.buttons.paymentDetails' | translate
              }}"
              nbTooltipPlacement="left"
              status="primary"
              size="small"
              type="submit"
              (click)="openPaymentDetails(serviceData.id)"
            >
              <nb-icon icon="credit-card-outline"></nb-icon>
            </button>
          </ng-container>
          <ng-container *appRequirePermission="'serviceData.list.buttons.edit'">
            <button
              nbButton
              class="p-1 ms-1"
              (click)="editServiceData(serviceData.id)"
              nbTooltip="{{ 'serviceData.list.buttons.edit' | translate }}"
              nbTooltipPlacement="left"
              status="primary"
              size="small"
              type="submit"
            >
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </ng-container>
        </div>
      </div>
      <hr class="mt-0" />
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="partner-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.referenceKey' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.referenceKey }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="partner-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.partner' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.partner?.name }}
          </div>
        </div>
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="country-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.country' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.partner?.address.country }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="generated-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.generated' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.generated | date }}
          </div>
        </div>
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="bundles-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.bundles' | translate
          }}</label>
          <div
            class="col-8"
            [innerHTML]="serviceData.bundleIds | getList: (bundles$ | async)"
          ></div>
        </div>
      </div>
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="last-status-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.lastStatus' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.status }}
          </div>
        </div>
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="last-status-change-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.lastStatusChanged' | translate
          }}</label>

          <div class="col-8">
            {{ serviceData.statusChanged | date }}
          </div>
        </div>
      </div>
      <div class="row">
        <div
          *appRequirePermission="'serviceData.details.patientName'"
          class="col-12 col-md-6 row align-items-center mb-2"
          data-cy="name-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.name' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.patientInformation.firstName }}
            {{ serviceData.patientInformation.lastName }}
          </div>
        </div>
        <div
          *appRequirePermission="'serviceData.details.dateOfBirth'"
          class="col-12 col-md-6 row align-items-center mb-2"
          data-cy="date-of-birth-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.dateOfBirth' | translate
          }}</label>

          <div class="col-8">
            {{ serviceData.patientInformation.dateOfBirth | date }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="height-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.height' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.patientInformation.height }} cm
          </div>
        </div>
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="weight-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.weight' | translate
          }}</label>

          <div class="col-8">
            {{ serviceData.patientInformation.weight }} kg
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md-6 row align-items-center" data-cy="phone-row">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.phone' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.patientInformation.phoneNumber }}
          </div>
        </div>
        <div class="col-12 col-md-6 row align-items-center" data-cy="email-row">
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.email' | translate
          }}</label>

          <div class="col-8">
            {{ serviceData.patientInformation.email }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        @if (serviceData.patientInformation.documentNumber) {
          <div
            *appRequirePermission="'serviceData.details.documentNumber'"
            class="col-12 col-md-6 row align-items-center"
            data-cy="document-number-row"
          >
            <label class="label col-4 mb-0 text-end">{{
              'serviceData.details.documentNumber' | translate
            }}</label>
            <div class="col-8">
              {{ serviceData.patientInformation.documentNumber }}
            </div>
          </div>
        }
      </div>
      <!--      End Personal information-->
      <!--      Nipt service information-->
      <div class="d-flex justify-content-between align-items-end">
        <p class="caption mb-1">
          {{ 'serviceData.details.niptServiceInformation' | translate }}
        </p>
      </div>
      <hr class="mt-0" />
      @if (serviceData.dateOfService) {
        <div class="row mb-2">
          <div
            class="col-12 col-md-6 row align-items-center"
            data-cy="doctor-row"
          >
            <label class="label col-4 mb-0 text-end">{{
              'serviceData.details.nipt.dateOfService' | translate
            }}</label>
            <div class="col-8">
              {{ serviceData.dateOfService | date }}
            </div>
          </div>
        </div>
      }
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="doctor-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.nipt.doctor' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.doctor?.name }}
          </div>
        </div>
        <div
          *appRequirePermission="'serviceData.details.genderInformation'"
          class="col-12 col-md-6 row align-items-center"
          data-cy="gender-info-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.nipt.genderInformation' | translate
          }}</label>

          <div class="col-8">
            {{
              serviceData.serviceInformation.nipt.provideGenderInformation
                ? 'YES'
                : 'NO'
            }}
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="pregnancy-type-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.nipt.pregnancyType' | translate
          }}</label>

          <div class="col-8">
            {{
              serviceData.serviceInformation.nipt.currentPregnancyInformation
                .pregnancyType
            }}
          </div>
        </div>
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="incidental-findings-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.nipt.incidentalFindings' | translate
          }}</label>
          <div class="col-8">
            {{
              serviceData.serviceInformation.nipt.incidentalFindings
                ? 'YES'
                : 'NO'
            }}
          </div>
        </div>
      </div>
      <div class="row">
        <div
          *appRequirePermission="'serviceData.details.expDateOfConfinement'"
          class="col-12 col-md-6 row align-items-center mb-2"
          data-cy="due-date-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.nipt.estimatedDueDate' | translate
          }}</label>
          <div class="col-8">
            {{
              serviceData.serviceInformation.nipt.currentPregnancyInformation
                .expectedConfinementDate | date
            }}
          </div>
        </div>
        <div
          *appRequirePermission="'serviceData.details.gestationalWeek'"
          class="col-12 col-md-6 row align-items-center mb-2"
          data-cy="gestation-age-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.nipt.gestationAge' | translate
          }}</label>
          <div class="col-8">
            {{
              serviceData.serviceInformation.nipt.currentPregnancyInformation
                .gestationAge.week
            }}W
            {{
              serviceData.serviceInformation.nipt.currentPregnancyInformation
                .gestationAge.day
            }}D
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-end">
        <p class="caption mb-1">
          {{ 'serviceData.details.commentsAndNotes' | translate }}
        </p>
      </div>
      <hr class="mt-0" />
      <div class="row mb-2">
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="partner-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.patientState' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.serviceInformation.nipt.comments || '—' }}
          </div>
        </div>
        <div
          class="col-12 col-md-6 row align-items-center"
          data-cy="partner-row"
        >
          <label class="label col-4 mb-0 text-end">{{
            'serviceData.details.notes' | translate
          }}</label>
          <div class="col-8">
            {{ serviceData.notes || '—' }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-end mb-1">
        <p class="caption mb-1">
          {{ 'serviceData.details.samples' | translate }}
        </p>
        <div class="text-end col-3">
          <button
            nbButton
            class="p-1"
            (click)="manageSamples(serviceData.id, serviceData.sampleIds)"
            nbTooltip="{{ 'serviceData.details.manageSamples' | translate }}"
            nbTooltipPlacement="left"
            status="primary"
            size="small"
            type="submit"
          >
            <nb-icon icon="droplet-outline"></nb-icon>
          </button>
        </div>
      </div>
      <hr class="mt-0" />
      <app-samples-list
        [serviceDataId]="serviceData.id"
        [readonly]="true"
      ></app-samples-list>

      <!--      End Nipt service information-->
    } @else {
      <div class="py-4 loading"></div>
    }
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton (click)="dialogRef.close()" data-cy="sd-details-close-btn">
      {{ 'common.buttons.btnClose' | translate }}
    </button>
  </nb-card-footer>
</nb-card>
