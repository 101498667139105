import { Injectable } from '@angular/core';
import { Logger } from '@core/services/logger.service';
import { LOCAL_STORAGE_KEYS } from '../../../app.constants';
import { Credentials } from '../core.types';

@Injectable()
export class LocalStorageService {
  private readonly log = new Logger(this.constructor.name);

  saveLanguageInLocalStorage(lang: string) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.language, lang);
  }

  getLanguageFromLocalStorage(): string {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.language);
  }

  saveCredentialsToLocalStorage(token: Credentials) {
    this.saveAccessTokenToLocalStorage(token.accessToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.refreshToken, token.refreshToken);
  }

  getCredentialsFromLocalStorage(): Credentials {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.refreshToken);
    let userId = null;
    try {
      const { sub } = JSON.parse(atob(accessToken.split('.')[1]));
      userId = sub;
    } catch (e) {
      // this.log.error(e);
    }
    return { accessToken, refreshToken, userId };
  }

  getRefreshToken() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.refreshToken);
  }

  saveAccessTokenToLocalStorage(accessToken: string) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.accessToken, accessToken);
  }

  clearLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.accessToken);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.refreshToken);
  }
}
