import {
  Treatment,
  TreatmentDialogExtended,
} from '@treatments/treatment.types';
import {
  TreatmentFilters,
  TreatmentHeaders,
} from '@core/services/treatments.service.new';
import { Properties } from '@core/core.types';

export const TREATMENTS_TYPE_NAME = 'treatments';

export class LoadTreatmentsAction {
  static type = `[${TREATMENTS_TYPE_NAME}] LoadTreatments`;

  constructor(
    public filters: TreatmentFilters,
    public headers: TreatmentHeaders,
    public pageToLoad?: number,
    public reload: boolean = false
  ) {}
}

export class LoadTreatmentsByPiiIdAction {
  static type = `[${TREATMENTS_TYPE_NAME}] LoadTreatmentsByPiiId`;

  constructor(public piiId: string, public refresh = false) {}
}

export class AddTreatment {
  static type = `[${TREATMENTS_TYPE_NAME}] AddTreatment`;

  constructor(public treatment: Treatment & TreatmentDialogExtended) {}
}

export class UpdateTreatment {
  static type = `[${TREATMENTS_TYPE_NAME}] UpdateTreatment`;

  constructor(public treatment: Treatment) {}
}

export class DeleteTreatment {
  static type = `[${TREATMENTS_TYPE_NAME}] DeleteTreatment`;

  constructor(public treatmentId: string) {}
}

export class DeactivateTreatment {
  static type = `[${TREATMENTS_TYPE_NAME}] DeactivateTreatment`;

  constructor(public treatmentId: string) {}
}

export class ReactivateTreatment {
  static type = `[${TREATMENTS_TYPE_NAME}] ReactivateTreatment`;

  constructor(public treatmentId: string) {}
}

export class MarkTreatmentAsFailed {
  static type = `[${TREATMENTS_TYPE_NAME}] MarkTreatmentAsFailed`;

  constructor(public treatmentId: string, public failureReason: string) {}
}

export class SetTreatmentNote {
  static type = `[${TREATMENTS_TYPE_NAME}] SetTreatmentNotes`;

  constructor(public treatmentId: string, public notes: string) {}
}

export class SetDoctorInvoiceNumber {
  static type = `[${TREATMENTS_TYPE_NAME}] SetDoctorInvoiceNumber`;

  constructor(public treatmentId: string, public invoiceNumber: string) {}
}

export class SetGeneplanetInvoiceNumber {
  static type = `[${TREATMENTS_TYPE_NAME}] SetGeneplanetInvoiceNumber`;

  constructor(public treatmentId: string, public invoiceNumber: string) {}
}

export class SetLaboratoryInvoiceNumber {
  static type = `[${TREATMENTS_TYPE_NAME}] SetLaboratoryInvoiceNumber`;

  constructor(public treatmentId: string, public invoiceNumber: string) {}
}

export class SetTreatmentsByIds {
  static type = `[${TREATMENTS_TYPE_NAME}] SetTreatmentsByIds`;

  constructor(public treatments: Treatment[], public prependOnPage = false) {}
}

export class LoadTreatmentById {
  static type = `[${TREATMENTS_TYPE_NAME}] LoadTreatmentById`;

  constructor(
    public treatmentId: string,
    public refresh = false,
    public prependToPage = false
  ) {}
}


export class SearchTreatments {
  static type = `[${TREATMENTS_TYPE_NAME}] SearchTreatments`;

  constructor(
    public searchFilters: {
      ids: string;
      kitToken: string;
      patientNameToken: string;
      patientEmail: string;
    }
  ) {}
}

export class LoadTreatmentsStatisticsAction {
  static type = `[${TREATMENTS_TYPE_NAME}] LoadTreatmentsStatistics`;

  constructor(public filters: TreatmentFilters, public type: string) {}
}

export class MarkTreatmentsAsExported {
  static type = `[${TREATMENTS_TYPE_NAME}] MarkTreatmentsAsExported`;

  constructor(public ids: string[]) {}
}

export class AddDocumentToTreatmentAction {
  static type = `[${TREATMENTS_TYPE_NAME}] AddDocumentToTreatment`;

  constructor(
    public documentId: string,
    public fileName: string,
    public base64EncodedFile: string,
    public treatment?: Treatment,
    public documentProperties?: Properties[]
  ) {}
}

export class AddDocumentsToTreatmentsAction {
  static type = `[${TREATMENTS_TYPE_NAME}] AddDocumentsToTreatments`;

  constructor(
    public addDocumentsList: {
      guid: string;
      fileName: string;
      data: string;
      kitId: string;
      treatment?: Treatment;
      documentProperties?: Properties[];
    }[]
  ) {}
}

export class AssignDocumentToTreatmentAction {
  static type = `[${TREATMENTS_TYPE_NAME}] AssignDocumentToTreatment`;

  constructor(public treatmentId: string, public documentId: string) {}
}

export class AssignDocumentsToTreatmentsAction {
  static type = `[${TREATMENTS_TYPE_NAME}] AssignDocumentsToTreatments`;

  constructor(
    public documentsList: { treatmentId: string; documentId: string }[]
  ) {}
}

export class MarkTreatmentAsResampleAction {
  static type = `[${TREATMENTS_TYPE_NAME}] MarkTreatmentAsResample`;

  constructor(public treatmentId: string, public reason: string) {}
}

export class ReturnKitToStockAction {
  static type = `[${TREATMENTS_TYPE_NAME}] ReturnKitToStock`;

  constructor(public treatment: Treatment) {}
}

export class SetTreatmentStatus {
  static type = `[${TREATMENTS_TYPE_NAME}] SetTreatmentStatus`;

  constructor(public treatmentId: string, public status: string) {}
}

export class RemoveTreatmentFromState {
  static type = `[${TREATMENTS_TYPE_NAME}] RemoveTreatmentFromState`;

  constructor(public treatmentId: string) {}
}
