import { SortDirection } from '@core/core.types';
import { PatientFormsFilter } from '@app/modules/patient-forms/state/patient-forms.state';
import { PatientFormInvite } from '@app/modules/patient-forms/patient-forms.types';

export const PATIENT_FORMS_STATE_TYPE_NAME = 'patientFormsState';

export class LoadPatientFormsAction {
  static type = `[${PATIENT_FORMS_STATE_TYPE_NAME}] LoadPatientForms`;

  constructor(
    public options: {
      pageSize?: number;
      pageToLoad?: number;
      orderBy?: string;
      orderDirection?: SortDirection;
      filter?: PatientFormsFilter;
    } = {}
  ) {}
}

export class ProvisionPatientFormAction {
  static type = `[${PATIENT_FORMS_STATE_TYPE_NAME}] ProvisionPatientForm`;

  constructor(public data: PatientFormInvite) {}
}

export class ProvisionPatientFormUpdateAction {
  static type = `[${PATIENT_FORMS_STATE_TYPE_NAME}] ProvisionPatientUpdateForm`;

  constructor(public data: PatientFormInvite) {}
}

export class CompletePatientFormAction {
  static type = `[${PATIENT_FORMS_STATE_TYPE_NAME}] CompletePatientForm`;

  constructor(public patientFormId: string) {}
}
