import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BundleConfiguration } from '@core/core.types';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BundleConfigurationService {
  private apiUrl = `${environment.apiUrl}/bundle-configuration`;
  constructor(private http: HttpClient) {}

  public getBundleConfigurationById(
    bundleConfigurationId: string
  ): Observable<BundleConfiguration> {
    return this.http.get<BundleConfiguration>(
      `${this.apiUrl}/${bundleConfigurationId}`
    );
  }
}
