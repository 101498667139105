import { KitsListFilterSettings } from '@core/store/kits/kits.state';
import { Kit, SortDirection } from '../../core.types';

export class ClearKitsStateAction {
  static type = '[Kits] ClearKitsState';
}

export class LoadPartnerKitsAction {
  static readonly type = '[Kits] LoadPartnerKits';
  constructor(
    public options: {
      pageToLoad?: number;
      orderBy?: string;
      orderDirection?: SortDirection;
      filter?: KitsListFilterSettings;
      pageSize?: number;
    },
    public refresh = false
  ) {}
}

export class LoadPartnerFreeKitsAction {
  static readonly type = '[Kits] LoadPartnerFreeKitsAction';
  constructor(public partnerId: string) {}
}

export class LoadPartnersFreeKitsAction {
  static readonly type = '[Kits] LoadPartnersFreeKitsAction';
  constructor(public partnerIds: string[]) {}
}

export class LoadPartnersFreeKitsCountAction {
  static readonly type = '[Kits] LoadPartnersFreeKitsCount';
  constructor(public partnerIds: string[]) {}
}

export class AddKitsToPartnerAction {
  static readonly type = '[Kits] AddKitsToPartner';

  constructor(public payload: { kitsList: Kit[] }) {}
}

export class UpdateKitsAction {
  static readonly type = '[Kits] UpdateKits';
  constructor(public payload: { kitsList: Kit[] }) {}
}

export class SetKitStateReturnedAction {
  static readonly type = '[Kits] SetKitStateReturned';
  constructor(public payload: { kitId: string; date: Date }) {}
}

export class AssignPartnerToKits {
  static readonly type = '[Kits] AssignToPartner';
  constructor(public payload: { partnerId: string; kitsList: Kit[] }) {}
}

export class UnassignProductInventoryItem {
  static readonly type = '[Kits] UnassignProductInventoryItem';
  constructor(
    public kitId,
    public productInventoryId
  ) {}
}

export class LoadKitById {
  static type = '[Kits] LoadKitById';
  constructor(
    public kitId: string,
    public refresh = false
  ) {}
}

export class LoadKitsByIdAction {
  static type = '[Kits] LoadKitsById';
  constructor(
    public kitsIds: string[],
    public refresh = false
  ) {}
}

export class LoadAllPartnerKitsAction {
  static type = '[Kits] LoadAllPartnerKitsAction';
  constructor(
    public partnerId: string,
    public cache: boolean = true
  ) {}
}

export class DeleteKitsAction {
  static readonly type = '[Kits] DeleteKits';
  constructor(public payload: { kitsList: Kit[] }) {}
}

export class SetKitsById {
  static readonly type = '[Kits] SetKitsById';
  constructor(public kits: Kit | Kit[]) {}
}

export class LoadKitCollectorTypesAction {
  static readonly type = '[Kits] LoadKitCollectorTypesAction';
  constructor() {}
}

export class ClearKitCollectorTypesAction {
  static readonly type = '[Kits] ClearKitCollectorTypesAction';
  constructor() {}
}
