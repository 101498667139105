import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { LoadProductInventoryById } from '../../treatments/store/product-inventory/product-inventory.action';
import { ProductInventoryState } from '../../treatments/store/product-inventory/product-inventory.state';
import {
  PriceContract,
  Product,
  ProductConfiguration,
  ProductInventory,
} from '../core.types';
import { LoadProductConfigurationById } from '../store/product-configuration/product-configuration.actions';
import { ProductConfigurationsState } from '../store/product-configuration/product-configuration.state';
import { ProductsListState } from '../store/products/products.state';
import { Logger } from './logger.service';
import { PantheonService } from './pantheon.service';
import { ProductsService } from './products.service';
import { PregnancyType } from '@treatments/treatment.types';

@Injectable({
  providedIn: 'root',
})
export class ProductUtilsService {
  private readonly log = new Logger(this.constructor.name);

  private readonly productsOrder = [
    'pro',
    'plus',
    'standard',
    'basic',
    'twins',
  ];

  constructor(
    private store: Store,
    private productsService: ProductsService,
    private pantheonService: PantheonService
  ) {}

  getProductById$(productId: string): Observable<Product> {
    return this.store.select(ProductsListState.getProduct).pipe(
      map((filterByProduct) => filterByProduct(productId)),
      distinctUntilChanged()
    );
  }

  getProductConfigurationById$(
    productConfigurationId: string
  ): Observable<ProductConfiguration> {
    this.log.debug(
      'resolve product configuration by id',
      productConfigurationId
    );
    if (productConfigurationId) {
      return this.store
        .dispatch(new LoadProductConfigurationById(productConfigurationId))
        .pipe(
          switchMap(() =>
            this.store
              .select(ProductConfigurationsState.getProductConfigurationById)
              .pipe(
                map(
                  (
                    filterByProductConfigurationId: (
                      id: string
                    ) => ProductConfiguration
                  ) => filterByProductConfigurationId(productConfigurationId)
                ),
                filter((pc) => pc !== undefined)
              )
          )
        );
    }
    return of(null);
  }

  getPriceContactsFromPantheonByPartnerExternalId$(
    externalId: string
  ): Observable<PriceContract[]> {
    if (!externalId) {
      return of([]);
    }
    return this.pantheonService.getPriceContactsFromPantheonByPartnerExternalId(
      externalId
    );
  }

  getBundlesNumberOfFetuses(products: Product[]): PregnancyType[] {
    if (products.length === 0) return [];

    // Initialize a set with the types of the first product
    let commonTypes = new Set(Object.values(PregnancyType));

    // Iterate over the rest of the products
    for (const product of products) {
      const productTypesSet = new Set(product.productSubTypes);

      // Intersect the sets
      commonTypes = new Set(
        [...commonTypes].filter((type) => productTypesSet.has(type))
      );
    }

    // Convert the set back to an array
    return Array.from(commonTypes);
  }

  public productOrder(a: string, b: string): number {
    return this.productsOrder.indexOf(a.toLowerCase().split(' ')[1]) <
      this.productsOrder.indexOf(b.toLowerCase().split(' ')[1])
      ? -1
      : 1;
  }
}
