import { Injectable } from '@angular/core';
import { AwbData } from '@core/core.types';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@root/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AwbService {
  constructor(private http: HttpClient) {}

  generateDhlWaybill(data: {
    recipientLaboratory: string;
    fullName: string;
    companyName: string;
    street: string;
    city: string;
    postCode: string;
    countryCode: string;
    contactNumber: string;
  }): Observable<AwbData> {
    return this.http.post<AwbData>(
      `${environment.apiUrl}/shipping/waybill`,
      data
    );
  }

  getWaybill(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/shipping/waybill/${id}`);
  }
}
