import { Injectable } from '@angular/core';
import { SampleDialogComponent } from '@app/modules/service-data/components/sample/sample-dialog/sample-dialog.component';
import {
  ExcelFileName,
  ExportDialogDataType,
  ExportDialogType,
  ExportType,
  ExportTypeNames,
  ExtendedSample,
  Sample,
  SampleExtensions,
} from '@app/modules/service-data/service-data.types';
import { ServiceDataUtilsService } from '@app/modules/service-data/services/service-data-utils.service';
import { GetServiceDataAction } from '@app/modules/service-data/store/service-data.actions';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import { LoadKitById, LoadKitsByIdAction } from '@core/store/kits/kits.actions';
import { KitsState } from '@core/store/kits/kits.state';
import {
  LoadPartnerById,
  LoadPartnersByIds,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { ProductsListState } from '@core/store/products/products.state';
import { HttpUtils } from '@core/utils/http-utils';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first, map, switchMap } from 'rxjs/operators';
import { SampleDetailsComponent } from '../components/sample/sample-details/sample-details.component';
import { SampleResultsComponent } from '../components/sample/sample-results/sample-results.component';
import { SampleFilters } from './sample.service';
import { ExportersUtilsService } from '@core/services/exporters-utils.service';
import { CoreUtilsService } from '@core/utils/core-utils.service';
import { SampleKitChangeDialogComponent } from '@app/modules/service-data/components/sample/sample-kit-change-dialog/sample-kit-change-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SampleUtilsService {
  constructor(
    private store: Store,
    private serviceDataUtils: ServiceDataUtilsService,
    private nbDialogService: NbDialogService,
    private exportersUtilsService: ExportersUtilsService,
    private coreUtils: CoreUtilsService
  ) {}

  getExtendedSample(
    sample: Sample,
    appends: SampleExtensions = {
      partner: true,
      product: true,
      doctor: true,
      serviceData: null,
      kit: false,
    }
  ): Observable<ExtendedSample> {
    if (!sample) {
      return of(null);
    }
    return forkJoin([
      appends.partner
        ? this.store.dispatch(new LoadPartnerById(sample?.partnerId))
        : of(null),
      appends.doctor
        ? this.store.dispatch(
            new LoadPartnerById(sample?.serviceInformation.nipt.doctorId)
          )
        : of(null),
      appends.serviceData
        ? this.store.dispatch(new GetServiceDataAction(sample?.serviceDataId))
        : of(null),
      appends.kit
        ? this.store.dispatch(new LoadKitById(sample?.kitId))
        : of(null),
    ]).pipe(
      switchMap(() =>
        combineLatest([
          of(sample),
          this.store
            .select(ProductsListState.getProduct)
            .pipe(map((getProduct) => getProduct(sample?.productId))),
          this.store
            .select(PartnersState.getPartnerById)
            .pipe(map((getPartnerById) => getPartnerById(sample?.partnerId))),
          this.store
            .select(PartnersState.getPartnerById)
            .pipe(
              map((getPartnerById) =>
                getPartnerById(sample?.serviceInformation?.nipt?.doctorId)
              )
            ),
          appends.serviceData
            ? this.store
                .select(ServiceDataState.getServiceDataById)
                .pipe(
                  first(),
                  map((getServiceDataById) =>
                    getServiceDataById(sample?.serviceDataId)
                  )
                )
                .pipe(
                  switchMap((serviceData) =>
                    this.serviceDataUtils.getExtendedServiceData(
                      serviceData,
                      appends.serviceData
                    )
                  )
                )
            : of(null),
          this.store
            .select(KitsState.getKitById)
            .pipe(map((getKitById) => getKitById(sample?.kitId))),
        ])
      ),
      map(([sample, product, partner, doctor, serviceData, kit]) => ({
        ...sample,
        product,
        partner,
        doctor,
        serviceData,
        kit,
      }))
    );
  }

  getExtendedSampleList(
    sampleList: Sample[],
    appends: SampleExtensions
  ): Observable<ExtendedSample[]> {
    if (!sampleList.length) {
      return of([]);
    }

    const productIds: string[] = [];
    const kitIds: string[] = [];
    const partnerIds: string[] = [];
    sampleList.forEach((sample) => {
      productIds.push(sample?.productId);
      partnerIds.push(sample?.partnerId);
      partnerIds.push(sample?.serviceInformation?.nipt?.doctorId);
      kitIds.push(sample?.kitId);
    });

    return forkJoin([
      this.store.dispatch(new LoadPartnersByIds(partnerIds)),
      this.store.dispatch(new LoadKitsByIdAction(kitIds)),
    ]).pipe(
      switchMap(() =>
        combineLatest(
          sampleList.map((sample) => this.getExtendedSample(sample, appends))
        )
      )
    );
  }

  public openDetails(sampleId: string) {
    this.nbDialogService.open(SampleDetailsComponent, {
      context: {
        sampleId,
      },
    });
  }

  public openEdit(sampleId: string, serviceDataId: string) {
    this.nbDialogService.open(SampleDialogComponent, {
      context: {
        sampleId,
        serviceDataId,
      },
    });
  }

  public openResample(sampleId: string, serviceDataId: string, resampleKitId) {
    this.nbDialogService.open(SampleDialogComponent, {
      context: {
        sampleId,
        serviceDataId,
        resampleKitId,
      },
    });
  }

  public replaceKit(sampleId: string) {
    this.nbDialogService.open(SampleKitChangeDialogComponent, {
      context: {
        sampleId,
      },
    });
  }

  buildHttpParams(params: SampleFilters) {
    let httpParams = HttpUtils.buildHttpParams(params);

    if (params.kitIds?.length) {
      httpParams = httpParams.set('kitIds', params.kitIds.join(','));
    }

    if (params.serviceDataPartnerIds?.length) {
      httpParams = httpParams.set(
        'serviceDataPartnerIds',
        params.serviceDataPartnerIds.join(',')
      );
    }

    if (params.productIds?.length) {
      httpParams = httpParams.set('productIds', params.productIds.join(','));
    }

    return httpParams;
  }

  public openResults(sampleIds: string[]) {
    this.nbDialogService.open(SampleResultsComponent, {
      context: { sampleIds },
    });
  }

  public async exportNiptTests(
    exportDialogType: ExportDialogType,
    exportType: ExportTypeNames,
    ids?: string[],
    filters?: { [key: string]: string }
  ) {
    const progressSubject =
      this.exportersUtilsService.openProgressDialog(exportType);

    let success = true;

    if (exportDialogType === ExportDialogType.Selected) {
      if (exportType === ExportTypeNames.Export) {
        success = !!(await this.exportersUtilsService.exportSelected(
          ExportDialogDataType.Sample,
          ids,
          null,
          ExcelFileName.InformationSheet,
          true,
          false
        ));
      } else if (exportType === ExportTypeNames.Logistics) {
        success = !!(await this.exportersUtilsService.exportSelected(
          ExportDialogDataType.Sample,
          ids,
          ExportType.Logistics,
          ExcelFileName.Logistics
        ));
      }
    } else {
      if (exportType === ExportTypeNames.Export) {
        success = !!(await this.exportersUtilsService.export(
          filters,
          null,
          ExcelFileName.InformationSheet,
          true,
          false
        ));
      } else if (exportType === ExportTypeNames.Logistics) {
        success = !!(await this.exportersUtilsService.export(
          filters,
          ExportType.Logistics,
          ExcelFileName.Logistics
        ));
      } else {
        this.coreUtils.showErrowMessage(
          'Please provide required information for export.'
        );
      }
    }

    progressSubject.next({ value: 100, status: 'Done', success });

    return success;
  }
}
