<nb-card>
  <nb-card-header class="position-relative">
    <nb-icon (click)="cancel()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body class="pt-0 text-center">
    <div class="pt-4 mb-1 loading"></div>
    <small class="text-muted pdf-loading-text" translate>
      {{options.message | translate}}
    </small>
  </nb-card-body>
</nb-card>
