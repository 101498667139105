import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
})
export class AddEmailComponent {
  @Input() title = 'To';
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(private dialog: NbDialogRef<AddEmailComponent>) {}

  addEmail(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.emailControl.valid) {
      return;
    }
    const email = this.emailControl.value;
    this.dialog.close({ email });
  }

  close() {
    this.dialog.close();
  }
}
