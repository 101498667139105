<nb-card [style.width]="options.width ?? '30vw'">
  <nb-card-header class="position-relative">
    <p class="mb-0">
      {{ options.title | translate }}
    </p>
  </nb-card-header>
  <nb-card-body class="py-4">
    <div [innerHtml]="options.message | translate"></div>
  </nb-card-body>
  <nb-card-footer class="d-flex flex-row-reverse">
    <button nbButton status="basic" (click)="no()">No</button>
    <button class="me-2" nbButton status="primary" (click)="yes()">Yes</button>
  </nb-card-footer>
</nb-card>
