<div class="data-table h-100 d-flex flex-column" [class.collapsed]="collapsed">
  <ng-container *ngIf="loading">
    <div style="height: 100%" class="loader-overlay position-absolute">
      <nb-spinner status="primary" size="large"></nb-spinner>
    </div>
  </ng-container>
  <div
    class="data-table-header"
    *ngIf="
      configuration.title ||
      configuration.pagination$ ||
      configuration.headerFilters ||
      configuration?.addAction ||
      configuration.massActions?.length ||
      (configuration.singleActions?.length &&
        configuration.actionsLocation === DataTableActionsLocation.TOP)
    "
  >
    <ng-container *ngLet="configuration.pagination$ | async as pagination">
      <div class="title">
        <span
          (click)="collapse()"
          [class.cursor-pointer]="configuration.collapsable"
        >
          <nb-icon
            *ngIf="configuration.collapsable"
            [icon]="collapsed ? 'chevron-down-outline' : 'chevron-up-outline'"
          ></nb-icon>
          {{ configuration.title }}
        </span>
        <span *ngIf="pagination"
          >[{{ selectedRows.length }}/{{ pagination.totalCount ?? '?' }}]</span
        >
        <ng-template
          *ngTemplateOutlet="
            configuration.titleFilters;
            context: {
              selectedRows: [],
              totalCount: pagination?.totalCount ?? 0,
              pageSize: 30
            }
          "
        >
        </ng-template>
      </div>
      <div class="header-filters mt-3 collapsable">
        <ng-template
          *ngTemplateOutlet="
            configuration.headerFilters;
            context: {
              selectedRows: [],
              totalCount: pagination?.totalCount ?? 0,
              pageSize: 30
            }
          "
        >
        </ng-template>
      </div>
    </ng-container>
    <div class="actions mb-3 d-flex flex-row collapsable">
      <div>
        <app-data-table-nestable-actions
          *ngIf="configuration.addAction"
          [button]="configuration.addAction"
          [rows]="[]"
        >
        </app-data-table-nestable-actions>
        <ng-container
          *ngIf="configuration.actionsLocation === DataTableActionsLocation.TOP"
        >
          <app-data-table-nestable-actions
            *ngFor="let button of configuration.singleActions"
            [button]="button"
            type="single"
            [rows]="selectedRows"
          >
          </app-data-table-nestable-actions>
        </ng-container>
        <app-data-table-nestable-actions
          *ngFor="let button of configuration.massActions"
          [button]="button"
          [rows]="selectedRows"
        >
        </app-data-table-nestable-actions>
      </div>
      <div class="flex-fill"></div>
      <div>
        <app-data-table-nestable-actions
          *ngFor="let button of configuration.additionalActions"
          [button]="button"
          [rows]="selectedRows"
        >
        </app-data-table-nestable-actions>
      </div>
    </div>
  </div>
  <div
    class="data-table-content flex-fill overflow-auto collapsable"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="configuration.scrollBottom?.()"
  >
    <table
      class="table table-hover mb-0"
      [ngClass]="configuration.tableClasses"
    >
      <thead>
        <tr [ngClass]="configuration.tableHeadClasses">
          <th
            *ngIf="configuration.selectionMode === DataTableSelectionType.MULTI"
            [ngClass]="configuration.tableCellClasses"
            class="border-0"
          >
            <nb-checkbox
              (checkedChange)="toggleAllCheckboxes($event)"
              [checked]="checkAllStatus"
            ></nb-checkbox>
          </th>
          <ng-container *ngFor="let column of configuration.columns">
            <th
              *appRequirePermission="column.permission"
              [ngClass]="configuration.tableCellClasses"
              class="border-0"
            >
              <div class="text-nowrap small fw-bold" [attr.data-cy]="column.label + '-label'">
                {{ (column.label | translate) ?? ' ' }}
              </div>
              <div class="filter mt-1" *ngIf="column.filter">
                <!-- Filter type TEXT-->
                <input
                  nbInput
                  type="text"
                  class="w-100"
                  *ngIf="column.filter.type === DataTableFilterType.TEXT"
                  [ngModel]="column.filter.subject.value"
                  (ngModelChange)="column.filter.subject.next($event)"
                  [placeholder]="column.filter.placeholder ?? ''"
                  [attr.data-cy]="column.label + '-input'"
                />
                <!-- Filter type SELECT-->
                <nb-select
                  class="w-100"
                  *ngIf="column.filter.type === DataTableFilterType.SELECT"
                  [ngModel]="column.filter.subject.value"
                  (ngModelChange)="column.filter.subject.next($event)"
                  [placeholder]="column.filter.placeholder ?? ''"
                  size="medium"
                  [attr.data-cy]="column.label + '-select'"
                >
                  <nb-option [value]="null">All</nb-option>
                  <nb-option
                    *ngFor="let option of column.filter.options | async"
                    [value]="
                      column.filter.idProperty
                        ? option[column.filter.idProperty]
                        : option
                    "
                    >{{
                      (column.filter.nameProperty
                        ? option[column.filter.nameProperty]
                        : option
                      ) | translate
                    }}</nb-option
                  >
                </nb-select>
                <!-- Filter type SELECT-->
                <nb-select
                  class="w-100"
                  multiple
                  *ngIf="
                    column.filter.type === DataTableFilterType.SELECTMULTIPLE
                  "
                  [ngModel]="column.filter.subject.value"
                  (ngModelChange)="column.filter.subject.next($event)"
                  [placeholder]="column.filter.placeholder ?? ''"
                  size="medium"
                  [attr.data-cy]="column.label + '-select'"
                >
                  <nb-option
                    (click)="column.filter.subject.next([])"
                    class="clear_all"
                  >
                    <nb-icon icon="close"></nb-icon>
                    {{ 'common.clear_all' | translate }}
                  </nb-option>
                  <nb-option
                    *ngFor="let option of column.filter.options | async"
                    [value]="
                      column.filter.idProperty
                        ? option[column.filter.idProperty]
                        : option
                    "
                    >{{
                      (column.filter.nameProperty
                        ? option[column.filter.nameProperty]
                        : option
                      ) | translate
                    }}</nb-option
                  >
                </nb-select>
                <!-- Filter type DATE-->
                <ng-container
                  *ngIf="column.filter.type === DataTableFilterType.DATE"
                >
                  <input
                    class="w-100"
                    nbInput
                    [nbDatepicker]="datePicker"
                    [ngModel]="column.filter.subject.value"
                    (ngModelChange)="column.filter.subject.next($event)"
                    [placeholder]="column.filter.placeholder ?? ''"
                    [attr.data-cy]="column.label + '-input'" />
                  <nb-datepicker [format]="DATEPICKER_DATE_FORMAT" #datePicker>
                  </nb-datepicker
                ></ng-container>
                <!-- Filter type DATERANGE-->
                <ng-container
                  *ngIf="column.filter.type === DataTableFilterType.DATERANGE"
                >
                  <input
                    class="w-100"
                    nbInput
                    [nbDatepicker]="dateRangePicker"
                    [ngModel]="column.filter.subject.value"
                    (ngModelChange)="column.filter.subject.next($event)"
                    [placeholder]="column.filter.placeholder ?? ''"
                    [attr.data-cy]="column.label + '-input'" />
                  <nb-rangepicker
                    [format]="DATEPICKER_DATE_FORMAT"
                    #dateRangePicker
                  >
                  </nb-rangepicker
                ></ng-container>
                <ng-container *ngIf="column.filter.template">
                  <ng-template *ngTemplateOutlet="column.filter.template">
                  </ng-template>
                </ng-container>
              </div>
            </th>
          </ng-container>
          <th
            *ngIf="
              configuration.actionsLocation === DataTableActionsLocation.RIGHT
            "
            [ngClass]="
              configuration.tableCellClasses +
              ' ' +
              configuration.actionsClasses
            "
            class="border-0 actions"
          >
            <div class="small fw-bold">Actions</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="rows$ | async as rows">
          <ng-container *ngIf="rows.length > 0; else noData">
            <ng-container *ngFor="let row of rows; index as rowIndex"
              ><tr
                (dblclick)="openDetails($event, row)"
                (click)="rowClick($event, rowIndex)"
                [class.table-active]="checkboxes[rowIndex]"
              >
                <td
                  *ngIf="
                    configuration.selectionMode === DataTableSelectionType.MULTI
                  "
                  [ngClass]="configuration.tableCellClasses"
                  class="align-middle"
                >
                  <nb-checkbox
                    (click)="rowClick($event, rowIndex)"
                    [(ngModel)]="checkboxes[rowIndex]"
                  ></nb-checkbox>
                </td>
                <ng-container
                  *ngFor="
                    let column of configuration.columns;
                    index as columnIndex
                  "
                >
                  <td
                    *appRequirePermission="column.permission"
                    class="caption"
                    [ngClass]="configuration.tableCellClasses"
                    [style]="column.customStyle"
                  >
                    <!--          Check if displaying property or template-->
                    <ng-container
                      *ngIf="
                        column.property || column.invoke;
                        else propertyTemplate
                      "
                    >
                      <!--            Check if property exists on object-->
                      <ng-container
                        *ngIf="column.property && get(row, column.property)"
                      >
                        <!--              Check for property type, so we choose correct display template-->
                        <ng-container
                          *ngIf="
                            column.type === DataTableDataType.TEXT ||
                            !column.type
                          "
                        >
                          {{ get(row, column.property) }}
                        </ng-container>
                        <ng-container
                          *ngIf="column.type === DataTableDataType.DATETIME"
                        >
                          {{
                            moment(get(row, column.property)).format(
                              MOMENT_DATE_TIME_FORMAT
                            )
                          }}
                        </ng-container>
                        <ng-container
                          *ngIf="column.type === DataTableDataType.DATE"
                        >
                          {{
                            moment(get(row, column.property)).format(
                              MOMENT_DATE_FORMAT
                            )
                          }}
                        </ng-container>
                      </ng-container>
                      <!--            Invoke method instead of reading property (for reading properties and similar methods) -->
                      <ng-container
                        *ngIf="
                          column.invoke &&
                          get(column.invoke.objectReference, column.invoke.name)
                        "
                      >
                        {{
                          invoke(
                            column.invoke.objectReference,
                            column.invoke.name,
                            column.invoke.args,
                            row
                          )
                        }}
                      </ng-container>
                    </ng-container>
                    <!--          If "property" is not set, we display propertyTemplate-->
                    <ng-template #propertyTemplate>
                      <ng-template
                        *ngTemplateOutlet="
                          column.propertyTemplate;
                          context: { row: row, index: rowIndex }
                        "
                      >
                      </ng-template>
                    </ng-template>
                    <div
                      class="rowActions text-nowrap mt-1"
                      *ngIf="
                        columnIndex === 0 &&
                        (!configuration.actionsLocation ||
                          configuration.actionsLocation ===
                            DataTableActionsLocation.BOTTOM)
                      "
                    >
                      <app-data-table-nestable-actions
                        *ngFor="let button of configuration.singleActions"
                        [button]="button"
                        type="single"
                        [rows]="[row]"
                      >
                      </app-data-table-nestable-actions>
                    </div>
                  </td>
                </ng-container>
                <td
                  *ngIf="
                    configuration.actionsLocation ===
                    DataTableActionsLocation.RIGHT
                  "
                  [ngClass]="
                    configuration.tableCellClasses +
                    ' ' +
                    configuration.actionsClasses
                  "
                  class="actions"
                >
                  <app-data-table-nestable-actions
                    *ngFor="let button of configuration.singleActions"
                    [button]="button"
                    type="single"
                    [rows]="[row]"
                  >
                  </app-data-table-nestable-actions>
                </td>
              </tr>
              <tr *ngIf="configuration.extraRow" class="extraRow">
                <td class="p-0" [colSpan]="getTableColSpan()">
                  <ng-template
                    *ngTemplateOutlet="
                      configuration.extraRow;
                      context: { row: row, index: rowIndex }
                    "
                  >
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr *ngIf="hasFooterCells">
          <td
            *ngIf="configuration.selectionMode === DataTableSelectionType.MULTI"
          ></td>
          <td
            *ngFor="let column of configuration.columns; index as columnIndex"
            class="caption"
            [ngClass]="configuration.tableCellClasses"
            [style]="column.customStyle"
          >
            <ng-template *ngTemplateOutlet="column.footerTemplate">
            </ng-template>
          </td>
          <td
            *ngIf="
              configuration.actionsLocation === DataTableActionsLocation.RIGHT
            "
            [ngClass]="
              configuration.tableCellClasses +
              ' ' +
              configuration.actionsClasses
            "
            class="actions"
          >
            <app-data-table-nestable-actions
              *ngFor="let button of configuration.footerActions"
              [button]="button"
              [rows]="[]"
            >
            </app-data-table-nestable-actions>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    class="data-table-footer pt-2 collapsable"
    *ngIf="
      !configuration.hidePagination && configuration.pagination$
        | async as pagination
    "
  >
    <ng-container *ngIf="pagination !== null">
      <div
        class="pagination-container d-flex justify-content-between align-items-center"
        *ngIf="pagination.totalCount > 0"
      >
        <ng-container
          *ngLet="pagination.currentPageSettings.pageIndex as pageIndex"
        >
          <ng-container *ngLet="pagination.collectionSize as pageSize">
            <div class="d-flex align-items-center">
              <div>
                <ng-container *ngIf="configuration.dynamicPageSize">
                  <span class="small fw-bold me-1"> Items per page </span>
                  <nb-select
                    class="page-size-selection me-3"
                    [selected]="
                      pagination.currentPageSettings.sortSettings.pageSize + ''
                    "
                    (selectedChange)="pageSizeChange.emit($event)"
                  >
                    <nb-option value="30">30</nb-option>
                    <nb-option value="60">60</nb-option>
                    <nb-option value="100">100</nb-option>
                  </nb-select>
                </ng-container>
                <ng-container *ngLet="pageIndex * pageSize + 1 as from">
                  <ng-container *ngLet="(pageIndex + 1) * pageSize as to">
                    <span
                      class="small fw-bold"
                      translate="common.table.pagination"
                      [translateParams]="{
                        from: from,
                        to:
                          to > pagination.totalCount
                            ? pagination.totalCount
                            : to,
                        totalCount: pagination.totalCount
                      }"
                    ></span>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <ng-container *ngLet="pageIndex + 1 as page">
              <ng-container *ngLet="pagination.pagesSize as pagesLength">
                <ngb-pagination
                  size="sm"
                  [page]="page"
                  [pageSize]="pageSize"
                  [maxSize]="1"
                  [rotate]="true"
                  [collectionSize]="
                    pagesLength * pageSize < pagination.totalCount
                      ? (pagesLength + 1) * pageSize
                      : pagination.totalCount
                  "
                  [boundaryLinks]="true"
                  [directionLinks]="true"
                  (pageChange)="onPageChange($event)"
                >
                </ngb-pagination>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-template #noData>
    <tr>
      <td [attr.colspan]="getTableColSpan()">
        <p class="text-center caption m-0" translate="common.table.noData"></p>
      </td>
    </tr>
  </ng-template>
</div>
