import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getList',
  standalone: true
})
export class GetListPipe implements PipeTransform {

  transform(values: string[], list: any[], separator = "<br>", match = 'id', display = 'name'): any {
    return list.filter((item) => values.includes(item[match])).map((item) => item[display]).join(separator)
  }

}
