import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appRequirePermission]',
})
export class RequirePermissionDirective implements OnInit {
  @Input() appRequirePermission: string | string[];

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    if (!this.appRequirePermission) {
      this.view.createEmbeddedView(this.template);
      return;
    }

    this.authService
      .hasRequiredPermission$(this.appRequirePermission)
      .pipe(untilDestroyed(this))
      .subscribe((hasPermission) => {
        this.view.clear();

        if (hasPermission) {
          this.view.createEmbeddedView(this.template);
        }
      });
  }
}
