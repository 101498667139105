import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbMomentDateModule } from '@nebular/moment';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import { DEFAULT_DIALOG_OPTIONS } from '../../app.constants';
import { SharedModule } from '../@shared/shared.module';
import { HeaderComponent, SearchInputComponent } from './components';
import { SearchResultsDialogComponent } from './components/search-results/search-results-dialog.component';
import { OneColumnLayoutComponent } from './layouts';
import { ColLeftComponent } from './layouts/two-column-page/col-left/col-left.component';
import { ColRightComponent } from './layouts/two-column-page/col-right/col-right.component';
import { TwoColumnPageLayoutComponent } from './layouts/two-column-page/two-column-page-layout.component';
import {
  CapitalizePipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
} from './pipes';
import { WindowModeBlockScrollService } from './services/window-mode-block-scroll.service';
import { DEFAULT_THEME } from './styles/theme.default';

const NB_MODULES = [
  NbLayoutModule,
  NbCardModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbDatepickerModule,
  NbMomentDateModule,
];
const COMPONENTS = [
  HeaderComponent,
  OneColumnLayoutComponent,
  SearchResultsDialogComponent,
  TwoColumnPageLayoutComponent,
  ColLeftComponent,
  ColRightComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [
    CommonModule,
    ...NB_MODULES,
    NbDialogModule.forChild(DEFAULT_DIALOG_OPTIONS),
    SharedModule,
    SearchInputComponent,
  ],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME]
        ).providers,
        WindowModeBlockScrollService,
        ...NbDatepickerModule.forRoot().providers,
      ],
    };
  }
}
