import { Injectable } from '@angular/core';
import { Logger } from '@core/services/logger.service';
import { Treatment } from '../../treatment.types';
import {
  Kit,
  Pagination,
  SortDirection,
  StatePageSettings,
} from '@core/core.types';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import {
  TreatmentFilters,
  TreatmentsService,
} from '@core/services/treatments.service.new';
import { switchMap, tap } from 'rxjs/operators';
import {
  LoadTreatmentsStatisticsAction,
  MarkTreatmentsAsExported,
  SetTreatmentsByIds,
  TREATMENTS_TYPE_NAME,
} from '@treatments/store/treatments/treatments.actions';

// Actual treatment objects are located in state.treatments.
// treatmentsByPiiId, treatmentsByDocumentId and pages.items hold only treatment IDs
// statistics holds only counts, key is custom -> make sure you use same key for @Action and selector.
// currentPageSettings is used for pagination and making sure that filters didnt change as you call next page.
export interface TreatmentsStateModel {
  treatments: { [id: string]: Treatment };
  treatmentsByPiiId: { [id: string]: string };
  treatmentsByDocumentId: { [id: string]: string };
  treatmentsSearchResults: string[];
  statistics: { [key: string]: number };
  pages?: {
    [key: string]: {
      items: string[];
      continuationToken: string;
    };
  };
  currentPageSettings: StatePageSettings<TreatmentFilters>;
  totalCount?: number;
}

const TREATMENTS_DEFAULT_STATE: TreatmentsStateModel = {
  treatments: {},
  treatmentsSearchResults: [],
  statistics: {},
  treatmentsByPiiId: {},
  treatmentsByDocumentId: {},
  pages: {},
  currentPageSettings: {
    pageIndex: 0,
    sortSettings: {
      orderBy: 'DateOfVenipuncture',
      sortDirection: SortDirection.desc,
      pageSize: 30,
    },
  },
  totalCount: 0,
};

@State<TreatmentsStateModel>({
  name: TREATMENTS_TYPE_NAME,
  defaults: { ...TREATMENTS_DEFAULT_STATE },
})
@Injectable()
export class TreatmentsState {
  readonly log = new Logger(this.constructor.name);

  constructor(private treatmentService: TreatmentsService) {}

  static getStatistics(type: string) {
    return createSelector([TreatmentsState], (state) => state.statistics[type]);
  }

  @Selector()
  static getTreatmentById({ treatments }: TreatmentsStateModel) {
    return (treatmentId: string) => treatments[treatmentId];
  }

  @Selector()
  static getTreatmentByIds({ treatments }: TreatmentsStateModel) {
    return (treatmentIds: string[]) => treatmentIds.map((id) => treatments[id]);
  }

  @Selector()
  static getTreatmentsByDocumentIds({
    treatments,
    treatmentsByDocumentId,
  }: TreatmentsStateModel) {
    return (documentIds: string[]) =>
      documentIds
        .map((documentId) => treatments[treatmentsByDocumentId[documentId]])
        .filter((element) => !!element);
  }

  @Selector()
  static getKitTreatment({
    treatmentsByPiiId,
    treatments,
  }: TreatmentsStateModel) {
    return (kit: Kit) =>
      treatments[treatmentsByPiiId[kit?.productInventoryItemIds[0]]] ?? null;
  }

  @Selector()
  static getTreatmentsSearchResults({
    treatments,
    treatmentsSearchResults,
  }: TreatmentsStateModel) {
    return treatmentsSearchResults.map(
      (treatmentId) => treatments[treatmentId]
    );
  }

  @Selector()
  static getKitsTreatments({
    treatmentsByPiiId,
    treatments,
  }: TreatmentsStateModel) {
    return (kits: Kit[]) =>
      kits
        .map(
          (kit) =>
            treatments[treatmentsByPiiId[kit?.productInventoryItemIds[0]]]
        )
        .filter((element) => !!element);
  }

  @Selector()
  static getTreatmentsByPiiIds({
    treatments,
    treatmentsByPiiId,
  }: TreatmentsStateModel) {
    return (piiIds: string[]) =>
      piiIds
        .map((piiId) => treatments[treatmentsByPiiId[piiId]])
        .filter((element) => !!element);
  }

  @Selector()
  static getCurrentPage(state: TreatmentsStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;

    if (
      Object.keys(pages).length === 0 ||
      pageIndex > Object.keys(pages).length ||
      pageIndex < 0
    ) {
      return [];
    }
    return state.pages[pageIndex].items.slice();
  }

  @Selector()
  static getPagination({
    currentPageSettings,
    totalCount,
    pages,
  }: TreatmentsStateModel): Pagination<TreatmentFilters> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: Object.keys(pages).length,
      collectionSize: currentPageSettings.sortSettings.pageSize,
    };
  }

  @Action(LoadTreatmentsStatisticsAction)
  loadStatistics(
    { patchState, getState }: StateContext<TreatmentsStateModel>,
    { filters, type }: LoadTreatmentsStatisticsAction
  ) {
    return this.treatmentService.getTreatmentsStatistics(filters).pipe(
      tap((result) => {
        const state = getState();
        const statistics = { ...state.statistics };
        statistics[type] = result;
        patchState({ statistics });
      })
    );
  }

  @Action(MarkTreatmentsAsExported)
  markTreatmentsAsExported(
    { getState, dispatch }: StateContext<TreatmentsStateModel>,
    { ids }: MarkTreatmentsAsExported
  ) {
    return this.treatmentService.markTreatmentAsExported(ids).pipe(
      switchMap(() => {
        const treatments = getState().treatments;
        return dispatch(
          new SetTreatmentsByIds(
            ids.map((id) => ({
              ...treatments[id],
              exported: {
                by: 'me',
                timestamp: new Date().toISOString(),
              },
            }))
          )
        );
      })
    );
  }
}
