/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { EntityBaseService } from '@core/services/entityBase.service';
import {
  PatientForm,
  PatientFormInvite,
} from '@app/modules/patient-forms/patient-forms.types';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PatientFormsService extends EntityBaseService<PatientForm> {
  private readonly BASE_URL = `${environment.apiUrl}/nipts`;

  private readonly ROUTES = {
    listPatientForms: 'ListNiptPatientForms',
    provisionPatientForm: 'ProvisionNiptPatientForm',
  };

  private readonly API_CONSTANTS = {
    directory: 'NiptPatientFormDirectory',
    manager: 'NiptPatientFormManager',
    patientForm: 'NiptPatientForm',
  };

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public completePatientForm(formId) {
    return this.httpClient.patch(
      `${this.BASE_URL}/patient-forms/${formId}/status/complete`,
      {}
    );
  }

  public provisionPatientForm(formData: PatientFormInvite) {
    return this.httpClient.post(`${this.BASE_URL}/patient-forms`, formData);
  }

  public provisionPatientFormUpdate(formData: PatientFormInvite) {
    return this.httpClient.put(
      `${this.BASE_URL}/patient-forms/${formData.id}`,
      formData
    );
  }

  protected getCreateEntityUrl(newEntityId: string): string {
    return '';
  }

  protected getDeleteEntityUrl(entityId: string): string {
    return '';
  }

  protected getFetchEntityUrl(entityId: string): string {
    return '';
  }

  protected getListUrl(): string {
    return `${this.BASE_URL}/patient-forms`;
  }

  protected getUpdateEntityUrl(entityId: string): string {
    return '';
  }

  protected setCreateEntityBody(
    entity: PatientForm,
    body: Partial<PatientForm>
  ) {}

  protected setUpdateEntityBody(
    entity: PatientForm,
    body: Partial<PatientForm>
  ) {}

  protected setUrlBody(run: PatientForm) {}
}
