import { Injectable } from '@angular/core';
import { BundleConfigurationService } from '@core/services/bundle-configuration.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BundleConfiguration } from '../../core.types';
import {
  BUNDLE_CONFIGURATION_STATE_NAME,
  LoadBundleConfigurationByIds,
} from './bundle-configuration.actions';

export interface BundleConfigurationsStateModel {
  bundleConfigurations: {
    [bundleConfigurationId: string]: BundleConfiguration;
  };
}

@State<BundleConfigurationsStateModel>({
  name: BUNDLE_CONFIGURATION_STATE_NAME,
  defaults: {
    bundleConfigurations: {},
  },
})
@Injectable()
export class BundleConfigurationsState {
  constructor(private bundleConfigurationService: BundleConfigurationService) {}

  @Selector()
  static getBundleConfigurationById(state: BundleConfigurationsStateModel) {
    return (bundleConfigurationId: string) =>
      state.bundleConfigurations[bundleConfigurationId];
  }

  @Selector()
  static getBundleConfigurationByIds(state: BundleConfigurationsStateModel) {
    return (bundleConfigurationIds: string[]) => {
      if (!bundleConfigurationIds || !bundleConfigurationIds.length) {
        return [];
      }
      return bundleConfigurationIds.map(
        (bundleConfigurationId) =>
          state.bundleConfigurations[bundleConfigurationId]
      );
    };
  }

  @Action(LoadBundleConfigurationByIds)
  loadBundleConfigurationByIds(
    { patchState, getState }: StateContext<BundleConfigurationsStateModel>,
    { bundleConfigurationIds }: LoadBundleConfigurationByIds
  ) {
    const { bundleConfigurations } = getState();

    return forkJoin(
      bundleConfigurationIds
        .filter((id) => !bundleConfigurations[id])
        .map((id) =>
          this.bundleConfigurationService.getBundleConfigurationById(id)
        )
    ).pipe(
      tap((bundleConfigurations: BundleConfiguration[]) =>
        patchState({
          bundleConfigurations: {
            ...getState().bundleConfigurations,
            ...bundleConfigurations.reduce((acc, bc) => {
              acc[bc.id] = bc;
              return acc;
            }, {}),
          },
        })
      )
    );
  }
}
