import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Sample } from '@app/modules/service-data/service-data.types';
import {
  ChangeSampleKitAction,
  GetSampleAction,
} from '@app/modules/service-data/store/sample.actions';
import { map, switchMap } from 'rxjs/operators';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  NbButtonModule,
  NbCardModule,
  NbDialogRef,
  NbIconModule,
  NbInputModule,
  NbOptionModule,
  NbSelectModule,
} from '@nebular/theme';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Kit } from '@core/core.types';
import { LoadPartnerFreeKitsAction } from '@core/store/kits/kits.actions';
import { KitsState } from '@core/store/kits/kits.state';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import { ConfirmCloseReason } from '@shared/shared.types';

@Component({
  selector: 'app-sample-kit-change-dialog',
  standalone: true,
  imports: [
    JsonPipe,
    AsyncPipe,
    NbOptionModule,
    NbSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
  ],
  templateUrl: './sample-kit-change-dialog.component.html',
  styleUrl: './sample-kit-change-dialog.component.scss',
})
export class SampleKitChangeDialogComponent implements OnInit {
  @Input() sampleId: string;

  sample$: Observable<Sample>;
  protected kits$: Observable<Kit[]>;
  loading = false;

  kitFormControl = new FormControl(null, Validators.required);

  constructor(
    private store: Store,
    private dialogRef: NbDialogRef<SampleKitChangeDialogComponent>,
    private confirmService: ConfirmService
  ) {}

  ngOnInit() {
    this.sample$ = this.store
      .dispatch(new GetSampleAction(this.sampleId))
      .pipe(
        map(() =>
          this.store.selectSnapshot(SampleState.getSampleById)(this.sampleId)
        )
      );

    this.kits$ = this.sample$.pipe(
      switchMap((sample) =>
        this.store
          .dispatch(new LoadPartnerFreeKitsAction(sample.partnerId))
          .pipe(map(() => sample.partnerId))
      ),
      switchMap((partnerId: string) =>
        this.store
          .select(KitsState.getFreePartnerKits)
          .pipe(map((getFreePartnerKits) => getFreePartnerKits(partnerId)))
      )
    );
  }

  submit() {
    const oldSample = this.store.selectSnapshot(SampleState.getSampleById)(
      this.sampleId
    );
    this.confirmService
      .confirm({
        title: 'Replacing kit',
        message: `Are you sure you want to switch kit ${oldSample.kitId} with ${this.kitFormControl.value}?`,
      })
      .subscribe((result) => {
        if (result.closeReason === ConfirmCloseReason.Yes) {
          this.loading = true;
          this.store
            .dispatch(
              new ChangeSampleKitAction(
                this.sampleId,
                this.kitFormControl.value
              )
            )
            .subscribe(() => {
              this.dialogRef.close();
            });
        }
      });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
